import { Stack } from '@mui/material'
import styled from 'styled-components'

export const KSG_TABLE_CELL_HEIGHT = 60

export const TableWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

export const EmptyPageWrapper = styled(Stack)`
  min-height: 670px;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.bg.white};

  position: absolute;
  left: 0;
  right: 13px;
  bottom: 13px;
`
