import { AnalyticsFilterDrawerProps } from './AnalyticsFilterDrawer.types'
import { AnalyticsFilterForm } from './components/AnalyticsFilterForm'
import { Drawer, Stack, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const AnalyticsFilterDrawer: FC<AnalyticsFilterDrawerProps> = ({ isOpen, onClose }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer anchor='left' open={isOpen} onClose={() => onClose(dirty)}>
      <Stack width={'464px'} height={'100%'}>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Фильтрация
          </Typography>
        </DrawerTopBar>

        <Divider />

        <AnalyticsFilterForm onFormChange={onFormChange} onClose={onClose} />
      </Stack>
    </Drawer>
  )
}
