import { EditAndAddDrawer } from './ObjectDrawers/EditAndAddDrawer'
import { ImportDrawer } from './ObjectDrawers/ImportDrawer'
import { ObjectsTable } from './ObjectsTable'
import { Stack } from '@mui/material'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { objectsApi, useClearObjectsMutation, useGetObjectsQuery } from 'api/objects'
import { ObjectFull } from 'api/objects/api.types'
import { useGetProjectDashboardQuery } from 'api/projects'
import { Table } from 'components/Table'
import { CustomColumnDef } from 'components/Table/Table.types'
import { TABLE_CELL_HEIGHT } from 'components/Table/components/TableCell/TableCell.styles'
import { TableManagment } from 'components/TableManagment'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { DrawerData } from 'pages/Administration/References/ReferenceContent'
import { DocumentsTable } from 'pages/Documents/components/DocumentsTable'
import { FC, useCallback, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const Objects: FC = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [drawerData, setDrawerData] = useState<DrawerData>({
    isEditAndAddDrawerOpen: false,
    isImportDrawerOpen: false,
  })
  const [clearObjects, clearObjectsResponse] = useClearObjectsMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { data: objectData, isLoading } = useGetObjectsQuery({
    projectId,
    limit: 99999,
    offset: 0,
  })

  const navigate = useNavigate()

  const { data: projectData, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )
  const { data: dashboardProject } = projectData || {}
  const { project } = dashboardProject || {}

  const { onProjectBreadClick, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/objects/{projectId}',
  })

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Объекты' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  const onReturnClick = useCallback(() => {
    navigate(`/project/${projectId}`)
  }, [projectId])

  const onClearObjects = useCallback(() => {
    clearObjects({ projectId })
  }, [])

  const onDrawerOpen = useCallback((drawerType: keyof typeof drawerData) => {
    setDrawerData((prev) => ({ ...prev, [drawerType]: true }))
  }, [])

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setDrawerData({
        isEditAndAddDrawerOpen: false,
        isImportDrawerOpen: false,
      })
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback((drawerType: keyof typeof drawerData, dirty: boolean) => {
    dirty ? openConfirm() : setDrawerData((prev) => ({ ...prev, [drawerType]: false }))
  }, [])

  useMutationHandlers(clearObjectsResponse, () => {
    enqueueSnackbar('Объекты успешно очищены.', { variant: 'success' })
  })

  return (
    <Stack flex={1} maxWidth={'100%'}>
      <Stack pr={2.5}>
        <TableManagment
          onReturnClick={onReturnClick}
          onClearClick={onClearObjects}
          onImportClick={() => onDrawerOpen('isImportDrawerOpen')}
          onAddClick={() => onDrawerOpen('isEditAndAddDrawerOpen')}
          buttonManagmentTitle='Управление'
        />
      </Stack>

      <ObjectsTable />

      <EditAndAddDrawer
        open={drawerData.isEditAndAddDrawerOpen}
        onClose={(dirty, immediately) => onDrawerClose('isEditAndAddDrawerOpen', dirty)}
        target={null}
      />

      <ImportDrawer isOpen={drawerData.isImportDrawerOpen} onClose={() => onDrawerClose('isImportDrawerOpen', false)} />

      {ProjectsMenu}

      <ConfirmDialog />
    </Stack>
  )
}
