import * as yup from 'yup'

export const defectValidation = yup.object({
  code: yup.string().trim().required('common:errors.required'),
  title: yup.string().trim().required('common:errors.required'),
  type: yup.string().trim().required('common:errors.required'),
  assignmentType: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .nullable(),
  measures: yup.string().trim(),
  basis: yup.string().trim(),
  note: yup.string().trim(),
})
