import { uiContext } from '../../contexts/ui'
import { AnalyticsCard } from './components/AnalyticsCard'
import { CalendarCard } from './components/CalendarCard'
import { MainCard } from './components/MainCard'
import { StatisticsCard } from './components/StatisticsCard'
import { Stack } from '@mui/material'
import { useGetProjectDashboardQuery } from 'api/projects'
import { Progress } from 'components/Progress'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { ProjectDashboardWrapper } from 'pages/ProjectDashboard/ProjectDashboard.styles'
import React, { FC, useContext, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

/**
 * TODO: из за ошибки на сервере в MainCard, StatisticsCard и AnalyticsCard сейчас используется замещающий текст
 *
 * много некрасивых выходов, при необходимости (если будет желайние) после фиксов на стороне бэка, можно это все убрать
 * сам замещающий текст лежит в индексном фале src/pages/ProjectDashboard/index.ts
 */
export const ProjectDashboard: FC = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const cUI = useContext(uiContext)

  const { data: projectData, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )
  const { data: dashboardProject } = projectData || {}
  const { project } = dashboardProject || {}

  const { onProjectBreadClick, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}',
  })
  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])
  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
    ],
    [isMenuOpen, project],
    isFetching,
  )
  return (
    <Stack flex={1} pt={2.5}>
      {isFetching ? (
        <Progress />
      ) : (
        <ProjectDashboardWrapper overflow={'auto'} direction={'row'} gap={'20px'}>
          <Stack gap={'20px'} width={'68.6%'} height={'fit-content'}>
            <MainCard dashboardProject={dashboardProject!} />
            <StatisticsCard dashboardProject={dashboardProject!} />
          </Stack>
          <Stack gap={'20px'} width={'30%'}>
            <CalendarCard />
            <AnalyticsCard dashboardProject={dashboardProject!} />
          </Stack>
        </ProjectDashboardWrapper>
      )}
      {ProjectsMenu}
    </Stack>
  )
}
