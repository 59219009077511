import { RowItemProps } from './RowItem.types'
import { Box, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'
import { theme } from 'styles/theme'

export const RowItem: FC<RowItemProps> = ({ title, rowStrings, emptyDataText = 'Нет данных' }) => {
  return (
    <Stack width='100%'>
      <Box>
        <Typography
          variant='subtitle2'
          fontWeight={400}
          fontSize={12}
          letterSpacing={0}
          textAlign='left'
          color={theme.palette.secondary.gray}
        >
          {title}:
        </Typography>
      </Box>
      <Stack
        maxHeight={3 * 20} //20-lineHeight
        component={ScrollableContainer}
      >
        {rowStrings.some((string) => string !== '') ? (
          rowStrings.map(
            (row) =>
              row && (
                <Box>
                  <Typography variant='body2' textAlign='left' color={theme.palette.text.dark}>
                    {row}
                  </Typography>
                </Box>
              ),
          )
        ) : (
          <Box height={20}>
            <Typography variant='body2' textAlign='left' color={theme.palette.disabled}>
              {emptyDataText}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
