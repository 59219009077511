import { FormControlLabel, Typography } from '@mui/material'
import styled from 'styled-components'

export const LabelWrapper = styled(FormControlLabel)`
  // min-height: 52px;

  &:hover {
    background-color: rgba(0, 68, 180, 0.06);
    border-radius: 4px;
  }
`

export const TypeName = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
