import { Stack } from '@mui/material'
import styled from 'styled-components'

interface ScrollableContainerProps {
  hideScroll?: boolean
  isContainer?: boolean
}

export const ScrollableContainer = styled(Stack)<ScrollableContainerProps>`
  flex: 1;
  padding-right: ${(props) => props.isContainer && (props.hideScroll ? '20px' : '7px')};
  overflow: auto scroll; // maybe auto
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: ${(props) => props.hideScroll && 'none'};
  }

  &:hover {
    padding-right: ${(props) => props.isContainer && props.hideScroll && '7px'};

    &::-webkit-scrollbar {
      display: block;
    }
  }

  @supports (-moz-appearance: none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  }
`
