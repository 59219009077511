import { Icon } from '@mui/material'
import styled from 'styled-components'

export const SearchWrapper = styled.div`
  padding: 20px;
`

export const StyledSearch = styled.input`
  width: 100%;
  padding: 8px 40px 8px 8px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  outline: none;
  border: 2px solid rgba(120, 144, 178, 0.1);
  border-radius: 8px;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;

  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    background-color: red;
  }
`

export const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
  fill: ${(props) => props.theme.palette.secondary.dark};
`
