import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const validationPrescription = yup.object({
  status: yup.string().trim().required('common:errors.required'),
  number: yup.string().trim().required('common:errors.required'),
  dateStart: yup.date().transform(formatDateInInput).max(today).required('common:errors.required'),
  inspectionType: yup.object().shape({
    id: yup.string().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),
  type: yup.object().shape({
    id: yup.string().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),
  comment: yup.string().trim(),

  senderCompany: yup.object().shape({
    id: yup.mixed().required('common:errors.required'),
    value: yup.mixed().required('common:errors.required'),
  }),

  senderUser: yup.object().shape({
    id: yup.mixed().required('common:errors.required'),
    value: yup.mixed().required('common:errors.required'),
  }),

  contractor: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),

  receiver: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),

  executionControl: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),

  dateEnd: yup.date().transform(formatDateInInput).min(yup.ref('dateStart')).required('common:errors.required'),
  dateDone: yup
    .date()
    .transform(formatDateInInput)
    .min(yup.ref('dateStart'))
    .max(today)
    .when('status', {
      is: 'COMPLETE',
      then: yup
        .date()
        .transform(formatDateInInput)
        .min(yup.ref('dateStart'))
        .max(today)
        .required('common:errors.required'),
      otherwise: yup.date().transform(formatDateInInput).min(yup.ref('dateStart')).max(today).nullable(),
    }),

  automaticDateEnd: yup.boolean(),
})
