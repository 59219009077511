import { ListItemIcon, ListItemText } from '@mui/material'
import { AdminNavBarItemProps, StyledListItem } from 'components/AdminNavBar'
import { FC } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

export const AdminNavBarItem: FC<AdminNavBarItemProps> = ({
  label,
  icon: Icon,
  link,
  selectedIcon: SelectedIcon,
  onClick,
}) => {
  const location = useLocation()

  // const selected = matchPath(location.pathname, link) !== null
  const selected = location.pathname.includes(link)

  const listItem = (
    <StyledListItem selected={selected}>
      <ListItemIcon>
        {selected && SelectedIcon ? <SelectedIcon height='24' width='24' /> : <Icon height='24' width='24' />}
      </ListItemIcon>
      <ListItemText primary={label} />
    </StyledListItem>
  )

  return (
    <Link to={link} onClick={(e) => onClick?.(e, link)}>
      {listItem}
    </Link>
  )
}
