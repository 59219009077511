import { MultiSelectHeader } from '../AnalyticsFilterForm.styles'
import { MultiSelectProps } from './MultiSelect.types'
import { MultiSelectTotal } from './components/MultiSelectTotal'
import { Checkbox, Divider, Stack, Typography } from '@mui/material'
import { GetDropdownData } from 'api/remarks/api.types'
import { useField } from 'formik'
import { FC, useMemo } from 'react'
import { MultiSelect as StyledMultiSelect } from 'styles/global/MultiSelect'
import { MultiSelectMenuItem as StyledMultiSelectMenuItem } from 'styles/global/MultiSelect'

export const MultiSelect: FC<MultiSelectProps> = ({ name, dropdownList }) => {
  const [{ onBlur: onFieldBlur, ...field }, { touched, error: fieldError }, { setValue, setTouched }] = useField({
    name: name,
  })

  const formValue = field.value || []

  const onClear = () => {
    setValue([])
  }

  const onSelectAll = () => {
    setValue(dropdownList.map((item) => item.id))
  }

  const filtredList = useMemo(() => {
    const selectedItems: GetDropdownData[] = []
    const unselectedItems: GetDropdownData[] = []

    dropdownList?.forEach((item) => {
      if (formValue.includes(item.id)) selectedItems.push(item)
      else unselectedItems.push(item)
    })

    return [selectedItems, unselectedItems]
  }, [formValue, dropdownList])

  const multiSelectItem = (item: GetDropdownData) => {
    return (
      <StyledMultiSelectMenuItem value={item.id} key={item.id}>
        <Checkbox checked={formValue.includes(item.id)} />
        <Typography variant='body1'>{item.text}</Typography>
      </StyledMultiSelectMenuItem>
    )
  }

  return (
    <StyledMultiSelect
      version='select'
      name={name}
      style={{ minWidth: '300px' }}
      helperText=''
      SelectProps={{
        multiple: true,
        displayEmpty: true,
        MenuProps: {
          PaperProps: {
            style: { maxWidth: 300 },
          },
        },
        renderValue: () =>
          !formValue.length ? (
            <Typography variant='body2'>Выберите из списка</Typography>
          ) : (
            formValue.length && <MultiSelectTotal selectedList={formValue} dropdownList={dropdownList} />
          ),
      }}
    >
      <MultiSelectHeader>
        <MultiSelectTotal selectedList={formValue} dropdownList={dropdownList} />
        {formValue.length ? (
          <Typography onClick={onClear} variant='subtitle2' fontSize={14}>
            Сбросить
          </Typography>
        ) : (
          <Typography onClick={onSelectAll} variant='subtitle2' fontSize={14}>
            Выбрать все
          </Typography>
        )}
      </MultiSelectHeader>

      {filtredList[0].map((item) => multiSelectItem(item))}

      {filtredList[0].length && filtredList[1].length && (
        <Stack padding={'4px 8px 4px 16px'}>
          <Divider />
        </Stack>
      )}

      {filtredList[1].map((item) => multiSelectItem(item))}
    </StyledMultiSelect>
  )
}
