import { Stack } from '@mui/material'
import styled from 'styled-components'

export const TabFormWrapper = styled(Stack)`
  flex-direction: row;
  flex: 1;
  gap: 80px;
  margin-right: 20px;
  padding: 30px;
  padding-right: 7px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 16px;
  overflow-y: scroll;
  flex-wrap: wrap;
`
