import { DashboardBasicCard } from '../MainCard'
import { ColorsCell } from './CalendarCard.types'
import styled from 'styled-components'

export const DashboardCalendarCard = styled(DashboardBasicCard)`
  flex: 1;
  grid-area: calendar;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;
  gap: 10px;
  /*TODO: При необходимости потом удалить, сейчас просто чтобы курсор не менялся при наведении*/
  cursor: default;
`
export const CalendarLink = styled.div`
  display: flex;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  align-items: center;
  justify-content: flex-start;
  svg {
    transform-origin: center center;
    rotate: 180deg;
    cursor: pointer;
  }
`
export const CalendarHead = styled.div`
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  height: 24px;
  padding: 6px 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 150%;
  letter-spacing: 0.15px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.dark};
  &:first-letter {
    text-transform: uppercase;
  }
`
export const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 36px;
  gap: 5.67px 0;
`

export const Cell = styled.div<ColorsCell>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4.33px 0;
  position: relative;

  span {
    color: ${({
      color,
      disable,
      theme: {
        palette: { legends, text },
      },
    }) => (color ? color : (disable && legends.disabled) || text.dark)};
    z-index: 1;
    font-weight: 200;
    font-size: 14px;
  }

  div {
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid ${(props) => props.color || 'transparent'};
    position: absolute;
    top: 4.33px;
    bottom: 4.33px;
    left: 0;
    right: 0;
    z-index: 0;

    &:after {
      content: '';
      background: ${(props) => props.color};
      filter: alpha(0.8);
      opacity: 0.2;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
`

Cell.defaultProps = {
  disable: false,
}

export const CellHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 500;
`
