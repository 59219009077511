import { Stack } from '@mui/material'
import styled from 'styled-components'

export const EliminationResultDrawerWrapper = styled(Stack)`
  width: 460px;
  height: 100%;
`

export const EliminationResultTabs = styled(Stack)`
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  border-radius: 8px;
  overflow: hidden;
`

interface EliminationResultTabItemProps {
  isSelected: boolean
}

export const EliminationResultTabItem = styled(Stack)<EliminationResultTabItemProps>`
  padding: 8px 0;
  width: 100%;
  align-items: center;

  border-right: 1px solid ${(props) => props.theme.palette.primary.light};

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${props.theme.palette.bg.shades};

    p {
      color: ${props.theme.palette.primary.main};
    }
  `}

  &:last-child {
    border-right: none;
  }

  &:hover {
    cursor: pointer;
  }
`
