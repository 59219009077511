import styled from 'styled-components'

export const StyledStatisticalTable = styled.table`
  // border-collapse: separate;
  border-spacing: 8px;
  width: 100%;
`

export const StatisticalTableCell = styled.td`
  position: relative;

  &:nth-child(3)::after,
  &:nth-child(6)::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: ${(props) => props.theme.palette.bg.darkGray};
    height: 53px;
    right: 6%;
    top: -27px;
  }

  &:nth-child(6)::after {
    right: 5%;
  }
`
