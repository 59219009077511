import { AccountForm } from '../../AccountForm'
import { ContactForm } from '../../ContactForm'
import { UserDataProps } from './UserData.types'
import { Stack } from '@mui/material'
import React, { useCallback } from 'react'
import {
  DEFAULT_DISPLAY_PASSWORD_VALUE,
  MAX_WIDTH_USER_MANAGEMENT_BLOCK,
  MIN_WIDTH_USER_MANAGEMENT_BLOCK,
} from 'utils/constants'
import { generateRandomString } from 'utils/generateRandomString'

export const UserData: React.FC<UserDataProps> = ({
  name = '',
  values,
  isEditUser,
  setFieldValue,
  initialValues,
  phoneConfirmed,
  emailConfirmed,
}) => {
  const setLoginGeneration = useCallback(() => {
    setFieldValue('login', generateRandomString({}))
  }, [setFieldValue])

  const setPasswordGeneration = useCallback(() => {
    setFieldValue(
      'password',
      generateRandomString({
        length: 8,
        lower: true,
        symbols: true,
        numbers: true,
        upper: true,
      }),
    )
  }, [setFieldValue])

  const resetPasswordValue = useCallback(() => {
    if (values?.password === DEFAULT_DISPLAY_PASSWORD_VALUE) {
      setFieldValue('password', '')
    }
  }, [setFieldValue, values])

  const setPasswordInitialValue = useCallback(() => {
    if (values.password === '') {
      setFieldValue('password', DEFAULT_DISPLAY_PASSWORD_VALUE)
    }
  }, [setFieldValue, values])

  const isShowConfirmPhone = values.phone === initialValues.phone && !!values.phone
  const isShowConfirmEmail = values.email === initialValues.email && !!values.email

  return (
    <Stack
      maxWidth={MAX_WIDTH_USER_MANAGEMENT_BLOCK}
      minWidth={MIN_WIDTH_USER_MANAGEMENT_BLOCK}
      spacing={2}
      flex={1}
      mb={2}
    >
      <AccountForm name={name} isEditUser={isEditUser} />
      <ContactForm
        isEditUser={isEditUser}
        isShowConfirmPhone={isShowConfirmPhone}
        isShowConfirmEmail={isShowConfirmEmail}
        isPhoneConfirmed={phoneConfirmed}
        isEmailConfirmed={emailConfirmed}
        handlePasswordGeneration={setPasswordGeneration}
        handleLoginGeneration={setLoginGeneration}
        onFocusPassword={resetPasswordValue}
        onBlurPassword={setPasswordInitialValue}
      />
    </Stack>
  )
}
