/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { compareAsc } from 'date-fns'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

export const validationWork = yup.object({
  assignmentType: yup
    .object({
      assignmentType: yup.number().nullable(),
      custom: yup.string().trim().nullable(),
    })
    .nullable(),
  documentCypher: yup.string().trim().nullable(),
  end: yup.date().transform(formatDateInInput).min(yup.ref('start')).nullable(),
  start: yup.date().transform(formatDateInInput).nullable(),
  capacity: yup.object({
    confirmed: yup.number().min(0).nullable(),
    total: yup.number().min(0).nullable(),
    unit: yup.string().trim().required('common:errors.required'),
  }),
  contractor: yup
    .object({
      contractor: yup.number().nullable(),
      custom: yup.string().trim().nullable(),
    })
    .nullable(),
  name: yup.string().trim().required('common:errors.required'),
  identifier: yup.string().trim().nullable(),
  object: yup
    .object({
      object: yup.number().nullable(),
      custom: yup.string().trim().nullable(),
    })
    .nullable(),
  qcInfo: yup.object({
    status: yup.string().trim().nullable(),
    comment: yup.string().trim().nullable(),
    date: yup
      .date()
      .transform(formatDateInInput)
      .when('status', {
        is: (status: string) => status === 'ACCEPT' || status === 'PARTIAL',
        then: yup
          .date()
          .transform(formatDateInInput)
          .required('common:errors.required')
          .test('is-before-startDate', 'Not before', (item, testContext) => {
            // @ts-ignore
            const startDate: Date | null = testContext.from[1].value.start
              ? // @ts-ignore
                new Date(`${formatDateForServer(testContext.from[1].value.start)}T00:00:00`)
              : null
            const itemDate: Date | null = item ? new Date(`${formatDateForServer(item)}T00:00:00`) : null
            const endDate: Date = new Date()

            return item && startDate && itemDate
              ? compareAsc(itemDate, startDate) >= 0 && compareAsc(endDate, itemDate) === 1
              : true
          }),
        otherwise: yup.date().transform(formatDateInInput).nullable(),
      }),
  }),
  rd: yup
    .object({
      rd: yup.number().nullable(),
      custom: yup.string().nullable(),
    })
    .nullable(),
})
