import { Stack } from '@mui/material'
import styled from 'styled-components'

export const DocWrapper = styled(Stack)`
  padding: 12px;
  border: 2px solid ${(props) => props.theme.palette.bg.gray};
  // border: 1px solid ${(props) => props.theme.palette.secondary.dark};
  border-radius: 6px;
`

export const AccordionWrapper = styled(Stack)`
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.bg.shades};

  &:hover {
    cursor: pointer;
  }
`

interface AccordionContentProps {
  isOpen: boolean
  isScrollable: boolean
}

export const AccordionContent = styled(Stack)<AccordionContentProps>`
  max-height: ${(props) => (props.isOpen ? '300px' : '0px')};
  overflow: ${(props) => (props.isScrollable ? 'auto' : 'hidden')};
  transition: max-height 0.2s linear;
  scrollbar-gutter: stable;
  margin: 8px -13px 0 0;
`
