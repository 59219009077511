import { DrawerTopBar } from '../../../ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { DefectForm } from '../DefectForm'
import { IDefectManagmentProps } from './DefectManagment.types'
import { Divider, Drawer, Stack, Typography } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const DefectManagment: FC<IDefectManagmentProps> = ({ isOpen, defect, onClose }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onClose(dirty)}>
      <Stack width={'400px'} height={'100%'}>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {defect ? 'Редактирование' : 'Добавление'} позиции справочника
          </Typography>
        </DrawerTopBar>

        <Divider />

        <DefectForm onFormChange={onFormChange} defect={defect} onClose={onClose} />
      </Stack>
    </Drawer>
  )
}
