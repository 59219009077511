import { FC } from 'react'

export const DocumentsSelfIcon: FC = () => {
  return (
    <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.5 34.625H13.5C5.355 34.625 1.875 31.145 1.875 23V14C1.875 5.855 5.355 2.375 13.5 2.375H21C21.615 2.375 22.125 2.885 22.125 3.5C22.125 4.115 21.615 4.625 21 4.625H13.5C6.585 4.625 4.125 7.085 4.125 14V23C4.125 29.915 6.585 32.375 13.5 32.375H22.5C29.415 32.375 31.875 29.915 31.875 23V15.5C31.875 14.885 32.385 14.375 33 14.375C33.615 14.375 34.125 14.885 34.125 15.5V23C34.125 31.145 30.645 34.625 22.5 34.625Z'
        fill='#6D9ADC'
      />
      <path
        d='M33 16.6208H27C21.87 16.6208 19.875 14.6258 19.875 9.49583V3.49583C19.875 3.04583 20.145 2.62583 20.565 2.46083C20.985 2.28083 21.465 2.38583 21.795 2.70083L33.795 14.7008C33.9514 14.8584 34.0578 15.0588 34.1007 15.2766C34.1436 15.4945 34.1212 15.7201 34.0362 15.9253C33.9513 16.1305 33.8076 16.3059 33.6232 16.4297C33.4389 16.5534 33.222 16.6199 33 16.6208ZM22.125 6.21083V9.49583C22.125 13.3658 23.13 14.3708 27 14.3708H30.285L22.125 6.21083ZM19.5 21.1238H10.5C9.885 21.1238 9.375 20.6138 9.375 19.9988C9.375 19.3838 9.885 18.8738 10.5 18.8738H19.5C20.115 18.8738 20.625 19.3838 20.625 19.9988C20.625 20.6138 20.115 21.1238 19.5 21.1238ZM16.5 27.1238H10.5C9.885 27.1238 9.375 26.6138 9.375 25.9988C9.375 25.3838 9.885 24.8738 10.5 24.8738H16.5C17.115 24.8738 17.625 25.3838 17.625 25.9988C17.625 26.6138 17.115 27.1238 16.5 27.1238Z'
        fill='#6D9ADC'
      />
    </svg>
  )
}
