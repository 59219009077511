import { Button } from '../Button'
import { EmptyPageWrapper } from './EmptyPage.styles'
import { EmptyPageProps } from './EmptyPage.types'
import { Box, Stack, Typography } from '@mui/material'
import { ReactComponent as EmptyImage } from 'assets/empty.svg'
import React from 'react'

export const EmptyPage: React.FC<EmptyPageProps> = ({ data, forFilter = false, fullPage = false }) => {
  const { text, buttons } = data

  return (
    <EmptyPageWrapper
      spacing={forFilter ? 3 : 7.5}
      justifyContent='center'
      alignItems='center'
      forFilter={forFilter}
      fullPage={fullPage}
    >
      <Stack spacing={2.5} alignItems='center'>
        <Typography variant='h2'>{text}</Typography>
        <Stack direction='row' spacing={1.5}>
          {buttons.map((button) => (
            <Button startIcon={<button.icon />} onClick={() => button.onClick()} key={button.text}>
              {button.text}
            </Button>
          ))}
        </Stack>
      </Stack>

      <Box>
        <EmptyImage width={630} height={400} />
      </Box>

      {/*{!forFilter*/}
      {/*  ? (*/}
      {/*    <Box>*/}
      {/*      <Typography variant='h1' color={theme.palette.primary.main}>Нужна помощь?</Typography>*/}
      {/*      <Typography variant='h1'>*/}
      {/*        <EmptyLink to='#'>Перейти в справочный центр </EmptyLink>*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  )*/}
      {/*  : null*/}
      {/*}*/}
    </EmptyPageWrapper>
  )
}
