import styled from 'styled-components'

interface ChartInfoValueProps {
  borderColor: string
}

export const ChartInfoValue = styled.div<ChartInfoValueProps>`
  padding: 2px 8px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.borderColor};
  min-width: 34px;
  background-color: ${(props) => props.borderColor};
`
