import { RemarkFormData } from '../../../RemarkForm.types'
import {
  MediaNumber,
  RemarkMediaImageWrapper,
  RemarkMediaItemButton,
  RemarkMediaItemWrapper,
  RemarkMediaViewer,
} from './RemarkMediaItem.styles'
import { RemarkMediaItemProps } from './RemarkMediaItem.types'
import { MediaDescription } from './components/MediaDescription'
import { MediaImage } from './components/MediaImage'
import { InsertDriveFile as FileIcon } from '@mui/icons-material'
import { Download as DownloadIcon, Delete as DeleteIcon, Person as PersonIcon } from '@mui/icons-material'
import { Dialog, Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { EliminationFormData } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.types'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { connectNames } from 'utils/connectNames'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'
import { downloadMedia } from 'utils/downloadMedia'

export const RemarkMediaItem: FC<RemarkMediaItemProps> = ({
  media,
  onDelete,
  number,
  mediaRef,
  viewingOnly,
  onViewerOpen,
  format = 'image',
}) => {
  const profile = useTypedSelector(profileSelector)
  const { values, setFieldValue } = useFormikContext<RemarkFormData | EliminationFormData>()
  const { mediaForCreate, mediaCommentById, newMediaCommentById } = values || {}
  const { fullDate, time } = parseResponseDateTime(media.created)

  useEffect(() => {
    if (!mediaForCreate.length) return

    if (mediaForCreate.slice(-1)[0].temporaryId === media.temporaryId) {
      mediaRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [mediaForCreate])

  const isNewMedia = !media.id

  const isImage = format?.includes('image')
  const isPdf = format === 'application/pdf'

  const imageSource = useMemo(() => {
    return isImage && (!media.id ? URL.createObjectURL(media) : media.variations.medium)
  }, [media, isImage])

  const onDownload = (media: any) => {
    if (!media.id) {
      const url = URL.createObjectURL(media)
      const link = document.createElement('a')
      link.href = url
      link.download = media.name
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    } else downloadMedia(media.link)
  }

  // const onViewerOpen = () => {
  //   setIsViewerOpen(true)
  // }

  // const onViewerClose = () => {
  //   setIsViewerOpen(false)
  // }

  return (
    <RemarkMediaItemWrapper ref={mediaRef}>
      {isImage && <MediaImage onViewerOpen={onViewerOpen} imageSrc={imageSource} />}

      {isPdf && (
        <RemarkMediaImageWrapper onClick={onViewerOpen}>
          <FileIcon color='error' />
          <Typography variant='subtitle1'>PDF</Typography>
        </RemarkMediaImageWrapper>
      )}

      <Stack width={'100%'} spacing={1.5}>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
          <Stack spacing={1.25} direction={'row'} alignItems={'center'}>
            <MediaNumber>
              <Typography variant='body2' color={theme.palette.text.disabled} textAlign={'center'}>
                {number}
              </Typography>
            </MediaNumber>

            <Typography variant='body2' color={theme.palette.secondary.dark}>
              {fullDate} {time}
            </Typography>
          </Stack>

          <Stack spacing={1.25} direction={'row'} alignItems={'center'}>
            {isNewMedia
              ? true
              : media.author && (
                  <Stack direction={'row'} spacing={0.5}>
                    <PersonIcon fontSize={'medium'} color={'secondary'} />
                    <Typography variant='body2' color={theme.palette.secondary.dark}>
                      {connectNames(isNewMedia ? profile : media.author, true)}
                    </Typography>
                  </Stack>
                )}

            <RemarkMediaItemButton onClick={() => onDownload(media)} type='button'>
              <DownloadIcon color='primary' fontSize={'medium'} />
            </RemarkMediaItemButton>

            {!viewingOnly && (
              <RemarkMediaItemButton onClick={onDelete} type='button'>
                <DeleteIcon color='error' fontSize={'medium'} />
              </RemarkMediaItemButton>
            )}
          </Stack>
        </Stack>

        <MediaDescription media={media} viewingOnly={viewingOnly} />
      </Stack>
      {/* 
      <Dialog
        open={isViewerOpen}
        onClose={onViewerClose}
      >
        <img src={!media.id ? URL.createObjectURL(media) : media.variations?.medium} alt='Файл замечания' />
      </Dialog> */}
    </RemarkMediaItemWrapper>
  )
}
