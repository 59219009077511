import { Stack } from '@mui/material'
import styled from 'styled-components'

interface ColumnsDrawerItemWrapperProps {
  checked: boolean
}

export const ColumnsDrawerItemWrapper = styled(Stack)<ColumnsDrawerItemWrapperProps>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 42px;
  padding-left: ${(props) => !props.checked && '26px'};

  & .MuiSvgIcon-root {
    cursor: grab;
  }
`
