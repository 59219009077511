import { uiContext } from '../contexts/ui'
import { useContext, useEffect } from 'react'

interface useSearchProps {
  preventClearOnUnmount?: boolean
}
interface SearchData {
  searchValue: string
}

export const useSearch = (props: useSearchProps | void): SearchData => {
  const cUI = useContext(uiContext)
  const { searchValue } = cUI

  useEffect(() => {
    return () => {
      !props?.preventClearOnUnmount && cUI.clearSearchRefValue()
    }
  }, [])

  return {
    searchValue,
  }
}
