import { DashboardModule, DashboardModuleIconWrapper, Indicator } from './IntegratedModule.styles'
import { IntegratedModuleProps, dashboardModuleIconByModuleName } from './IntegratedModule.types.types'
import { Stack, Typography } from '@mui/material'
import { useGetIntegratedModuleInfoQuery } from 'api/integrations'
import { integratedModuleInfo } from 'api/integrations/types'
import { Tooltip } from 'components/Tooltip'
import { pragmaModuleLabel } from 'pages/Administration/components/ProjectIntegrationDrawer/ProjectIntegrationDrawer.types'
import { FC, useMemo } from 'react'
import { theme } from 'styles/theme'

export const IntegratedModule: FC<IntegratedModuleProps> = ({ projectId, module, role }) => {
  const { module: moduleName, status: moduleStatus } = module || {}

  const { data: integratedModuleData } = useGetIntegratedModuleInfoQuery({ projectId, module: moduleName })
  const { error, url } = integratedModuleData?.data || {}
  const status = error || moduleStatus

  const moduleInfoByStatus = useMemo(() => {
    return integratedModuleInfo[status]
  }, [status])

  const { tooltipData, disabled, indicatorColor } = moduleInfoByStatus || {}
  const { title, text } = tooltipData || {}
  const moduleLabel = pragmaModuleLabel[moduleName].split('.')[1]

  if (role === 'none') return null

  return (
    <Tooltip
      title={
        status === 'active' ? (
          ''
        ) : (
          <Stack>
            <Typography variant='body1' fontSize={14}>
              {title}
            </Typography>
            <Typography variant='body2' fontSize={14}>
              {text}
            </Typography>
          </Stack>
        )
      }
      bgColor={theme.palette.bg.white}
    >
      <div>
        <DashboardModule href={url || ''} target='_blank' isDisabled={disabled || !url}>
          <DashboardModuleIconWrapper isDisabled={disabled || !url}>
            {dashboardModuleIconByModuleName[moduleName]}
            <Indicator bgColor={indicatorColor} />
          </DashboardModuleIconWrapper>
          <Typography variant='body1' color={disabled ? theme.palette.text.disabled : theme.palette.text.dark}>
            {moduleLabel}
          </Typography>
        </DashboardModule>
      </div>
    </Tooltip>
  )
}
