import {
  GetAnalyticsSettingsResponse,
  GetPrescriptionDynamicStatsRequest,
  GetPrescriptionDynamicStatsResponse,
  GetPrescriptionGeneralStatsRequest,
  GetPrescriptionGeneralStatsResponse,
  GetPrescriptionProjectStatsRequest,
  GetPrescriptionProjectStatsResponse,
  GetPrescriptionStatisticalStatsRequest,
  GetPrescriptionStatisticalStatsResponse,
  GetProjectsForAnalyticsFilterRequest,
  GetProjectsForAnalyticsFilterResponse,
  SaveAnalyticsFilterRequest,
  SaveAnalyticsFilterResponse,
} from './api.types'
import { api } from 'api/api'

export const analyticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPrescriptionDynamicStats: build.query<GetPrescriptionDynamicStatsResponse, GetPrescriptionDynamicStatsRequest>({
      query: ({ params, body }) => ({
        url: `/analytics/prescriptions/dynamic-stats`,
        method: 'POST',
        params,
        body,
      }),
      providesTags: ['Analytics', 'Prescriptions'],
    }),
    getPrescriptionGeneralStats: build.query<GetPrescriptionGeneralStatsResponse, GetPrescriptionGeneralStatsRequest>({
      query: ({ params, body }) => ({
        url: `/analytics/prescriptions/general-stats`,
        method: 'POST',
        params,
        body,
      }),
      providesTags: ['Analytics', 'Prescriptions'],
    }),
    getPrescriptionStatisticalStats: build.query<
      GetPrescriptionStatisticalStatsResponse,
      GetPrescriptionStatisticalStatsRequest
    >({
      query: ({ params, body }) => ({
        url: `/analytics/prescriptions/statistical-stats`,
        method: 'POST',
        params,
        body,
      }),
      providesTags: ['Analytics', 'Prescriptions'],
    }),
    getPrescriptionProjectStats: build.query<GetPrescriptionProjectStatsResponse, GetPrescriptionProjectStatsRequest>({
      query: ({ params, body }) => ({
        url: `/analytics/prescriptions/projects`,
        method: 'POST',
        params,
        body,
      }),
      providesTags: ['Analytics', 'Projects'],
    }),
    getProjectsForAnalyticsFilter: build.query<
      GetProjectsForAnalyticsFilterResponse,
      GetProjectsForAnalyticsFilterRequest
    >({
      query: () => ({
        url: '/analytics/prescriptions/dropdown/projects',
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Analytics', 'Projects', 'Users'],
    }),
    getContractorsForAnalyticsFilter: build.query<
      GetProjectsForAnalyticsFilterResponse,
      GetProjectsForAnalyticsFilterRequest
    >({
      query: () => ({
        url: '/analytics/prescriptions/dropdown/contractors',
        params: { page: 1, num: 99999 },
        method: 'POST',
        body: {
          projects: null,
        },
      }),
      providesTags: ['Analytics', { type: 'References', id: 'PROJECT_MEMBERS' }, 'Users'],
    }),
    getAnalyticsSettings: build.query<GetAnalyticsSettingsResponse, void>({
      query: () => ({
        url: '/analytics/prescriptions/panel/view/load',
        method: 'GET',
      }),
      providesTags: [{ type: 'Analytics', id: 'ANALYTICS_FILTER' }],
    }),
    saveAnalyticsFilter: build.mutation<SaveAnalyticsFilterResponse, SaveAnalyticsFilterRequest>({
      query: ({ body }) => ({
        url: '/analytics/prescriptions/panel/view/save',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Analytics', id: 'ANALYTICS_FILTER' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPrescriptionDynamicStatsQuery,
  useGetPrescriptionGeneralStatsQuery,
  useGetPrescriptionStatisticalStatsQuery,
  useGetPrescriptionProjectStatsQuery,
  useGetProjectsForAnalyticsFilterQuery,
  useGetContractorsForAnalyticsFilterQuery,
  useGetAnalyticsSettingsQuery,
  useSaveAnalyticsFilterMutation,
} = analyticsApi
