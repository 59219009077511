import { alpha, Stack, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

export const FocusedObjectItemWrapper = css`
  background-color: ${(props) => alpha(props.theme.palette.primary.main, 0.06)};

  & .MuiFormControlLabel-root .MuiTypography-root,
  & .MuiCheckbox-root {
    color: ${(props) => props.theme.palette.primary.main} !important;
  }
`

interface ObjectItemWrapperProps {
  selected: boolean
}

export const ObjectItemWrapper = styled(Stack)<ObjectItemWrapperProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 220px;
  height: 35px;
  padding: 5px 10px 5px 20px;
  border-radius: 4px;
  cursor: pointer;

  ${(props) => props.selected && FocusedObjectItemWrapper}
  ${(props) =>
    props.selected &&
    `
  & > div > .MuiTypography-root {
    color: ${props.theme.palette.primary.main};
  }
  `}
  &:hover {
    ${FocusedObjectItemWrapper}

    & > div > .MuiTypography-root {
      color: ${(props) => props.theme.palette.primary.main};
      background-color: transparent;
    }
  }

  & .MuiCheckbox-root {
    padding: 0;
    color: ${(props) => props.theme.palette.secondary.dark};

    &.MuiCheckbox-indeterminate,
    &.Mui-checked,
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
      background-color: transparent;
    }
  }
`

export const ObjectItemTitle = styled(Typography)`
  max-width: 130px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.secondary.gray};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
