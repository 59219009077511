import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const filterDrawerValidation = yup.object({
  columnId: yup.string(),
  dateStart: yup
    .date()
    .when('columnId', {
      is: 'period_dateEnd',
      then: yup.date().transform(formatDateInInput).nullable(),
      otherwise: yup.date().transform(formatDateInInput).max(today).nullable(),
    })
    .test((item, testContext) => {
      const { dateStart, dateEnd, columnId } = testContext.parent || {}

      if (columnId === 'period_dateEnd') return true
      let maxDateStart: Date = today

      if (dateEnd && dateEnd < today) maxDateStart = dateEnd

      return dateStart <= maxDateStart
    })
    .nullable(),

  dateEnd: yup.date().when('columnId', {
    is: 'period_dateEnd',
    then: yup.date().transform(formatDateInInput).min(yup.ref('dateStart')).nullable(),
    otherwise: yup.date().transform(formatDateInInput).min(yup.ref('dateStart')).max(today).nullable(),
  }),
})
