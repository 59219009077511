import { BreadcrumbsItem, uiContext } from '../contexts/ui'
import { useContext, useEffect } from 'react'

type Dependencies = any[]

export const useBreadcrumbs = (items: BreadcrumbsItem[], deps: Dependencies = [], loading: boolean = false) => {
  const cUI = useContext(uiContext)

  useEffect(() => {
    if (!items || loading) return

    cUI.setBreadcrumbs(...(items.filter(Boolean) as BreadcrumbsItem[]))
    return () => cUI.setBreadcrumbs()
  }, [...deps])
}
