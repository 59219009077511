import ChoreDrawer from './components/Drawers/ChoreDrawer/ChoreDrawer'
import { ImportDrawer } from './components/Drawers/ImportDrawer'
import { WorkTable } from './components/WorkTable'
import { Stack } from '@mui/material'
import { WorkFull } from 'api/ksg/types'
import { TableManagment } from 'components/TableManagment'
import { uiContext } from 'contexts/ui'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { FC, useCallback, useContext, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const Ksg: FC = () => {
  const navigate = useNavigate()
  const cUI = useContext(uiContext)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  // const [ksgDrawerOpen, setKsgDrawerOpen] = useState<boolean>(false)
  const { role } = useTypedSelector(profileSelector)
  const [targetWork, setTargetWork] = useState<WorkFull | null>(null)

  const [drawerData, setDrawerData] = useState({
    isManagmentKsgDrawerOpen: false,
    isImportKsgDrawerOpen: false,
  })

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  const access = role === 'admin' || role === 'engineer_qc'

  const { onProjectBreadClick, project, isFetching, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/ksg',
  })

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'КСГ' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  const onDrawerOpen = useCallback((drawerType: keyof typeof drawerData, work?: WorkFull) => {
    work ? setTargetWork(work) : setTargetWork(null)
    setDrawerData((prev) => ({ ...prev, [drawerType]: true }))
  }, [])

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) closeDrawers()
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    dirty || immediately ? openConfirm() : closeDrawers()
  }, [])

  const onReturnClick = useCallback(() => {
    navigate(`/project/${projectId}`)
  }, [projectId])

  const closeDrawers = () => {
    setDrawerData({
      isManagmentKsgDrawerOpen: false,
      isImportKsgDrawerOpen: false,
    })
  }

  return (
    <Stack flex={1} maxWidth={'100%'}>
      <Stack pr={2.5}>
        <TableManagment
          onReturnClick={onReturnClick}
          onImportClick={access ? () => onDrawerOpen('isImportKsgDrawerOpen') : undefined}
          onAddClick={access ? () => onDrawerOpen('isManagmentKsgDrawerOpen') : undefined}
          buttonManagmentTitle={'Управление'}
        />
      </Stack>

      <WorkTable onEditDrawerOpen={(work) => onDrawerOpen('isManagmentKsgDrawerOpen', work)} key={projectId} />

      <ChoreDrawer
        open={drawerData.isManagmentKsgDrawerOpen}
        onClose={(dirty) => onDrawerClose(dirty)}
        data={targetWork}
      />

      <ImportDrawer open={drawerData.isImportKsgDrawerOpen} onClose={(dirty) => onDrawerClose(dirty)} />

      {ProjectsMenu}
      <ConfirmDialog />
    </Stack>
  )
}
