import { useMutationHandlers } from '../../../../hooks/useMutationHandlers'
import { theme } from '../../../../styles/theme'
import { ObjectsDrawerTopBar, ObjectsDrawerWrapper } from './EditAndAddDrawer.styles'
import { EditAndAddDrawerProps } from './EditAndAddDrawer.types'
import { EditAndAddDrawerForm } from './EditAndAddDrawerForm'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { useDeleteObjectMutation } from 'api/objects'
import { Button } from 'components/Button'
import { DeleteConfirm } from 'components/DeleteConfirm'
import { Divider } from 'components/Divider'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useState } from 'react'

export const EditAndAddDrawer: FC<EditAndAddDrawerProps> = ({ open, onClose, target }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const [deleteObject, deleteObjectResponse] = useDeleteObjectMutation()

  const onDeleteSubmit = useCallback(() => {
    if (target) {
      deleteObject({ objectId: target.id })
    }
  }, [target])

  const onDeleteCancel = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }, [])

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  useMutationHandlers(deleteObjectResponse, (data) => {
    if (data.success) {
      onClose(false, true)
      enqueueSnackbar('Объект успешно удалён.', { variant: 'success' })
    } else {
      enqueueSnackbar(`Не удалось удалить объект. ${data.description}`, { variant: 'error' })
      setIsDeleteClicked(false)
    }
  })

  useEffect(() => {
    if (!open) {
      setIsDeleteClicked(false)
    }
  }, [open])

  return (
    <Drawer anchor='right' open={open} onClose={() => onDrawerClose(dirty)}>
      <ObjectsDrawerWrapper>
        <ObjectsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {target ? 'Редактирование объекта' : 'Добавление объекта'}
          </Typography>
        </ObjectsDrawerTopBar>

        <Divider />

        <EditAndAddDrawerForm target={target} onClose={(dirty) => onDrawerClose(dirty)} onFormChange={onFormChange} />

        {target && (
          <>
            <Stack justifyContent='flex-end' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
              <Box style={{ width: '100%', paddingTop: '50px', marginBottom: '20px' }}>
                {isDeleteClicked ? (
                  <DeleteConfirm
                    text='
                            Вы действительно хотите удалить объект?
                            Это действие нельзя отменить.
                          '
                    onDelete={onDeleteSubmit}
                    onCancel={onDeleteCancel}
                  />
                ) : null}
              </Box>
              <Box style={{ width: 'fit-content' }}>
                <Button onClick={onDeleteButtonClick} startIcon={<DeleteIcon />} variant='text' color='error'>
                  Удалить объект
                </Button>
              </Box>
            </Stack>
          </>
        )}
      </ObjectsDrawerWrapper>
    </Drawer>
  )
}
