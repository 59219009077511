/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { StatusCellContent } from '../../../../../Remarks/components/RemarksTable/cells/StatusCell'
import { Block, Field } from '../ChoreDrawer.style'
import { IInitialWork } from '../ChoreDrawer.types'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { IconButton, Menu, Typography } from '@mui/material'
import { useGetProjectByIdQuery } from 'api/projects'
import { ColoredTitle } from 'components/ColoredTitle'
import { FieldForm } from 'components/FieldForm'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { workStatuses, workStatusesBack, workStatusesClient, workStatusesColor } from 'core/types/ksg'
import { useField, useFormikContext } from 'formik'
import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

const BuildingControl: FC<{ viewingOnly: boolean }> = ({ viewingOnly }) => {
  const { projectId: projectString } = useParams()
  const projectId = Number(projectString)

  /* Запрос на сервер за проектом, для того, чтобы вытащить даты начала и окончания проекта */
  // const {data:project}  = useGetProjectByIdQuery({id:projectId})

  /* Локальное состояние о якоре для открытия выпадающего меню статусов */
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  const { values, setFieldValue } = useFormikContext<IInitialWork>()

  /* Компонент статуса */
  const ColoredStatus = (
    <ColoredTitle
      style={{ width: '100%' }}
      body={workStatusesClient[values.qcInfo.status as keyof typeof workStatusesClient]}
      color={workStatusesColor[values.qcInfo.status as keyof typeof workStatusesColor]}
    />
  )

  /* Установка якоря */
  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  /* Предотвращение закрытия выпавшего меню в случае клика на само меню */
  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  /**
   * Состояние поля "Дата"
   * если статус установлен в значение "Не установлено" или "Не принято" поле "Дата" будет выключено
   */
  const disabled = useMemo(() => {
    return values.qcInfo.status === 'REJECT' || values.qcInfo.status === 'EMPTY'
  }, [values.qcInfo.status])

  /**
   * Переменная для определения крайних сроков установки даты начала работ
   */
  // const range = useMemo(() => {
  //   let formattedDateStartProject = project?.data?.startDate || undefined;
  //   let formattedDateEndProject = project?.data?.endDate || undefined;
  //
  //   return  {
  //     minDate:formattedDateStartProject ? new Date(formatDate(formattedDateStartProject)) : undefined,
  //     maxDate:formattedDateEndProject ? new Date(formatDate(formattedDateEndProject)) : undefined,
  //   }
  // },[project]);

  const [{}, {}, { setTouched: setDateTouched }] = useField({
    name: 'qcInfo.date',
  })

  useEffect(() => {
    setDateTouched(true)
  }, [values.qcInfo.status])

  return (
    <Block>
      <Typography variant={'h1'}>Строительный контроль</Typography>
      <Block inner>
        <Field textPercent={43} title={'Подтвержденный объем'}>
          <FieldForm
            name='capacity.confirmed'
            version='project'
            type={'number'}
            dataValue={values.capacity.confirmed}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e' || e.key === '.') {
                e.preventDefault()
              }
            }}
            helperText={''}
            InputProps={{ style: { height: 30 } }}
            inputProps={{ style: { textAlign: 'right' }, readOnly: viewingOnly }}
            placeholder='Введите число'
          />
        </Field>
        <Field title={'Статус приемки'} textPercent={56}>
          <StatusCellContent
            p={'0 !important'}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end !important'}
          >
            {ColoredStatus}
            {!viewingOnly && (
              <IconButton>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
            )}
          </StatusCellContent>
          {!viewingOnly && (
            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
              {workStatuses.map((menuStatus) => {
                // @ts-ignore
                const checked = (workStatusesClient[values?.qcInfo?.status as any] as any) === menuStatus

                return (
                  <StyledSelectMenuItem
                    onClick={() => {
                      setFieldValue('qcInfo.status', workStatusesBack[menuStatus])
                      setMenuAnchor(null)
                    }}
                    style={{ width: '100%', maxWidth: '100%' }}
                    value={menuStatus}
                    checked={checked}
                    key={menuStatus}
                  >
                    {menuStatus}
                  </StyledSelectMenuItem>
                )
              })}
            </Menu>
          )}
        </Field>
        <Field
          title={'Дата приемки'}
          textPercent={56}
          isRequired={values.qcInfo.status === 'ACCEPT' || values.qcInfo.status === 'PARTIAL'}
        >
          <FieldForm
            name={'qcInfo.date'}
            onDataChange={(some: any) => {
              setFieldValue('qcInfo.date', some)
            }}
            disabled={disabled}
            placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
            dataValue={values.qcInfo.date}
            dateFieldProps={{
              readOnly: disabled || viewingOnly,
              minDate: values.start || undefined,
              maxDate: new Date() || undefined,
            }}
            helperText={''}
            InputProps={{ style: { height: 30 } }}
            version='date'
          />
        </Field>
        <FieldForm
          name={'qcInfo.comment'}
          placeholder={!viewingOnly ? 'Комментарий' : ''}
          version={'project'}
          multiline={true}
          dataValue={values.qcInfo.comment}
          minRows={3}
          inputProps={{
            readOnly: viewingOnly,
          }}
        />
      </Block>
    </Block>
  )
}

export default BuildingControl
