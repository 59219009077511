/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { WorkStatuses } from 'core/types/ksg'

export interface IInitialWork {
  documentCypher: string | null
  assignmentType: {
    id: number
    value: string
    type: string
    subtext: string | null
  }
  end: Date | null
  start: Date | null
  capacity: {
    confirmed: string | null
    total: string | null
    unit: string
  }
  contractor: {
    id: number
    value: string
    type: string
    subtext: string | null
  }
  name: string
  identifier: string | null
  object: {
    id: number
    value: string
    type: string
    subtext: string | null
  }
  qcInfo: {
    status: WorkStatuses
    comment: string
    date: Date | null
  }
  rd: {
    id: number
    value: string
    type: string
    subtext: string | null
    custom: string
  }
}

export const initialWork: IInitialWork = {
  documentCypher: '',
  assignmentType: {
    id: 0,
    type: '',
    value: '',
    subtext: null,
  },
  end: null,
  start: null,
  capacity: {
    confirmed: '',
    total: '',
    unit: '',
  },
  contractor: {
    id: 0,
    type: '',
    value: '',
    subtext: null,
  },
  name: '',
  identifier: '',
  object: {
    id: 0,
    type: '',
    subtext: null,
    value: '',
  },
  qcInfo: {
    status: 'EMPTY',
    date: null,
    comment: '',
  },
  rd: {
    custom: '',
    subtext: null,
    value: '',
    type: '',
    id: 0,
  },
}
