import {
  DocDrawer as DocDrawerRegulation,
  DocDrawerHeader as DocDrawerHeaderRegulation,
  DocQCLogo as DocQCLogoRegulation,
  DocDrawerContent as DocDrawerContentRegulation,
  DocDrawerDocTitle as DocDrawerDocTitleRegulation,
  DocLogoWrapper as DocLogoWrapperRegulation,
} from 'pages/RegulationDoc/components/DocNavBar'
import styled from 'styled-components'

export const DocDrawer = styled(DocDrawerRegulation)``

export const DocDrawerHeader = styled(DocDrawerHeaderRegulation)``

export const DocLogoWrapper = styled(DocLogoWrapperRegulation)``

export const DocQCLogo = styled(DocQCLogoRegulation)``

export const DocDrawerContent = styled(DocDrawerContentRegulation)``

export const DocDrawerDocTitle = styled(DocDrawerDocTitleRegulation)``
