import styled from 'styled-components'

export const Row = styled.tr`
  background-color: ${(props) => props.theme.palette.bg.white};
  // box-shadow: inset -1px -1px 0px rgba(0, 36, 95, 0.1);
  border-bottom: 1px solid rgba(0, 36, 95, 0.1);

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }
`

/**
 * стиль для кнопки скачивания шаблона
 */
export const DownloadFile = styled.a`
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin: auto;
  &:hover {
    text-decoration: underline;
  }
`

interface CellWrapperProps {
  cellType: string
  widthCell?: string
}

export const CellWrapper = styled.td<CellWrapperProps>`
  width: ${(props) =>
    ((props.cellType === 'PROJECT_MEMBER' || props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE') && '19.55%') ||
    (props.cellType === 'DEFECT' && '25%') ||
    (props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE' && '25%') ||
    props.widthCell ||
    '32.5%'};
  position: relative;
  height: ${60}px;

  &:first-child {
    width: ${(props) =>
      ((props.cellType === 'PROJECT_MEMBER' || props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE') && '21.8%') ||
      (props.cellType === 'DEFECT' && '27.3%') ||
      (props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE' && '27.3%') ||
      props.widthCell ||
      '35%'};
    & div {
      padding-left: ${(props) => (props.widthCell ? '16px' : '56px')};
    }
  }

  &:hover {
    & div {
      position: absolute;
      z-index: 9;
      padding: 10px 8px;
      width: calc(100% - 2px);
      top: 0;
      background-color: ${(props) => props.theme.palette.bg.shades};
      // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

      & span {
        display: block;
      }
    }

    &:first-child div {
      padding-left: ${(props) => (props.widthCell ? '16px' : '56px')} !important;
    }
  }
`

export const Cell = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
`

export const CellValue = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.palette.text.dark};
  text-align: start;
  overflow-wrap: anywhere;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
