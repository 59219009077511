import { UserFormData } from '../UserForm'
import { AccessStatusBlock } from '../UserForm/UserAccesses'
import { SelectedProjectItem } from './UserAccessesSelection.styles'
import { UserAccessesSelectionProps } from './UserAccessesSelection.types'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Checkbox, FormControl, Stack, Typography } from '@mui/material'
import { useGetProjectsQuery } from 'api/projects'
import { GetProjectsResponse } from 'api/projects/api.types'
import { Button } from 'components/Button'
import { ROLE_SELECT_WIDTH } from 'components/UserManagement/components/UserRoleSelection'
import { FieldArray, useFormikContext } from 'formik'
import { FC, useCallback, useState } from 'react'
import { MultiSelect, MultiSelectMenuItem } from 'styles/global/MultiSelect'
import { theme } from 'styles/theme'

export const UserAccessesSelection: FC<UserAccessesSelectionProps> = ({ children }) => {
  const { values: formValues, setFieldValue } = useFormikContext<UserFormData>()
  const isProjectsSelected = !!formValues.projects?.length
  const isAllProjectsSelected = formValues.allProjects

  const [selectHidden, setSelectHidden] = useState<boolean>(isProjectsSelected || isAllProjectsSelected)
  const [open, setOpen] = useState<boolean>(false)

  const { data } = useGetProjectsQuery({})
  const { data: projects } = data || ({} as GetProjectsResponse)

  const onAddClick = useCallback(() => {
    setSelectHidden(false)
    setOpen(true)
  }, [])

  const onSelectOpen = useCallback(() => {
    setOpen(true)
    setSelectHidden(false)
  }, [])

  const onSelectClose = useCallback(() => {
    setOpen(false)
    if (isProjectsSelected || isAllProjectsSelected) {
      setSelectHidden(true)
    }
  }, [formValues.projects])

  if (children) {
    return <>{children}</>
  }

  return (
    <Stack flex={1} alignItems='flex-end' spacing={2}>
      <FieldArray
        name='projects'
        validateOnChange={false}
        render={() => {
          return (
            <>
              {isAllProjectsSelected && (
                <SelectedProjectItem maxWidth={276}>
                  <Typography>Доступ ко всем проектам</Typography>
                </SelectedProjectItem>
              )}

              {isProjectsSelected && (
                <Stack width={276} spacing={1}>
                  {formValues.projects.map((project) => (
                    <SelectedProjectItem>
                      <Typography>{project.name}</Typography>
                    </SelectedProjectItem>
                  ))}
                </Stack>
              )}

              {projects?.length ? (
                (isProjectsSelected || isAllProjectsSelected) && selectHidden ? (
                  <Button
                    startIcon={<ModeEditIcon />}
                    variant='text'
                    bgColor={theme.palette.bg.shades}
                    onClick={onAddClick}
                    style={{ maxWidth: '276px', width: '100%', height: '44px' }}
                    sx={{
                      '& .MuiButton-startIcon': { paddingTop: '1px' },
                    }}
                  >
                    Изменить доступ
                  </Button>
                ) : (
                  <FormControl style={{ width: '100%', maxWidth: ROLE_SELECT_WIDTH }}>
                    <MultiSelect
                      version='select'
                      name='helper'
                      value={formValues.projects.map((project) => project.id)}
                      SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        MenuProps: {
                          PaperProps: {
                            style: { maxWidth: ROLE_SELECT_WIDTH },
                          },
                        },
                        renderValue: () => <Typography component='span'>Выберите проект</Typography>,

                        onChange: (e) => {
                          const value = e.target.value as (number | 'all')[]
                          if (value[value.length - 1] === 'all') {
                            setFieldValue(`allProjects`, !isAllProjectsSelected)
                            setFieldValue(`projects`, [], false)
                            return
                          }

                          if (isAllProjectsSelected) {
                            setFieldValue(
                              `projects`,
                              projects
                                ?.filter((project) => project.id !== value[0])
                                .map((project) => ({
                                  id: project.id,
                                  name: project.shortName,
                                })),
                            )
                            setFieldValue(`allProjects`, false)
                            return
                          }

                          // if (value?.length === projects?.length) {
                          //   setFieldValue(`projects`, [])
                          //   setFieldValue(`allProjects`, true)
                          //   return
                          // }

                          setFieldValue(
                            `projects`,
                            value.sort().map((id) => ({
                              id,
                              name: projects?.find((project) => project.id === id)?.shortName,
                            })),
                          )
                        },
                        open: open,
                        onOpen: onSelectOpen,
                        onClose: onSelectClose,
                      }}
                    >
                      <MultiSelectMenuItem value='all'>
                        <Checkbox checked={isAllProjectsSelected} />
                        <Typography variant='body1'>Все проекты</Typography>
                      </MultiSelectMenuItem>
                      {projects.map((project) => (
                        <MultiSelectMenuItem value={project.id} key={project.id}>
                          <Checkbox
                            checked={
                              isAllProjectsSelected
                                ? true
                                : formValues.projects.findIndex((rootProject) => rootProject.id === project.id) > -1
                            }
                          />
                          <Typography variant='body1'>{project.shortName}</Typography>
                        </MultiSelectMenuItem>
                      ))}
                    </MultiSelect>
                  </FormControl>
                )
              ) : (
                <AccessStatusBlock color={theme.palette.text.dark}>
                  <Typography variant='body1'>Проекты отсутствуют</Typography>
                </AccessStatusBlock>
              )}
            </>
          )
        }}
      />
    </Stack>
  )
}
