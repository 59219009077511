import { DashboardBasicCard } from '../MainCard'
import styled from 'styled-components'

export const DashboardAnalyticsCard = styled(DashboardBasicCard)`
  flex: 1;
  grid-area: analytics;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  gap: 20px;
`
export const ChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: 1fr 1fr;
  gap: 30px;
`
export const Chart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  //grid-template-columns: 1fr;
  //grid-template-rows: 130px 1fr;
  gap: 20px;
`
export const ChartLegend = styled.div`
  padding-left: 36.67px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px 10px;
`
export const StatusLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`
export const StatusLegendColorBlock = styled.div<{ color: string; widthBlock: number }>`
  height: 15px;
  justify-self: end;
  border-radius: 10px;
  width: ${(props) =>
    props.widthBlock <= 0 ? 100 : props.widthBlock > 0 && props.widthBlock <= 3 ? 3 : props.widthBlock}px;
  background: ${(props) => (props.widthBlock <= 0 ? '#F6F7FB' : props.color)};
`
export const ChartLegendList = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr min-content;
  grid-auto-rows: 20px;
  justify-content: start;
  align-items: center;
  gap: 4px 40px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  line-height: normal;
  font-weight: 400;
  span {
    justify-self: end;
    font-weight: 600;
  }
  div[data-last='last'] {
    grid-column: span 2;
    color: ${({ theme }) => theme.palette.error.main};
    & + span {
      position: relative;
      color: ${({ theme }) => theme.palette.text.dark};
      span {
        position: relative;
        z-index: 2;
      }
      &:before {
        content: '';
        position: absolute;
        border-radius: 17px;
        top: -5px;
        bottom: -5px;
        left: -8px;
        right: -8px;
        background: ${({ theme: { palette } }) => palette.bg.gray};
      }
    }
  }
  div[data-hr] {
    grid-column: span 3;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.palette.bg.gray};
  }
`
export const ChartLegendItemTitle = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
`
