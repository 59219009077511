import { PublicUserProfile } from 'api/profile/types'
import { RemarkMediaComments } from 'api/remarks/types'
import { VieweredItemInfo } from 'components/CarouselViewer/CarouselViewer.types'
import { connectNames } from 'utils/connectNames'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'

export const generateVieweredItemInfo = (
  media: any,
  mediaIndex: number,
  listIndex: number,
  newMediaCommentById?: RemarkMediaComments | null,
  mediaCommentById?: RemarkMediaComments | null,
  profile?: PublicUserProfile,
) => {
  const isNewMedia = !media.id
  let id = isNewMedia ? media.temporaryId : media.id
  let format = '' as any
  let src = ''
  let date: string | null = null
  if (!isNewMedia) {
    const { fullDate, time } = parseResponseDateTime(media.created)
    date = `${fullDate} ${time}`
  }

  isNewMedia
    ? (format = media.type)
    : (format = media.link.split('.').slice(-1)[0] === 'pdf' ? 'application/pdf' : 'image')

  if (format === 'image') {
    src = isNewMedia ? URL.createObjectURL(media) : media.variations.big
  } else {
    src = isNewMedia ? URL.createObjectURL(media) : media.link
  }

  const vieweredItemInfo: VieweredItemInfo = {
    mediaIndex,
    listIndex,
    name: isNewMedia ? media.name : media.link.split('/').slice(-1)[0],
    isNewMedia,
    format,
    src,
    description: (isNewMedia ? newMediaCommentById?.[id] : mediaCommentById?.[id]) || media?.comment || '',
    author: connectNames(isNewMedia ? profile : media.author, true),
    date,
  }

  return vieweredItemInfo
}
