import { IconButton, Stack } from '@mui/material'
import styled from 'styled-components'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

export const SectionsMenuWrapper = styled(Stack)`
  gap: 12px;
  width: 350px;
  padding: 20px 0;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  box-shadow: 8px 0px 8px 0px rgba(0, 0, 0, 0.06);

  & .sections-title {
    font-weight: 500;
    font-size: 18px;
  }

  @media (max-width: 1310px) {
    display: none;
  }
`

export const SectionsAddButton = styled(IconButton)`
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 6px;

  &:hover {
    background-color: ${(props) => props.theme.palette.bg.shades};
  }
`

export const SectionsWrapper = styled(ScrollableContainer)`
  gap: 6px;
  padding-right: 8px;
  padding-left: 20px;

  &::-webkit-scrollbar {
    width: 8px;
  }
`
