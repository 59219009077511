import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const RemarkItemWrapper = styled(Stack)`
  height: 110px;
  padding: 12px;
  padding-left: 0;

  &:first-child {
    padding-top: 0;
  }
`

export const RemarkItemCheckboxWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 220px;
  height: 35px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    & .MuiCheckbox-root {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  & .MuiCheckbox-root {
    padding: 0;
    padding-top: 4px;
    color: ${(props) => props.theme.palette.secondary.dark};

    &.Mui-checked,
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
      background-color: transparent;
    }
  }
`

export const RemarkItemDescription = styled(Typography)`
  line-height: 16px;
  color: ${(props) => props.theme.palette.text.dark};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`
