import Icon from '@mui/icons-material/Search'
import { Typography } from '@mui/material'
import styled from 'styled-components'

interface ClearTextProps {
  isDisabled: boolean
}

export const FilterDrawerWrapper = styled.div`
  width: 400px;
`

export const ClearText = styled(Typography)<ClearTextProps>`
  display: block;
  width: fit-content;
  text-align: end;
  ${(props) =>
    props.isDisabled
      ? `
    pointer-events: none;
    color: ${props.theme.palette.text.disabled};
  `
      : `
    color: ${props.theme.palette.primary.main};
    
    &:hover {
      cursor: pointer;
    }
  `}
`

export const SearchTypeControlWrapper = styled.div`
  padding: 12px 20px;

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    color: #2b3648;
  }
`

export const DateRangeWrapper = styled.div`
  display: flex;
  padding: 12px 20px;

  column-gap: 10px;
`
