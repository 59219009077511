import { CalendarLink } from '../CalendarCard'
import {
  Chart,
  ChartLegend,
  ChartLegendItemTitle,
  ChartLegendList,
  ChartWrapper,
  DashboardAnalyticsCard,
  StatusLegend,
  StatusLegendColorBlock,
} from './AnalyticsCard.styles'
import { AnalyticsCardProps } from './AnalyticsCard.types'
import { DoughnutChart } from './Chart'
import { FC, useMemo } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
  REMARK_COMPLETE_STATUS_COLOR,
  REMARK_CREATED_STATUS_COLOR,
  REMARK_DISCARDED_STATUS_COLOR,
  REMARK_SENT_STATUS_COLOR,
} from 'utils/constants'
import { replaceNumber } from 'utils/replaceNumber'

export const AnalyticsCard: FC<AnalyticsCardProps> = ({ dashboardProject }) => {
  const { role } = useTypedSelector(profileSelector)

  /**
   * деструктуризация пропсов
   */
  const { shortcoming, prescription } = useMemo(() => {
    // if (dashboardProject) {
    return dashboardProject?.statistics
    // }
    // return {shortcoming:({} as any), prescription:({} as any)}
  }, [dashboardProject])

  /**
   * Просто убрал дублирование для reduce
   */
  const sumReduce = (acc: number, item: number) => {
    acc += item
    return acc
  }

  /**
   * вычисляются данные для графика (внешний и внутренний график)
   */
  const dataShortcoming = useMemo(() => {
    /* внешний круг */
    const outer = [
      shortcoming.created?.percent,
      shortcoming.sent.percent,
      shortcoming.discarded.percent,
      shortcoming.complete.percent,
    ]

    /* проценты для вычислений того сколько от основной секции должен занимать внутренняя секция */
    const percentCreated = shortcoming.created.percent / 100
    const percentSent = shortcoming.sent.percent / 100
    const percentComplete = shortcoming.complete.percent / 100

    /* внутренний круг */
    const inner: number[] = [
      shortcoming.expired.created.percent * percentCreated,
      shortcoming.created.percent - shortcoming.expired.created.percent * percentCreated,
      shortcoming.expired.sent.percent * percentSent,
      shortcoming.sent.percent - shortcoming.expired.sent.percent * percentSent,
      shortcoming.discarded.percent,
      shortcoming.expired.complete.percent * percentComplete,
      shortcoming.complete.percent - shortcoming.expired.complete.percent * percentComplete,
    ]

    /* если будет ноль, значит данных совем нет, это нужно для окраски кругов и легенды справа в серый цвет */
    let isEmpty = [...outer, ...inner].reduce(sumReduce, 0) <= 0

    return isEmpty
      ? {
          outer: [1],
          inner: [1],
        }
      : {
          outer,
          inner,
        }
  }, [shortcoming])

  /**
   * тоже самое что и выше, разница в наименовании полей, и их порядке
   * уникализировать и вынести в отдельную функцию не вышло ...
   *
   * (а может просто не догодался =) )
   */
  const dataPrescription = useMemo(() => {
    const outer = [
      prescription.created.percent,
      prescription.sent.percent,
      prescription.discarded.percent,
      prescription.complete.percent,
    ]

    const percentSent = prescription.sent.percent / 100
    const percentComplete = prescription.complete.percent / 100

    const inner: number[] = [
      prescription.created.percent,
      prescription.expired.sent.percent * percentSent,
      prescription.discarded.percent,
      prescription.sent.percent - prescription.expired.sent.percent * percentSent,
      prescription.expired.complete.percent * percentComplete,
      prescription.complete.percent - prescription.expired.complete.percent * percentComplete,
    ]
    let isEmpty = [...outer, ...inner].reduce(sumReduce, 0) <= 0
    return isEmpty
      ? {
          outer: [1],
          inner: [1],
        }
      : {
          outer,
          inner,
        }
  }, [prescription])

  return (
    <DashboardAnalyticsCard>
      <CalendarLink>Аналитика</CalendarLink>
      <ChartWrapper>
        <Chart>
          <DoughnutChart mode={'remarks'} inner={dataShortcoming.inner} outer={dataShortcoming.outer} />
          <ChartLegend>
            <ChartLegendItemTitle>Замечания</ChartLegendItemTitle>
            <ChartLegendList>
              {role !== 'contractor' && (
                <>
                  <StatusLegendColorBlock
                    color={theme.palette.legends[REMARK_CREATED_STATUS_COLOR]}
                    widthBlock={shortcoming?.created?.percent}
                  />
                  <StatusLegend>Создано</StatusLegend>
                  <span>{replaceNumber(shortcoming?.created?.percent || 0)}%</span>
                </>
              )}

              <StatusLegendColorBlock
                color={theme.palette.legends[REMARK_SENT_STATUS_COLOR]}
                widthBlock={shortcoming?.sent?.percent}
              />
              <StatusLegend>Направлено</StatusLegend>
              <span>{replaceNumber(shortcoming?.sent?.percent || 0)}%</span>

              <StatusLegendColorBlock
                color={theme.palette.legends[REMARK_DISCARDED_STATUS_COLOR]}
                widthBlock={shortcoming?.discarded?.percent}
              />
              <StatusLegend>Снято</StatusLegend>
              <span>{replaceNumber(shortcoming?.discarded?.percent || 0)}%</span>

              <StatusLegendColorBlock
                color={theme.palette.legends[REMARK_COMPLETE_STATUS_COLOR]}
                widthBlock={shortcoming?.complete?.percent}
              />
              <StatusLegend>Исполнено</StatusLegend>
              <span>{replaceNumber(shortcoming?.complete?.percent || 0)}%</span>

              <div data-hr={true} />
              <StatusLegend data-last={'last'}>Просрочено:</StatusLegend>
              <span>
                <span>{replaceNumber(shortcoming?.expired?.total?.percent || 0)}%</span>
              </span>
            </ChartLegendList>
          </ChartLegend>
        </Chart>
        <Chart>
          <DoughnutChart mode={'regulations'} inner={dataPrescription.inner} outer={dataPrescription.outer} />
          <ChartLegend>
            <ChartLegendItemTitle>Предписания</ChartLegendItemTitle>
            <ChartLegendList>
              {role !== 'contractor' && (
                <>
                  <StatusLegendColorBlock
                    color={theme.palette.legends[PRESCRIPTION_CREATED_STATUS_COLOR]}
                    widthBlock={prescription?.created?.percent}
                  />
                  <StatusLegend>Создано</StatusLegend>
                  <span>{replaceNumber(prescription?.created?.percent || 0)}%</span>
                </>
              )}

              <StatusLegendColorBlock
                color={theme.palette.legends[PRESCRIPTION_SENT_STATUS_COLOR]}
                widthBlock={prescription?.sent?.percent}
              />
              <StatusLegend>Направлено</StatusLegend>
              <span>{replaceNumber(prescription?.sent?.percent || 0)}%</span>

              <StatusLegendColorBlock
                color={theme.palette.legends[PRESCRIPTION_DISCARDED_STATUS_COLOR]}
                widthBlock={prescription?.discarded?.percent}
              />
              <StatusLegend>Снято</StatusLegend>
              <span>{replaceNumber(prescription?.discarded?.percent || 0)}%</span>

              <StatusLegendColorBlock
                color={theme.palette.legends[PRESCRIPTION_COMPLETE_STATUS_COLOR]}
                widthBlock={prescription?.complete?.percent}
              />
              <StatusLegend>Исполнено</StatusLegend>
              <span>{replaceNumber(prescription?.complete?.percent || 0)}%</span>

              <div data-hr={true} />
              <StatusLegend data-last={'last'}>Просрочено:</StatusLegend>
              <span>
                <span>{replaceNumber(prescription?.expired?.total?.percent || 0)}%</span>
              </span>
            </ChartLegendList>
          </ChartLegend>
        </Chart>
      </ChartWrapper>
    </DashboardAnalyticsCard>
  )
}
