import { AuditTooltipMailsProps } from './AuditTooltipMails.types'
import { Person as PersonIcon, Email as EmailIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const AuditTooltipMails: FC<AuditTooltipMailsProps> = ({ mailRecipients }) => {
  return (
    <Stack spacing={1}>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <EmailIcon fontSize={'small'} color={'secondary'} />
        <Typography variant='body1' fontSize={14} color={theme.palette.secondary.gray}>
          Уведомление на e-mail
        </Typography>
      </Stack>
      {mailRecipients.map((emailInfo) => (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <PersonIcon fontSize={'small'} color={'secondary'} />
          <Stack>
            <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
              {emailInfo.fullName || '—'}
            </Typography>
            <Typography
              variant='body1'
              fontSize={14}
              color={theme.palette.text.dark}
              style={{ wordBreak: 'break-all' }}
            >
              {emailInfo.email || '—'}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
