import { DateDoneCellWrapper, DeviationElement, RemarksDateDoneCell } from './DateDoneCell.styles'
import { DateDoneCellProps } from './DateDoneCell.types'
import { Typography } from '@mui/material'
import { FC } from 'react'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const DateDoneCell: FC<DateDoneCellProps> = ({ info, dateVariant, splitDeviation = false }) => {
  const { row, column } = info
  const { minSize, size, maxSize } = column.columnDef
  const { id, period } = row.original
  const { dateEnd, dateDone, deviation } = period

  const parsedDateDone = parseResponseDate(dateDone).fullDate
  const parsedDateEnd = parseResponseDate(dateEnd).fullDate

  const date: Record<typeof dateVariant, string> = {
    done: parsedDateDone,
    end: parsedDateEnd,
  }

  const showDeviation = !splitDeviation || (dateDone ? dateVariant === 'done' : dateVariant === 'end')

  return (
    <RemarksDateDoneCell
      key={id}
      style={{
        minWidth: minSize,
        width: size,
        maxWidth: maxSize,
      }}
    >
      <DateDoneCellWrapper>
        <Typography variant='body2'>{date[dateVariant] || '—'}</Typography>

        {showDeviation && !!deviation && (
          <DeviationElement value={deviation} isEmpty={!date[dateVariant]}>
            {deviation > 0 && '+'}
            {deviation}
          </DeviationElement>
        )}
      </DateDoneCellWrapper>
    </RemarksDateDoneCell>
  )
}
