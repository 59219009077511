import { Box, Stack } from '@mui/material'
import styled from 'styled-components'

export const ProjectDashboardWrapper = styled(Stack)`
  overflow: auto;
  gap: 20px
  flex-direction: row;

  @media (max-width: 1735px) {
    flex-direction: column;

    & > div {
      width: 100%;

      &:nth-child(2) {
        flex-direction: row;
      }
    }
  }

  @media (max-width: 860px) {
    & > div:nth-child(2)  {
      flex-direction: column;
    }
  }
`

// export const ProjectDashboardWrapper = styled(Box)`
//   display: grid;
//   grid-template-columns: 1fr 540px;
//   grid-template-rows: 414px minmax(130px, 1fr) min-content;
//   gap: 20px;
//   max-height: 100%;
//   height: 100%;
//   padding-right: 7px;
//   grid-template-areas:
//       "main calendar"
//       "main analytics"
//       "statistics analytics";
//   overflow: hidden scroll;

//   //@media (max-width: 1650px) {
//   //  grid-template-areas:
//   //    "main main main"
//   //    "main main main"
//   //    "rd graph graph"
//   //    "rd graph graph graph graph .";
//   //}
//   //
//   //@media (max-width: 1470px) {
//   //  grid-template-areas:
//   //    "main main main main ird ii"
//   //    "main main main main ird ii"
//   //    "pd rd graph graph graph graph"
//   //    "pd rd graph graph graph graph";
//   //}
//   //
//   //@media (max-width: 1280px) {
//   //  grid-template-rows: repeat(6, minmax(200px, 1fr));
//   //  grid-template-columns: repeat(3, 1fr);
//   //  grid-template-areas:
//   //    "main main ird"
//   //    "main main ird"
//   //    "ii pd rd"
//   //    "ii pd rd"
//   //    "graph graph graph"
//   //    "graph graph graph";
//   //}
//   //
//   //@media (max-width: 850px) {
//   //  grid-template-columns: repeat(2, 1fr);
//   //  grid-template-rows: repeat(6, minmax(200px, 1fr));
//   //  grid-template-areas:
//   //    "main main"
//   //    "main main"
//   //    "ird ii"
//   //    "ird ii"
//   //    "pd rd"
//   //    "pd rd"
//   //    "graph graph"
//   //    "graph graph";
//   //}
// `
