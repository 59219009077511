import {
  DrawerTopBar,
  DrawerWrapper,
} from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import styled from 'styled-components'

export const ObjectsDrawerWrapper = styled(DrawerWrapper)`
  justify-content: flex-start;
`

export const ObjectsDrawerTopBar = styled(DrawerTopBar)``
