import { Stack } from '@mui/material'
import styled from 'styled-components'

export const RemarkMediaItemWrapper = styled(Stack)`
  width: 100%;
  flex-direction: row;
  gap: 20px;
  // margin-top: 20px;

  // &:first-child {
  //   margin-top: 0;
  // }
`

export const MediaNumber = styled(Stack)`
  height: 30px;
  min-width: 30px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 4px;
  border-radius: 8px;
  border: 1px solid rgba(92, 110, 140, 0.1);
`

export const RemarkMediaItemButton = styled.button`
  background-color: ${(props) => props.theme.palette.bg.shades};
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
  }
`

export const RemarkMediaImageWrapper = styled(Stack)`
  position: relative;
  min-width: 140px;
  // background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 8px;
  overflow: hidden;

  & img {
    object-fit: cover;
  }

  &:hover {
    cursor: pointer;
  }

  & svg {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(8.8);
    position: absolute;
  }

  & h6 {
    user-select: none;
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.palette.text.light};
    font-size: 24px;
  }
`

export const RemarkMediaViewer = styled.div``
