import { Stack } from '@mui/material'
import styled from 'styled-components'

export const TableWrapper = styled(Stack)`
  align-items: center;
  flex: 1;
  max-width: calc(100% - 375px);

  @media (max-width: 1310px) {
    max-width: 100%;
  }
`
