import { UploadResultItemWrapper, UploadResultRowText } from './UploadResultItem.styles'
import { UploadResultItemProps } from './UploadResultItem.types'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

export const UploadResultItem: React.FC<UploadResultItemProps> = ({ data }) => {
  const { title, text, optionalInfo, icon: Icon } = data

  return (
    <UploadResultItemWrapper spacing={1}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing={1.25} alignItems='center' flex={1}>
          {Icon}
          <Typography variant='subtitle2'>{title}</Typography>
        </Stack>
        {optionalInfo ? (
          <Box>
            <UploadResultRowText variant='tooltip'>{optionalInfo}</UploadResultRowText>
          </Box>
        ) : null}
      </Stack>
      <Box>
        <Typography variant='body1' style={{ fontSize: 14 }}>
          {text}
        </Typography>
      </Box>
    </UploadResultItemWrapper>
  )
}
