import { FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const EmployeesContractorsWrapper = styled(FormTitledWrapper)`
  & > .stack-wrapper {
    padding: 0;
    gap: 8px;
  }
`

export const ContractorWrapper = styled(FormTitledWrapper)`
  & > .stack-wrapper {
    align-items: end;
  }
`

export const EmployeesContractorsDelete = styled(IconButton)`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 6px;
`
