import { useGetUserByIdQuery } from 'api/profile'
import { Progress } from 'components/Progress/Progress'
import { UserManagement } from 'components/UserManagement/UserManagement'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import React from 'react'
import { useParams } from 'react-router-dom'

export const EditUser: React.FC = () => {
  const { userId } = useParams()

  useBreadcrumbs([{ title: 'Управление пользователем' }])

  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
    isFetching: profileIsFetching,
  } = useGetUserByIdQuery({ userId: Number(userId) }, { refetchOnMountOrArgChange: true })

  const isDataLoading = profileIsLoading || profileIsFetching

  return <>{isDataLoading ? <Progress /> : <UserManagement profile={profile} />}</>
}
