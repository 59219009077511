import { LegendColors } from 'core/types/global'
import { AnalyticsQueryData } from 'pages/Analytics/Analytics.types'

export interface ProjectWidgetProps {
  analyticsQueryData: AnalyticsQueryData
}

export type ProjectChartStatus = 'active' | 'complete' | 'paused'
export type ProjectChartStatusRu = 'Активные' | 'Завершенные' | 'Приостановленные'

export const projectChartStatuses: ProjectChartStatus[] = ['active', 'complete', 'paused']
export const projectChartStatusesRu: ProjectChartStatusRu[] = ['Активные', 'Завершенные', 'Приостановленные']

export const projectChartStatusRuByEn: Record<ProjectChartStatus, ProjectChartStatusRu> = {
  active: 'Активные',
  complete: 'Завершенные',
  paused: 'Приостановленные',
}

export const projectChartColors: Record<ProjectChartStatus, LegendColors> = {
  active: 'paleBlue',
  complete: 'paleGreen',
  paused: 'lightGray',
}

export type ProjectWidgetTab = 'status' | 'type'

export type ProjectChartType = 'industrial' | 'linear'
export type ProjectChartTypeRu = 'Произв. и непроизв. назначения' | 'Линейные объекты'

export const projectChartTypes: ProjectChartType[] = ['industrial', 'linear']
export const projectChartTypesRu: ProjectChartTypeRu[] = ['Произв. и непроизв. назначения', 'Линейные объекты']

export const projectChartTypeRuByEn: Record<ProjectChartType, ProjectChartTypeRu> = {
  industrial: 'Произв. и непроизв. назначения',
  linear: 'Линейные объекты',
}

export const projectChartTypeColors: Record<ProjectChartType, LegendColors> = {
  industrial: 'paleBlue',
  linear: 'paleGreen',
}
