import { SearchWrapper, StyledSearch } from './Search.styles'
import { SearchIcon } from './Search.styles'
import { SearchProps } from './Search.types'
import { Stack } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { debounce } from 'throttle-debounce'
import { SEARCH_QUERY_DELAY } from 'utils/constants'

export const Search: FC<SearchProps> = ({ setValue, ...props }) => {
  const onThrottledSearchChange = debounce(SEARCH_QUERY_DELAY, (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  })

  return (
    // @ts-ignore
    <SearchWrapper {...props}>
      <Stack position={'relative'}>
        <StyledSearch placeholder='Поиск' onChange={onThrottledSearchChange} />

        <SearchIcon />
      </Stack>
    </SearchWrapper>
  )
}
