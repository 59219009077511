import { Avatar } from '@mui/material'
import styled from 'styled-components'

type ManageUserAvatarProps = {
  isDisabled?: boolean
}

export const ManageUserAvatar = styled(Avatar)<ManageUserAvatarProps>`
  width: 220px;
  height: 220px;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  background-color: ${(props) => props.theme.palette.bg.shades};

  .MuiSvgIcon-root {
    opacity: 0.42;
  }

  .MuiTypography-root,
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.secondary.gray};
  }
`
