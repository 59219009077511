import max from 'date-fns/max'
import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const validationRemark = yup.object({
  status: yup.string().trim().required('common:errors.required'),
  dateStart: yup
    .date()
    .transform(formatDateInInput)
    .required('common:errors.required')
    .test((item, testContext) => {
      const { dateStart, dateEnd, dateDone, previousDateEnd, copyNumber } = testContext.parent || {}

      let minDate: Date = previousDateEnd
      let maxDate: Date = today

      if (previousDateEnd && previousDateEnd) if (dateEnd && dateEnd < today) maxDate = dateEnd

      if (dateDone && dateDone < maxDate) maxDate = dateDone

      return dateStart <= maxDate && (copyNumber ? dateStart > minDate : true)
    }),
  number: yup.string().trim(),
  object: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),
  senderCompany: yup.object().shape({
    id: yup.mixed().required('common:errors.required'),
    value: yup.mixed().required('common:errors.required'),
  }),

  senderUser: yup.object().shape({
    id: yup.mixed().required('common:errors.required'),
    value: yup.mixed().required('common:errors.required'),
  }),

  activityType: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),
  measures: yup.string().trim().required('common:errors.required'),
  basis: yup.string().trim(),
  // basis: yup.object().shape({
  //   id: yup.number().required('common:errors.required'),
  //   value: yup.string().trim().required('common:errors.required'),
  //   type: yup.string().trim().required('common:errors.required')
  // }),
  description: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
  }),
  // description: yup.string().trim().required('common:errors.required'),
  comments: yup.string().trim(),

  contractor: yup.object().shape({
    id: yup.number().required('common:errors.required'),
    value: yup.string().trim().required('common:errors.required'),
    type: yup.string().trim().required('common:errors.required'),
  }),

  responsible: yup
    .object()
    .shape({
      id: yup.number().nullable(),
      value: yup.string().trim(),
      type: yup.string().trim(),
    })
    .nullable(),

  copyNumber: yup.string().trim().nullable(),
  previousDateEnd: yup.date().transform(formatDateInInput).nullable(),

  dateEnd: yup.date().transform(formatDateInInput).min(yup.ref('dateStart')).required('common:errors.required'),

  dateDone: yup
    .date()
    .transform(formatDateInInput)
    .min(yup.ref('dateStart'))
    .max(today)
    .when('status', {
      is: 'COMPLETE',
      then: yup
        .date()
        .transform(formatDateInInput)
        .min(yup.ref('dateStart'))
        .max(today)
        .required('common:errors.required'),
      otherwise: yup.date().transform(formatDateInInput).min(yup.ref('dateStart')).max(today).nullable(),
    }),
})
