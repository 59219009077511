import { ImportDrawerProps } from './ImportDrawer.types'
import { ksgApi, useLazyGetKsgImportResultQuery, useLazyGetKsgLinkExampleQuery, useUploadKsgMutation } from 'api/ksg'
import { UploadDrawer } from 'components/UploadDrawer'
import { ExcelUploadResponse } from 'core/types/global'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'

export const ImportDrawer: FC<ImportDrawerProps> = ({ open, onClose }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [file, setFile] = useState<File | null>(null)
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)
  const [uploadKsg, { isLoading, ...uploadKsgResponse }] = useUploadKsgMutation()
  let [getKsgLinkExampleRequest, getKsgLinkExampleResult] = useLazyGetKsgLinkExampleQuery()
  const [getKsgImportResult, ksgImportResult, lastPromiseInfo] = useLazyGetKsgImportResultQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (file instanceof File) {
      uploadKsg({
        projectId,
        file: file,
      })
    }
  }, [file])

  useEffect(() => {
    if (ksgImportResult.isSuccess && !ksgImportResult.isLoading) {
      setResponseData(ksgImportResult.data)
    }
  }, [ksgImportResult])

  const dowloadExample = () => {
    getKsgLinkExampleRequest({ projectId })
  }

  const onFormSubmit = useCallback((files: File[]) => {
    setFile(files[0])
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean) => {
    dirty ? onClose(true) : onClose(false)
    setFile(null)
    setResponseData(undefined)
  }, [])

  // useMutationHandlers(
  //   getKsgLinkExampleResult,
  //   (data) => {
  //     window.location.href = data.data;
  //   }
  // )

  useEffect(() => {
    const { status, data } = getKsgLinkExampleResult
    if (status === 'fulfilled' && data) {
      window.location.href = data.data
    }
  }, [getKsgLinkExampleResult])

  useMutationHandlers(uploadKsgResponse, (data) => {
    if (data.success && data.data !== lastPromiseInfo.lastArg?.importId) {
      // dispatch(ksgApi.util.invalidateTags(['WorksTotal']))

      getKsgImportResult(
        {
          projectId,
          importId: data.data,
        },
        true,
      )
    }
  })

  return (
    <>
      <UploadDrawer
        open={open}
        mode='single'
        onSubmit={onFormSubmit}
        responseData={responseData}
        onChange={onFormChange}
        onClose={onDrawerClose}
        // onClose={() => onClose(!!(file && !responseData))}
        title='Загрузка КСГ'
        text='Вы можете добавить список работ с помощью exсel документа с уже подготовленными данными.'
        uploadData={{
          title: 'Загрузить xls(х)',
          text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
          formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
        }}
        loading={isLoading}
        getExampleLink={dowloadExample}
      />
    </>
  )
}
