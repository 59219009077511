import { CardContent, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledCardInfo = styled(CardContent)`
  padding: 16px 0 0 0 !important;
  text-align: left;
`

export const CardPositionInfo = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
