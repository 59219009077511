import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const DashboardDesignModuleIcon: FC<IconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M23.6496 8.7506C23.538 8.63924 23.4055 8.55097 23.2597 8.49085C23.1139 8.43073 22.9577 8.39992 22.8 8.4002H19.2V1.2002C19.2015 0.927547 19.1082 0.662852 18.936 0.451404C18.8213 0.307656 18.675 0.192206 18.5086 0.113972C18.3421 0.0357372 18.1599 -0.00318749 17.976 0.000204063H5.97601C5.66193 0.00648695 5.36283 0.135683 5.14295 0.36005C4.92308 0.584416 4.79995 0.886059 4.80001 1.2002V4.8002H1.20001C0.88175 4.8002 0.576525 4.92663 0.351482 5.15168C0.126438 5.37672 9.67835e-06 5.68194 9.67835e-06 6.0002V22.8002C-0.000625195 22.958 0.0299803 23.1143 0.0900611 23.2602C0.150142 23.4061 0.238509 23.5386 0.350066 23.6501C0.461623 23.7617 0.594162 23.8501 0.740039 23.9102C0.885917 23.9702 1.04225 24.0008 1.20001 24.0002H22.8C23.1179 23.9989 23.4224 23.8721 23.6471 23.6473C23.8719 23.4226 23.9987 23.1181 24 22.8002V9.6002C24.0003 9.44251 23.9695 9.28631 23.9094 9.14053C23.8492 8.99475 23.761 8.86225 23.6496 8.7506Z'
        fill='#F6F7FB'
      />
      <path d='M5.77917 1.43945H18.144' stroke='white' stroke-width='4' stroke-miterlimit='10' />
      <path d='M1.0752 6.24023H8.7216' stroke='white' stroke-width='4' stroke-miterlimit='10' />
      <path d='M1.14722 9.84961H23.1216' stroke='white' stroke-width='4' stroke-miterlimit='10' />
      <path d='M23.6496 8.7506C23.538 8.63924 23.4055 8.55097 23.2597 8.49085C23.1139 8.43073 22.9577 8.39992 22.8 8.4002H19.2V1.2002C19.2015 0.927547 19.1082 0.662852 18.936 0.451404C18.8213 0.307656 18.675 0.192206 18.5086 0.113972C18.3421 0.0357372 18.1599 -0.00318749 17.976 0.000204063H5.97601C5.66193 0.00648695 5.36283 0.135683 5.14295 0.36005C4.92308 0.584416 4.79995 0.886059 4.80001 1.2002V4.8002H1.20001C0.88175 4.8002 0.576525 4.92663 0.351482 5.15168C0.126438 5.37672 9.67835e-06 5.68194 9.67835e-06 6.0002V22.8002C-0.000625195 22.958 0.0299803 23.1143 0.0900611 23.2602C0.150142 23.4061 0.238509 23.5386 0.350066 23.6501C0.461623 23.7617 0.594162 23.8501 0.740039 23.9102C0.885917 23.9702 1.04225 24.0008 1.20001 24.0002H22.8C23.1179 23.9989 23.4224 23.8721 23.6471 23.6473C23.8719 23.4226 23.9987 23.1181 24 22.8002V9.6002C24.0003 9.44251 23.9695 9.28631 23.9094 9.14053C23.8492 8.99475 23.761 8.86225 23.6496 8.7506ZM6.24001 1.4402H17.76V8.4002H12L8.40001 4.8002H6.24001V1.4402ZM1.44001 6.2402H7.80481L9.96481 8.4002H1.44001V6.2402ZM22.56 22.5602H1.44001V9.8402H22.56V22.5602Z' />
      <path d='M17.5392 17.0498H6.49917C6.37186 17.0498 6.24977 16.9993 6.15975 16.9093C6.06974 16.8192 6.01917 16.6971 6.01917 16.5698C6.01917 16.4425 6.06974 16.3204 6.15975 16.2304C6.24977 16.1404 6.37186 16.0898 6.49917 16.0898H17.5392C17.6665 16.0898 17.7886 16.1404 17.8786 16.2304C17.9686 16.3204 18.0192 16.4425 18.0192 16.5698C18.0192 16.6971 17.9686 16.8192 17.8786 16.9093C17.7886 16.9993 17.6665 17.0498 17.5392 17.0498Z' />
    </SvgIcon>
  )
}
