import { DocumentManagment } from '../../../Regulations/RegulationsDrawers/DocumentManagment'
import { DocInfoRow, DocInfoRowProps } from './DocInfoRow'
import {
  DocDrawer,
  DocDrawerContent,
  DocDrawerDocTitle,
  DocDrawerHeader,
  DocLogoWrapper,
  DocQCLogo,
} from './DocNavBar.styles'
import { DocNavBarProps } from './DocNavBar.types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { HomeIcon } from 'assets/icons/HomeIcon'
import { Divider } from 'components/Divider'
import { regulationBodyRuByEn, regulationStatusRuByEn, regulationTypeRuByEn } from 'core/types/regulation'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { RegulationLocationState } from 'pages/Regulations/Regulations.types'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'

export const DocNavBar: FC<DocNavBarProps> = ({ doc, onDrawerOpen, onReturnClick }) => {
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)

  const {
    name,
    type,
    identifier,
    body,
    accepted: acceptedDate,
    created: createdDate,
    status,
    comment,
    link,
    author,
  } = doc

  const typeText = type?.id ? regulationTypeRuByEn[type?.id] : type?.custom || '—'
  const bodyText = body?.id ? regulationBodyRuByEn[body?.id] : body?.custom || '—'
  const acceptedDateText = (acceptedDate && parseResponseDate(acceptedDate).fullDate) || '—'
  const createdDateText = (createdDate && parseResponseDateTime(createdDate).fullDate) || '—'
  const authorText = `${author?.lastName || ''} ${author?.firstName || ''} ${author?.middleName || ''}`

  const mainInfoRowsData: DocInfoRowProps[] = [
    { title: 'Тип документа', text: typeText },
    { title: 'Идентификатор', text: identifier || '—' },
    { title: 'Принявший\nорган', text: bodyText },
    { title: 'Введен\nв действие', text: acceptedDateText },
    { title: 'Статус', text: regulationStatusRuByEn[status] },
  ]

  return (
    <DocDrawer variant='permanent'>
      <DocDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <DocLogoWrapper>
            <DocQCLogo />
          </DocLogoWrapper>
        </Link>

        <Stack direction='row' alignItems='center' spacing={1.25}>
          <IconButton color='primary' onClick={onReturnClick}>
            <ArrowBackIcon />
          </IconButton>
          {/*<IconButton color='primary' onClick={onHomeClick}>*/}
          {/*  <HomeIcon />*/}
          {/*</IconButton>*/}
        </Stack>
      </DocDrawerHeader>

      <DocDrawerContent spacing={2.5} flex={1}>
        {isUserHighAccess && (
          <Button onClick={onDrawerOpen} variant='outlined' color='secondary'>
            Редактировать
          </Button>
        )}

        <Stack flex={1} justifyContent='space-between' spacing={3}>
          <Stack>
            <Box>
              <DocDrawerDocTitle variant='body2'>{name}</DocDrawerDocTitle>
            </Box>

            <Stack px={1.5} spacing={2}>
              <Divider sx={{ my: 1 }} />

              {mainInfoRowsData.map((rowData) => (
                <DocInfoRow {...rowData} />
              ))}

              <Divider style={{ margin: '24px 0 8px' }} />

              {link && <DocInfoRow title='Ссылка' text={link || ''} isLink />}

              <DocInfoRow title='Комментарий' text={comment || '—'} direction='column' rows={3} />
            </Stack>
          </Stack>

          <Stack px={1.5} spacing={2}>
            <DocInfoRow title='Автор' text={authorText} direction='column' rows={2} />
            <DocInfoRow title='Дата создания' text={createdDateText} direction='column' />
          </Stack>
        </Stack>
      </DocDrawerContent>
    </DocDrawer>
  )
}
