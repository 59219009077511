import { RemarksStatusCell } from '../StatusCell'
import styled from 'styled-components'

export const RemarksAuditCell = styled(RemarksStatusCell)`
  &:hover {
    box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;

    & .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.primary.light};
    }
  }
`
