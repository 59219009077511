import { TableCellWrapper } from './components/TableCell/TableCell.styles'
import styled, { css } from 'styled-components'

export const TABLE_RADIUS = 16

export const TableHeadRadius = css`
  // border-start-end-radius: ${TABLE_RADIUS}px;
  // border-start-start-radius: ${TABLE_RADIUS}px;
`

export const TableBodyRadius = css`
  border-end-start-radius: ${TABLE_RADIUS}px;
  border-end-end-radius: ${TABLE_RADIUS}px;
`

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 7px;
  overflow-y: scroll;
  overflow-x: scroll;
`

interface TableRowProps {
  head?: boolean
  showAnimation?: boolean
}

export const TableRow = styled.div<TableRowProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;

  ${(props) =>
    props.head
      ? `
      background-color: ${props.theme.palette.bg.gray};

      & ${TableCellWrapper} {
        &:first-child {
          border-start-start-radius: ${TABLE_RADIUS}px;
        }

        &:last-child {
          border-start-end-radius: ${TABLE_RADIUS}px;
        }
      }
    `
      : `
      &:last-child {
        & div {
          & div, div {
            box-shadow: rgba(0, 36, 95, 0.1) -1px 0 0 inset !important;
          }

          &:first-child > div {
            border-bottom-left-radius: ${TABLE_RADIUS}px;
          }
  
          &:last-child > div {
            border-bottom-right-radius: ${TABLE_RADIUS}px;
          }
        }
      }
    `}

  &:hover {
    z-index: 2;
  }

  ${(props) =>
    props.showAnimation &&
    `
    ${TableCellWrapper}, .display-cell, .cell {
      background-color: ${props.theme.palette.bg.gray};
    }
  `}
`

export const TableHead = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;

  ${TableRow} {
    position: static;
  }

  ${TableCellWrapper} {
    background-color: ${(props) => props.theme.palette.secondary.gray};

    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.text.light};
    }
  }
`

export const TableBody = styled.div`
  position: relative;

  ${TableCellWrapper}, .display-cell, .cell {
    background-color: ${(props) => props.theme.palette.bg.white};
  }

  ${TableRow}:hover {
    cursor: pointer;

    ${TableCellWrapper}, .display-cell, .cell {
      background-color: ${(props) => props.theme.palette.bg.shades};
    }
  }
`
