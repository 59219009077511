import { Card, CardWrapper, Title, Type } from './ReferenceCard.styles'
import { ReferenceCardProps } from './ReferenceCard.types'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const ReferenceCard: FC<ReferenceCardProps> = ({ item, modified }) => {
  const typeBackground: string = (modified ? theme.palette.legends.lightgreen : theme.palette.legends.blue) || ''

  return (
    <CardWrapper>
      <Link to={`${item.id.toLowerCase()}`}>
        <Card>
          <Type color={typeBackground}>{modified ? 'Модифицированный' : 'Базовый'}</Type>
          <Title>{item.name}</Title>
        </Card>
      </Link>
    </CardWrapper>
  )
}
