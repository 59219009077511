import { EditAndAddDrawer } from '../ObjectDrawers/EditAndAddDrawer'
import { TObjectsDialogTrigger, TObjectsDrawerType } from './ObjectsTable.types'
import { Stack } from '@mui/material'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useGetObjectsQuery } from 'api/objects'
import { ObjectFull } from 'api/objects/api.types'
import { EmptyPage } from 'components/EmptyPage'
import { EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress/Progress'
import { TableBody, TableContainer, TableHead, TableRow } from 'components/Table/Table.styles'
import { CustomColumnDef } from 'components/Table/Table.types'
import { TableBodyCell } from 'components/Table/components/TableBodyCell/TableBodyCell'
import { TableCell } from 'components/Table/components/TableCell'
import { TABLE_CELL_HEIGHT } from 'components/Table/components/TableCell/TableCell.styles'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useSearch } from 'hooks/useSearch'
import { getEmptyPageData } from 'pages/Projects'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { filterByFieldNames } from 'utils/filterByFieldNames'

export const ObjectsTable: FC = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { searchValue } = useSearch()
  const { data: objectData, isLoading } = useGetObjectsQuery({
    projectId,
    limit: 99999,
    offset: 0,
  })
  const objectsList: ObjectFull[] = objectData?.data || []
  const [selectedItem, setSelectedItem] = useState<ObjectFull | null>(null)
  const [openedDrawer, setOpenedDrawer] = useState<TObjectsDrawerType | null>(null)

  const onRowClick = (item: ObjectFull) => {
    setSelectedItem(item)
    setOpenedDrawer('form')
  }

  const filteredBySearch = useMemo(() => {
    return searchValue && objectData?.data.length
      ? // @ts-ignore
        filterByFieldNames<Project>(objectData?.data, ['number', 'title', 'description'], searchValue)
      : objectData?.data
  }, [objectData, searchValue])

  const sortedList = useMemo(() => {
    if (filteredBySearch?.length) {
      return [...filteredBySearch]?.sort((a, b) => {
        if (Number(a.number) && Number(b.number)) return a - b

        if (a?.number.toLowerCase() > b?.number.toLowerCase()) return 1
        else return -1
      })
    } else return []
  }, [objectData, searchValue])

  const emptyPageData: EmptyPageData = getEmptyPageData(<>Данные в справочнике отсутствуют.</>)

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют объекты, соответствующие результатам запроса.</>,
  )

  const columns: CustomColumnDef<ObjectFull>[] = [
    {
      accessorKey: 'number',
      header: 'Номер объекта',
      textAlign: 'right',
      size: 220,
      minSize: 220,
      maxSize: 220,
    },
    {
      accessorKey: 'title',
      header: 'Краткое наименование',
      textAlign: 'left',
      size: 250,
      minSize: 250,
    },
    {
      accessorKey: 'description',
      header: 'Полное наименование',
      textAlign: 'left',
      minSize: 250,
      size: 250,
    },
  ]

  const table = useReactTable({
    data: sortedList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef?.current!,
  })

  const onDrawerClose = useCallback((dialogType: TObjectsDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) {
      setOpenedDrawer(null)
      setSelectedItem(null)
    } else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  const handleCloseManagment = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
      setSelectedItem(null)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TObjectsDialogTrigger>('closeForm')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeForm: { handleConfirm: handleCloseManagment },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  return (
    <>
      <TableContainer ref={tableContainerRef}>
        {!sortedList.length && isLoading && (
          <Stack height='100%'>
            <Progress />
          </Stack>
        )}

        {!sortedList.length && !isLoading && <EmptyPage data={{ text: 'Данные отсутствуют.', buttons: [] }} fullPage />}

        {!!sortedList.length && !isLoading && (
          <>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow head style={{ zIndex: '10' }} key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} header={header} />
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody style={{ height: virtualizer.getTotalSize() }}>
              {virtualizer.getVirtualItems().map((row) => {
                const rowData = objectsList[row.index]

                return (
                  <TableRow
                    key={row.key}
                    onClick={() => onRowClick(rowData)}
                    data-index={row.index}
                    ref={virtualizer.measureElement}
                    style={{
                      width: '100%',
                      transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                    }}
                  >
                    {columns.map((column, columnIndex) => {
                      const cell = rows[row.index].getVisibleCells()[columnIndex]

                      return <TableBodyCell key={column.id} column={column} cell={cell} />
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </>
        )}

        <EditAndAddDrawer
          open={openedDrawer === 'form'}
          onClose={(dirty, immediately) => onDrawerClose('closeForm', dirty, immediately)}
          target={selectedItem}
        />

        <ConfirmDialog />
      </TableContainer>
    </>
  )
}
