import { format } from 'date-fns'
import { formatDateInInput } from 'utils/dates/formatDateInInput'

export const oldFormatToDate = (dateString?: string | null): Date | null => {
  if (!dateString) return null

  const splitedDate = dateString.split('.')
  const formattedDate = [splitedDate[1], splitedDate[0], splitedDate[2]].join('/')
  return new Date(formattedDate)
}

export const oldFormatDateForServer = (date?: Date | null): string => {
  if (!date) return ''
  const parsedDate = formatDateInInput('', date)

  if (!parsedDate) return ''
  return format(parsedDate, 'dd.MM.yyyy')
}
