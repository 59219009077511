import { FC } from 'react'

export const BuildingsIcon: FC = () => {
  return (
    <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.5 12.5C4.5 12.5 3 14 3 17V29C3 32 4.5 33.5 7.5 33.5H19.5C16.5 33.5 15 32 15 29V12.5M7.5 12.5H15M7.5 12.5V9.5C7.5 7.85 8.85 6.5 10.5 6.5H15.165C15.045 6.95 15 7.445 15 8V12.5M21 12.5V20M27 12.5V20M9 20V26M25.5 26H22.5C21.675 26 21 26.675 21 27.5V33.5H27V27.5C27 26.675 26.325 26 25.5 26Z'
        stroke='#6D9ADC'
        stroke-width='2.25'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 29V8C15 5 16.5 3.5 19.5 3.5H28.5C31.5 3.5 33 5 33 8V29C33 32 31.5 33.5 28.5 33.5H19.5C16.5 33.5 15 32 15 29Z'
        stroke='#6D9ADC'
        stroke-width='2.25'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
