import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const validationIntegrationRequest = yup.object({
  phone: yup.string().required('common:errors.required'),
  email: yup.string().email('common:errors.email').required('common:errors.required'),
  message: yup.string(),
})
