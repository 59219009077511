import { RemarksFilters } from 'api/remarks/types'

export const defaultRemarkFilters: RemarksFilters = {
  status: null,
  contractor: null,
  contractorStatus: null,
  sender_company: null,
  sender_user: null,
  number: null,
  period_dateStart: null,
  period_dateStart_range: null,
  period_dateEnd: null,
  period_dateEnd_range: null,
  period_dateDone: null,
  period_dateDone_range: null,
  prescription: null,
  type: null,
  assignment_type: null,
  author: null,
  responsible: null,
  object: null,
}
