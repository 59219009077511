import React from 'react'

export const ProjectDrawerForm: React.FC = () => {
  return null

  // const { enqueueSnackbar } = useSnackbar()
  // const { id, shortName, fullName, startDate, endDate, status, phase, type } = project || {}
  //
  // const initialValues: ProjectFormData = useMemo(() => ({
  //   shortName: shortName || '',
  //   fullName: fullName || '',
  //   status: status || '',
  //   phase: phase || '',
  //   type: type || '',
  //   startDate: formatToDate(startDate),
  //   endDate: formatToDate(endDate),
  // }), [project])
  //
  // const [createProject, createProjectResponse] = useCreateProjectMutation()
  // const [editProject, editProjectResponse] = useEditProjectMutation()
  //
  // const onSubmit = useCallback((values: ProjectFormData) => {
  //   const dataForRequest: ProjectRequest = {
  //     fullName: values.fullName,
  //     shortName: values.shortName,
  //     startDate: formatDateForServer(values.startDate!, DATE_FORMAT),
  //     endDate: formatDateForServer(values.endDate!, DATE_FORMAT),
  //     status: values.status as ProjectFullStatuses,
  //     phase: values.phase as ProjectPhase,
  //     type: values.type as ProjectType,
  //   }
  //   if (id) {
  //     editProject({
  //       id,
  //       ...dataForRequest
  //     })
  //   } else {
  //     createProject(dataForRequest)
  //   }
  // }, [project])
  //
  //
  // const { formik, isSubmitDisabled } = useForm({
  //   validationSchema: validationProject,
  //   enableReinitialize: true,
  //   initialValues,
  //   onSubmit: (values) => {
  //     onSubmit(values)
  //   },
  // })
  //
  // const { values, handleReset, setFieldValue, setFieldError, dirty } = formik
  //
  // useEffect(() => {
  //   onFormChange(dirty)
  // }, [dirty])
  //
  // useMutationHandlers(
  //   createProjectResponse,
  //   (data: ProjectBaseResponse) => {
  //     if (!data.success) {
  //       enqueueSnackbar(data.description, { variant: 'error' })
  //       return
  //     }
  //     onClose(false, true)
  //   }
  // )
  //
  // useMutationHandlers(
  //   editProjectResponse,
  //   (data: ProjectBaseResponse) => {
  //     if (!data.success) {
  //       enqueueSnackbar(data.description, { variant: 'error' })
  //       return
  //     }
  //     onClose(false, true)
  //   }
  // )
  //
  //
  // return (
  //   <ProjectDrawerFormWrapper>
  //     <FormikProvider value={formik}>
  //       <Stack spacing={2.5} component={Form}>
  //         <Stack spacing={1.5}>
  //           <FieldForm
  //             version='project'
  //             name='shortName'
  //             placeholder='Укажите краткое название проекта'
  //           />
  //           <FieldForm
  //             version='project'
  //             name='fullName'
  //             placeholder='Укажите полное название проекта или его описание'
  //             multiline
  //             minRows={2}
  //           />
  //         </Stack>
  //
  //         <Stack spacing={1.5}>
  //           <SelectFormField<string, ProjectFormData>
  //             fieldName='status'
  //             title='Статус'
  //             placeholder='Выберите статус проекта'
  //             icon={ErrorOutlineIcon}
  //             data={allProjectsFullStatuses.map(status => ({
  //               value: status,
  //               label: status,
  //               key: status,
  //             }))}
  //             wrapperStyles={{
  //               maxWidth: 220,
  //               '& .MuiSelect-select': {
  //                 padding: '5px !important'
  //               },
  //               '& .MuiInputLabel-root': {
  //                 transform: 'translate(14px, 6px) scale(1) !important',
  //
  //                 '&.MuiInputLabel-shrink': {
  //                   transform: 'translate(14px, -9px) scale(0.75) !important'
  //                 }
  //               }
  //             }}
  //             primary
  //           />
  //
  //           <SelectFormField<string, ProjectFormData>
  //             fieldName='type'
  //             title='Тип'
  //             placeholder='Выберите тип проекта'
  //             icon={InsertDriveFileOutlinedIcon}
  //             data={allProjectsTypes.map(type => ({
  //               value: type,
  //               label: projectTypeShort[type],
  //               key: type,
  //             }))}
  //             wrapperStyles={{
  //               maxWidth: 220,
  //               '& .MuiSelect-select': {
  //                 padding: '5px !important'
  //               },
  //               '& .MuiInputLabel-root': {
  //                 transform: 'translate(14px, 6px) scale(1) !important',
  //
  //                 '&.MuiInputLabel-shrink': {
  //                   transform: 'translate(14px, -9px) scale(0.75) !important'
  //                 }
  //               }
  //             }}
  //
  //           />
  //
  //           <SelectFormField<string, ProjectFormData>
  //             fieldName='phase'
  //             title='Стадия'
  //             placeholder='Выберите стадию проекта'
  //             icon={GradingIcon}
  //             data={allProjectsPhases.map(phase => ({
  //               value: phase,
  //               label: phase,
  //               key: phase,
  //             }))}
  //             wrapperStyles={{
  //               maxWidth: 220,
  //               '& .MuiSelect-select': {
  //                 padding: '5px !important'
  //               },
  //               '& .MuiInputLabel-root': {
  //                 transform: 'translate(14px, 6px) scale(1) !important',
  //
  //                 '&.MuiInputLabel-shrink': {
  //                   transform: 'translate(14px, -9px) scale(0.75) !important'
  //                 }
  //               }
  //             }}
  //           />
  //         </Stack>
  //
  //         <Stack spacing={2}>
  //           <Box>
  //             <Typography variant='body2'>Сроки реализации:</Typography>
  //           </Box>
  //
  //           <Stack direction='row' justifyContent='space-between' alignItems='center'>
  //             <Stack direction='row' spacing={1}>
  //               <EventNoteIcon fontSize='medium' color='secondary' />
  //               <Typography variant='body2' component='span'>Дата начала:</Typography>
  //             </Stack>
  //
  //             <FieldForm
  //               version='date'
  //               name='startDate'
  //               dataValue={values.startDate}
  //               onDataChange={(value: Date | null) => setFieldValue('startDate', value)}
  //             />
  //           </Stack>
  //
  //           <Stack direction='row' justifyContent='space-between' alignItems='center'>
  //             <Stack direction='row' spacing={1}>
  //               <EventAvailableIcon fontSize='medium' color='secondary' />
  //               <Typography variant='body2' component='span'>Дата окончания:</Typography>
  //             </Stack>
  //
  //             <FieldForm
  //               version='date'
  //               name='endDate'
  //               dataValue={values.endDate}
  //               onDataChange={(value: Date | null) => setFieldValue('endDate', value)}
  //             />
  //           </Stack>
  //         </Stack>
  //
  //         <Stack direction='row' spacing={2}>
  //           <Button
  //             type='submit'
  //             color='success'
  //             size='medium'
  //             fullWidth
  //           >
  //             Сохранить
  //           </Button>
  //           <Button size='medium' fullWidth onClick={() => onClose(false, true)}>Отменить</Button>
  //         </Stack>
  //
  //       </Stack>
  //     </FormikProvider>
  //   </ProjectDrawerFormWrapper>
  // )
}
