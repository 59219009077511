import { DesignModuleIcon } from 'assets/icons/DesignModuleIcon'
import { DesignRmIcon } from 'assets/icons/DesignRmIcon'
import { LegendColors } from 'core/types/global'
import { ReactElement } from 'react'
import { theme } from 'styles/theme'

export interface ProjectIntegrationDrawerProps {
  isOpen: boolean
  onClose: () => void
  project: ProjectInfo
}

export interface ProjectInfo {
  id: number
  shortName: string
}

export type ProjectSliderType = 'integration' | 'request'

export type ProjectDialogTrigger = 'exit'

export type IntegrationModuleStatus = 'available' | 'binded' | 'active'
export type IntegrationModuleStatusRu = 'доступно' | 'подключено' | 'активно'

export const integrationModuleStatusRuByEn: Record<IntegrationModuleStatus, IntegrationModuleStatusRu> = {
  available: 'доступно',
  binded: 'подключено',
  active: 'активно',
}

export const integrationModuleStatusEnByRu: Record<IntegrationModuleStatusRu, IntegrationModuleStatus> = {
  доступно: 'available',
  подключено: 'binded',
  активно: 'active',
}

export const integrationModuleStatusColor: Record<IntegrationModuleStatus, string> = {
  available: theme.palette.legends.blue,
  binded: theme.palette.legends.lightOrange,
  active: theme.palette.legends.lightgreen,
}

export interface IntergationModuleCard {
  value: PragmaModule
  label: PragmaModuleLabel
  status: IntegrationModuleStatus
  icon: ReactElement | null
}

export type PragmaModule =
  | 'PragmaQC'
  | 'PragmaProcurement'
  | 'PragmaDesign'
  | 'PragmaRM'
  | 'PragmaFinance'
  | 'PragmaOffer'

export type PragmaModuleLabel =
  | 'Pragma.QC'
  | 'Pragma.Procurement'
  | 'Pragma.Design'
  | 'Pragma.RM'
  | 'Pragma.Finance'
  | 'Pragma.Offer'

export const pragmaModuleLabel: Record<PragmaModule, PragmaModuleLabel> = {
  PragmaQC: 'Pragma.QC',
  PragmaProcurement: 'Pragma.Procurement',
  PragmaDesign: 'Pragma.Design',
  PragmaRM: 'Pragma.RM',
  PragmaFinance: 'Pragma.Finance',
  PragmaOffer: 'Pragma.Offer',
}

export const pragmaModuleIcon: Record<PragmaModule, ReactElement> = {
  PragmaQC: <></>,
  PragmaProcurement: <></>,
  PragmaDesign: <DesignModuleIcon />,
  PragmaRM: <DesignRmIcon />,
  PragmaFinance: <></>,
  PragmaOffer: <></>,
}
