import { FormDataType } from '../EditAndAddDrawer.types'

/**
 * добавил алиас к интерфейсу
 */
export interface EditAndAddDrawerFormProps {
  onClose: () => void
  closeWithCheckingDirty: () => void
  isDirty: () => boolean
  formData: FormDataType
  setFormData: any
  target?: any
}

export const multilineFields: Record<string, string[]> = {
  ASSIGNMENT_TYPE: ['title', 'description', 'note'],
  PROJECT_MEMBER: ['note'],
  PROJECT_MEMBER_REPRESENTATIVE: ['note'],
  RD: ['note'],
  DOCUMENTS: ['note'],
  DEFECT: ['title', 'note'],
}
