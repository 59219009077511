import { api } from '../api'
import {
  GetObjectsResponse,
  GetObjectsRequest,
  DeleteObjectRequest,
  DeleteObjectResponse,
  EditObjectRequest,
  EditObjectResponse,
  CreateObjectRequest,
  CreateObjectResponse,
  GetObjectsLinkExampleResponse,
  GetObjectsLinkExampleRequest,
  UploadObjectsRequest,
  ClearObjectRequest,
} from './api.types'
import { ExcelUploadResponse } from 'core/types/global'

export const objectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getObjects: build.query<GetObjectsResponse, GetObjectsRequest>({
      query: ({ projectId, offset, limit }) => ({
        url: `/project/${projectId}/object/list`,
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: ['Objects', 'ObjectsUpload'],
    }),
    createObject: build.mutation<CreateObjectResponse, CreateObjectRequest>({
      query: ({ projectId, body }) => ({
        url: `/project/${projectId}/object/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Objects'],
    }),
    editObject: build.mutation<EditObjectResponse, EditObjectRequest>({
      query: ({ objectId, body }) => ({
        url: `/project/object/${objectId}/update`,
        method: 'PUT',
        body,
      }),
    }),
    deleteObject: build.mutation<DeleteObjectResponse, DeleteObjectRequest>({
      query: ({ objectId }) => ({
        url: `/project/object/${objectId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(objectsApi.util.invalidateTags(['Objects']))
        } catch {}
      },
    }),
    uploadObjects: build.mutation<ExcelUploadResponse, UploadObjectsRequest>({
      query: ({ projectId, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${projectId}/object/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
      invalidatesTags: ['ObjectsUpload'],
    }),
    getObjectsLinkExample: build.mutation<GetObjectsLinkExampleResponse, void>({
      query: () => ({
        url: '/project/object/link-example',
        method: 'GET',
      }),
    }),
    clearObjects: build.mutation<void, ClearObjectRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/object/delete/all`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Objects'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetObjectsQuery,
  useCreateObjectMutation,
  useEditObjectMutation,
  useDeleteObjectMutation,
  useUploadObjectsMutation,
  useGetObjectsLinkExampleMutation,
  useClearObjectsMutation,
} = objectsApi
