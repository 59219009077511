import { PrescriptionAutofilledData } from '../../PrescriptionsForm.types'

export interface ControlProps {
  viewingOnly: boolean
  setIsLocalValid: (value: boolean) => void
  formMode: 'add' | 'edit'
  setAutofilledData: (value: ((prev: PrescriptionAutofilledData) => any) | PrescriptionAutofilledData) => void
}

export const automaticDateEndInfo =
  'Установленная дата устранения автоматически будет приравнена к самой поздней Установленной дате устранения в добавленных замечаниях. Для того, чтобы заполнить Установленную дату устранения предписания самостоятельно, выключите автоматическое заполнение в переключателе.'
