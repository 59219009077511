import { Stack } from '@mui/material'
import styled from 'styled-components'

interface DashboardModuleProps {
  isDisabled: boolean
}

interface DashboardModuleIconWrapperProps extends DashboardModuleProps {}

interface IndicatorProps {
  bgColor: string
}

export const DashboardModule = styled.a<DashboardModuleProps>`
  display: flex;
  padding: 8px;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.palette.legends.gray};
  border-radius: 8px;
  gap: 8px;

  ${(props) =>
    props.isDisabled
      ? `
    cursor: default;
    pointer-events: none;
  `
      : `
    &:hover {
      border-color: ${props.theme.palette.primary.main}
    }
    cursor: pointer;
  `}
`

export const DashboardModuleIconWrapper = styled(Stack)<DashboardModuleIconWrapperProps>`
  width: 24px;
  height: 24px;
  position: relative;

  & svg {
    fill: ${(props) => (props.isDisabled ? props.theme.palette.legends.disabled : props.theme.palette.primary.main)};
  }
`

export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  background-color: ${(props) => props.bgColor};
  border-radius: 50%;
  height: 8px;
  aspect-ratio: 1;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  border: 4px solid white;
  box-sizing: content-box;
`
