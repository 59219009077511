import { MapHeight, MapWidth } from '../AboutTabImage'
import { StyledCarousel } from './MoreTabImage.styles'
import { MoreTabImageProps } from './MoreTabImage.types'
import { Box } from '@mui/material'
import EmptyPreview from 'assets/empty-project-preview.png'
import React, { FC } from 'react'

export const MoreTabImage: FC<MoreTabImageProps> = ({ images }) => {
  return images?.length > 1 ? (
    <StyledCarousel
      animation='slide'
      autoPlay={false}
      indicators={false}
      navButtonsAlwaysVisible={true}
      navButtonsWrapperProps={{ className: 'navButtonsWrapper' }}
    >
      {images.map((image) => (
        <Box width={MapWidth}>
          <img
            src={image.imagePreview}
            width={MapWidth}
            height={MapHeight}
            alt='Превью проекта'
            style={{ borderRadius: 16 }}
          />
        </Box>
      ))}
    </StyledCarousel>
  ) : (
    <Box width={MapWidth}>
      <img
        src={images[0]?.imagePreview || EmptyPreview}
        width={MapWidth}
        height={MapHeight}
        alt='Превью проекта'
        style={{ borderRadius: 16 }}
      />
    </Box>
  )
}
