export const downloadMedia = (url: string) => {
  fetch(url, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const localUrl = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = localUrl
        link.download = url.replace(/^.*[\/]/, '')
        link.click()
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
