import { AdminLegend } from '../components/AdminLegend/AdminLegend'
import { ProjectCard } from '../components/ProjectCard'
import { CardsFullWrapper } from './AdminProjects.styles'
import { ProjectDrawer } from './ProjectDrawer'
import AddIcon from '@mui/icons-material/Add'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import { useGetProjectsQuery } from 'api/projects'
import { GetProjectsResponse } from 'api/projects/api.types'
import { Project } from 'api/projects/types'
import { EmptyPage } from 'components/EmptyPage'
import { EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress/Progress'
import { TabData } from 'components/Tabs'
import { projectStatusByTabLabel, ProjectTabLabel, projectTabLabels } from 'core/types/project'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useSearch } from 'hooks/useSearch'
import { getEmptyPageData } from 'pages/Projects'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { XXL_FOR_LAYOUT } from 'utils/constants'
import { filterByFieldNames } from 'utils/filterByFieldNames'

export const AdminProjects: React.FC = () => {
  const xxl = useMediaQuery(`(min-width: ${XXL_FOR_LAYOUT})`)
  const { searchValue } = useSearch()
  const navigate = useNavigate()

  useBreadcrumbs([{ title: 'Управление проектами' }])

  const profile = useTypedSelector(profileSelector)
  const { firstName } = profile

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [chosenProjectId, setChosenProjectId] = useState<number | null>(null)

  const [currentTab, setCurrentTab] = useState<ProjectTabLabel>('Все проекты')

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false)
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: ProjectTabLabel) => {
    setCurrentTab(tabValue)
  }, [])

  const onAddProjectClick = useCallback(() => {
    navigate('add')
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm()
  }, [])

  const { data, isLoading, isFetching } = useGetProjectsQuery({})

  const { data: projectsData } = data || ({} as GetProjectsResponse)

  const filteredBySearch = useMemo(() => {
    return searchValue && projectsData?.length
      ? filterByFieldNames<Project>(projectsData, ['fullName', 'shortName'], searchValue)
      : projectsData
  }, [projectsData, searchValue])

  const filteredByStatus = useMemo(() => {
    return currentTab === 'Все проекты'
      ? filteredBySearch
      : filteredBySearch?.filter((project) => project.status === projectStatusByTabLabel[currentTab])
  }, [filteredBySearch, currentTab])

  const onProjectCardClick = useCallback((projectId: number) => {
    navigate(`edit/${projectId}`)
  }, [])

  const tabsData: TabData<ProjectTabLabel>[] = useMemo(() => {
    return projectTabLabels.map((tabName) => ({ value: tabName, label: tabName }))
  }, [filteredBySearch])

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}.<br /> У Вас еще нет проектов для отображения.
      <br />
      {profile.role === 'admin' ? 'Давайте создадим ваш первый проект.' : ''}{' '}
    </>,
    profile.role === 'admin'
      ? [
          {
            text: 'Добавить проект',
            icon: AddIcon,
            onClick: () => onAddProjectClick(),
          },
        ]
      : undefined,
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют проекты, соответствующие результатам запроса.</>,
  )

  return (
    <Stack flex={1}>
      {isLoading || isFetching ? (
        <Progress />
      ) : projectsData?.length ? (
        <>
          <AdminLegend<ProjectTabLabel>
            currentTab={currentTab}
            tabsData={tabsData}
            onTabChange={onTabChange}
            onAddClick={onAddProjectClick}
          />
          {filteredByStatus?.length ? (
            <CardsFullWrapper>
              <Grid spacing={2.5} container>
                {filteredByStatus.map((project) => (
                  <Grid item xs={12} md={6} lg={4} xl={xxl ? 2 : 3} container justifyContent='center' key={project.id}>
                    <ProjectCard data={project} onClick={onProjectCardClick} />
                  </Grid>
                ))}
              </Grid>
            </CardsFullWrapper>
          ) : (
            <EmptyPage data={emptyFilteredData} forFilter />
          )}
        </>
      ) : (
        <EmptyPage data={emptyPageData} fullPage />
      )}

      <ProjectDrawer open={isDrawerOpen} onClose={onDrawerClose} projectId={chosenProjectId} />
      <ConfirmDialog />
    </Stack>
  )
}
