import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const IasInfo = styled.div`
  width: 300px;
  padding: 10px;
  position: absolute;
  top: calc(100% + 10px);
  background-color: ${(props) => props.theme.palette.bg.shades};

  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: ${(props) => props.theme.palette.bg.shades};
    transform: rotate(45deg);
    top: -6px;
    z-index: -1;
  }
`
