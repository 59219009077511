import { Box } from '@mui/material'
import styled from 'styled-components'

interface MapWrapperProps {
  hover?: boolean
}

export const MapWrapper = styled(Box)<MapWrapperProps>`
  cursor: ${(props) => props.hover && 'pointer'};
`
