import { ActsTooltipProps } from './ActsTooltip.types'
import { InsertDriveFile as FileIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const ActsTooltip: FC<ActsTooltipProps> = ({ completionActs }) => {
  return (
    <Stack spacing={1} minWidth={'246px'}>
      <Typography variant='body2'>Документы</Typography>
      {completionActs.map((act) => (
        <Stack direction={'row'} spacing={1}>
          <FileIcon color='secondary' fontSize={'small'} />
          <Typography variant='body2' fontSize={14} color={theme.palette.text.dark}>
            № {act.number} от {parseResponseDate(act.date).fullDate}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}
