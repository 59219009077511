import { FormDataType } from '../EditAndAddDrawer.types'
import { EditAndAddDrawerFormProps } from '../EditAndAddDrawerForm'
import {
  DownloadLinkAsButton,
  FormTextArea,
  HorizontalLine,
  IncorrectFormatBox,
  TextField,
} from '../EditAndAddDrawerForm/EditAndAddDrawerForm.styles'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Box, Stack, Typography } from '@mui/material'
import { useCreateSectionMutation, useEditSectionMutation } from 'api/references'
import { Button } from 'components/Button'
import { DragWrapper, UploadText, UploadTitle, UploadWrapper } from 'components/FileUpload'
import { UploadDrawerAttention, UploadDrawerText, UploadTextWrapper } from 'components/UploadDrawer/UploadDrawerForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { Reference, basicReferences } from 'pages/Administration/References/References.types'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { MAX_FILE_SIZE_IN_BYTES } from 'utils/constants'

const initialFormForPattern = {
  suffix: '',
  prefix: '',
  note: '',
  link: '',
  name: '',
  originalLink: '',
}

/**
 * За основу компоненту был скопирован компонент EditAndAddDrawerForm.tsx
 * пропсы принимает теже, разница только в отрисовке, и отправке данных
 *
 * Перенес onSubmit на саму кнопку, потому что игнорировался `preventDefault()`
 * и вместо отправки страница тупа перезагружалась, а новые введенные данные не появлялась
 */
export const EditAndAddDrawerFormForPattern: FC<EditAndAddDrawerFormProps> = ({
  onClose,
  closeWithCheckingDirty,
  isDirty,
  formData,
  setFormData,
  target,
}) => {
  const { projectId, referenceId } = useParams()
  const [createSection, createSectionResponse] = useCreateSectionMutation()
  const [editSection, editSectionResponse] = useEditSectionMutation()
  const [updateTemplate, updateTemplateResponse] = useEditSectionMutation()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * локальный стейт для переключения ошибки выбора файла
   */
  const [isError, setIsError] = useState(false)

  const getCurrentReference = (): Reference => {
    return basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0]
  }

  useEffect(() => {
    let localFormData: any = {}
    const keys = Object.keys(initialFormForPattern)
    getCurrentReference().columns.forEach((item) => {
      if (keys.includes(item.id as keyof typeof initialFormForPattern)) {
        localFormData[item.id] = { value: target ? target[item.id] || '' : '', required: item.required }
      }
    })
    /* добавляется поле newFile для отслеживания isDirty и в целом для редактировани текущего (активного) шаблона */
    localFormData.newFile = { value: undefined, required: false }
    setFormData(localFormData)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleResponse(createSectionResponse, 'Позиция успешно добавлена.')
    //eslint-disable-next-line
  }, [createSectionResponse])

  useEffect(() => {
    handleResponse(editSectionResponse, 'Позиция успешно изменена.')
    //eslint-disable-next-line
  }, [editSectionResponse])

  useMutationHandlers(editSectionResponse, (data) => {
    // if (!data.success) {
    //   enqueueSnackbar(data.description, { variant: 'error' })
    //   return
    // }
    // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
    // onChangeTab('view', true)
  })

  const isRequiredFieldsFilledIn = useMemo(() => {
    if (!Object.keys(formData).length) return true
    if (
      Object.values(formData).some(
        (item: FormDataType) =>
          (typeof item.value === 'string' && !item?.value?.trim() && item?.required) ||
          (!item?.value === undefined && item?.required),
      )
    )
      return true
    else return false
  }, [formData])

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const result: typeof formData = {}
    let key: keyof typeof formData
    /**
     * в этом блоке добавлено условие, по которому применяется функция trim
     * но в справочнике шаблонов, есть файл, к которому невозможно его применить
     */
    for (key in formData) {
      if (typeof formData[key].value === 'string') {
        result[key] = formData[key].value.trim()
      } else {
        result[key] = formData[key].value
      }
    }
    /**
     * по замечанию, вынес в отдельную константу
     *
     * если есть ключ newFile уйдет запрос на запись нового шаблона
     * updateDoc - обновление шаблона
     * apiPatternEdit - просто сократил запись
     * apiPatternCreate - просто сократил запись (пока нет создания, но возможно появится)
     */
    const apiPatternEdit = getCurrentReference().api.editSection
    const apiPatternCreate = getCurrentReference().api.editSection

    /**
     * убираю ненужные поля перед отправкой, для редактирования записи нужно всего три поля, остальное копипаст
     */
    const { name, newFile, originalLink, link, ...rest } = result
    if (target) {
      editSection({
        url: projectId
          ? apiPatternEdit.projectUrl.replace('{id}', String(target.id)).replace('{project}', projectId)
          : apiPatternEdit.url.replace('{id}', String(target.id)),
        body: rest,
      })
    } else {
      createSection({
        url: projectId ? apiPatternCreate.projectUrl.replace('{project}', projectId) : apiPatternCreate.url,
        body: rest,
      })
    }
  }

  const changeValue = useCallback((id: string, value: string | File) => {
    setFormData((prev: FormDataType) => ({ ...prev, [id]: { ...prev[id], value: value } }))
  }, [])

  const handleResponse = (response: any, successText: string) => {
    if (response.isUninitialized || response.isLoading) return
    if (response.isError) {
      enqueueSnackbar(response.error.status === 409 ? 'Позиция уже существует.' : 'Произошла ошибка.', {
        variant: 'error',
      })
    } else {
      const result: typeof formData = {}
      let key: keyof typeof formData
      for (key in formData) {
        if (typeof formData[key].value === 'string') {
          result[key] = formData[key].value.trim()
        } else {
          result[key] = formData[key].value
        }
      }
      const updateDoc = getCurrentReference().api.updatePattern
      /**
       * если есть файл, будет отправка данных но переопределение активного шаблона
       * не уверен что это необходимо при создании, да и требования такого пока нет,
       * поэтому закоментирован блок отправки при создании
       */
      if (result.newFile && updateDoc) {
        const form = new FormData()
        form.set('file', result.newFile)
        if (target) {
          updateTemplate({
            url: projectId
              ? updateDoc.projectUrl.replace('{id}', String(target.id)).replace('{project}', projectId)
              : updateDoc.url.replace('{id}', String(target.id)),
            body: form,
          })
        } else {
          // createSection({
          //     url: projectId ? getCurrentReference().api.updatePattern!.projectUrl.replace("{id}", String(target.id)).replace("{project}", projectId) :
          //         getCurrentReference().api.updatePattern!.url.replace("{id}", String(target.id)),
          //     body: form
          // })
        }
      } else {
        enqueueSnackbar(successText, { variant: 'success' })
        onClose()
      }
    }
  }

  useMutationHandlers(updateTemplateResponse, (data) => {
    // if (!data.success) {
    //   enqueueSnackbar(data.description, { variant: 'error' })
    //   return
    // }
    // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
    enqueueSnackbar('Позиция успешно изменена..', { variant: 'success' })
    onClose()
  })

  /**
   * прослушиватель события выбора файла,
   * при сробатывании переключает флаг отображения ошибки
   */
  const onDropRejected = () => {
    setIsError(true)
  }

  /**
   * строки с 162 по 173 скопированы с FileUpload (80-97 строки)
   * поменял функцию внутри на changeValue
   *
   * добавил переключение флага отображения ошибки
   * если будет верный формат флаг переключится в состояние false
   */
  const onDropFile = useCallback(
    (files: File[]) => {
      if (files?.length) {
        setIsError(false)
        changeValue('newFile', files[0])
      }
    },
    [changeValue],
  )

  /**
   * добавил форматы
   */
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropFile,
    accept: { 'application/msword': [], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] },
    onDropRejected,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
  })

  /**
   * Изменен render добавлены поля
   */
  return (
    <>
      <Stack spacing={2.5} overflow={'auto'} height={'100%'}>
        <FormWrapper spacing={1.5} height={'100%'}>
          <Stack
            height={'52px'}
            direction={'row'}
            position={'relative'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            <Typography fontSize={'14px'} color={(theme) => theme.palette.secondary.dark}>
              Вид документа:
            </Typography>
            <Typography>{formData?.name?.value}</Typography>
            <HorizontalLine />
          </Stack>
          {formData.name?.value === 'Предписание' && (
            <>
              <Stack>
                <Typography variant={'subtitle2'} color={(theme) => theme.palette.secondary.dark}>
                  Нумерация документа
                </Typography>
              </Stack>
              <Stack spacing={1.5}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography fontSize={'14px'} color={(theme) => theme.palette.secondary.dark}>
                    Префикс:
                  </Typography>
                  <Box width={168}>
                    <TextField
                      maxLength={10}
                      style={{ textAlign: 'right' }}
                      width={168}
                      value={formData?.prefix?.value}
                      onChange={(e) => changeValue('prefix', e.target.value)}
                      placeholder={'Префикс'}
                    />
                  </Box>
                </Stack>
                <Stack margin={'10px'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography fontSize={'14px'} color={(theme) => theme.palette.secondary.dark}>
                    Суффикс:
                  </Typography>
                  <Box width={168}>
                    <TextField
                      maxLength={10}
                      style={{ textAlign: 'right' }}
                      width={'168px'}
                      value={formData?.suffix?.value}
                      onChange={(e) => changeValue('suffix', e.target.value)}
                      placeholder={'Суффикс'}
                    />
                  </Box>
                </Stack>
              </Stack>
              <Stack direction={'row'} position={'relative'}>
                <HorizontalLine />
              </Stack>
            </>
          )}
          <Stack direction={'column'} gap={'8px'}>
            <Typography fontSize={'14px'} paddingLeft={'11px'} color={(theme) => theme.palette.secondary.dark}>
              Примечание:
            </Typography>
            <FormTextArea>
              <TextField
                as={'textarea'}
                value={formData?.note?.value}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => changeValue('note', e.target.value)}
                // key={item.id}
                rows={2}
                placeholder={'Примечание'}
              />
            </FormTextArea>
          </Stack>
          <UploadTextWrapper spacing={3.5}>
            <Box>
              <UploadDrawerText variant='body2'>
                Вы можете изменить шаблон, дополнив дефолтный шаблон необходимыми данными.
              </UploadDrawerText>
            </Box>

            <Stack>
              <UploadDrawerAttention variant='body2'>Внимание!</UploadDrawerAttention>
              <UploadDrawerText variant='body2'>
                В случае нарушения структуры дефолтного шаблона документ будет сформирован некорректно.
              </UploadDrawerText>
            </Stack>
          </UploadTextWrapper>
          <DragWrapper
            margin={'20px auto 0 !important'}
            height={'228px'}
            width={'320px'}
            drag={isDragActive}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <UploadWrapper justifyContent='center' alignItems='center' spacing={1} isPointer={true}>
              <CloudUploadIcon sx={{ fontSize: '48px !important', color: theme.palette.secondary.gray }} />

              {/* Переименовал docx в doc(x) это выходит за рамки макета в фигме но отображает суть, в трелло нописано что нужно блочить все кроме WORD */}
              <UploadTitle variant='body2'>загрузить doc(x)</UploadTitle>
              <UploadText variant='body2' fontWeight={200}>
                {formData?.newFile?.value
                  ? `Загружен документ ${formData?.newFile?.value.name}`
                  : 'Чтобы загрузить документ,\n' +
                    'выберите его из папки на компьютере\n' +
                    'или просто перетяните его в эту область.'}
              </UploadText>
              {formData?.newFile?.value && (
                <Button bgColor={'white'} variant='text'>
                  <CachedOutlinedIcon style={{ rotate: '90deg', marginRight: 8 }} />
                  Выбрать другой файл
                </Button>
              )}
            </UploadWrapper>
          </DragWrapper>

          {/* в зависимости от состояния  отображается текст ошибки */}
          {isError && <IncorrectFormatBox>Неверный формат файла</IncorrectFormatBox>}
          <Stack direction='row' spacing={2}>
            <DownloadLinkAsButton href={formData?.originalLink?.value}>
              Скачать шаблон по умолчанию
            </DownloadLinkAsButton>
          </Stack>
        </FormWrapper>

        <FormButtonWrapper padding={2.5} spacing={1} direction={'row'}>
          <Button
            onClick={submitHandler}
            // type='submit'
            disabled={isRequiredFieldsFilledIn || !isDirty()}
            color='success'
            size='medium'
            fullWidth
          >
            Сохранить
          </Button>
          <Button size='medium' fullWidth onClick={closeWithCheckingDirty}>
            Отменить
          </Button>
        </FormButtonWrapper>
      </Stack>
    </>
  )
}
