import { TableCellWrapper } from './TableCell.styles'
import { TableCellProps } from './TableCell.types'
import { Typography } from '@mui/material'
import { flexRender } from '@tanstack/react-table'
import { FC } from 'react'

export const TableCell: FC<TableCellProps> = ({ header }) => {
  return (
    <TableCellWrapper
      style={{
        flex: 1,
        minWidth: header.column.columnDef.minSize,
        width: header.column.columnDef.size,
        maxWidth: header.column.columnDef.maxSize,
      }}
    >
      <Typography variant='body2'>{flexRender(header.column.columnDef.header, header.getContext())}</Typography>
    </TableCellWrapper>
  )
}
