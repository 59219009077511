import { VieweredItemInfo } from '../CarouselViewer.types'
import { generateVieweredItemInfo } from './generateVieweredItemInfo'
import { PublicUserProfile } from 'api/profile/types'
import { RemarkMediaComments } from 'api/remarks/types'

export const generateNextVieweredItemInfo = (
  step: number,
  currentVieweredItemInfo: VieweredItemInfo | null,
  list: any[][],
  newMediaCommentById?: RemarkMediaComments | null,
  mediaCommentById?: RemarkMediaComments | null,
  profile?: PublicUserProfile,
) => {
  const { mediaIndex = 0, listIndex = 0 } = currentVieweredItemInfo || {}
  let currentList = list
  let currentInnerList = currentList[listIndex]

  let nextInnerList = currentList[listIndex + step]

  let nextMedia = null
  let nextMediaIndex = mediaIndex + step
  let nextInnerListIndex = listIndex + step

  if (step === 1) {
    if (nextMediaIndex < currentInnerList.length) {
      nextMedia = currentInnerList[nextMediaIndex]
      nextInnerListIndex = listIndex
    } else if (nextInnerListIndex < currentList.length) {
      nextMedia = nextInnerList[0]
      nextMediaIndex = 0
    } else {
      nextMedia = currentList[0][0]
      nextMediaIndex = 0
      nextInnerListIndex = 0
    }
  }

  if (step === -1) {
    if (nextMediaIndex >= 0) {
      nextMedia = currentInnerList[nextMediaIndex]
      nextInnerListIndex = listIndex
    } else if (nextInnerListIndex >= 0) {
      nextMedia = currentList[nextInnerListIndex].slice(-1)[0]
      nextMediaIndex = currentList[nextInnerListIndex].length - 1
    } else {
      const lastInnerList = currentList.slice(-1)[0]
      nextMedia = lastInnerList.slice(-1)[0]
      nextMediaIndex = lastInnerList.length - 1
      nextInnerListIndex = currentList.length - 1
    }
  }

  const nextVieweredItemInfo = generateVieweredItemInfo(
    nextMedia,
    nextMediaIndex,
    nextInnerListIndex,
    newMediaCommentById,
    mediaCommentById,
    profile,
  )

  return nextVieweredItemInfo
}
