import { Stack } from '@mui/material'
import styled from 'styled-components'

export const AuditItemWrapper = styled(Stack)`
  width: 100%;
  // height: 100px;
  padding: 16px 20px;

  & .icon-create {
    color: ${(props) => props.theme.palette.legends.lightgreen};
  }
`
