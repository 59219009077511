import { SectionsControlsWrapper, SectionsMenuItemWrapper } from './SectionsMenuItem.styles'
import { SectionsMenuItemProps } from './SectionsMenuItem.types'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Typography } from '@mui/material'
import { FC, MouseEvent } from 'react'

export const SectionsMenuItem: FC<SectionsMenuItemProps> = ({
  section,
  selected,
  onSectionSelect,
  onSectionEdit,
  onSectionDelete,
  sectionsEditAccess,
}) => {
  const { id, name } = section

  const onEdit = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    e.stopPropagation()
    onSectionEdit(id)
  }

  const onDelete = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    e.stopPropagation()
    onSectionDelete(id)
  }

  return (
    <SectionsMenuItemWrapper onClick={() => onSectionSelect(id)} selected={selected}>
      <Typography variant='body2'>{name}</Typography>

      {sectionsEditAccess && (
        <SectionsControlsWrapper>
          <IconButton onClick={(e) => onEdit(e, id)}>
            <EditIcon fontSize='small' color='primary' />
          </IconButton>
          <IconButton onClick={(e) => onDelete(e, id)}>
            <DeleteIcon fontSize='small' color='primary' />
          </IconButton>
        </SectionsControlsWrapper>
      )}
    </SectionsMenuItemWrapper>
  )
}
