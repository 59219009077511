import { CardSubtitle, StyledAvatar } from '../styles'
import { UserCardHeaderProps } from './UserCardHeader.types'
import { CardUserName, StyledCardHeader } from './styles'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import { Box, Stack, Typography } from '@mui/material'
import { ColoredTitle } from 'components/ColoredTitle'
import { formatMaskPhone } from 'components/PhoneFieldForm/PhoneFieldForm.utils'
import { UserRolesColors, userRolesEnToRu, UserRolesRu } from 'core/types/user'
import React from 'react'

export const UserCardHeader: React.FC<UserCardHeaderProps> = ({ userData, employeeData, mode }) => {
  const { email, firstName, middleName, avatarPreview, lastName, phone, role } = (userData || employeeData)!
  const formattedName = (
    <>
      {lastName}
      <br />
      {firstName} {middleName}
    </>
  )
  const roleRu: UserRolesRu = userRolesEnToRu[role]

  const formattedPhone = () => {
    return phone ? formatMaskPhone(phone) : phone
  }

  return (
    <StyledCardHeader
      avatar={
        <Box width={120}>
          {mode === 'full' && <ColoredTitle body={roleRu} color={UserRolesColors[roleRu]} />}
          <StyledAvatar src={avatarPreview}>
            <PersonSharpIcon sx={{ width: '80px', height: '80px' }} />
          </StyledAvatar>
        </Box>
      }
      title={<CardUserName variant='subtitle1'>{formattedName}</CardUserName>}
      subheader={
        <Stack spacing={0.5}>
          <CardSubtitle>Контакты:</CardSubtitle>
          <Typography variant='body2'>{formattedPhone()}</Typography>
          <Typography variant='body2' overflow='hidden' textOverflow='ellipsis' className='email'>
            {email}
          </Typography>
        </Stack>
      }
    />
  )
}
