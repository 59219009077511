import { ProjectIntegrationModule } from 'api/integrations/types'
import { DashboardDesignModuleIcon } from 'assets/icons/DashboardDesignModuleIcon'
import { DashboardRmModuleIcon } from 'assets/icons/DashboardRmModuleIcon'
import { UserRoles } from 'core/types/user'
import { PragmaModule } from 'pages/Administration/components/ProjectIntegrationDrawer/ProjectIntegrationDrawer.types'
import { ReactElement } from 'react'

export interface IntegratedModuleProps {
  projectId: number
  module: ProjectIntegrationModule
  role: UserRoles
}

export const dashboardModuleIconByModuleName: Record<PragmaModule, ReactElement> = {
  PragmaQC: <></>,
  PragmaProcurement: <></>,
  PragmaDesign: <DashboardDesignModuleIcon />,
  PragmaRM: <DashboardRmModuleIcon />,
  PragmaFinance: <></>,
  PragmaOffer: <></>,
}
