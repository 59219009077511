import { Stack } from '@mui/material'
import styled from 'styled-components'

export const CarouselViewerWrapper = styled(Stack)`
  padding: 40px;
  position: relative;
  height: 100vh;
  max-width: 1000px;
  width: 100vh;
  overflow: hidden;
  gap: 10px;
`

export const PreviewWrapper = styled(Stack)`
  position: relative;
  height: 75%;

  & img {
    object-fit: contain;
    max-height: 100%;
  }
`

interface ArrowButtonProps {
  isRight?: boolean
}

export const ArrowButton = styled.button<ArrowButtonProps>`
  position: absolute;
  top: 50%;
  left: -4%;
  display: flex;
  padding: 10px;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.isRight &&
    `
    left: 104%;
    transform: translate(100%, 0%);
    rotate: 180deg;
  `}
`

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;

  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`

export const RemarkNumberWrapper = styled.div`
  padding: 4px 8px;
  background-color: ${(props) => props.theme.palette.bg.gray};
  border-radius: 4px;
  min-width: 157px;
  text-align: center;
`
