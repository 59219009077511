import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

export const validationDocument = yup.object({
  type: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .required('common:errors.required'),
  number: yup.string().trim().required('common:errors.required'),
  date: yup.date().transform(formatDateInInput).required('common:errors.required'),
  comment: yup.string().trim(),
  file: yup.mixed(),
})
