import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface EmptyPageWrapperProps {
  forFilter: boolean
  fullPage?: boolean
}
export const EmptyPageWrapper = styled(Stack)<EmptyPageWrapperProps>`
  width: 100%;
  padding: 0 0 50px;
  padding-top: ${(props) => props.forFilter && '50px'};
  padding-top: ${(props) => props.fullPage && '107px'};
`

export const EmptyLink = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: ${(props) => props.theme.palette.primary.main};
`
