export interface InfoProps {
  viewingOnly: boolean
  formMode: 'add' | 'edit'
  isStatusChangeAvailable: boolean
}

export const inspectionTypeDropdown = [
  { id: 'CURRENT', text: 'Текущее' },
  { id: 'PLANNED', text: 'Плановое' },
  { id: 'INSPECTION', text: 'Инспекционное' },
  { id: 'TARGET', text: 'Целевое' },
]

export const typeDropdown = [
  { id: 'CONTINUE_WORK', text: 'Без остановки работ' },
  { id: 'PAUSE_WORK', text: 'С остановкой работ' },
]
