import styled from 'styled-components'

export const MediaTextarea = styled.textarea`
  padding: 12px 16px;
  width: 100%;
  text-align: end;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border: 2px solid transparent;
  outline: none;
  pointer-events: ${(props) => props.readOnly && 'none'};

  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.palette.text.dark};

  white-space: normal;
  font-family: sans-serif;
  line-height: 20px;

  resize: none;
  overflow: auto;
  min-height: 112px;
  max-height: 100%;
  text-align: start;
`
