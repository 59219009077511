import { AuditRemarkHeader, AuditRemarkWrapper } from './NewAuditItem.styles'
import { NewAuditItemProps } from './NewAuditItem.types'
import { AuditInfoItem } from './components/AuditInfoItem'
import { AuditTooltipMails } from './components/AuditTooltipMails'
import { Email as EmailIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { Tooltip } from 'components/Tooltip'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'

export const NewAuditItem: FC<NewAuditItemProps> = ({ auditData, children }) => {
  const { title, timestamp, mailRecipients, info } = auditData || {}
  const { fullDate, time } = parseResponseDateTime(timestamp)

  return (
    <AuditRemarkWrapper>
      <AuditRemarkHeader>
        <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
          {title}
        </Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
            {fullDate} {time}
          </Typography>
          {mailRecipients && (
            <Tooltip
              title={<AuditTooltipMails mailRecipients={mailRecipients} />}
              bgColor={theme.palette.bg.white}
              textColor={theme.palette.text.dark}
            >
              <EmailIcon fontSize={'small'} color={'primary'} />
            </Tooltip>
          )}
        </Stack>
      </AuditRemarkHeader>

      {info.map((infoItem) => (
        <AuditInfoItem infoItem={infoItem} />
      ))}

      {children}
    </AuditRemarkWrapper>
  )
}
