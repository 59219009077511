import { FormWrapper, TextField } from './EditAndAddDrawerForm.styles'
import { EditAndAddDrawerFormProps, multilineFields } from './EditAndAddDrawerForm.types'
import { Stack } from '@mui/material'
import {
  useCreateSectionMutation,
  useEditSectionMutation,
  useGetDropdownContractorsReferenceQuery,
  useGetProjectContractorsQuery,
} from 'api/references'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { Form, FormikProvider, useFormikContext } from 'formik'
import { useForm } from 'hooks/useForm'
import { useSnackbar } from 'notistack'
import { Reference, basicReferences } from 'pages/Administration/References/References.types'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const EditAndAddDrawerForm: FC<EditAndAddDrawerFormProps> = ({
  onClose,
  closeWithCheckingDirty,
  isDirty,
  formData,
  setFormData,
  target,
}) => {
  const { projectId: projectIdString, referenceId } = useParams()
  const projectId = Number(projectIdString)
  const [createSection, createSectionResponse] = useCreateSectionMutation()
  const [editSection, editSectionResponse] = useEditSectionMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [isEmailBlured, setIsEmailBlured] = useState(false)

  const getCurrentReference = (): Reference => {
    return basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0]
  }

  const { data: contractorsDropdown } = useGetDropdownContractorsReferenceQuery()
  const { data: projectContractorsDropdown } = useGetProjectContractorsQuery(
    { projectId: Number(projectId) },
    { skip: !projectId },
  )

  useEffect(() => {
    let localFormData: any = {}
    getCurrentReference().columns.forEach((item) => {
      localFormData[item.id] = { value: target ? target[item.id] || '' : '', required: item.required }
    })
    setFormData(localFormData)
  }, [])

  useEffect(() => {
    handleResponse(createSectionResponse, 'Позиция успешно добавлена.')
  }, [createSectionResponse])

  useEffect(() => {
    handleResponse(editSectionResponse, 'Позиция успешно изменена.')
  }, [editSectionResponse])

  const isRequiredFieldsFilledIn = useMemo(() => {
    if (!Object.keys(formData).length) return true
    // @ts-ignore
    if (Object.values(formData).some((item) => !item?.value && item?.required)) return true
    // if (Object.values(formData).some((item) => !item?.value?.trim() && item?.required)) return true;
    else return false
  }, [formData])

  const submitHandler = () => {
    const result = {}
    for (let key in formData) {
      // @ts-ignore
      result[key] = formData[key].value || null
    }

    if (getCurrentReference().id === 'PROJECT_MEMBER_REPRESENTATIVE') {
      // @ts-ignore
      result.projectMember = values.projectMember?.id
    }

    if (target) {
      editSection({
        url: projectId
          ? getCurrentReference()
              .api.editSection.projectUrl.replace('{id}', String(target.id))
              .replace('{project}', String(projectId))
          : getCurrentReference().api.editSection.url.replace('{id}', String(target.id)),
        body: result,
      })
    } else {
      createSection({
        url: projectId
          ? getCurrentReference().api.createSection.projectUrl.replace('{project}', String(projectId))
          : getCurrentReference().api.createSection.url,
        body: result,
      })
    }
  }

  const changeValue = (id: string, value: string) => {
    // @ts-ignore
    setFormData((prev) => ({ ...prev, [id]: { ...prev[id], value: value } }))
  }

  const handleResponse = (response: any, successText: string) => {
    if (response.isUninitialized || response.isLoading) return
    if (response.isError) {
      enqueueSnackbar(response.error.status === 409 ? 'Позиция уже существует.' : 'Произошла ошибка.', {
        variant: 'error',
      })
    } else {
      enqueueSnackbar(successText, { variant: 'success' })
      resetForm()
      onClose()
    }
  }

  const { values, setFieldValue, initialValues, dirty, resetForm } = useFormikContext<any>()

  const validateEmail = (email: string) => {
    if (!email.trim()) return true

    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  return (
    <>
      <FormWrapper>
        <Stack spacing={2.5} component={Form}>
          <Stack spacing={1.5}>
            {getCurrentReference().columns.map((item) => (
              <>
                {item.id === 'projectMember' && (
                  <CustomSelect
                    name='projectMember'
                    list={(projectId ? projectContractorsDropdown?.data : contractorsDropdown?.data) || []}
                    placeholder='Подрядчик'
                    isSearch={true}
                    style={{
                      textAlign: 'start',
                      height: '46px',
                      paddingLeft: '16px',
                    }}
                  />
                )}

                {item.id !== 'projectMember' &&
                  (multilineFields[getCurrentReference().id].includes(item.id) ? (
                    <FieldForm
                      version='project'
                      name='any'
                      placeholder={item.name}
                      helperText=''
                      // @ts-ignore
                      value={formData?.[item?.id]?.value}
                      onChange={(e) => changeValue(item.id, e.target.value)}
                      multiline
                      inputProps={{
                        style: {
                          minHeight: '60px',
                        },
                      }}
                    />
                  ) : (
                    <FieldForm
                      version='project'
                      name='any'
                      placeholder={item.name}
                      helperText=''
                      // @ts-ignore
                      value={formData?.[item?.id]?.value}
                      onChange={(e) => changeValue(item.id, e.target.value)}
                      onBlur={() => {
                        item.id === 'email' && setIsEmailBlured(true)
                      }}
                      inputProps={{
                        style:
                          item.id === 'email' && isEmailBlured && !validateEmail(formData['email']?.value || '')
                            ? { outline: '1px solid red' }
                            : {},
                      }}
                    />
                  ))}
              </>
            ))}
          </Stack>

          <Stack direction='row' spacing={2}>
            <Button
              onClick={submitHandler}
              disabled={
                getCurrentReference().id === 'PROJECT_MEMBER_REPRESENTATIVE'
                  ? !validateEmail(formData['email']?.value || '') ||
                    (target
                      ? !values.projectMember ||
                        isRequiredFieldsFilledIn ||
                        ((isRequiredFieldsFilledIn || !isDirty()) && (!values.projectMember || !dirty))
                      : isRequiredFieldsFilledIn || !isDirty() || !values.projectMember || !dirty)
                  : isRequiredFieldsFilledIn || !isDirty()
              }
              color='success'
              size='medium'
              fullWidth
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={closeWithCheckingDirty}>
              Отменить
            </Button>
          </Stack>
        </Stack>
      </FormWrapper>
    </>
  )
}
