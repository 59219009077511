import { LabelWrapper, TypeName } from './FilterItem.styles'
import { FilterItemProps } from './FilterItem.types'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Box, Stack, Typography } from '@mui/material'
import { StyledCheckbox } from 'components/FieldForm'
import { FC, useState } from 'react'
import { addTypeId, removeTypeId } from 'store/slices/documents'
import { useAppDispatch } from 'store/store'
import { theme } from 'styles/theme'

export const FilterItem: FC<FilterItemProps> = ({ type, checked }) => {
  const dispatch = useAppDispatch()

  const onLabelClick = () => {}

  const onCheckBoxChange = () => {
    checked ? dispatch(removeTypeId(type.id)) : dispatch(addTypeId(type.id))
  }

  return (
    <Stack height={'100%'}>
      <LabelWrapper
        onClick={onLabelClick}
        control={
          <StyledCheckbox
            checked={checked}
            onChange={onCheckBoxChange}
            icon={checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          />
        }
        label={
          <Stack direction={'row'} spacing={1} alignItems={'center'} width={'100%'}>
            <TypeName variant='subtitle2' color={theme.palette.text.dark}>
              {type.text}
            </TypeName>{' '}
            <Typography variant='h1' color={theme.palette.text.disabled}>
              ({type.subtext})
            </Typography>
          </Stack>
        }
        sx={{ mr: 0 }}
      />
    </Stack>
  )
}
