import { FullUserCardWrapper } from '../../AdminUsers/AdminUsers.styles'
import { ProjectCardProps } from './ProjectCard.types'
import { ProjectCardContent } from './ProjectCardContent'
import { ProjectCardContentData } from './ProjectCardContent/ProjectCardContent.types'
import { ProjectCardHeader } from './ProjectCardHeader'
import { ProjectCardHeaderData } from './ProjectCardHeader/ProjectCardHeader.types'
import { Divider } from 'components/Divider'
import React from 'react'

export const ProjectCard: React.FC<ProjectCardProps> = ({ data, onClick }) => {
  const { id, fullName, shortName, status, type, startDate, endDate } = data

  const headerData: ProjectCardHeaderData = { id, status, shortName, fullName }
  const contentData: ProjectCardContentData = { startDate, endDate, type }

  return (
    <FullUserCardWrapper onClick={() => onClick(id)}>
      <ProjectCardHeader data={headerData} />
      <Divider />
      <ProjectCardContent data={contentData} />
    </FullUserCardWrapper>
  )
}
