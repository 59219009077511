import { Stack, Box } from '@mui/material'
import styled from 'styled-components'

export const FullWrapper = styled(Stack)`
  height: calc(100vh - 66px);
  padding: 20px;
  margin-right: -13px;
`

export const FormItemsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  margin-bottom: 20px;
  overflow: scroll;

  @media (max-width: 1555px) {
    flex-wrap: wrap;
  }
`

export const ItemWrapper = styled(Stack)`
  border: 1px solid ${(props) => props.theme.palette.legends.gray};
  border-radius: 16px;
  // padding: 24px 4px 24px 20px;
  // overflow-y: scroll;

  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const FormWrapper = styled.div`
  height: calc(100% - 66px);
`

interface InfoWrapperProps {
  width: string
}

export const InfoWrapper = styled(Stack)<InfoWrapperProps>`
  width: ${(props) => props.width};
  border: 1px solid ${(props) => props.theme.palette.legends.gray};
  border-radius: 16px;
  margin-right: ${(props) => props.margin && '20px'};
  padding: 24px 20px;
  overflow-y: auto;
`

export const LinkText = styled.span`
  cursor: pointer;
  color: #00528c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
