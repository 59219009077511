import {
  ClearProjectMembersByProjectRequest,
  CreateProjectMemberByProjectRequest,
  CreateProjectMemberByProjectResponse,
  CreateProjectMemberRequest,
  CreateProjectMemberResponse,
  DeleteProjectMemberByProjectRequest,
  DeleteProjectMemberByProjectResponse,
  DeleteProjectMemberRequest,
  DeleteProjectMemberResponse,
  EditProjectMemberByProjectRequest,
  EditProjectMemberByProjectResponse,
  EditProjectMemberRequest,
  EditProjectMemberResponse,
  GetProjectMembersByProjectRequest,
  GetProjectMembersByProjectResponse,
  GetProjectMembersRequest,
  GetProjectMembersResponse,
  IExportProjectMembersByProjectRequest,
  IExportProjectMembersByProjectResponse,
  IExportProjectMembersResponse,
  IGetExportProjectMembersExportResultRequest,
  IGetExportProjectMembersExportResultResponse,
  IGetProjectMembersExportResultByProjectRequest,
  IGetProjectMembersExportResultByProjectResponse,
} from './api.types'
import { api } from 'api/api'
import { getTimestamp } from 'utils/dates/getTimestamp'

export const projectMembersApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Admin mode
    getProjectMembers: build.query<GetProjectMembersResponse, GetProjectMembersRequest>({
      query: ({ ...params }) => {
        return {
          method: 'GET',
          url: `/project-member/list`,
          params: params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          referenceId: 'projectMembers',
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged

        if (currentArg && otherArgsChanged) currentArg.page = 1

        return pageChanged || otherArgsChanged
      },
      providesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    clearProjectMembers: build.mutation<void, void>({
      query: () => ({
        url: '/project-member/delete/all',
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    createProjectMember: build.mutation<CreateProjectMemberResponse, CreateProjectMemberRequest>({
      query: ({ body }) => ({
        url: '/project-member/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    editProjectMember: build.mutation<EditProjectMemberResponse, EditProjectMemberRequest>({
      query: ({ projectMemberId, body }) => ({
        url: `/project-member/${projectMemberId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ projectMemberId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(projectMembersApi.util.invalidateTags(['References', { type: 'References', id: 'PROJECT_MEMBERS' }]))
        } catch {}
      },
    }),
    deleteProjectMember: build.mutation<DeleteProjectMemberResponse, DeleteProjectMemberRequest>({
      query: ({ projectMemberId }) => ({
        url: `/project-member/${projectMemberId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectMemberId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(projectMembersApi.util.invalidateTags(['References', { type: 'References', id: 'PROJECT_MEMBERS' }]))
        } catch {}
      },
    }),
    exportProjectMembers: build.query<IExportProjectMembersResponse, void>({
      query: () => ({
        url: `/project-member/export`,
        method: 'POST',
        body: { timestamp: getTimestamp() },
      }),
    }),
    getProjectMembersExportResult: build.query<
      IGetExportProjectMembersExportResultResponse,
      IGetExportProjectMembersExportResultRequest
    >({
      query: ({ ...params }) => ({
        url: `/project-member/export/result`,
        method: 'GET',
        params,
      }),
    }),

    // Project mode
    getProjectMembersByProject: build.query<GetProjectMembersByProjectResponse, GetProjectMembersByProjectRequest>({
      query: ({ projectId, ...params }) => {
        return {
          method: 'GET',
          url: `/project-member/${projectId}/list`,
          params: params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged

        if (currentArg && otherArgsChanged) currentArg.page = 1

        return pageChanged || otherArgsChanged
      },
      providesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    clearProjectMembersByProject: build.mutation<void, ClearProjectMembersByProjectRequest>({
      query: ({ projectId }) => ({
        url: `/project-member/${projectId}/delete/all`,
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    createProjectMemberByProject: build.mutation<
      CreateProjectMemberByProjectResponse,
      CreateProjectMemberByProjectRequest
    >({
      query: ({ projectId, body }) => ({
        url: `/project-member/${projectId}/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    editProjectMemberByProject: build.mutation<EditProjectMemberByProjectResponse, EditProjectMemberByProjectRequest>({
      query: ({ projectId, projectMemberId, body }) => ({
        url: `/project-member/${projectId}/${projectMemberId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(projectMembersApi.util.invalidateTags(['References', { type: 'References', id: 'PROJECT_MEMBERS' }]))
        } catch {}
      },
    }),
    deleteProjectMemberByProject: build.mutation<
      DeleteProjectMemberByProjectResponse,
      DeleteProjectMemberByProjectRequest
    >({
      query: ({ projectId, projectMemberId }) => ({
        url: `/project-member/${projectId}/${projectMemberId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectMemberId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(projectMembersApi.util.invalidateTags(['References', { type: 'References', id: 'PROJECT_MEMBERS' }]))
        } catch {}
      },
    }),
    exportProjectMembersByProject: build.query<
      IExportProjectMembersByProjectResponse,
      IExportProjectMembersByProjectRequest
    >({
      query: ({ projectId }) => ({
        url: `/project-member/${projectId}/export`,
        method: 'POST',
        body: { timestamp: getTimestamp() },
      }),
    }),
    getProjectMembersExportResultByProject: build.query<
      IGetProjectMembersExportResultByProjectResponse,
      IGetProjectMembersExportResultByProjectRequest
    >({
      query: ({ projectId, ...params }) => ({
        url: `/project-member/${projectId}/export/result`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  // Admin mode
  useGetProjectMembersQuery,
  useClearProjectMembersMutation,
  useCreateProjectMemberMutation,
  useEditProjectMemberMutation,
  useDeleteProjectMemberMutation,
  useLazyExportProjectMembersQuery,
  useLazyGetProjectMembersExportResultQuery,

  // Project mode
  useGetProjectMembersByProjectQuery,
  useClearProjectMembersByProjectMutation,
  useCreateProjectMemberByProjectMutation,
  useEditProjectMemberByProjectMutation,
  useDeleteProjectMemberByProjectMutation,
  useLazyExportProjectMembersByProjectQuery,
  useLazyGetProjectMembersExportResultByProjectQuery,
} = projectMembersApi
