import { Container } from '@mui/material'
import styled from 'styled-components'

export const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
`

export const InnerContainer = styled(Container)`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  padding: 0 0 16px 20px;
  overflow: hidden;
`
