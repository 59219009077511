import { Box, IconButton, Stack } from '@mui/material'
import styled from 'styled-components'

export const StyledPdfViewer = styled(Box)`
  width: 100%;
  height: 100%;

  &,
  & .rpv-core__textbox,
  & button:not(.rpv-core__menu-item) {
    font-family: 'Roboto', sans-serif;
    font-weight: ${(props) => props.theme.typography.subtitle1.fontWeight};
    font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
    line-height: ${(props) => props.theme.typography.subtitle1.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.subtitle1.letterSpacing};
    color: ${(props) => props.theme.palette.text.dark};
  }

  & .rpv-default-layout__container {
    border: 0;
    border-radius: 8px;
  }

  & .rpv-default-layout__body {
    border-radius: 0 8px 8px 0;
  }

  & .rpv-default-layout__sidebar-headers {
    background-color: ${(props) => props.theme.palette.bg.white};
    border-radius: 0 0 0 8px;
  }

  & .rpv-default-layout__toolbar {
    background-color: ${(props) => props.theme.palette.bg.white};
    border-radius: 8px 8px 0 0;
    min-height: 2.5rem;
    height: auto;
  }

  & .rpv-core__tooltip-body {
    display: none;
  }

  & .rpv-default-layout__sidebar-headers .rpv-core__minimal-button--selected {
    background-color: transparent;

    &:hover {
      background-color: #dddee1;
    }
  }
`

export const PageNavigationWrapper = styled(Stack)`
  & .rpv-page-navigation__current-page-input {
    margin: 0;

    & > input {
      width: inherit;
      height: 28px;
      text-align: center;
      background-color: ${(props) => props.theme.palette.bg.shades};
      border: none;
      border-radius: 0;
    }
  }
`

export const ZoomWrapper = styled(Stack)`
  & .rpv-zoom__popover-target-scale {
    margin: 0;
  }

  & .rpv-zoom__popover-target-arrow {
    display: none;
  }

  & .rpv-core__minimal-button {
    height: 28px;
    padding: 0 15px;
    text-align: center;
    background-color: ${(props) => props.theme.palette.bg.shades};
    border-radius: 0;
  }

  & button {
    line-height: 100% !important;
  }

  & .rpv-core__menu-item-label {
    font-family: 'Roboto', sans-serif;
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.body2.letterSpacing};
    color: ${(props) => props.theme.palette.text.dark};
  }
`

export const MenuIconButton = styled(IconButton)`
  padding: 0;
`
