import { Stack, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

export const RemarkItemWrapper = styled(Stack)`
  padding: 12px 0;
  border-top: 1px solid ${(props) => props.theme.palette.legends.gray};

  &:first-child {
    border-top: none;
  }

  &:last-child {
    padding: 12px 0 0 0;
  }
`

export const Description = styled(Typography)`
  text-align: start;
  white-space: pre-wrap;
`

const InitialWrapper = css`
  width: 100px;
  height: 100px;
  aspect-ratio: 1;
  border-radius: 3px;
`

export const Image = styled.img`
  display: block;
  object-fit: cover;
  ${InitialWrapper}

  &:hover {
    cursor: pointer;
  }
`

export const FileWrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  ${InitialWrapper}

  & svg {
    transform: scale(5.4);
  }

  &:hover {
    cursor: pointer;
  }
`

interface IconWrapperProps {
  isDisabled: boolean
}

export const IconWrapper = styled(Stack)<IconWrapperProps>`
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 4px;

  ${(props) =>
    props.isDisabled
      ? `
    & svg {
      fill: ${props.theme.palette.disabled};
    }
  `
      : `
    &:hover {
      cursor: pointer
    }
  `}
`
