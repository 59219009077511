import { Box, Stack } from '@mui/material'
import { ProjectDrawerTopBar, ProjectDrawerWrapper } from 'pages/Administration/AdminProjects/ProjectDrawer/styles'
import styled from 'styled-components'

export const UploadResultsDrawerWrapper = styled(ProjectDrawerWrapper)``

export const UploadResultsDrawerTopBar = styled(ProjectDrawerTopBar)``

export const UploadResultsEntireWrapper = styled(Box)`
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @supports (-moz-appearance: none) {
    scrollbar-width: none;
  }

  & .MuiDivider-root {
    height: 1px;
  }
`

export const UploadResultsWrapper = styled(Stack)``

interface UploadResultsButtonWrapperProps {
  disabled: boolean
}

export const UploadResultsButtonWrapper = styled(Stack)<UploadResultsButtonWrapperProps>`
  position: sticky;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  background-color: ${(props) => props.theme.palette.bg.white};

  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};

  & .MuiSvgIcon-root {
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  }
`
