import styled from 'styled-components'
import { Stack } from '@mui/material'

export const TanglIntegrationInfoCard = styled(Stack)`
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 80px 20px;
  gap: 40px;
  border-radius: 16px;
  max-width: 450px;
  width: 100%;
`
