import { Stack, Typography } from '@mui/material'
import { Wrapper as MediaWrapper } from 'pages/Remarks/components/RemarkForm/Media/Media.styles'
import styled from 'styled-components'

export const Wrapper = styled(MediaWrapper)`
  width: 34.6%;

  @media (max-width: 1555px) {
    width: 99.5%;
    margin-top: 20px;
  }
`

export const RemarkList = styled(Stack)`
  height: 100%;
  overflow-y: scroll;
  padding-right: 6px;
  margin-right: -20px;
`

export const RemarkWarning = styled(Typography)`
  color: ${(props) => props.theme.palette.legends.disabled};
  font-size: 18px;
  width: fit-content;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
