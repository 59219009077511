import { ImportDrawer } from '../ReferenceDrawers/ImportDrawer'
import { RepresentativesDialogTrigger, RepresentativesDrawerType, RepresentativesProps } from './Representatives.types'
import { RepresentativesManagment } from './components/RepresentativesManagment'
import { Stack } from '@mui/material'
import { ProjectMember } from 'api/references/projectMembers/types'
import {
  representativesApi,
  useClearRepresentativesByProjectMutation,
  useClearRepresentativesMutation,
  useGetRepresentativesByProjectQuery,
  useGetRepresentativesQuery,
} from 'api/references/representatives'
import { Representative } from 'api/references/representatives/types'
import { Table } from 'components/Table'
import { CustomColumnDef } from 'components/Table/Table.types'
import { TableManagment } from 'components/TableManagment'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSearch } from 'hooks/useSearch'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const Representatives: FC<RepresentativesProps> = () => {
  const { projectId: projectIdString } = useParams()
  const { searchValue } = useSearch()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const queryForTable = isProjectMode ? useGetRepresentativesByProjectQuery : useGetRepresentativesQuery
  const queryArgs = isProjectMode ? { projectId, query: searchValue } : { query: searchValue }
  const navigate = useNavigate()
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess
  const [openedDrawer, setOpenedDrawer] = useState<RepresentativesDrawerType | null>(null)
  const [selectedRepresentative, setSelectedRepresentative] = useState<Representative | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const [clearRepresentatives, { isLoading: isClearing, ...clearRepresentativesResponse }] =
    useClearRepresentativesMutation()
  const [clearRepresentativesByProject, { isLoading: isClearingByProject, ...clearRepresentativesByProjectResponse }] =
    useClearRepresentativesByProjectMutation()

  const columns: CustomColumnDef<Representative>[] = [
    {
      accessorKey: 'projectMember',
      header: 'Участник проекта',
      cell: (info) => {
        const projectMember = info.getValue<ProjectMember>()
        return projectMember.shortName || '—'
      },
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'fullName',
      header: 'ФИО',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'basis',
      header: 'Основание',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'position',
      header: 'Должность',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'email',
      header: 'E-mail',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'note',
      header: 'Примечание',
      textAlign: 'left',
      minSize: 150,
    },
  ]

  const onRowClick = (representative: Representative) => {
    setSelectedRepresentative(representative)
    setOpenedDrawer('managment')
  }

  const onDrawerOpen = useCallback((drawerType: RepresentativesDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback(
    (dialogType: RepresentativesDialogTrigger, dirty: boolean, immediately?: boolean) => {
      if (immediately || !dirty) {
        setOpenedDrawer(null)
        setSelectedRepresentative(null)
      } else {
        setConfirmDialogTrigger(dialogType)
        openConfirm()
      }
    },
    [],
  )

  const handleCloseManagment = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
      setSelectedRepresentative(null)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<RepresentativesDialogTrigger>('closeManagment')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeManagment: { handleConfirm: handleCloseManagment },
    delete: { handleConfirm: handleCloseManagment },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onClearReference = useCallback(() => {
    isProjectMode ? clearRepresentativesByProject({ projectId }) : clearRepresentatives()
  }, [])

  const onReturnClick = useCallback(() => {
    projectId ? navigate(`/project/${projectId}/references`) : navigate(`/administration/references`)
  }, [projectId])

  useMutationHandlers(isProjectMode ? clearRepresentativesByProjectResponse : clearRepresentativesResponse, () => {
    enqueueSnackbar('Справочник успешно очищен.', { variant: 'success' })
  })

  return (
    <Stack height={'100%'}>
      <Stack paddingRight={'20px'}>
        <TableManagment
          onReturnClick={onReturnClick}
          onClearClick={onClearReference}
          onImportClick={!viewingOnly ? () => onDrawerOpen('import') : undefined}
          onAddClick={!viewingOnly ? () => onDrawerOpen('managment') : undefined}
          buttonManagmentTitle='Управление'
        />
      </Stack>

      <Table
        api={representativesApi}
        columns={columns}
        query={queryForTable}
        tags={[{ type: 'References', id: 'PROJECT_MEMBERS' }]}
        onRowClick={onRowClick}
        queryArgs={queryArgs}
      />

      <RepresentativesManagment
        isOpen={openedDrawer === 'managment'}
        onClose={(dirty, immediately) => onDrawerClose('closeManagment', dirty, immediately)}
        representative={selectedRepresentative}
      />

      <ImportDrawer isOpen={openedDrawer === 'import'} onClose={() => onDrawerClose('closeManagment', false, false)} />

      <ConfirmDialog />
    </Stack>
  )
}
