import { DynamicWidgetProps } from './DynamicWidget.types'
import { DynamicWidgetChart } from './components/DynamicWidgetChart'
import { Stack } from '@mui/material'
import { AnalyticsWidgetItem } from 'pages/Analytics/Analytics.styles'
import { WidgetHeader } from 'pages/Analytics/components/WidgetHeader'
import { FC } from 'react'

export const DynamicWidget: FC<DynamicWidgetProps> = ({ analyticsQueryData }) => {
  return (
    <AnalyticsWidgetItem width={'58%'}>
      <Stack spacing={1.25}>
        <WidgetHeader
          title='Динамические показатели'
          tooltipTitle='В статистике учитываются предписания с датой предписания в выбранном временном интервале.'
        />

        <Stack height={'458px'}>
          <DynamicWidgetChart analyticsQueryData={analyticsQueryData} />
        </Stack>
      </Stack>
    </AnalyticsWidgetItem>
  )
}
