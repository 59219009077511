import { emptyRemarksTxt } from '../../BindRemarksDrawer.text'
import { BindRemarksFormData } from '../BindRemarksForm'
import { ObjectItem } from '../ObjectItem'
import { allRemarkSelectTxt } from './ObjectList.text'
import {
  ObjectsAllItemWrapper,
  ObjectsFullWrapper,
  ObjectsItemsWrapper,
  ObjectsListWrapper,
} from './ObjectsList.styles'
import { ObjectsListProps } from './ObjectsList.types'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { Progress } from 'components/Progress'
import { Tooltip } from 'components/Tooltip'
import { FieldArray, useFormikContext } from 'formik'
import { cloneDeep } from 'lodash'
import { ChangeEvent, FC, useEffect } from 'react'
import { theme } from 'styles/theme'

export const ObjectsList: FC<ObjectsListProps> = ({ searchValue, shouldWriteFirstChoice, data, isLoading }) => {
  const { values: formValues, setFieldValue } = useFormikContext<BindRemarksFormData>()
  const { choice: formChoice } = formValues

  // all checkbox
  const checked = formChoice.all

  // useEffect(() => {
  //   if (!data?.data?.length) setFieldValue('viewObjectId', null)
  // }, [data])

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const checked = e.target.checked
    let localChoice = cloneDeep(formChoice)
    const { includedObjects } = localChoice

    data?.data.forEach((item) => {
      delete includedObjects[item.id]
    })

    if (checked) {
      const newIncludedObjects = data?.data
        .map((item) => ({
          [item.id]: { all: false, exclude: [], include: item.shortcomings.map((item) => item.shortcoming.id) },
        }))
        .reduce((acc, obj) => {
          const key: any = Object.keys(obj)[0]
          acc[key] = obj[key]
          return acc
        }, {})

      localChoice = { ...localChoice, includedObjects: { ...includedObjects, ...newIncludedObjects } }
    }

    setFieldValue('choice', { ...localChoice, all: checked })
  }

  console.log('choice', formChoice)

  return (
    <ObjectsFullWrapper isEmpty={!data?.data?.length}>
      {isLoading ? (
        <Progress />
      ) : !data?.data?.length ? (
        searchValue ? (
          <>Отсутствуют замечания, соответствующие результатам запроса.</>
        ) : (
          <Stack textAlign={'center'}>{emptyRemarksTxt}</Stack>
        )
      ) : (
        <ObjectsListWrapper>
          <Stack direction={'row'} alignItems={'center'}>
            <ObjectsAllItemWrapper>
              <FieldForm
                version='checkbox'
                name={``}
                checkboxData={{
                  label: (
                    <Typography variant='subtitle2' fontWeight={400} color={theme.palette.text.dark}>
                      Выбрать все замечания
                    </Typography>
                  ),
                  checked,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e),
                  onLabelClick: (e) => e.stopPropagation(),
                }}
              />
            </ObjectsAllItemWrapper>
            <Tooltip title={allRemarkSelectTxt} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
              <InfoIcon fontSize='inherit' color='action' />
            </Tooltip>
          </Stack>

          <FieldArray
            name=''
            validateOnChange={false}
            render={() => {
              return (
                <ObjectsItemsWrapper>
                  {data.data.map((object) => (
                    <ObjectItem object={object} objectList={data.data} />
                  ))}
                </ObjectsItemsWrapper>
              )
            }}
          />
        </ObjectsListWrapper>
      )}
    </ObjectsFullWrapper>
  )
}
