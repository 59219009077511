import { ColumnsDrawer } from '../../../Prescriptions/components/ColumnsDrawer'
import { ReturnIconButton } from './RemarksLegend.styles'
import { ColumnSettings, RemarksLegendProps } from './RemarksLegend.types'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Badge, Button, Stack } from '@mui/material'
import { UseQuery } from 'api/api.types'
import { useGetColumnSettingsQuery } from 'api/prescriptions'
import { useGetRemarksColumnSettingsQuery } from 'api/remarks'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import React, { FC, useCallback, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

export const RemarksLegend: FC<RemarksLegendProps> = ({ accessToAdd, createButtonData, children, columnSettings }) => {
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState<boolean>(false)
  const onColumnsDrawerOpen = () => {
    setColumnsDrawerOpen(true)
  }

  const columnSettingsQueries: Record<ColumnSettings, UseQuery<any, any>> = {
    prescriptions: useGetColumnSettingsQuery,
    remarks: useGetRemarksColumnSettingsQuery,
  }

  const query = columnSettings && columnSettingsQueries[columnSettings]

  // confirm close
  const handleExitConfirm = (confirm: boolean) => {
    if (confirm) {
      setColumnsDrawerOpen(false)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleExitConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onColumnsDrawerClose = (dirty: boolean) => {
    dirty ? openConfirm() : setColumnsDrawerOpen(false)
  }

  const onAddClick = () => {
    navigate(createButtonData.href, createButtonData?.hrefState || undefined)
  }

  const onReturnClick = () => {
    navigate(`/project/${projectId}`)
  }

  // @ts-ignore
  const { data: columnsData } = query({ projectId }, { skip: !columnSettings })

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5, pr: 2.5 }}>
        <Stack direction='row' alignItems='center' spacing={1.5}>
          <ReturnIconButton onClick={onReturnClick} color='secondary'>
            <ArrowBackIcon color='primary' />
          </ReturnIconButton>
          {accessToAdd && (
            <Button
              // component={Link}
              // to={createButtonData.href, }
              onClick={onAddClick}
              variant='outlined'
              color='secondary'
              startIcon={<AddIcon />}
            >
              {createButtonData.text}
            </Button>
          )}
          {children}
        </Stack>

        {columnSettings && (
          <Badge
            invisible={!!columnsData?.default}
            color='primary'
            badgeContent=''
            variant='dot'
            componentsProps={{ badge: { style: { height: 10, width: 10, borderRadius: '50%' } } }}
          >
            <Button onClick={onColumnsDrawerOpen} variant='text' color='secondary'>
              Настройки отображения
            </Button>
          </Badge>
        )}
      </Stack>

      {columnSettings && (
        <ColumnsDrawer
          open={columnsDrawerOpen}
          columnsData={columnsData}
          columnSettings={columnSettings}
          onClose={onColumnsDrawerClose}
        />
      )}

      <ConfirmDialog />
    </>
  )
}
