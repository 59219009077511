import { AdminLegendProps } from './AdminLegend.types'
import AddIcon from '@mui/icons-material/Add'
import { Stack } from '@mui/material'
import { Button } from 'components/Button'
import { Tabs, TabsEntities } from 'components/Tabs'
import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const AdminLegend = <T extends TabsEntities>({
  currentTab,
  tabsData,
  onTabChange,
  onAddClick,
}: AdminLegendProps<T> & { children?: ReactNode }) => {
  const location = useLocation()
  const isAdminPage: boolean = location.pathname.split('/')[1] === 'administration'
  const profile = useTypedSelector(profileSelector)

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1, pr: 2.5 }}>
      <Tabs<T> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />
      {(isAdminPage || profile.role === 'admin') && onAddClick && (
        <Button icon onClick={onAddClick} color='secondary' variant='outlined'>
          <AddIcon />
        </Button>
      )}
    </Stack>
  )
}
