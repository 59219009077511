import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const ProjectNameWrapper = styled(Stack)`
  max-width: 311px;
  width: fit-content;
  // width: 100%;
  padding: 8px;
  border-radius: 6px;
`
interface NameProps {
  maxLines: number
}

export const Name = styled(Typography)<NameProps>`
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`
