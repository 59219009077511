import { SvgIconProps } from '@mui/material'
import { FC } from 'react'

export const IasLogoIcon: FC<SvgIconProps> = (props) => {
  return (
    <svg {...props} width='79' height='44' viewBox='0 0 79 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M46 30L48 28L47 27L45 29L46 30Z' fill='#005CE5' />
      <path d='M47 29L48.5 27.5L64 40H54.5L45.5 30.5L47 29Z' fill='#005CE5' />
      <path d='M46.1641 33H22L19 36H49L46.1641 33Z' fill='#005CE5' />
      <path d='M15 40H52.7891L49.9531 37H18L15 40Z' fill='#005CE5' />
      <path
        d='M0 37.5C0 41.0899 2.91015 44 6.5 44H39V41H7C4.79086 41 3 39.2091 3 37C3 34.7909 4.79086 33 7 33C7 33 8 33 8 33.5C8 34 7.00009 34.4999 6.00013 34.9999L5.00006 35.5C4.28782 35.8269 4.04151 36.133 4.12501 37C4.46363 38.3413 5.47677 39.5224 7 39.8855C7.86731 39.7785 8.32723 39.5358 9.25 39L22 30.5V30L11 35C11 33.6985 10.1563 32.397 8.8759 31.6466L23 27V25L4.86509 31.2073C2.06662 31.9324 0 34.4748 0 37.5Z'
        fill='#005CE5'
      />
      <path
        d='M79 37.5C79 41.0899 76.0899 44 72.5 44H39V41H72C74.2091 41 76 39.2091 76 37C76 34.7909 74.2091 33 72 33C72 33 71 33 71 33.5C71 34 71.9999 34.4999 72.9999 34.9999L73.9999 35.5C74.7122 35.8269 74.9585 36.133 74.875 37C74.5364 38.3413 73.5232 39.5224 72 39.8855C71.3752 39.8218 70.8394 39.5988 69.75 39L55 30.5V30L68 35C68 33.6985 68.8437 32.397 70.1241 31.6466L55 27V25L74.1349 31.2073C76.9334 31.9324 79 34.4748 79 37.5Z'
        fill='#005CE5'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M59 12L39 0L19 12H32.6738C34.4632 10.7399 36.6452 10 39 10C41.3548 10 43.5368 10.7399 45.3262 12H59ZM31.4501 13H21V15H29.7789C30.2559 14.2684 30.8176 13.5971 31.4501 13ZM48.2211 15C47.7441 14.2684 47.1824 13.5971 46.5499 13H57V15H48.2211ZM27 16H23V18H24V30H23V32H27V30H26V18H27V16ZM51 16H55V18H54V29.8594L52 28.6719V18H51V16Z'
        fill='#005CE5'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M39 31C44.5228 31 49 26.5228 49 21C49 15.4772 44.5228 11 39 11C33.4772 11 29 15.4772 29 21C29 26.5228 33.4772 31 39 31ZM39 29C43.4183 29 47 25.4183 47 21C47 16.5817 43.4183 13 39 13C34.5817 13 31 16.5817 31 21C31 25.4183 34.5817 29 39 29Z'
        fill='#005CE5'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M39.75 14H38.25V15H34.5V16.5V18.5H32V20H38.25V22H32V23.5H34.5V25.5V27H38.25V28H39.75V27H43.5V25.5V23.5H46V22H39.75V20H45.5V18.5H43.5V16.5V15H39.75V14ZM42 18.5V16.5H36V18.5H42ZM42 23.5V25.5H36V23.5H42Z'
        fill='#005CE5'
      />
    </svg>
  )
}
