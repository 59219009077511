import { RegulationAttachmentCell } from './AttachmentCell.styles'
import { AttachmentCellProps, AttachmentCellVariant } from './AttachmentCell.types'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import LinkIcon from '@mui/icons-material/Link'
import { Stack } from '@mui/material'
import { TABLE_CELL_HEIGHT } from 'pages/Remarks/components/RemarksTable'
import { FC, MouseEvent, ReactNode } from 'react'
import { downloadMedia } from 'utils/downloadMedia'

export const AttachmentCell: FC<AttachmentCellProps> = ({ info, variant, onFileClick }) => {
  const { row, column } = info
  const { minSize, size, maxSize } = column.columnDef
  const item = row.original
  const { id, name, link, file } = item

  const LinkContent =
    variant === 'link' && link ? (
      <a href={link} target='_blank'>
        <LinkIcon color='primary' />
      </a>
    ) : (
      '—'
    )

  const FileContent = variant === 'file' && file?.link ? <DescriptionOutlinedIcon color='primary' /> : '—'

  const Content: Record<AttachmentCellVariant, ReactNode> = {
    file: FileContent,
    link: LinkContent,
  }

  const onCellClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (variant === 'file') {
      file?.link && onFileClick?.(file?.link, name, () => downloadMedia(file?.link))
    }
  }

  const hoverable: Record<AttachmentCellVariant, boolean> = {
    file: !!file?.link,
    link: !!link,
  }

  return (
    <RegulationAttachmentCell
      key={id}
      style={{
        minWidth: minSize,
        width: size,
        maxWidth: maxSize,
      }}
      onClick={onCellClick}
      hoverable={hoverable[variant]}
    >
      <Stack height={TABLE_CELL_HEIGHT} justifyContent='center'>
        {Content[variant]}
      </Stack>
    </RegulationAttachmentCell>
  )
}
