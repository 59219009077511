import { Stack } from '@mui/material'
import styled from 'styled-components'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

interface ObjectsFullWrapperProps {
  isEmpty?: boolean
}
export const ObjectsFullWrapper = styled(Stack)<ObjectsFullWrapperProps>`
  width: 100%;
  justify-content: ${(props) => props.isEmpty && 'center'};
  align-items: ${(props) => props.isEmpty && 'center'};
  min-width: 240px;
  box-shadow: ${(props) => `inset -1px 0 0 ${props.theme.palette.legends.gray}`};
`

export const ObjectsListWrapper = styled(Stack)`
  gap: 12px;
`

export const ObjectsAllItemWrapper = styled(Stack)`
  height: 35px;
  padding: 5px 10px 5px 12px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    & .MuiTypography-root,
    & .MuiCheckbox-root {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  & .MuiCheckbox-root {
    padding: 0;
    color: ${(props) => props.theme.palette.secondary.dark};

    &.Mui-checked,
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
      background-color: transparent;
    }
  }
`

export const ObjectsItemsWrapper = styled(ScrollableContainer)`
  width: 240px;
  align-items: center;
  gap: 4px;
`
