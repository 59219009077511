import {
  Card,
  CardInfo,
  CardInfoWrapper,
  CardSubtitle,
  CardTitle,
  CardWrapper,
  Customer,
  CustomerCompany,
  DateWrapper,
  IconWrapper,
  Status,
} from './ProjectCard.styles'
import { ProjectCardProps } from './ProjectCard.types'
import { OverallProject } from 'api/projects/types'
import { AlertIcon } from 'assets/icons/AlertIcon'
import { CalendarIcon } from 'assets/icons/CalendarIcon'
import { CustomerIcon } from 'assets/icons/CustomerIcon'
import { MapMarkerIcon } from 'assets/icons/MapMarkerIcon'
import { FC, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const ProjectCard: FC<ProjectCardProps> = ({ item }) => {
  const projectId = item.project.id

  return (
    <CardWrapper>
      <Link to={`/project/${projectId}`}>
        <Card>
          <CardTitle>{item.project.shortName || '—'}</CardTitle>
          <CardSubtitle>{item.project.fullName || '—'}</CardSubtitle>
          <CardInfoWrapper>
            <CardInfo>
              <span>
                <IconWrapper>
                  <AlertIcon fill={theme.palette.secondary.dark} width={14} height={14} />
                </IconWrapper>
                Статус:
              </span>
              <Status>{item.project.status || '—'}</Status>
            </CardInfo>
            <CardInfo>
              <span>
                <IconWrapper>
                  <CalendarIcon fill={theme.palette.secondary.dark} width={14} height={14} />
                </IconWrapper>
                Срок реализации:
              </span>
              <DateWrapper>
                <span>{item.project.startDate}</span> — <span>{item.project.endDate || '—'}</span>
              </DateWrapper>
            </CardInfo>
            <CardInfo>
              <span>
                <IconWrapper>
                  <MapMarkerIcon fill={theme.palette.secondary.dark} width={12} height={14} />
                </IconWrapper>
                Регион:
              </span>
              <span>{item.addressData.region || '—'}</span>
            </CardInfo>
          </CardInfoWrapper>
          <Customer>
            <span>
              <IconWrapper>
                <CustomerIcon fill={theme.palette.secondary.dark} width={12} height={12} />
              </IconWrapper>
              Заказчик:
            </span>
            <CustomerCompany>{item.responsiblePersons.clientCurator.company || '—'}</CustomerCompany>
          </Customer>
        </Card>
      </Link>
    </CardWrapper>
  )
}
