import { StyledCardInfo } from '../../UserCardContent/UserCardInfo/styles'
import { InfoRow } from 'components/InfoRow'
import styled from 'styled-components'

export const StyledProjectCardContent = styled(StyledCardInfo)`
  padding: 10px 0 0 0 !important;
`

export const TypeInfoRow = styled(InfoRow)`
  height: 40px;

  & .info-row-title {
    min-width: fit-content;
  }
`
