import { FC } from 'react'

export const StickyNoteIcon: FC = () => {
  return (
    <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 3.5V8M24 3.5V8M10.5 17H22.5M10.5 23H18M22.5 33.5H13.5C6 33.5 4.5 30.41 4.5 24.23V14.975C4.5 7.925 7.005 6.035 12 5.75H24C28.995 6.02 31.5 7.925 31.5 14.975V24.5'
        stroke='#6D9ADC'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M31.5 24.5L22.5 33.5V29C22.5 26 24 24.5 27 24.5H31.5Z'
        stroke='#6D9ADC'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
