export type ProjectMemberRole =
  | 'CLIENT'
  | 'GENERAL_DESIGNER'
  | 'TECHNICAL_CLIENT'
  | 'GENERAL_CONTRACTOR'
  | 'QUALITY_CONTROL'
  | 'CONTRACTOR'
  | 'NONE'

export type ProjectMemberRoleRu =
  | 'Заказчик'
  | 'Генпроектировщик'
  | 'Технический заказчик'
  | 'Генеральный подрядчик'
  | 'Строительный контроль'
  | 'Подрядчик'
  | 'Без роли'

export const projectMemberRoles: ProjectMemberRoleRu[] = [
  'Заказчик',
  'Генпроектировщик',
  'Технический заказчик',
  'Генеральный подрядчик',
  'Строительный контроль',
  'Подрядчик',
  'Без роли',
]

export const projectMemberRoleRuByEn: Record<ProjectMemberRole, ProjectMemberRoleRu> = {
  CLIENT: 'Заказчик',
  GENERAL_DESIGNER: 'Генпроектировщик',
  TECHNICAL_CLIENT: 'Технический заказчик',
  GENERAL_CONTRACTOR: 'Генеральный подрядчик',
  QUALITY_CONTROL: 'Строительный контроль',
  CONTRACTOR: 'Подрядчик',
  NONE: 'Без роли',
}

export const projectMemberRoleEnByRu: Record<ProjectMemberRoleRu, ProjectMemberRole> = {
  Заказчик: 'CLIENT',
  Генпроектировщик: 'GENERAL_DESIGNER',
  'Технический заказчик': 'TECHNICAL_CLIENT',
  'Генеральный подрядчик': 'GENERAL_CONTRACTOR',
  'Строительный контроль': 'QUALITY_CONTROL',
  Подрядчик: 'CONTRACTOR',
  'Без роли': 'NONE',
}
