import { ProjectFormData } from '../../ProjectManagement.types'
import { AddressBlock } from '../AddressBlock'
import { EmployeesBlock } from '../EmployeesBlock'
import { FormLeftBlockWrapper, FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles'
import { TabFormWrapper } from '../ManagementForm'
import './MapForm.scss'
import { MapWrapper } from './MapForm.styles'
import { Stack } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import 'react-dadata/dist/react-dadata.css'
import { googleKey } from 'utils/constants'

export const MapForm: FC = () => {
  const { values: formValues } = useFormikContext<ProjectFormData>()
  const { latitude, longitude } = formValues.addressData || {}

  const addressForMap =
    formValues.addressData.address?.value ||
    (latitude && longitude ? `${formValues.addressData.latitude}, ${formValues.addressData.longitude}` : 'Москва')

  return (
    <TabFormWrapper>
      <FormLeftBlockWrapper spacing={2.5}>
        <FormTitledWrapper title='Адрес проекта' variant='primary' contentWrapper='stack' bordered='border'>
          <AddressBlock />
        </FormTitledWrapper>

        <EmployeesBlock />
      </FormLeftBlockWrapper>

      <MapWrapper>
        <iframe
          loading='lazy'
          allowFullScreen
          referrerPolicy='no-referrer-when-downgrade'
          src={`https://www.google.com/maps/embed/v1/place?key=${googleKey}&q=${addressForMap}`}
        ></iframe>
      </MapWrapper>
    </TabFormWrapper>
  )
}
