import { i18n } from 'i18n/index'
import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()
export const PROJECT_NAME_MAX_LENGTH = 30

export const validationProject = yup.object({
  shortName: yup
    .string()
    .trim()
    .required('common:errors.required')
    .max(PROJECT_NAME_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: PROJECT_NAME_MAX_LENGTH })),
  fullName: yup.string().trim().required('common:errors.required'),
  status: yup.string().required('common:errors.required'),
  type: yup.string().required('common:errors.required'),
  finType: yup.string().required('common:errors.required'),
  startDate: yup
    .date()
    .transform(formatDateInInput)
    .nullable()
    .required('common:errors.required')
    .typeError('Неверный формат даты'),
  endDate: yup
    .date()
    .transform(formatDateInInput)
    .min(today, 'Дата не может быть раньше сегодняшней')
    .min(yup.ref('startDate'), 'Дата окончания должна быть позже даты начала')
    .nullable()
    .required('common:errors.required')
    .typeError('Неверный формат даты'),
})
