import { uiContext } from '../../../../contexts/ui'
import { StyledSearch, StyledSearchBox, StyledSearchIcon } from './Search.styles'
import { InputAdornment } from '@mui/material'
import React, { useContext } from 'react'

export const Search: React.FC = () => {
  const cUI = useContext(uiContext)

  return (
    <StyledSearchBox>
      <StyledSearch
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <StyledSearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder='Поиск'
        ref={cUI.searchRef}
        onChange={(e) => cUI.setSearchValue(e.target.value, 'userInput')}
      />
    </StyledSearchBox>
  )
}
