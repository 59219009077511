import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'
import { FC } from 'react'

export const DesignModuleIcon: FC<IconProps> = (props) => {
  return (
    <svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M38.2273 88.1421C38.2273 88.1421 40.2955 89.1137 40.5341 89.1932C40.7728 89.2727 41.2216 89.3977 41.2387 89.7159C41.2557 90.0341 41.0966 90.2841 40.7841 90.2841C40.4716 90.2841 35.8125 90.2841 35.6364 90.2841C35.4603 90.2841 35.3409 89.9489 35.3239 89.3523C35.3068 88.7557 35.8353 88.3239 35.8523 87.9659C35.8523 87.7159 36.1648 87.0909 37.4716 87.3978C37.8296 87.5 37.8353 88.0057 38.2273 88.1421Z'
        fill='#303267'
      />
      <path
        d='M37.4204 61.5C37.7159 63.0966 39.7386 71.8579 40.0057 73.5682C40.2727 75.2784 40.0738 76.6591 38.8693 76.9773C37.6648 77.2954 36.6648 77.8238 35.3523 74.3409C34.7443 72.7329 33.9318 70.6534 33.2329 68.6591C32.4886 66.5341 31.8863 64.5113 31.8238 63.2841C31.8182 63.1724 31.8182 63.0605 31.8238 62.9488C32.0057 59.2898 37.1193 59.8977 37.4204 61.5Z'
        fill='#444789'
      />
      <path
        d='M35.8408 88.0682C36.6593 88.277 37.5203 88.2494 38.3238 87.9886C38.4005 87.9633 38.469 87.9178 38.522 87.8567C38.575 87.7957 38.6106 87.7216 38.6249 87.642C38.9431 85.7102 39.8749 79.0284 40.0795 78.1648C40.3152 76.8056 40.3152 75.416 40.0795 74.0568C39.551 71.8352 35.6476 72.9204 35.9885 75.7045C36.2215 77.6364 34.9999 78.7329 35.4204 83.1591C35.5454 84.648 35.5929 86.1425 35.5624 87.6364C35.5555 87.7288 35.579 87.821 35.6292 87.8989C35.6795 87.9769 35.7538 88.0363 35.8408 88.0682Z'
        fill='#444789'
      />
      <path
        d='M27.0398 88.1421C27.0398 88.1421 29.108 89.1137 29.3466 89.1932C29.5853 89.2728 30.0398 89.3978 30.0512 89.7159C30.0625 90.0341 29.9091 90.2841 29.5966 90.2841C29.2841 90.2841 24.625 90.2841 24.4489 90.2841C24.2727 90.2841 24.1534 89.9489 24.1364 89.3523C24.1193 88.7557 24.6534 88.2898 24.733 87.9432C24.8921 87.2557 24.983 87.0909 26.2841 87.4091C26.6421 87.5 26.6477 88.0057 27.0398 88.1421Z'
        fill='#303267'
      />
      <path
        d='M35.4092 63.3296C35.6819 66.375 34.358 72.8864 33.7387 75.5625C33.3467 77.2671 32.7444 78.4887 31.5171 78.4034C30.2898 78.3182 29.1535 78.4489 29.108 74.7216C29.108 73.0171 29.0285 70.7443 29.0512 68.6705C29.0512 66.3977 29.1932 64.3125 29.5569 63.1364C29.5868 63.0302 29.6228 62.9259 29.6648 62.8239C31.0739 59.4148 35.2671 61.7102 35.4092 63.3296Z'
        fill='#5C60B9'
      />
      <path
        d='M24.8694 87.7897C25.5704 88.2649 26.3922 88.5309 27.2387 88.5568C27.319 88.5606 27.3989 88.5418 27.4691 88.5027C27.5394 88.4635 27.5973 88.4055 27.6364 88.3352C28.5966 86.6307 31.75 80.6647 32.233 79.9261C32.9145 78.7258 33.3835 77.4168 33.6194 76.0568C33.875 73.7841 29.2614 72.6988 29.1364 75.4886C29.0455 77.6477 27.0966 78.7443 26.1137 83.0113C25.7544 84.4639 25.2892 85.8882 24.7216 87.2727C24.6831 87.3627 24.6767 87.4633 24.7037 87.5575C24.7306 87.6516 24.7891 87.7337 24.8694 87.7897Z'
        fill='#5C60B9'
      />
      <path
        d='M30.7215 64.8011C29.9536 65.9851 29.389 67.289 29.051 68.6591C29.051 66.3864 29.1931 64.3011 29.5567 63.125L30.7215 64.8011Z'
        fill='#444789'
      />
      <path
        d='M32.9546 48.8637C32.9546 48.8637 36.1478 48.608 39.841 48.5512C39.998 48.5485 40.1529 48.5133 40.2956 48.4478C40.4384 48.3822 40.5661 48.2878 40.6705 48.1705C42.0991 46.5154 43.4363 44.7837 44.6762 42.983C44.7569 42.869 44.8738 42.7856 45.0079 42.7464C45.142 42.7072 45.2854 42.7145 45.4148 42.7671C45.4937 42.799 45.5651 42.8467 45.6246 42.9074C45.6842 42.9681 45.7306 43.0405 45.761 43.1199C45.7914 43.1993 45.8051 43.2841 45.8012 43.3691C45.7974 43.454 45.7761 43.5373 45.7387 43.6137L42.5739 50C42.4632 50.2694 42.2995 50.5138 42.0926 50.7187C41.8857 50.9237 41.6398 51.085 41.3694 51.1932C39.0026 51.9484 36.5405 52.3636 34.0569 52.4262C32.4319 52.5 31.4546 51.6705 31.4717 50.858C31.4944 49.8864 32.0171 48.9659 32.9546 48.8637Z'
        fill='#DA6F5B'
      />
      <path
        d='M32.0797 49.1307C32.5048 48.912 32.9715 48.7861 33.449 48.7614C34.216 48.6932 35.9149 48.517 37.4263 48.4375C37.9944 48.4375 38.5626 48.4886 38.9774 48.483C39.0091 48.4811 39.0407 48.4887 39.068 48.5049C39.0954 48.5211 39.1172 48.5451 39.1308 48.5739C39.6013 49.5458 39.7808 50.633 39.6478 51.7045C39.642 51.7361 39.6271 51.7653 39.6049 51.7886C39.5827 51.8118 39.5541 51.828 39.5228 51.8352C39.0399 51.9375 38.5342 52.1136 38.0285 52.1989C37.0507 52.3594 36.064 52.46 35.074 52.5C34.0058 52.5 31.9831 52.5852 31.4035 51.1875C31.3105 50.8158 31.3246 50.4253 31.4442 50.0613C31.5639 49.6973 31.7842 49.3747 32.0797 49.1307Z'
        fill='#358F99'
      />
      <path
        d='M43.8125 41.5455C44.1762 42.4261 44.0796 43.358 44.5796 43.7273C45.0796 44.0966 45.7444 44.1818 46.5285 43.4886C47.3125 42.7955 47.2046 42.2841 47.483 41.6534C47.8069 40.9091 47.8466 40.6705 47.8125 40.5909C47.716 40.3466 47.4887 40.625 47.3637 40.8352C47.2387 41.0455 47.3637 40.6136 47.4035 40.4261C47.5569 39.858 47.5739 39.7159 47.483 39.6364C47.3921 39.5568 47.2216 39.7841 47.2557 39.6364C47.2557 39.608 47.3069 39.4943 47.2557 39.4659C47.1137 39.3693 46.8921 39.5227 46.5 39.9602C46.4603 39.9943 46.4489 39.9602 46.4603 39.9602C46.8239 39.5284 46.5398 39.4659 46.4603 39.4943C46.0449 39.7797 45.7008 40.1569 45.4546 40.5966C45.1023 41.0682 44.8523 41.9432 44.5512 41.5057C44.25 41.0682 43.75 40.5455 43.4773 40.6591C43.2046 40.7727 43.6478 41.1591 43.8125 41.5455Z'
        fill='#FF9D84'
      />
      <path
        d='M37.767 63.1477C37.767 63.1477 36.7329 64.4375 33.7897 64.7557C32.7575 64.8757 31.7152 64.8814 30.6818 64.7727C30.2278 64.7296 29.78 64.6362 29.3465 64.4943C29.3885 64.0159 29.4549 63.5399 29.5454 63.0682C29.9431 61.3636 29.3579 59.3523 27.892 57.608C26.7045 56.1421 23.017 55.0341 24.4318 52.1761C25.909 49.0568 32.0227 48.5 33.1249 48.8636C33.7336 49.0514 34.281 49.3986 34.7102 49.8693C35.6761 50.9545 36.0113 52.7784 36.6363 55.8636C37.4659 59.9716 37.767 63.1477 37.767 63.1477Z'
        fill='#83CEDA'
      />
      <path
        d='M29.4999 53.4091C29.3863 55.8125 32.6022 60.6591 30.6704 64.7727C30.2164 64.7296 29.7686 64.6362 29.3352 64.4943C29.3809 64.0157 29.4511 63.5397 29.5454 63.0682C29.9431 61.3636 29.3579 59.3523 27.892 57.608C26.7045 56.1421 23.017 55.0341 24.4318 52.1761C25.909 49.0568 32.0227 48.5 33.1249 48.8636C33.7336 49.0514 34.281 49.3986 34.7102 49.8693C32.9545 49.4318 29.6022 51.0341 29.4999 53.4091Z'
        fill='#65BDCC'
      />
      <path
        d='M29.5056 63.3466C29.3442 63.8121 29.1388 64.2611 28.8919 64.6875C28.7912 64.8617 28.6383 64.9998 28.4547 65.0824C28.2712 65.165 28.0664 65.1878 27.8692 65.1477L27.0567 64.9829C26.9431 64.9618 26.838 64.9082 26.7543 64.8285C26.6706 64.7488 26.6118 64.6465 26.5851 64.5341L26.5397 64.3352C26.5362 64.32 26.5362 64.3042 26.5398 64.2891C26.5434 64.2739 26.5504 64.2597 26.5602 64.2477C26.5701 64.2356 26.5826 64.226 26.5967 64.2195C26.6109 64.213 26.6264 64.2098 26.6419 64.2102H27.0794C27.1254 64.2125 27.1714 64.2056 27.2147 64.1899C27.258 64.1742 27.2977 64.1501 27.3316 64.1189C27.3654 64.0877 27.3927 64.05 27.4119 64.0081C27.431 63.9663 27.4416 63.921 27.4431 63.875L27.4885 63.0795C27.495 63.0105 27.4806 62.941 27.4471 62.8803C27.4137 62.8195 27.3628 62.7701 27.301 62.7386C25.328 61.7068 23.4123 60.5687 21.5624 59.3295C21.267 59.081 21.0682 58.7367 21.0007 58.3567C20.9332 57.9766 21.0013 57.585 21.1931 57.25C21.5567 56.5397 22.1078 55.5909 22.7556 54.5511C23.0283 54.1079 23.3238 53.6534 23.6192 53.1932C24.0998 52.4388 24.6353 51.7209 25.2215 51.0454C25.501 50.7222 25.8759 50.4961 26.2922 50.3998C26.7085 50.3034 27.1446 50.3417 27.5377 50.5092C27.9308 50.6767 28.2606 50.9647 28.4795 51.3316C28.6984 51.6986 28.795 52.1256 28.7556 52.5511C28.716 52.9239 28.5766 53.2792 28.3522 53.5795C27.8863 54.1932 27.4772 54.6647 26.8976 55.2841C26.5283 55.6988 26.1476 56.1363 25.8124 56.5227L24.9601 57.5113C24.8958 57.5798 24.86 57.6702 24.86 57.7642C24.86 57.8581 24.8958 57.9485 24.9601 58.017C25.7669 58.75 28.2101 61.1647 28.7669 61.4943C29.4715 61.9318 29.8578 62.3693 29.5056 63.3466Z'
        fill='#FFA083'
      />
      <path
        d='M28.9318 52.5227C28.8953 52.92 28.7498 53.2995 28.5113 53.6193C28.0511 54.2386 26.2954 56.4091 25.7897 57.0284C25.7676 57.0569 25.7367 57.0774 25.7018 57.0866C25.6669 57.0959 25.6299 57.0934 25.5965 57.0796C24.3952 56.6452 23.3293 55.9026 22.5056 54.9261C22.4839 54.8954 22.4722 54.8587 22.4722 54.821C22.4722 54.7834 22.4839 54.7466 22.5056 54.7159C22.7329 54.375 23.0738 53.8807 23.3693 53.4375C23.9357 52.5633 24.5602 51.728 25.2386 50.9375C25.5867 50.5194 26.0842 50.2535 26.6252 50.1962C27.1663 50.1389 27.7083 50.2948 28.1363 50.6307C28.4167 50.8543 28.6364 51.1447 28.7754 51.4752C28.9144 51.8058 28.9682 52.166 28.9318 52.5227Z'
        fill='#83CEDA'
      />
      <path
        d='M31.8181 46.5057C31.5738 46.7898 30.6818 47.0739 30.6136 47.6421C30.6 48.1217 30.7437 48.5926 31.0227 48.983C31.0697 49.215 31.0334 49.4561 30.9201 49.664C30.8068 49.8719 30.6238 50.0331 30.4034 50.1193C30.1426 50.2178 29.8575 50.2318 29.5883 50.1595C29.3192 50.0871 29.0795 49.9319 28.9034 49.7159C28.6647 49.3296 28.8352 47.4432 27.5852 47.1761C26.3352 46.9091 26.1022 45.9489 28.0227 45.3636C29.9431 44.7784 32.8636 45.3239 31.8181 46.5057Z'
        fill='#DA6F5B'
      />
      <path
        d='M28.8069 49.2216C29.0335 49.4137 29.3076 49.5414 29.6004 49.5913C29.8932 49.6413 30.1941 49.6117 30.4716 49.5057C31.1535 49.25 30.9432 48.733 30.9432 48.733L31.3069 48.9602C31.3932 49.1268 31.4119 49.3203 31.359 49.5003C31.306 49.6803 31.1856 49.8329 31.0228 49.9262C30.5153 50.2105 29.9246 50.3089 29.3523 50.2046C29.0154 50.1201 28.7194 49.9188 28.5171 49.6364L28.8069 49.2216Z'
        fill='#358F99'
      />
      <path
        d='M31.0569 41.2614C32.7955 42.733 33.2444 45.0568 32.0682 46.4489C30.8921 47.8409 27.5228 47.6193 25.733 46.2387C23.5001 44.5341 24.5967 42.5114 25.7728 41.125C26.9489 39.7387 29.3182 39.7728 31.0569 41.2614Z'
        fill='#FF9E85'
      />
      <path
        d='M27.1249 47.125C27.4431 46.8068 27.1988 46.1818 26.6988 45.6137C26.4577 45.3825 26.2843 45.09 26.1972 44.7676C26.1101 44.4452 26.1126 44.1052 26.2045 43.7841C26.4431 43.2159 27.0056 43.1591 27.8124 42.8523C28.6192 42.5455 29.7215 41.7159 29.8181 40.8693C29.8428 40.4627 29.7597 40.0568 29.5771 39.6926C29.3945 39.3284 29.1189 39.0189 28.7783 38.7955C28.2101 38.4148 27.034 38.5 25.5454 39.7614C24.4601 40.6818 22.9942 42.2784 23.159 43.7841C23.2558 44.4507 23.5182 45.0824 23.9222 45.6214C24.3262 46.1603 24.8588 46.5894 25.4715 46.8693C26.6306 47.5057 26.9715 47.2898 27.1249 47.125Z'
        fill='#444789'
      />
      <path
        d='M61.3239 88.2216C61.3239 88.2216 59.2273 89.2102 58.9887 89.2955C58.75 89.3807 58.2841 89.5 58.2671 89.8182C58.25 90.1364 58.4148 90.3864 58.733 90.3864C59.0512 90.3864 63.7784 90.3864 63.9546 90.3864C64.1307 90.3864 64.2557 90.0511 64.2728 89.4432C64.2898 88.8352 63.75 88.3977 63.7046 88.0341C63.7046 87.7841 63.3864 87.1477 62.0625 87.4659C61.7273 87.5512 61.7273 88.0682 61.3239 88.2216Z'
        fill='#303267'
      />
      <path
        d='M62.1477 61.1875C61.8466 62.8125 59.7898 71.6989 59.517 73.4318C59.2443 75.1648 59.4489 76.5682 60.6534 76.9034C61.858 77.2386 62.892 77.7614 64.2216 74.2216C64.8352 72.5909 65.6648 70.483 66.3693 68.4716C67.125 66.3125 67.7386 64.2614 67.8011 63.0171C67.8068 62.9035 67.8068 62.7897 67.8011 62.6761C67.642 58.9489 62.4489 59.5625 62.1477 61.1875Z'
        fill='#444789'
      />
      <path
        d='M63.7501 88.1705C62.918 88.3808 62.0434 88.3513 61.2274 88.0852C61.1495 88.06 61.0802 88.0138 61.0271 87.9516C60.9739 87.8894 60.939 87.8138 60.9262 87.733C60.5967 85.7727 59.6535 78.9943 59.4433 78.1193C59.2048 76.7394 59.2048 75.3288 59.4433 73.9489C59.9774 71.6762 63.9376 72.8125 63.5967 75.6193C63.358 77.5796 64.5967 78.6932 64.1989 83.1875C64.0707 84.6991 64.0233 86.2164 64.0569 87.733C64.0603 87.8293 64.0322 87.9242 63.9769 88.0032C63.9215 88.0821 63.8419 88.1409 63.7501 88.1705Z'
        fill='#444789'
      />
      <path
        d='M69.5114 50.7273C69.5512 48.8693 67.6251 48.6307 66.1023 48.6648C64.5796 48.6989 60.1649 48.8637 59.6592 48.8637C59.328 48.9154 59.0242 49.0779 58.7972 49.3245C58.5702 49.5711 58.4334 49.8873 58.4092 50.2216C58.3815 50.4202 58.3968 50.6225 58.4541 50.8146C58.5115 51.0068 58.6095 51.1843 58.7416 51.3352C58.8736 51.4861 59.0366 51.6069 59.2194 51.6892C59.4023 51.7716 59.6007 51.8136 59.8012 51.8125C60.5739 51.8921 66.2046 52.5682 67.0171 52.5682C67.8296 52.5682 69.4773 52.3807 69.5114 50.7273Z'
        fill='#DA6F5B'
      />
      <path
        d='M69.6647 50.8693C69.6954 50.606 69.6729 50.3393 69.5987 50.0848C69.5245 49.8304 69.4 49.5934 69.2326 49.3878C69.0652 49.1823 68.8583 49.0124 68.6242 48.8882C68.39 48.764 68.1333 48.6881 67.8693 48.6648C66.2333 48.557 64.5928 48.5342 62.9545 48.5966C62.914 48.598 62.8747 48.611 62.8414 48.6342C62.8082 48.6574 62.7823 48.6897 62.767 48.7273C62.5411 49.339 62.4201 49.9844 62.409 50.6364C62.3219 51.1438 62.379 51.6655 62.5738 52.142C62.587 52.1629 62.6044 52.1808 62.625 52.1945C62.6456 52.2082 62.6688 52.2174 62.6931 52.2216C63.0738 52.2898 65.3011 52.6761 66.6704 52.7898C68.1477 52.8921 69.5624 52.4886 69.6647 50.8693Z'
        fill='#5C60B9'
      />
      <path
        d='M58.8978 48.9943C60.8503 47.2127 62.957 45.6079 65.1933 44.1989C66.3296 43.6307 66.8978 44.7671 65.9944 45.6478C65.091 46.5284 62.699 49.4773 61.8978 50.358C61.0967 51.2387 59.8069 52.1591 58.8637 51.2557C58.7051 51.112 58.579 50.9361 58.4938 50.7398C58.4085 50.5435 58.3661 50.3313 58.3693 50.1173C58.3725 49.9033 58.4213 49.6925 58.5125 49.4988C58.6036 49.3052 58.735 49.1332 58.8978 48.9943Z'
        fill='#FFA083'
      />
      <path
        d='M72.6762 88.2216C72.6762 88.2216 70.5796 89.2102 70.341 89.2955C70.1023 89.3807 69.6364 89.5 69.6194 89.8182C69.6023 90.1364 69.7671 90.3864 70.0853 90.3864C70.4035 90.3864 75.1307 90.3864 75.3069 90.3864C75.483 90.3864 75.608 90.0511 75.6251 89.4432C75.6421 88.8352 75.0966 88.3636 75.0171 88.0114C74.858 87.3125 74.7671 87.1477 73.4432 87.4432C73.0796 87.5511 73.0796 88.0682 72.6762 88.2216Z'
        fill='#303267'
      />
      <path
        d='M64.2046 63.0682C63.9318 66.1591 65.2728 72.767 65.9091 75.4772C66.3068 77.1818 66.9091 78.4488 68.1818 78.3522C69.4546 78.2557 70.5796 78.3977 70.625 74.6193C70.625 72.875 70.7046 70.642 70.6875 68.4772C70.6875 66.2045 70.5398 64.0511 70.1705 62.8636C70.1364 62.7443 70.0966 62.6363 70.0625 62.5454C68.5853 59.0909 64.3296 61.4034 64.2046 63.0682Z'
        fill='#5C60B9'
      />
      <path
        d='M74.8806 87.8693C74.1701 88.3527 73.3362 88.6228 72.4772 88.6477C72.3954 88.6514 72.3143 88.632 72.243 88.5918C72.1717 88.5517 72.113 88.4923 72.0738 88.4204C71.1022 86.7159 67.9033 80.6364 67.409 79.8977C66.7184 78.6797 66.2437 77.3514 66.0056 75.9716C65.7386 73.6705 70.4261 72.5625 70.5511 75.4034C70.642 77.5966 72.6249 78.7102 73.6193 83.0398C73.9805 84.5149 74.4534 85.9603 75.034 87.3636C75.0661 87.4532 75.0686 87.5508 75.0409 87.6418C75.0133 87.7329 74.9571 87.8127 74.8806 87.8693Z'
        fill='#5C60B9'
      />
      <path
        d='M68.9431 64.5397C69.7237 65.7415 70.2997 67.0644 70.6477 68.4545C70.6477 66.1818 70.4999 64.0284 70.1306 62.8409L68.9431 64.5397Z'
        fill='#444789'
      />
      <path
        d='M61.75 62.9773C61.75 62.9773 62.8182 64.2329 65.7955 64.6818C66.8353 64.8537 67.8911 64.9089 68.9432 64.8466C69.4083 64.8239 69.8695 64.7496 70.3182 64.625C70.2912 64.1282 70.2381 63.6332 70.1591 63.142C69.8352 61.4375 70.5227 59.3863 72.0909 57.6875C73.3807 56.2841 77.1648 55.3352 75.8977 52.375C74.517 49.142 68.3466 48.2841 67.2102 48.6193C66.5851 48.7813 66.0144 49.1069 65.5568 49.5625C64.5284 50.6136 64.1023 52.4488 63.3239 55.5454C62.2898 59.6591 61.75 62.9773 61.75 62.9773Z'
        fill='#898DD1'
      />
      <path
        d='M70.6762 53.4091C70.6762 55.8466 67.3694 60.5852 69.1307 64.8523C69.5347 64.8504 69.9357 64.7832 70.3183 64.6534C70.2912 64.1567 70.2381 63.6617 70.1592 63.1705C69.8353 61.4659 70.5228 59.4148 72.091 57.7159C73.3807 56.3125 77.1648 55.3636 75.8978 52.4034C74.5171 49.1705 68.3467 48.3125 67.2103 48.6477C66.5852 48.8097 66.0145 49.1353 65.5569 49.5909C67.4603 50.2102 70.6819 50.983 70.6762 53.4091Z'
        fill='#7377C5'
      />
      <path
        d='M69.5511 64.142C69.6293 64.6348 69.751 65.1196 69.9148 65.5909C69.9864 65.7823 70.1155 65.9467 70.2844 66.0617C70.4533 66.1767 70.6536 66.2365 70.8579 66.2329H71.6988C71.8194 66.234 71.9375 66.1995 72.0385 66.1338C72.1396 66.0681 72.219 65.9742 72.267 65.8636L72.3466 65.6761C72.3529 65.6617 72.3557 65.646 72.3549 65.6302C72.3541 65.6145 72.3497 65.5991 72.342 65.5854C72.3343 65.5716 72.3235 65.5598 72.3105 65.5509C72.2975 65.542 72.2826 65.5363 72.267 65.5341L71.8238 65.4773C71.7774 65.471 71.7326 65.4555 71.6922 65.4318C71.6518 65.408 71.6165 65.3764 71.5885 65.3389C71.5604 65.3013 71.5402 65.2585 71.5289 65.213C71.5176 65.1675 71.5155 65.1202 71.5227 65.0738L71.6193 64.2727C71.6276 64.2031 71.6555 64.1373 71.6997 64.083C71.744 64.0287 71.8028 63.988 71.8693 63.9659C74.0242 63.2912 76.1409 62.5002 78.2102 61.5966C78.5448 61.3964 78.8007 61.0875 78.9351 60.7215C79.0695 60.3554 79.0744 59.9544 78.9488 59.5852C78.7102 58.8125 78.3295 57.767 77.8693 56.6136C77.6761 56.1193 77.4716 55.6136 77.25 55.1023C76.8645 54.1744 76.4185 53.2728 75.9148 52.4034C75.6492 51.9522 75.2208 51.6199 74.7176 51.4751C74.2145 51.3303 73.675 51.3839 73.2102 51.625C72.9046 51.7873 72.6445 52.0237 72.4539 52.3126C72.2633 52.6015 72.1483 52.9335 72.1193 53.2784C72.0895 53.6574 72.1663 54.0372 72.3409 54.375C72.6932 55.0738 73.1363 55.8807 73.5966 56.6477C73.892 57.1307 74.267 57.4829 74.5341 57.9261C74.8011 58.3693 75.2045 59.0057 75.3579 59.267C75.4055 59.3499 75.4196 59.4478 75.3974 59.5407C75.3753 59.6336 75.3184 59.7146 75.2386 59.767C74.3011 60.3352 71.2102 62.2841 70.5909 62.517C69.8295 62.7557 69.3693 63.1023 69.5511 64.142Z'
        fill='#FFA083'
      />
      <path
        d='M71.9091 53.25C71.8822 53.6594 71.9687 54.0683 72.1591 54.4318C72.5114 55.1421 73.8977 57.6591 74.2954 58.3523C74.3134 58.3853 74.3409 58.4122 74.3743 58.4294C74.4078 58.4467 74.4456 58.4534 74.4829 58.4489C75.7768 58.2228 76.9892 57.6626 78 56.8239C78.0299 56.7989 78.051 56.765 78.0602 56.7272C78.0694 56.6893 78.0662 56.6495 78.0511 56.6136C77.8807 56.2273 77.6136 55.6648 77.4091 55.1648C76.9912 54.187 76.5091 53.238 75.9659 52.3239C75.6896 51.8379 75.2348 51.4787 74.6979 51.3228C74.1611 51.1668 73.5846 51.2263 73.0909 51.4886C72.763 51.6608 72.4832 51.9121 72.2769 52.2196C72.0705 52.5272 71.9441 52.8813 71.9091 53.25Z'
        fill='#898DD1'
      />
      <path
        d='M69.2728 46.6932C69.5001 47 70.3694 47.3807 70.4092 47.9204C70.3812 48.4063 70.198 48.8704 69.8865 49.2443C69.824 49.4727 69.8432 49.7157 69.9407 49.9314C70.0382 50.1471 70.2079 50.3221 70.4205 50.4261C70.6769 50.5463 70.9645 50.5829 71.2428 50.5309C71.5211 50.479 71.776 50.3409 71.9717 50.1364C72.2444 49.767 72.2842 47.9091 73.5853 47.2273C74.7217 46.625 75.108 46.5511 73.216 45.8068C71.324 45.0625 68.3353 45.4148 69.2728 46.6932Z'
        fill='#DA6F5B'
      />
      <path
        d='M72.1591 49.6193C71.9226 49.8222 71.6316 49.9509 71.3224 49.9893C71.0131 50.0277 70.6994 49.9741 70.4205 49.8352C69.75 49.5284 70.0341 49.017 70.0341 49.017L69.625 49.2216C69.5225 49.3821 69.4865 49.5764 69.5246 49.763C69.5628 49.9497 69.6722 50.1142 69.8296 50.2216C70.3206 50.5514 70.9118 50.6982 71.5 50.6364C71.8613 50.5696 72.194 50.3953 72.4546 50.1363L72.1591 49.6193Z'
        fill='#5C60B9'
      />
      <path
        d='M69.9716 40.9091C68.0398 42.1818 67.3011 44.4659 68.3182 46.0227C69.3352 47.5796 72.7273 47.7273 74.7216 46.5909C77.1818 45.1534 76.3295 42.9716 75.2898 41.4262C74.25 39.8807 71.9034 39.6137 69.9716 40.9091Z'
        fill='#FF9E85'
      />
      <path
        d='M72.7273 47.2216C72.6301 46.8849 72.639 46.5264 72.7529 46.195C72.8668 45.8635 73.08 45.5752 73.3636 45.3693C73.9943 44.9432 74.5625 44.3693 74.5 43.7671C74.4375 43.1648 73.7671 42.6818 73.0568 42.1762C72.3466 41.6705 71.5852 41.2727 71.7159 40.3921C71.796 39.9918 71.979 39.6193 72.2471 39.3114C72.5151 39.0034 72.8589 38.7708 73.2443 38.6364C73.9205 38.4148 75.0284 38.7898 76.1705 40.4148C77 41.5966 78.0284 43.5398 77.483 44.9602C76.9375 46.3807 75.7216 47.0682 74.4205 47.3977C73.1193 47.7273 72.8466 47.4205 72.7273 47.2216Z'
        fill='#444789'
      />
      <path
        d='M67.9147 43.1818C68.3035 43.4495 68.6135 43.8163 68.8124 44.2443C68.9488 44.4887 68.7556 44.4262 68.9261 44.8409C69.0227 45.0796 68.8352 45.1193 68.9261 45.358C68.9775 45.5272 68.9911 45.7057 68.9658 45.8807C68.9658 45.9205 68.8977 46.0114 68.6761 46.1421C68.6249 46.1762 68.5283 46.1875 68.4999 46.25C68.4317 46.4034 68.1988 46.3921 68.1477 46.3864C67.9008 46.3442 67.6706 46.234 67.4829 46.0682C67.2897 45.9091 67.3692 45.75 67.6477 45.7671C67.9261 45.7841 67.8636 45.6591 67.6477 45.6364C67.4317 45.6137 67.3806 42.8693 67.9147 43.1818Z'
        fill='#DA6F5B'
      />
      <path
        d='M64.9318 44.4091C65.75 43.6307 67.6988 42.9148 67.9432 43.1534C68.0909 43.2955 67.9432 43.5909 68.0966 43.6875C68.25 43.7841 68.2045 44.0455 68.0966 44.2557C67.9886 44.4659 68.2386 44.5341 68.2102 44.767C68.2102 44.858 68.0397 44.9773 68.0966 45.233C68.0966 45.3352 67.9886 45.5398 67.6932 45.642C67.0305 45.69 66.3648 45.6767 65.7045 45.6023C65.2443 45.5511 64.6534 44.6705 64.9318 44.4091Z'
        fill='#FF9D84'
      />
      <path
        d='M70.8522 10.1875H37.0966C36.469 10.1875 35.9602 10.6963 35.9602 11.3239V33.2955C35.9602 33.923 36.469 34.4318 37.0966 34.4318H70.8522C71.4798 34.4318 71.9886 33.923 71.9886 33.2955V11.3239C71.9886 10.6963 71.4798 10.1875 70.8522 10.1875Z'
        fill='#A6BBFF'
      />
      <path
        d='M70.8522 9.61932H37.0966C36.469 9.61932 35.9602 10.1281 35.9602 10.7557V32.7273C35.9602 33.3549 36.469 33.8636 37.0966 33.8636H70.8522C71.4798 33.8636 71.9886 33.3549 71.9886 32.7273V10.7557C71.9886 10.1281 71.4798 9.61932 70.8522 9.61932Z'
        fill='#D3DEFF'
      />
      <path d='M67.0342 13.3239H59.0342V19.3011H67.0342V13.3239Z' fill='#B3C5FF' />
      <path d='M50.5169 13.4489H39.051V14.2954H50.5169V13.4489Z' fill='#B3C5FF' />
      <path d='M50.5169 27.6307H39.051V28.4773H50.5169V27.6307Z' fill='#B3C5FF' />
      <path d='M48.9885 15.142H39.051V15.9886H48.9885V15.142Z' fill='#B3C5FF' />
      <path d='M46.9487 18.5227H39.051V19.3693H46.9487V18.5227Z' fill='#B3C5FF' />
      <path d='M43.9544 29.3239H39.051V30.1704H43.9544V29.3239Z' fill='#B3C5FF' />
      <path d='M41.4999 31.0114H39.051V31.8579H41.4999V31.0114Z' fill='#B3C5FF' />
      <path d='M47.5853 29.3239H45.9092V30.1704H47.5853V29.3239Z' fill='#B3C5FF' />
      <path d='M68.6988 21.9091H57.2329V22.7557H68.6988V21.9091Z' fill='#B3C5FF' />
      <path d='M62.1363 23.5966H57.2329V24.4432H62.1363V23.5966Z' fill='#B3C5FF' />
      <path d='M65.767 23.5966H64.0908V24.4432H65.767V23.5966Z' fill='#B3C5FF' />
      <path d='M50.5227 16.8295H40.2329V17.6761H50.5227V16.8295Z' fill='#B3C5FF' />
      <path d='M53.9149 9.61932H53.3467V34.4489H53.9149V9.61932Z' fill='#444789' />
      <path
        d='M41.5965 25.7841C42.8987 25.7841 43.9544 24.7284 43.9544 23.4261C43.9544 22.1239 42.8987 21.0682 41.5965 21.0682C40.2942 21.0682 39.2385 22.1239 39.2385 23.4261C39.2385 24.7284 40.2942 25.7841 41.5965 25.7841Z'
        fill='#D0AFFF'
      />
      <path
        d='M51.1363 23.4261C51.1363 23.8925 50.998 24.3484 50.7389 24.7361C50.4798 25.1239 50.1116 25.4261 49.6807 25.6046C49.2499 25.7831 48.7758 25.8298 48.3184 25.7388C47.861 25.6478 47.4408 25.4232 47.111 25.0935C46.7813 24.7637 46.5567 24.3435 46.4657 23.8861C46.3747 23.4287 46.4214 22.9546 46.5999 22.5238C46.7784 22.0929 47.0806 21.7247 47.4684 21.4656C47.8561 21.2065 48.312 21.0682 48.7784 21.0682C49.1484 21.0708 49.5126 21.1606 49.8413 21.3304C50.1701 21.5002 50.4541 21.7452 50.6704 22.0454L50.7443 22.1591C50.9612 22.4689 51.096 22.8285 51.1363 23.2045C51.1363 23.2954 51.1363 23.3523 51.1363 23.4261Z'
        fill='#FFE08F'
      />
      <path
        d='M51.1363 23.2046C50.1476 23.2671 48.7783 23.3182 48.7783 23.3182C48.7783 23.3182 49.9886 22.4773 50.6988 22.0455L50.7726 22.1591C50.9795 22.4717 51.1045 22.8311 51.1363 23.2046Z'
        fill='#D0AFFF'
      />
      <path
        d='M58.6136 30.8352L58.3352 30.3409L62.2727 28.125L63.7386 29.0738L66.3636 27.5852L66.642 28.0795L63.7216 29.7386L62.25 28.7897L58.6136 30.8352Z'
        fill='#65BDCC'
      />
      <path d='M66.7044 28.6932L67.5681 27.2272L65.8635 27.2159L66.7044 28.6932Z' fill='#65BDCC' />
      <path
        d='M50.7444 22.1591L48.8637 23.2954H48.7671V21.0227C49.1371 21.0253 49.5013 21.1152 49.83 21.285C50.1588 21.4548 50.4429 21.6998 50.6591 22L50.7444 22.1591Z'
        fill='#83CEDA'
      />
    </svg>
  )
}
