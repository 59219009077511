import { Stack, Grid } from '@mui/material'
import styled from 'styled-components'

export const CardsFullWrapper = styled(Stack)`
  overflow-y: auto;
  margin: 0 0 -16px -20px;
  // margin: 0 0 -16px -20px;
  height: 100%;
`

export const Cards = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  // overflow-y: auto;
  padding: 10px;
  width: 100%;
`
