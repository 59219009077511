import { Menu, MenuItem, Typography } from '@mui/material'
import { useGetProjectsQuery } from 'api/projects'
import { Project } from 'api/projects/types'
import { Progress } from 'components/Progress'
import React, { useState, MouseEvent, useCallback, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

export interface UseBreadcrumbProjectLinkProps {
  projectId: number
  navigateUrl: string
  onMenuItemClickBefore?: () => void
}

export interface UseBreadcrumbProjectLinkReturns {
  onProjectBreadClick: (event: MouseEvent<HTMLSpanElement>) => void
  project?: Project
  isFetching: boolean
  ProjectsMenu: ReactNode
  isMenuOpen: boolean
}

export const useBreadcrumbProjectLink = ({
  projectId,
  navigateUrl,
  onMenuItemClickBefore,
}: UseBreadcrumbProjectLinkProps): UseBreadcrumbProjectLinkReturns => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  const { data: projectsData, isFetching } = useGetProjectsQuery({})
  const { data: projects = [] } = projectsData || {}
  const project = projects.find((project) => project.id === projectId)

  const onProjectBreadClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onMenuItemClick = useCallback(
    (projectId: number) => {
      onMenuItemClickBefore && onMenuItemClickBefore()
      navigate(navigateUrl.replace('{projectId}', String(projectId)))
      onClose()
    },
    [onClose],
  )

  const ProjectsMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={onClose}
      MenuListProps={{
        style: {
          width: '100%',
        },
      }}
      PaperProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
          minWidth: 200,
          // minHeight: 200,
          maxWidth: 450,
          maxHeight: 300,
        },
      }}
    >
      {isFetching ? (
        <Progress />
      ) : projects?.length > 1 ? (
        projects
          .filter((project) => project.id !== projectId)
          ?.map((project) => (
            <MenuItem onClick={() => onMenuItemClick(project.id)} style={{ maxWidth: 450 }} key={project.id}>
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {project.shortName}
              </Typography>
            </MenuItem>
          ))
      ) : (
        <MenuItem style={{ maxWidth: 450 }} disabled>
          <Typography>Другие проекты отсутствуют</Typography>
        </MenuItem>
      )}
    </Menu>
  )

  return {
    onProjectBreadClick,
    project,
    isFetching,
    ProjectsMenu,
    isMenuOpen,
  }
}
