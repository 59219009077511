import { Stack, Grid } from '@mui/material'
import styled from 'styled-components'

export const CardWrapper = styled.div`
  padding: 20px 0 0 20px;
  max-width: 25%;
  width: 100%;

  @media (max-width: 1605px) {
    max-width: calc(100% / 3);
  }

  @media (max-width: 1230px) {
    max-width: 50%;
  }

  @media (max-width: 820px) {
    max-width: 100%;
  }
`

export const Card = styled(Stack)`
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  padding: 20px 20px 30px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    cursor: pointer;
    box-shadow:
      0px 6px 6px -3px rgba(0, 0, 0, 0.2),
      0px 10px 14px 1px rgba(0, 0, 0, 0.14),
      0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
`

export const CardTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: 2px;
  text-align: start;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`
export const CardSubtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.palette.text.dark};
  margin-bottom: 28px;
  max-width: 100%;
  width: 100%;
  height: 40px;
  text-align: start;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const CardInfoWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.bg.gray};
  border-radius: 8px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 10px;
`

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & span {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.text.dark};
  }
`

export const Customer = styled.div`
  border: 1px solid ${(props) => props.theme.palette.bg.gray};
  border-radius: 8px;
  width: 100%;
  padding: 14px 0 14px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;

  & span {
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.text.dark};

    &:first-child {
      display: flex;
      align-items: center;
    }
  }
`

export const CustomerCompany = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.dark};
  margin-left: 10px;
  max-width: 100%;
  text-align: end;
  padding-right: 13px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const IconWrapper = styled.div`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`

export const DateWrapper = styled.div`
  display: flex;
  & span {
    color: ${(props) => props.theme.palette.text.dark};
    margin-right: 6px;
    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }
  }
`

export const Status = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.main};
`
