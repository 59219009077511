import { SectionModal } from '../SectionModal'
import { SectionsMenuItem } from '../SectionsMenuItem'
import { SectionsAddButton, SectionsMenuWrapper, SectionsWrapper } from './SectionsMenu.styles'
import { SectionsMenuProps } from './SectionsMenu.types'
import AddIcon from '@mui/icons-material/Add'
import { Stack, Typography } from '@mui/material'
import { useDeleteRegulationSectionMutation } from 'api/regulations'
import { Divider } from 'components/Divider'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const SectionsMenu: FC<SectionsMenuProps> = ({ sections, selectedSectionId, onSectionSelect }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { role } = useTypedSelector(profileSelector)

  const [sectionModalOpen, setSectionModalOpen] = useState<boolean>(false)
  const [selectedEditSectionId, setSelectedEditSectionId] = useState<number | null>(null)
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit')

  const sectionsEditAccess = role === 'admin'

  const onSectionModalOpen = () => {
    setSectionModalOpen(true)
  }

  const closeSectionModal = () => {
    setSectionModalOpen(false)
    selectedEditSectionId && setSelectedEditSectionId(null)
  }

  const onSectionEdit = (id: number) => {
    setSectionModalOpen(true)
    setSelectedEditSectionId(id)
  }

  const setInitialSection = () => {
    const selectedSectionId = Number(localStorage.getItem('selectedSectionId') || '0')
    if (sections.map((section) => section.id).includes(selectedSectionId)) {
      onSectionSelect(selectedSectionId)
      return
    }

    if (sections?.length) {
      onSectionSelect(sections[0].id)
    }
  }

  // set initial section
  useEffect(() => {
    !selectedSectionId && setInitialSection()
  }, [sections])

  const [deleteSection, deleteSectionResponse] = useDeleteRegulationSectionMutation()

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      closeSectionModal()
    }
  }, [])

  const onDeleteConfirm = useCallback(
    (confirm: boolean, sectionId: number) => {
      if (confirm) {
        deleteSection({ sectionId })
        closeSectionModal()

        const isCurrentSection = selectedSectionId === sectionId
        if (!isCurrentSection) return

        const isFirstSection = sections.findIndex((section) => section.id === selectedSectionId) === 0

        isFirstSection ? onSectionSelect(sections?.[1]?.id || null) : onSectionSelect(sections?.[0]?.id || null)
      }
    },
    [sections, selectedSectionId],
  )

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: {
      handleConfirm: handleExitConfirm,
    },
    delete: {
      handleConfirm: onDeleteConfirm,
      title: 'Удалить раздел?',
      body: 'Раздел будет удален.',
      confirmButtonText: 'Удалить',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onSectionDelete = (id: number) => {
    setConfirmDialogTrigger('delete')
    openConfirm(id)
  }

  const onSectionModalClose = (dirty: boolean, immediately?: boolean) => {
    setConfirmDialogTrigger('exit')

    immediately || !dirty ? closeSectionModal() : openConfirm()
  }

  useMutationHandlers(deleteSectionResponse, () => {
    enqueueSnackbar('Раздел успешно удалён.', { variant: 'success' })
  })

  return (
    <>
      <SectionsMenuWrapper>
        <Stack direction='row' justifyContent='space-between' alignItems='center' px={3} spacing={1} height={40}>
          <Typography variant='body1' className='sections-title'>
            Разделы
          </Typography>

          {sectionsEditAccess && (
            <SectionsAddButton onClick={onSectionModalOpen}>
              <AddIcon fontSize='large' color='primary' />
            </SectionsAddButton>
          )}
        </Stack>

        <Divider />

        <SectionsWrapper>
          {sections?.length ? (
            sections.map((section) => (
              <SectionsMenuItem
                section={section}
                selected={selectedSectionId === section.id}
                onSectionSelect={onSectionSelect}
                onSectionEdit={onSectionEdit}
                onSectionDelete={onSectionDelete}
                sectionsEditAccess={sectionsEditAccess}
                key={section.id}
              />
            ))
          ) : (
            <>Список разделов пуст.</>
          )}
        </SectionsWrapper>
      </SectionsMenuWrapper>

      <SectionModal
        sectionId={selectedEditSectionId}
        open={sectionModalOpen}
        onClose={onSectionModalClose}
        key={`${sectionModalOpen}`}
      />
      <ConfirmDialog />
    </>
  )
}
