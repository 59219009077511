import styled from 'styled-components'

export const DOCUMENTS_TABLE_CELL_HEIGHT = 64

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 7px;
  overflow-y: scroll;
`

export const IconButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
`

export const TableCellWrapper = styled.div`
  display: flex;
  min-height: ${60}px;
  position: relative;
  background-color: ${(props) => props.theme.palette.bg.white};

  &:hover {
    & div {
      position: absolute;
      z-index: 9;
      top: 0;
      padding: 10px 8px;
      // background-color: ${(props) => props.theme.palette.bg.white};
      // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

      & p {
        display: block;
        white-space: pre-wrap;
      }
    }
  }
`

export const TableCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  min-height: ${60}px;
  box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;

  & .MuiTypography-root {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${(props) => props.theme.palette.text.dark};
  }
`
