import { Stack } from '@mui/material'
import styled from 'styled-components'

interface LinkItemWrapperProps {
  disableLink?: boolean
}
export const LinkItemWrapper = styled(Stack)<LinkItemWrapperProps>`
  flex: 1;
  height: 62px;

  border: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 20px;

  & > a {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    pointer-events: ${(props) => props.disableLink && 'none'};
  }

  & .MuiTypography-root {
    text-align: left;
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:hover {
    border: ${(props) => !props.disableLink && `1px solid ${props.theme.palette.primary.light}`};
  }
`
