import { DrawerTopBar } from '../../../ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { ProjectMembersManagmentForm } from '../ProjectMembersManagmentForm'
import { ProjectMembersManagmentProps } from './ProjectMembersManagment.types'
import { Drawer, Stack, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const ProjectMembersManagment: FC<ProjectMembersManagmentProps> = ({ isOpen, projectMember, onClose }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onClose(dirty)}>
      <Stack width={'400px'} height={'100%'}>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {projectMember ? 'Редактирование' : 'Добавление'} позиции справочника
          </Typography>
        </DrawerTopBar>

        <Divider />

        <ProjectMembersManagmentForm onFormChange={onFormChange} projectMember={projectMember} onClose={onClose} />
      </Stack>
    </Drawer>
  )
}
