import { ColumnsDrawerFormData } from '../ColumnsDrawer.types'
import { ColumnsDrawerGlobalItemWrapper } from './ColumnsDrawerGlobalItem.styles'
import { ColumnsDrawerGlobalItemProps } from './ColumnsDrawerGlobalItem.types'
import { prescriptionColumnNameByValue } from 'api/prescriptions/types'
import { remarkGlobalColumnNameByValue } from 'api/remarks/types'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { ColumnSettings } from 'pages/Remarks/components/RemarksLegend'
import { ChangeEvent, FC, useCallback } from 'react'
import React from 'react'

export const ColumnsDrawerGlobalItem: FC<ColumnsDrawerGlobalItemProps> = React.memo(
  ({ column, columnSettings, checked }) => {
    const { setFieldValue } = useFormikContext<ColumnsDrawerFormData>()

    const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      if (column === 'showSecondary') {
        setFieldValue('showSecondary', e.target.checked)
      }
    }, [])

    const columnNameByValue: Record<ColumnSettings, any> = {
      prescriptions: prescriptionColumnNameByValue,
      remarks: remarkGlobalColumnNameByValue,
    }
    const currentColumnByValue = columnSettings && columnNameByValue[columnSettings]

    return (
      <ColumnsDrawerGlobalItemWrapper checked={checked}>
        <FieldForm
          version='switch'
          name=''
          checkboxData={{
            checked,
            onChange,
            label: currentColumnByValue[column],
          }}
        />
      </ColumnsDrawerGlobalItemWrapper>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.column === nextProps.column && prevProps.checked === nextProps.checked
  },
)
