import './api.types'
import {
  BindIntegrationProjectRequest,
  BindIntegrationProjectResponse,
  GetIntegratedModuleInfoRequest,
  GetIntegratedModuleInfoResponse,
  GetIntegratedProjectsRequest,
  GetIntegratedProjectsResponse,
  GetIntegrationProjectsByDesignModuleRequest,
  GetIntegrationProjectsByDesignModuleResponse,
  GetIntegrationProjectsByRmModuleRequest,
  GetIntegrationProjectsByRmModuleResponse,
  GetIntegrationStatusByProjectRequest,
  GetIntegrationStatusByProjectResponse,
  SendRequestIntegrationRequest,
  SendRequestIntegrationResponse,
  UnbindIntegrationProjectRequest,
  UnbindIntegrationProjectResponse,
} from './api.types'
import { api } from 'api/api'

export const integrationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIntegrationStatusByProject: build.query<
      GetIntegrationStatusByProjectResponse,
      GetIntegrationStatusByProjectRequest
    >({
      query: ({ projectId }) => ({
        url: `/module-binding/${projectId}/status`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      providesTags: ['Integrations'],
    }),
    sendRequestIntegration: build.mutation<SendRequestIntegrationResponse, SendRequestIntegrationRequest>({
      query: ({ projectId, body }) => ({
        url: `/module-binding/${projectId}/request`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),
    getIntegrationProjectsByDesignModule: build.query<
      GetIntegrationProjectsByDesignModuleResponse,
      GetIntegrationProjectsByDesignModuleRequest
    >({
      query: ({ projectId, ...params }) => {
        return {
          url: `/module-binding/design/get-project-list`,
          method: 'GET',
          params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: 'test',
        }
      },
      providesTags: ['Integrations'],
    }),
    getIntegrationProjectsByRmModule: build.query<
      GetIntegrationProjectsByRmModuleResponse,
      GetIntegrationProjectsByRmModuleRequest
    >({
      query: ({ projectId, ...params }) => {
        return {
          url: `/module-binding/rm/get-project-list`,
          method: 'GET',
          params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: 'test',
        }
      },
      providesTags: ['Integrations'],
    }),
    getIntegratedProjects: build.query<GetIntegratedProjectsResponse, GetIntegratedProjectsRequest>({
      query: ({ projectId }) => ({
        url: `/module-binding/${projectId}/pragma-binding/get`,
        method: 'GET',
      }),
      providesTags: ['Integrations'],
    }),
    bindIntegrationProject: build.mutation<BindIntegrationProjectResponse, BindIntegrationProjectRequest>({
      query: ({ projectId, body, ...params }) => ({
        url: `/module-binding/${projectId}/pragma-binding/bind`,
        method: 'POST',
        params,
        body,
      }),
      invalidatesTags: ['Integrations'],
    }),
    unbindIntegrationProject: build.mutation<UnbindIntegrationProjectResponse, UnbindIntegrationProjectRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/module-binding/${projectId}/pragma-binding/unbind`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['Integrations'],
    }),
    getIntegratedModuleInfo: build.query<GetIntegratedModuleInfoResponse, GetIntegratedModuleInfoRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/module-binding/${projectId}/pragma-binding/get-url`,
        method: 'GET',
        params,
      }),
      providesTags: ['Integrations'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetIntegrationStatusByProjectQuery,
  useSendRequestIntegrationMutation,
  useGetIntegrationProjectsByDesignModuleQuery,
  useGetIntegrationProjectsByRmModuleQuery,
  useGetIntegratedProjectsQuery,
  useBindIntegrationProjectMutation,
  useUnbindIntegrationProjectMutation,
  useGetIntegratedModuleInfoQuery,
} = integrationsApi
