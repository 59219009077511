import { Stack } from '@mui/material'
import styled from 'styled-components'

interface ProjectWidgetTabItemProps {
  isActive: boolean
}

export const ProjectWidgetTabs = styled(Stack)`
  flex-direction: row;
  padding: 2px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 6px;
  gap: 5px;
`

export const ProjectWidgetTabItem = styled(Stack)<ProjectWidgetTabItemProps>`
  min-width: 85px;
  border-radius: 6px;
  padding: 4px;
  background-color: ${(props) => props.isActive && props.theme.palette.bg.white};

  &:hover {
    cursor: pointer;
  }
`
