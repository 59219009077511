import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

interface StatisticsCardItemWrapperProps {
  disabled?: boolean
}

export const StatisticsCardItemWrapper = styled(Stack)<StatisticsCardItemWrapperProps>`
  flex: 1;
  border: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 20px;

  &:hover {
    border-color: ${(props) => !props.disabled && props.theme.palette.primary.main};
  }

  & > a {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 20px;
    padding-bottom: 4px;
    pointer-events: ${(props) => props.disabled && 'none'};

    & > .MuiTypography-root {
      margin-bottom: 8px;
    }
  }
`

export const StatisticsWrapper = styled(Stack)``

export const StatisticsWrapperColumnTitle = styled(Typography)`
  font-weight: 500;
  font-size: 11px;
  line-height: normal;
  color: ${(props) => props.theme.palette.secondary.main};
`
