import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const validationDocument = yup.object({
  prescriptionDateStart: yup.date().transform(formatDateInInput).nullable(),
  date: yup
    .date()
    .transform(formatDateInInput)
    .min(yup.ref('prescriptionDateStart'))
    .max(today)
    .required('common:errors.required'),
  number: yup.string().trim().required('common:errors.required'),
  comment: yup.string().trim().nullable(),
  isAnyRemarksSelected: yup.boolean().oneOf([true]),
})
