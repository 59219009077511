import { StyledTableBodyCell, TableBodyCellWrapper } from './TableBodyCell.styles'
import { TableBodyCellProps } from './TableBodyCell.types'
import { Typography } from '@mui/material'
import { flexRender } from '@tanstack/react-table'
import { FC } from 'react'

export const TableBodyCell: FC<TableBodyCellProps> = ({ column, cell }) => {
  const { minSize, size, maxSize, textAlign } = column || {}
  const cellValue = cell.getValue() ? flexRender(cell.column.columnDef.cell, cell.getContext()) : '—'

  return (
    <TableBodyCellWrapper
      style={{
        flex: 1,
        minWidth: minSize,
        width: size,
        maxWidth: maxSize,
      }}
    >
      <StyledTableBodyCell className='cell'>
        <Typography variant='body2' width='100%' textAlign={textAlign}>
          {cellValue}
        </Typography>
      </StyledTableBodyCell>
    </TableBodyCellWrapper>
  )
}
