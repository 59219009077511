import { api } from '../api'
import {
  CreateRegulationRequest,
  CreateRegulationResponse,
  CreateRegulationSectionRequest,
  CreateRegulationSectionResponse,
  DeleteRegulationRequest,
  DeleteRegulationResponse,
  DeleteRegulationSectionRequest,
  DeleteRegulationSectionResponse,
  EditRegulationRequest,
  EditRegulationResponse,
  EditRegulationSectionRequest,
  EditRegulationSectionResponse,
  GetRegulationDocRequest,
  GetRegulationDocResponse,
  GetRegulationDocsRequest,
  GetRegulationDocsResponse,
  GetRegulationSectionRequest,
  GetRegulationSectionResponse,
  GetRegulationSectionsRequest,
  GetRegulationSectionsResponse,
  UploadFileToRegulationRequest,
  UploadFileToRegulationResponse,
} from './api.types'
import { setShouldResetPage } from 'store/slices/infiniteScroll'

export const regulationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRegulationSections: build.query<GetRegulationSectionsResponse, GetRegulationSectionsRequest>({
      query: ({ ...params }) => ({
        url: `/regulatory-docs/section/list`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'Regulations', id: 'SECTION_LIST' }],
    }),
    createRegulationSection: build.mutation<CreateRegulationSectionResponse, CreateRegulationSectionRequest>({
      query: ({ name }) => ({
        url: `/regulatory-docs/section/create`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: [{ type: 'Regulations', id: 'SECTION_LIST' }],
    }),
    editRegulationSection: build.mutation<EditRegulationSectionResponse, EditRegulationSectionRequest>({
      query: ({ sectionId, name }) => ({
        url: `/regulatory-docs/section/${sectionId}/update`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: [{ type: 'Regulations', id: 'SECTION_LIST' }],
    }),
    getRegulationSection: build.query<GetRegulationSectionResponse, GetRegulationSectionRequest>({
      query: ({ sectionId }) => ({
        url: `/regulatory-docs/section/${sectionId}/get`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Regulations', id: 'SECTION_LIST' }],
    }),
    deleteRegulationSection: build.mutation<DeleteRegulationSectionResponse, DeleteRegulationSectionRequest>({
      query: ({ sectionId }) => ({
        url: `/regulatory-docs/section/${sectionId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Regulations', id: 'SECTION_LIST' }],
    }),

    getRegulationDocs: build.query<GetRegulationDocsResponse, GetRegulationDocsRequest>({
      query: ({ sectionId, ...params }) => ({
        url: `/regulatory-docs/section/${sectionId}/doc/list`,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          sectionId: queryArgs.sectionId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query
        const statusChanged = currentArg?.status !== previousArg?.status

        const otherArgsChanged = queryChanged || statusChanged

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: [{ type: 'Regulations' }],
    }),
    createRegulation: build.mutation<CreateRegulationResponse, CreateRegulationRequest>({
      query: ({ sectionId, body }) => ({
        url: `/regulatory-docs/section/${sectionId}/doc/create`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ sectionId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          // set should reset page
          dispatch(setShouldResetPage({ table: 'regulations', shouldResetPage: true }))
        } catch {}
      },
    }),
    getRegulationDoc: build.query<GetRegulationDocResponse, GetRegulationDocRequest>({
      query: ({ sectionId, docId }) => ({
        url: `/regulatory-docs/section/${sectionId}/doc/${docId}/get`,
        method: 'GET',
      }),
      // providesTags: [{ type: 'Regulations' }],
    }),

    uploadFileToRegulation: build.mutation<UploadFileToRegulationResponse, UploadFileToRegulationRequest>({
      query: ({ sectionId, docId, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/regulatory-docs/section/${sectionId}/doc/${docId}/update/file`,
          method: 'PUT',
          body: formData,
        }
      },
      async onQueryStarted({ sectionId, docId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedRegulationData } = await queryFulfilled
          const updatedRegulation = updatedRegulationData.data

          // getRegulationDoc
          dispatch(
            regulationsApi.util.updateQueryData('getRegulationDoc', { sectionId, docId }, (draft) => {
              Object.assign(draft, updatedRegulation)
            }),
          )

          // getRegulationDocs
          dispatch(
            regulationsApi.util.updateQueryData('getRegulationDocs', { sectionId }, (draft) => {
              const updatedRegulationIndex = draft.data.findIndex(
                (regulation) => regulation.id === updatedRegulation.id,
              )
              draft.data[updatedRegulationIndex] = updatedRegulation
            }),
          )
        } catch {}
      },
      // invalidatesTags: ['Regulations'],
    }),
    editRegulation: build.mutation<EditRegulationResponse, EditRegulationRequest>({
      query: ({ sectionId, docId, body }) => ({
        url: `/regulatory-docs/section/${sectionId}/doc/${docId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ sectionId, docId, ...patch }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedRegulationData } = await queryFulfilled
          const updatedRegulation = updatedRegulationData.data
          const state = getState()

          // getRegulationDocs
          dispatch(
            regulationsApi.util.updateQueryData('getRegulationDocs', { sectionId }, (draft) => {
              const docsStatusQuery =
                // @ts-ignore
                state.api.queries[`getRegulationDocs({"sectionId":${sectionId}})`]?.originalArgs?.status
              const updatedRegulationIndex = draft.data.findIndex(
                (regulation) => regulation.id === updatedRegulation.id,
              )

              if (!docsStatusQuery || docsStatusQuery === updatedRegulation.status) {
                draft.data[updatedRegulationIndex] = updatedRegulation
                return
              }

              if (docsStatusQuery && docsStatusQuery !== updatedRegulation.status) {
                draft.data.splice(updatedRegulationIndex, 1)
              }
            }),
          )

          // getRegulationDoc
          dispatch(
            regulationsApi.util.updateQueryData('getRegulationDoc', { sectionId, docId }, (draft) => {
              Object.assign(draft, updatedRegulation)
            }),
          )
        } catch {}
      },
    }),
    deleteRegulation: build.mutation<DeleteRegulationResponse, DeleteRegulationRequest>({
      query: ({ sectionId, docId }) => ({
        url: `/regulatory-docs/section/${sectionId}/doc/${docId}/delete`,
        method: 'DELETE',
      }),
      // invalidatesTags: ['Regulations'],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(regulationsApi.util.invalidateTags(['Regulations']))
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRegulationSectionsQuery,
  useCreateRegulationSectionMutation,
  useEditRegulationSectionMutation,
  useGetRegulationSectionQuery,
  useDeleteRegulationSectionMutation,

  useGetRegulationDocsQuery,
  useCreateRegulationMutation,
  useGetRegulationDocQuery,
  useUploadFileToRegulationMutation,
  useEditRegulationMutation,
  useDeleteRegulationMutation,
} = regulationsApi
