import { ColumnsDrawerFormData } from '../ColumnsDrawer.types'
import { ColumnsDrawerItem } from '../ColumnsDrawerItem'
import {
  ColumnsDrawerSectionAccordion,
  ColumnsDrawerSectionAccordionDetails,
  ColumnsDrawerSectionAccordionSummary,
  ColumnsDrawerSectionCount,
} from './ColumnsDrawerSection.styles'
import { ColumnsDrawerSectionProps } from './ColumnsDrawerSection.types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

export const ColumnsDrawerSection: FC<ColumnsDrawerSectionProps> = ({ title, columns, columnSettings, draggable }) => {
  const { values, setFieldValue } = useFormikContext<ColumnsDrawerFormData>()

  const Content = (
    <ColumnsDrawerSectionAccordionDetails>
      {columns.map((column) => (
        <ColumnsDrawerItem column={column} columnSettings={columnSettings} draggable={draggable} />
      ))}
    </ColumnsDrawerSectionAccordionDetails>
  )

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const sortedColumns = reorder(values.columns, result.source.index, result.destination.index)

    setFieldValue('columns', sortedColumns)
  }

  const DraggableContent = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ColumnsDrawerSectionAccordionDetails>
              {columns.map((column, index) => (
                <ColumnsDrawerItem
                  column={column}
                  columnSettings={columnSettings}
                  draggable={draggable}
                  index={index}
                />
              ))}
            </ColumnsDrawerSectionAccordionDetails>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  return (
    <ColumnsDrawerSectionAccordion disableGutters defaultExpanded>
      <ColumnsDrawerSectionAccordionSummary expandIcon={<ExpandMoreIcon fontSize='medium' color='secondary' />}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <Typography variant='body2'>{title}</Typography>

          <ColumnsDrawerSectionCount>
            <Typography variant='body2'>{columns?.length || 0}</Typography>
          </ColumnsDrawerSectionCount>
        </Stack>
      </ColumnsDrawerSectionAccordionSummary>

      {draggable ? DraggableContent : Content}
    </ColumnsDrawerSectionAccordion>
  )
}
