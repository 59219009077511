import { Stack } from '@mui/material'
import styled from 'styled-components'

export const Content = styled(Stack)`
  height: 100%;
  overflow: auto;
  // margin: 0 20px 0 0;
`

export const ReferenceTableWrapper = styled(Stack)`
  padding-right: 20px;
  overflow: auto;
  height: 100%;
  height: -moz-available; /* WebKit игнор. */
  height: -webkit-fill-available; /* Mozilla игнор. */
  height: fill-available;
`
