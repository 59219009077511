import { ProjectFormData } from '../../ProjectManagement.types'
import { ImageFieldWrapper } from './ImageField.styles'
import { ImageFieldProps } from './ImageField.types'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@mui/material'
import { CropModal } from 'components/CropModal'
import { useFormikContext } from 'formik'
import React, { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const ImageField: FC<ImageFieldProps> = ({ insert }) => {
  const { values: formValues } = useFormikContext<ProjectFormData>()
  // const indexToLastInsert = formValues.imagesForCreate.length

  const [isOpenCroppedDialog, setIsOpenCroppedDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleSetValue = useCallback(
    (blob: Blob) => {
      const reader = new FileReader()
      reader.onload = ({ target }) => {
        setIsOpenCroppedDialog(false)
        const file: Blob = new Blob([target!.result as ArrayBuffer])
        insert(0, file)
        // insert(indexToLastInsert, file)
      }
      if (blob) {
        reader.readAsArrayBuffer(blob)
      }
    },
    [insert],
    // [insert, indexToLastInsert]
  )

  const handleChangeAvatar: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e?.target?.files?.[0] || null
      setSelectedFile(file)
      setIsOpenCroppedDialog(true)
    },
    [handleSetValue],
  )

  const handleCancelCrop = useCallback(() => {
    setIsOpenCroppedDialog(false)
    setSelectedFile(null)
  }, [])

  return (
    <>
      <label htmlFor='upload-project-file'>
        <input
          style={{ display: 'none' }}
          accept='image/*'
          id='upload-project-file'
          type='file'
          onChange={handleChangeAvatar}
          onClick={(e: React.BaseSyntheticEvent) => (e.target.value = '')}
        />
        <ImageFieldWrapper>
          <AddIcon fontSize='medium' color='primary' />
          <Typography variant='body2' color={theme.palette.primary.main}>
            Добавить изображение
          </Typography>
        </ImageFieldWrapper>
      </label>
      <CropModal
        file={selectedFile}
        isOpen={isOpenCroppedDialog}
        onSave={handleSetValue}
        onClose={handleCancelCrop}
        onCancel={handleCancelCrop}
        aspect={277 / 305}
      />
    </>
  )
}
