import { MenuIconButton, PageNavigationWrapper, StyledPdfViewer, ZoomWrapper } from './PdfViewer.styles'
import { PdfViewerProps } from './PdfViewer.types'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import GridViewIcon from '@mui/icons-material/GridView'
import PrintIcon from '@mui/icons-material/Print'
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw'
import { Box, Divider, Stack } from '@mui/material'
import { LocalizationMap, RotateDirection, ScrollMode, Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import { FullScreenIcon } from 'assets/icons/FullScreenIcon'
import { Progress } from 'components/Progress'
import { Tooltip } from 'components/Tooltip'
import { FC, useCallback, useEffect, useState } from 'react'
import { theme } from 'styles/theme'

const PdfViewer: FC<PdfViewerProps> = ({
  fileUrl,
  title,
  rootViewerProps,
  onDownload,
  onDelete,
  disableMultiView,
  disableDelete,
}) => {
  const [switchMode, setSwitchMode] = useState<ScrollMode>(ScrollMode.Vertical)

  const onSwitchModeClick = () => {
    setSwitchMode(switchMode === ScrollMode.Vertical ? ScrollMode.Wrapped : ScrollMode.Vertical)
  }

  useEffect(() => {
    switchScrollMode(switchMode)
  }, [switchMode])

  const renderToolbar = useCallback(
    (Toolbar: (props: ToolbarProps) => React.ReactElement) => {
      return (
        <Toolbar>
          {(slots: ToolbarSlot) => {
            const { CurrentPageInput, NumberOfPages, ZoomIn, ZoomOut, Zoom, EnterFullScreen, Rotate, Download, Print } =
              slots
            return (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                flex={1}
                px={2.5}
                flexWrap={'wrap'}
              >
                <Stack direction='row' spacing={1.5} flex={1}>
                  <Box maxWidth={250} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                    {title}
                  </Box>
                </Stack>

                <Stack
                  direction='row'
                  flex={1.5}
                  justifyContent='center'
                  alignItems='center'
                  spacing={2.5}
                  divider={<Divider orientation='vertical' style={{ height: 20, alignSelf: 'center' }} flexItem />}
                >
                  <PageNavigationWrapper direction='row' alignItems='center' spacing={0.5}>
                    <Box>
                      <CurrentPageInput />
                    </Box>
                    <Box whiteSpace={'nowrap'}>
                      / <NumberOfPages />
                    </Box>
                  </PageNavigationWrapper>

                  <ZoomWrapper direction='row' alignItems='center'>
                    <Box>
                      <ZoomOut>
                        {(props) => (
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              borderRadius: '4px',
                              color: theme.palette.text.dark,
                              cursor: 'pointer',
                              padding: '8px',
                            }}
                            onClick={props.onClick}
                          >
                            -
                          </button>
                        )}
                      </ZoomOut>
                    </Box>
                    <Box>
                      <Zoom />
                    </Box>
                    <Box>
                      <ZoomIn>
                        {(props) => (
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              padding: '8px',
                            }}
                            onClick={props.onClick}
                          >
                            +
                          </button>
                        )}
                      </ZoomIn>
                    </Box>
                  </ZoomWrapper>

                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Box>
                      <EnterFullScreen>
                        {(props) => (
                          <Tooltip title='На весь экран'>
                            <MenuIconButton onClick={props.onClick} disableRipple>
                              <FullScreenIcon />
                            </MenuIconButton>
                          </Tooltip>
                        )}
                      </EnterFullScreen>
                    </Box>
                    <Box>
                      <Rotate direction={RotateDirection.Backward}>
                        {(props) => (
                          <Tooltip title='Повернуть против часовой стрелки'>
                            <MenuIconButton onClick={props.onClick} disableRipple>
                              <Rotate90DegreesCcwIcon />
                            </MenuIconButton>
                          </Tooltip>
                        )}
                      </Rotate>
                    </Box>
                  </Stack>
                </Stack>

                <Stack direction='row' flex={1} justifyContent='end' spacing={2}>
                  <Box>
                    <Download>
                      {() => (
                        <Tooltip title='Скачать'>
                          <MenuIconButton onClick={onDownload} disableRipple>
                            <FileDownloadIcon />
                          </MenuIconButton>
                        </Tooltip>
                      )}
                    </Download>
                  </Box>
                  <Box>
                    <Print>
                      {(props) => (
                        <Tooltip title='Печать'>
                          <MenuIconButton onClick={props.onClick} disableRipple>
                            <PrintIcon />
                          </MenuIconButton>
                        </Tooltip>
                      )}
                    </Print>
                  </Box>
                  {!disableDelete && (
                    <Box>
                      <Tooltip title='Удалить'>
                        <MenuIconButton onClick={onDelete} disableRipple>
                          <DeleteIcon />
                        </MenuIconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {!disableMultiView && (
                    <Box>
                      <Tooltip title='Многостраничный просмотр'>
                        <MenuIconButton
                          onClick={onSwitchModeClick}
                          style={{
                            padding: 2,
                            backgroundColor:
                              switchMode === 'Vertical' ? theme.palette.bg.white : theme.palette.secondary.gray,
                            borderRadius: 6,
                          }}
                          disableRipple
                        >
                          <GridViewIcon
                            style={{
                              color: switchMode === 'Vertical' ? theme.palette.text.dark : theme.palette.text.light,
                            }}
                          />
                        </MenuIconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Stack>
              </Stack>
            )
          }}
        </Toolbar>
      )
    },
    [switchMode, title],
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => {
      return [
        {
          ...defaultTabs[0],
          icon: (
            <Tooltip title='Миниатюра' placement='right'>
              <GridViewIcon style={{ marginTop: 4 }} />
            </Tooltip>
          ),
        },
      ]
    },
    renderToolbar,
    toolbarPlugin: {
      searchPlugin: { enableShortcuts: false },
    },
  })

  const {
    toolbarPluginInstance: {
      scrollModePluginInstance: { switchScrollMode },
    },
  } = defaultLayoutPluginInstance
  if (!fileUrl) return null

  return (
    <>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
        <StyledPdfViewer className='pdf-wrapper'>
          <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]}
            localization={ru_RU as unknown as LocalizationMap}
            renderLoader={() => <Progress />}
            {...rootViewerProps}
          />
        </StyledPdfViewer>
      </Worker>
    </>
  )
}

export default PdfViewer
