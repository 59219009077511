import { DocDrawer, DocDrawerContent, DocDrawerHeader, DocLogoWrapper, DocQCLogo } from './DocumentSideBar.styles'
import { DocumentSideBarProps } from './DocumentSideBar.types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, IconButton, Stack } from '@mui/material'
import { Divider } from 'components/Divider'
import { DocInfoRow, DocInfoRowProps } from 'pages/RegulationDoc/components/DocNavBar/DocInfoRow'
import { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { connectNames } from 'utils/connectNames'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'

export const DocumentSideBar: FC<DocumentSideBarProps> = ({ doc, onDrawerOpen, onReturnClick }) => {
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { type, number, date, comment, author, created } = doc

  const dateText = parseResponseDate(date).fullDate
  const createdDateText = (created && parseResponseDateTime(created).fullDate) || '—'
  const authorText = connectNames(author)

  const mainInfoRowsData: DocInfoRowProps[] = [
    { title: 'Вид документа', text: type.title },
    { title: 'Номер документа', text: number },
    { title: 'Дата документа', text: dateText },
  ]

  // const onDrawerOpen = () => {
  //   setIsDrawerOpen(true)
  // }

  // const onDrawerClose = () => {
  //   setIsDrawerOpen(false)
  // }

  // const onReturnClick = () => {
  //   navigate(`/project/${projectId}/documents`, { state: { locationRowId: doc.id, locationPage } })
  // }

  return (
    <DocDrawer variant='permanent'>
      <DocDrawerHeader direction='row' justifyContent='space-between' spacing={1}>
        <Link to='/home'>
          <DocLogoWrapper>
            <DocQCLogo />
          </DocLogoWrapper>
        </Link>

        <Stack direction='row' alignItems='center' spacing={1.25}>
          <IconButton color='primary' onClick={onReturnClick}>
            <ArrowBackIcon />
          </IconButton>
        </Stack>
      </DocDrawerHeader>

      <DocDrawerContent spacing={2.5} flex={1}>
        {isUserHighAccess && (
          <Button onClick={onDrawerOpen} variant='outlined' color='secondary'>
            Редактировать
          </Button>
        )}

        <Stack flex={1} justifyContent='space-between' spacing={3}>
          <Stack px={1.5} spacing={2}>
            <Divider sx={{ my: 1 }} />

            {mainInfoRowsData.map((rowData) => (
              <DocInfoRow {...rowData} />
            ))}

            <Divider style={{ margin: '24px 0 8px' }} />

            <DocInfoRow title='Комментарий' text={comment || '—'} direction='column' rows={5} />
          </Stack>

          <Stack px={1.5} spacing={2}>
            <DocInfoRow title='Автор' text={authorText} direction='column' rows={2} />
            <DocInfoRow title='Дата создания' text={createdDateText} direction='column' />
          </Stack>
        </Stack>
      </DocDrawerContent>
    </DocDrawer>
  )
}
