import { format } from 'date-fns'
import { formatDateInInput } from 'utils/dates/formatDateInInput'

// accept Date with full year or Date with short year
// return 2023-01-25
export const formatDateForServer = (date?: Date | null): string => {
  if (!date) return ''
  const parsedDate = formatDateInInput('', date)

  if (!parsedDate) return ''
  return format(parsedDate, 'yyyy-MM-dd')
}
