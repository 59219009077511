import styled from 'styled-components'

export const TABLE_BODY_CELL_HEIGHT = 60

export const TableBodyCellWrapper = styled.div`
  display: flex;
  min-height: ${TABLE_BODY_CELL_HEIGHT}px;
  position: relative;

  &:hover {
    & div {
      position: absolute;
      z-index: 9;
      top: 0;
      padding: 10px 8px;
      background-color: ${(props) => props.theme.palette.bg.shades};

      & p {
        display: block;
        white-space: pre-wrap;
      }
    }
  }
`

export const StyledTableBodyCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  min-height: ${TABLE_BODY_CELL_HEIGHT}px;
  box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;

  & .MuiTypography-root {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${(props) => props.theme.palette.text.dark};
  }
`
