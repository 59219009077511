import {
  DatePickerRenderInput,
  ProjectFormTextField,
  SmallFormTextField,
  StyledCheckbox,
  StyledSelectField,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTitle,
} from './FieldForm.styles'
import {
  FormFieldProps,
  SELECT_ITEM_HEIGHT,
  SELECT_ITEM_PADDING_TOP,
  SELECT_ITEMS_RENDER_COUNT,
} from './FieldForm.types'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Box, FormControlLabel, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { useField } from 'formik'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const FieldForm: React.FC<FormFieldProps> = ({
  version,
  title,
  maxLength,
  minLength,
  InputProps,
  fieldProps,
  dateFieldProps,
  onBlur,
  dataValue,
  checkboxData,
  onDataChange,
  children,
  className,
  dateIconType = 'default',
  ...props
}) => {
  const { t } = useTranslation()
  const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
    name: props.name,
    ...fieldProps,
  })

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onFieldBlur?.(e)
      onBlur?.(e)
    },
    [onBlur, onFieldBlur],
  )

  const isError = touched && !!error

  switch (version) {
    case 'profile':
      return (
        <label style={{ textAlign: 'left' }} className={className}>
          <StyledTextFieldTitle mb={0.75} variant='tooltip' pl={2}>
            {title}
          </StyledTextFieldTitle>
          <StyledTextField
            {...field}
            InputProps={{ ...InputProps, disableUnderline: true }}
            inputProps={{ ...props.inputProps, maxLength, minLength }}
            fullWidth
            variant='filled'
            size='small'
            helperText={isError ? t(error as string, { maxLength }) : undefined}
            onBlur={handleBlur}
            error={isError}
            autoComplete='off'
            {...props}
          />
        </label>
      )

    case 'project':
      return (
        <ProjectFormTextField
          {...field}
          InputProps={{ ...InputProps, disableUnderline: true }}
          inputProps={{ ...props.inputProps, maxLength, minLength }}
          fullWidth
          variant='filled'
          size='small'
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          className={className}
          autoComplete='off'
          {...props}
        />
      )

    case 'date':
      return (
        <DesktopDatePicker
          mask='__.__.____'
          value={dataValue}
          onChange={onDataChange!}
          {...dateFieldProps}
          components={{
            OpenPickerIcon: dateIconType === 'default' ? CalendarTodayIcon : DateRangeIcon,
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              helperText={isError ? t(error as string, { maxLength }) : undefined}
              {...props}
              {...params}
              height={props.inputProps?.style?.height || ''}
              onBlur={handleBlur}
              error={isError}
              inputProps={{
                ...params.inputProps,
                ...props.inputProps,
                placeholder: props.placeholder || 'Нажмите, чтобы добавить дату',
                maxLength,
                minLength,
                autoComplete: 'off',
              }}
            />
          )}
          className={className}
        />
      )

    case 'select':
      return (
        <StyledSelectField
          select
          {...field}
          InputProps={{ ...InputProps, disableUnderline: true }}
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          inputProps={{ ...props.inputProps, maxLength, minLength }}
          onBlur={handleBlur}
          error={isError}
          className={className}
          {...props}
          SelectProps={{
            ...props.SelectProps,
            MenuProps: {
              ...props.SelectProps?.MenuProps,
              PaperProps: {
                ...props.SelectProps?.MenuProps?.PaperProps,
                sx: {
                  maxHeight: SELECT_ITEM_HEIGHT * SELECT_ITEMS_RENDER_COUNT + SELECT_ITEM_PADDING_TOP,

                  '& .MuiMenuItem-root .MuiTypography-root': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                  ...props.SelectProps?.MenuProps?.PaperProps?.sx,
                },
              },
            },
          }}
        >
          {children}
        </StyledSelectField>
      )

    case 'small':
      return (
        <SmallFormTextField
          {...field}
          InputProps={{ ...InputProps, disableUnderline: true }}
          inputProps={{ ...props.inputProps, maxLength, minLength }}
          fullWidth
          variant='filled'
          size='small'
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          className={className}
          {...props}
        />
      )

    case 'checkbox':
      return (
        <FormControlLabel
          onClick={checkboxData?.onLabelClick}
          control={
            <StyledCheckbox
              {...field}
              checked={!!checkboxData?.checked}
              onChange={checkboxData?.onChange}
              indeterminate={checkboxData?.indeterminate}
              inputProps={{
                onBlur: handleBlur,
                ...props.inputProps,
              }}
              icon={checkboxData?.checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            />
          }
          label={<Box>{checkboxData?.label}</Box>}
          className={className}
          sx={{ mr: 0 }}
        />
      )

    case 'switch':
      return (
        <FormControlLabel
          control={
            <StyledSwitch
              {...field}
              checked={checkboxData?.checked}
              onChange={checkboxData?.onChange}
              inputProps={{
                onBlur: handleBlur,
                ...props.inputProps,
              }}
              _disabled={props.disabled}
            />
          }
          label={checkboxData?.label}
          className={className}
          disabled={props.disabled}
        />
      )

    default:
      return (
        <TextField
          {...field}
          onBlur={handleBlur}
          error={isError}
          helperText={isError ? t(error as string) : undefined}
          className={className}
          {...props}
        />
      )
  }
}
