import { DocumentManagmentProps } from './DocumentManagment.types'
import { DocumentManagmentForm } from './DocumentManagmentForm'
import { Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { Divider } from 'components/Divider'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import {
  DocumentsManagmentTopBar,
  DocumentsManagmentWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagment.styles'
import { FC, useState, useCallback } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const DocumentManagment: FC<DocumentManagmentProps> = ({ open, onClose, document }) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const { role } = useTypedSelector(profileSelector)
  const viewingOnly: boolean = role === 'client' || role === 'contractor'

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) onClose()
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback(
    (forceDirty?: boolean) => {
      if (typeof forceDirty === 'boolean' && !forceDirty) {
        onClose()
        return
      }
      dirty ? openConfirm() : onClose()
    },
    [dirty],
  )

  return (
    <Drawer anchor='right' open={open} onClose={() => onDrawerClose()}>
      <DocumentsManagmentWrapper>
        <DocumentsManagmentTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {(viewingOnly && 'Просмотр ') || (document ? 'Редактирование ' : 'Добавление ')}документа
          </Typography>
        </DocumentsManagmentTopBar>

        <Divider />

        <DocumentManagmentForm
          document={document}
          onClose={(forceDirty) => onDrawerClose(forceDirty)}
          onFormChange={onFormChange}
          viewingOnly={viewingOnly}
        />
      </DocumentsManagmentWrapper>
      <ConfirmDialog />
    </Drawer>
  )
}
