import { InnerContainer, MainContainer } from '../MainLayout'
import { AdminNavBar } from 'components/AdminNavBar'
import { Header } from 'components/Header'
import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const AdminLayout: FC = () => {
  const { role } = useTypedSelector(profileSelector)
  const navigate = useNavigate()

  useEffect(() => {
    if (role && role !== 'admin') {
      navigate('/projects')
    }
  }, [role])

  return role === 'admin' ? (
    <>
      <AdminNavBar />
      <MainContainer>
        <Header hideLogo />
        <InnerContainer>
          <Outlet />
        </InnerContainer>
      </MainContainer>
    </>
  ) : null
}
