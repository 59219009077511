import { IconProps } from '../types/iconProps'
import { FC } from 'react'

export const CustomerIcon: FC<IconProps> = (props) => {
  return (
    <svg {...props} viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.60854 2.66668C8.60854 4.13944 7.42331 5.33335 5.96126 5.33335C4.49921 5.33335 3.31398 4.13944 3.31398 2.66668C3.31398 1.19391 4.49921 0 5.96126 0C7.42331 0 8.60854 1.19391 8.60854 2.66668ZM8.60505 6.99998V6.92665C10.1901 7.36999 11.2556 8.19665 11.2556 9.16666V12H0.666504V9.14999C0.666504 8.19666 1.72873 7.36999 3.31379 6.92665V6.99998C3.31379 9.00999 4.03517 10.7067 5.04445 11.3733L5.51103 7.55332C5.34843 7.47002 5.21177 7.3432 5.11603 7.18678C5.02029 7.03037 4.96919 6.8504 4.96834 6.66665C4.96671 6.65448 4.96671 6.64215 4.96834 6.62998C5.29925 6.59998 5.63016 6.58332 5.96107 6.58332C6.29198 6.58332 6.62289 6.59998 6.9538 6.62998C6.95544 6.64215 6.95544 6.65448 6.9538 6.66665C6.95295 6.8504 6.90185 7.03037 6.80611 7.18678C6.71038 7.3432 6.57371 7.47002 6.41111 7.55332L6.87438 11.3733C7.88366 10.7167 8.60505 8.99999 8.60505 6.99998Z'
      />
    </svg>
  )
}
