import { LegendColors } from '../global'

export type UserRolesRu = 'Администратор' | 'Подрядчик' | 'Заказчик' | 'Инженер СК' | 'Без роли'
export type UserRoles = 'admin' | 'contractor' | 'client' | 'engineer_qc' | 'none'
export type UserRoleTabLabels =
  | 'Все пользователи'
  | 'Администраторы'
  | 'Подрядчики'
  | 'Заказчики'
  | 'Инженеры СК'
  | 'Без роли'

export interface UserRolesEnToRu extends Record<UserRoles, UserRolesRu> {}

export interface UserRolesRuToEn extends Record<UserRolesRu, UserRoles> {}

export const userRolesRuToEn: UserRolesRuToEn = {
  Администратор: 'admin',
  Подрядчик: 'contractor',
  Заказчик: 'client',
  'Инженер СК': 'engineer_qc',
  'Без роли': 'none',
}

export const userRolesEnToRu: UserRolesEnToRu = {
  admin: 'Администратор',
  contractor: 'Подрядчик',
  client: 'Заказчик',
  engineer_qc: 'Инженер СК',
  none: 'Без роли',
}

export const userRolesArrayRu = Object.keys(userRolesRuToEn) as Array<keyof typeof userRolesRuToEn>
export const userRolesArray = Object.keys(userRolesEnToRu) as Array<keyof typeof userRolesEnToRu>
export const userTabNamesArray: UserRoleTabLabels[] = [
  'Все пользователи',
  'Администраторы',
  'Подрядчики',
  'Заказчики',
  'Инженеры СК',
  'Без роли',
]

export const UserRoleByTabLabel: Record<Exclude<UserRoleTabLabels, 'Все пользователи'>, UserRoles> = {
  Администраторы: 'admin',
  Подрядчики: 'contractor',
  Заказчики: 'client',
  'Инженеры СК': 'engineer_qc',
  'Без роли': 'none',
}

export const UserRoleRuByTabLabel: Record<UserRoles, Exclude<UserRoleTabLabels, 'Все пользователи'>> = {
  admin: 'Администраторы',
  contractor: 'Подрядчики',
  client: 'Заказчики',
  engineer_qc: 'Инженеры СК',
  none: 'Без роли',
}

export const UserRolesColors: Record<UserRolesRu, LegendColors> = {
  Администратор: 'blue',
  Подрядчик: 'lightgreen',
  Заказчик: 'orange',
  'Инженер СК': 'purple',
  'Без роли': 'disabled',
}
