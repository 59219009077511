import { CardItemRowCircle, CardItemRowText, CardItemRowWrapper } from './StatisticsCardItemRow.styles'
import { StatisticsCardItemRowProps } from './StatisticsCardItemRow.types'
import { Box, Stack } from '@mui/material'
import { Divider } from 'components/Divider'
import _ from 'lodash'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const StatisticsCardItemRow: FC<StatisticsCardItemRowProps> = ({ columns, data, row }) => {
  const { title, color, isSum, dividerOnTop } = row

  const defaultPathToData = `${row.fieldName}.data`

  return (
    <>
      {dividerOnTop && isSum && <Divider color={theme.palette.bg.gray} style={{ height: '1px' }} />}

      <CardItemRowWrapper isSum={isSum}>
        <Stack direction='row' alignItems='center' spacing={0.75}>
          {color && <CardItemRowCircle bgColor={color} />}
          <CardItemRowText variant='body2' bold={!!isSum}>
            {title}
          </CardItemRowText>
        </Stack>

        <Stack direction='row' alignItems='center'>
          {columns.map((column) => {
            if (!data)
              return (
                <Box width={column.width}>
                  <CardItemRowText variant='body2' bold={!!isSum}>
                    —
                  </CardItemRowText>
                </Box>
              )

            const pathToData = (
              column.parentFieldName ? `${column.parentFieldName}.${defaultPathToData}` : defaultPathToData
            ) as keyof typeof data

            const value = _.get(data, pathToData) as unknown as string | number
            return (
              <Box width={column.width}>
                <CardItemRowText variant='body2' textColor={column.color} bold={!!isSum}>
                  {value || '—'}
                </CardItemRowText>
              </Box>
            )
          })}
        </Stack>
      </CardItemRowWrapper>

      {!dividerOnTop && isSum && <Divider color={theme.palette.bg.gray} style={{ height: '1px' }} />}
    </>
  )
}
