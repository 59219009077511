import { CircularProgress, Stack } from '@mui/material'
import { FC } from 'react'

export const Progress: FC = () => {
  return (
    <Stack flex={1} alignItems='center' justifyContent='center' height='100%' className='progress'>
      <CircularProgress />
    </Stack>
  )
}
