import { AuditItemWrapper } from './AuditItem.styles'
import { AuditItemProps } from './AuditItem.types'
import { Stack, Typography } from '@mui/material'
import { userRolesEnToRu } from 'core/types/user'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'

export const AuditItem: FC<AuditItemProps> = ({ audit, auditTitle, auditSubtitle, auditIcon, status }) => {
  const { user: userData, timestamp } = audit
  const { role, user: user } = userData
  const { lastName, firstName, middleName } = user

  const userText = `${lastName || ''} ${firstName || ''} ${middleName || ''}, ${userRolesEnToRu[role]}`

  const { fullDate, time } = parseResponseDateTime(timestamp)

  return (
    <AuditItemWrapper spacing={1}>
      <Stack spacing={0.25}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' alignItems='center' spacing={1}>
            {auditIcon}
            <Typography variant='subtitle2' lineHeight='14px'>
              {auditTitle}
            </Typography>
          </Stack>

          <Typography variant='body2' fontSize={12} lineHeight='12px' color={theme.palette.text.dark}>
            {fullDate} {time}
          </Typography>
        </Stack>

        <Typography variant='body2'>{userText}</Typography>
      </Stack>

      <Stack spacing={1}>
        {status && (
          <Typography variant='body1' fontSize={14} lineHeight='20px'>
            Статус: {status}
          </Typography>
        )}
        {auditSubtitle && (
          <Typography variant='body1' fontSize={14} lineHeight='20px'>
            {auditSubtitle}
          </Typography>
        )}
      </Stack>
    </AuditItemWrapper>
  )
}
