import { placeholderNoData } from '../../../../index'
import { PlaceholderImage } from '../../MainCard.styles'
import { MapWrapper } from './AboutTabImage.styles'
import { AboutTabImageProps, MapHeight, MapWidth } from './AboutTabImage.types'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import React, { FC, MouseEvent, useCallback, useMemo } from 'react'
import { googleKey } from 'utils/constants'

export const AboutTabImage: FC<AboutTabImageProps> = ({ addressData }) => {
  /**
   * деструктуризация пропсов
   */
  const address = useMemo(() => {
    // if (addressData) {
    return addressData?.address || ''
    // }
    // return null;
  }, [addressData])

  const getMapParams = useCallback(() => {
    // if (address) {
    const marker = address ? `markers=${address}` : ''
    const size = `size=${MapWidth}x${MapHeight}`
    const key = `key=${googleKey}`
    const center = !address ? `center=-30,0` : ''

    return [marker, center, size, key].join('&')
    // }
  }, [address])

  const onMapClick = (address: string) => {
    // if (address) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank')
    // }
  }
  const mapClickable = !!address

  return (
    <MapWrapper onClick={() => mapClickable && onMapClick(address)} hover={mapClickable}>
      {/*{address ?*/}
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?${getMapParams()}`}
        width={MapWidth}
        height={MapHeight}
        alt='Адрес проекта на карте'
        style={{ borderRadius: 16 }}
      />

      {/*: <PlaceholderImage>*/}
      {/*    <DoNotDisturbIcon/>*/}
      {/*    {placeholderNoData}*/}
      {/*</PlaceholderImage>*/}
      {/*}*/}
    </MapWrapper>
  )
}
