import { MapHeight, MapWidth } from './components/AboutTabImage'
import { Stack, Tabs } from '@mui/material'
import styled from 'styled-components'

export const DashboardBasicCard = styled(Stack)`
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.palette.bg.white};
`

export const DashboardMainCard = styled(DashboardBasicCard)`
  grid-area: main;
`

export const MainCardTabs = styled(Tabs)`
  min-height: 40px;

  & .MuiTab-root {
    min-width: fit-content;
    min-height: 40px;
    padding: 10px 8px;
    font-weight: 400;
    text-transform: none;
  }
`
export const PlaceholderImage = styled.div`
  width: ${MapWidth}px;
  height: ${MapHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`
