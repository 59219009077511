import { IInitialWork, initialWork } from './ChoreDrawer.types'
import { CreateWork } from 'api/ksg/api.types'
import { WorkFull } from 'api/ksg/types'

export function getInitialValues(data: WorkFull | null) {
  let initial = { ...initialWork }
  if (data) {
    const { qcInfo, contractor, capacity, name, rd, assignmentType, object, identifier, period, documentCypher } = data
    initial = {
      ...initialWork,
      documentCypher: documentCypher || '',
      capacity: {
        confirmed: capacity.confirmed || '0',
        unit: capacity.unit || '',
        total: capacity.total || '0',
      },
      assignmentType: {
        value: assignmentType?.title || '',
        type: '',
        subtext: null,
        id: assignmentType?.id || 0,
      },
      contractor: {
        subtext: null,
        value: contractor?.shortName || '',
        type: '',
        id: contractor?.id || 0,
      },
      rd: {
        custom: '',
        value: rd?.mark || '',
        type: '',
        id: rd?.id || 0,
        subtext: null,
      },
      qcInfo: {
        status: qcInfo?.status || 'EMPTY',
        date: qcInfo?.date ? new Date(qcInfo?.date) : null,
        comment: qcInfo?.comment || '',
      },
      object: {
        value: object?.title || '',
        subtext: null,
        type: '',
        id: object?.id || 0,
      },
      name,
      identifier: identifier || '',
      end: period?.end ? new Date(period?.end) : null,
      start: period?.start ? new Date(period?.start) : null,
    }
  }
  return initial
}

export function generateRequestData(values: IInitialWork) {
  const { qcInfo, contractor, capacity, documentCypher, assignmentType, end, object, identifier, name, rd, start } =
    values
  const payload: CreateWork = {
    qcInfo: {
      status: qcInfo.status === 'EMPTY' ? null : qcInfo.status,
      date: qcInfo?.date ? new Date(qcInfo?.date)?.toLocaleDateString('en-ca') : null,
      comment: qcInfo.comment || null,
    },
    assignmentType: {
      ...(assignmentType.type === 'custom'
        ? {
            assignmentType: null,
            custom: assignmentType.value,
          }
        : assignmentType.id
          ? {
              assignmentType: assignmentType.id,
              custom: null,
            }
          : {
              assignmentType: null,
              custom: null,
            }),
    },
    capacity: {
      ...capacity,
      confirmed: capacity.confirmed || '0',
      total: capacity.total || '0',
    },
    contractor: {
      ...(contractor.type === 'custom'
        ? {
            contractor: null,
            custom: contractor.value,
          }
        : contractor.id
          ? {
              contractor: contractor.id,
              custom: null,
            }
          : {
              contractor: null,
              custom: null,
            }),
    },
    rd: {
      ...(rd.type === 'custom'
        ? {
            rd: null,
            custom: rd.value,
          }
        : rd.id
          ? {
              rd: rd.id,
              custom: null,
            }
          : {
              rd: null,
              custom: null,
            }),
    },
    object: {
      ...(object.type === 'custom'
        ? {
            object: null,
            custom: object.value,
          }
        : object.id
          ? {
              object: object.id,
              custom: null,
            }
          : {
              object: null,
              custom: null,
            }),
    },
    documentCypher: documentCypher || null,
    end: end ? new Date(end).toLocaleDateString('en-ca') : null,
    name,
    identifier: identifier?.trim() || null,
    start: start ? new Date(start)?.toLocaleDateString('en-ca') : null,
  }
  return payload
}

/* From 'dd.mm.YYYY' -> 'YYYY-mm-dd' */
export const formatDate = (str: string) => {
  return str.replace(/(\d+)\.(\d+)\.(\d+)/, '$3-$2-$1')
}
