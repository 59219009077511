import { RemarksTableCell } from 'pages/Remarks/components/RemarksTable'
import styled from 'styled-components'

interface RemarksStatusCellProps {
  hoverable?: boolean
}

export const RegulationAttachmentCell = styled(RemarksTableCell)<RemarksStatusCellProps>`
  flex: 1;
  width: 100%;
  padding: 0;
  cursor: ${(props) => props.hoverable && 'pointer'};

  &:hover {
    box-shadow: ${(props) =>
      props.hoverable &&
      `
      ${props.theme.palette.primary.light} -1px -1px 0px inset,
      ${props.theme.palette.primary.light} 1px 1px 0px inset
    `};
  }

  & a {
    display: flex;
  }
`
