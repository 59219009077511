import { Accordion, AccordionDetails, AccordionSummary, alpha, Stack } from '@mui/material'
import styled from 'styled-components'

export const ColumnsDrawerSectionAccordion = styled(Accordion)`
  padding-top: 10px;
  padding-left: 6px;
  box-shadow: none;

  &::before {
    display: none;
  }
`

export const ColumnsDrawerSectionAccordionSummary = styled(AccordionSummary)`
  gap: 8px;
  min-height: 40px;
  padding: 0 16px 0 24px;
  background-color: transparent;

  &.Mui-expanded {
    min-height: 40px;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`

export const ColumnsDrawerSectionAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 0;
  padding-top: 4px;
`

export const ColumnsDrawerSectionCount = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  background-color: ${(props) => alpha(props.theme.palette.primary.main, 0.08)};
  border-radius: 100px;

  & .MuiTypography-root {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
