import { RemarksTableCell } from '../../RemarksTable.styles'
import { Stack } from '@mui/material'
import styled from 'styled-components'

interface RemarksStatusCellProps {
  hoverable?: boolean
}

export const RemarksStatusCell = styled(RemarksTableCell)<RemarksStatusCellProps>`
  flex: 1;
  width: 100%;
  padding: 0;
  cursor: ${(props) => props.hoverable && 'pointer'};

  &:hover {
    box-shadow: ${(props) =>
      props.hoverable &&
      `
      ${props.theme.palette.primary.light} -1px -1px 0px inset,
      ${props.theme.palette.primary.light} 1px 1px 0px inset
    `};
  }
`

export const StatusCellContent = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding-left: 7px;

  & .colored-title {
    justify-content: center;
    min-width: 90px;
  }

  & .MuiTypography-root {
    line-height: 14px;
    color: ${(props) => props.theme.palette.text.light} !important;
  }

  & .MuiIconButton-root {
    padding: 0;

    & .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.text.dark};
    }
  }
`
