import { UserTopBarProps } from './UserTopBar.types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Stack } from '@mui/material'
import { Button } from 'components/Button'
import React from 'react'
import { theme } from 'styles/theme'

export const UserTopBar: React.FC<UserTopBarProps> = ({ onReturnClick, onDeleteClick, isEditUser }) => {
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ px: 3.75, pt: 3.75, pb: 2.5 }}>
      <Box>
        <Button
          variant='text'
          style={{ color: theme.palette.text.dark }}
          startIcon={<ArrowBackIcon />}
          onClick={onReturnClick}
        >
          Назад
        </Button>
      </Box>
      {isEditUser ? (
        <Box>
          <Button icon color='error' onClick={onDeleteClick}>
            <DeleteIcon />
          </Button>
        </Box>
      ) : null}
    </Stack>
  )
}
