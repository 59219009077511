import { PrescriptionFilterableColumn } from 'core/types/prescription'
import { columnIdsWithTime } from './FilterList.constants'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { RemarkFilterableColumn } from 'core/types/remarks'

export function formateDate<T extends PrescriptionFilterableColumn | RemarkFilterableColumn>(
  date: Date | null,
  columnId: T,
) {
  if (!date) return null

  return columnIdsWithTime.includes(columnId) ? new Date(formatDateForServer(date)) : formatDateForServer(date)
}
