import { Menu, MenuItem, Typography } from '@mui/material'
import { useGetProjectsQuery } from 'api/projects'
import { Project } from 'api/projects/types'
import { Progress } from 'components/Progress'
import React, { useState, MouseEvent, useCallback, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

export interface UseBreadcrumbMenuProps {
  itemId: number
  onMenuItemClick: (item: any) => void
  list: any[]
  emptyTxt: string
}

export interface UseBreadcrumbMenuReturns {
  onItemBreadClick: (event: MouseEvent<HTMLSpanElement>) => void
  item?: any
  breadcrumbMenu: ReactNode
  isMenuOpen: boolean
}

export const useBreadcrumbMenu = ({
  itemId,
  onMenuItemClick,
  list,
  emptyTxt,
}: UseBreadcrumbMenuProps): UseBreadcrumbMenuReturns => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  const item = list.find((item: any) => item.id === itemId)

  const onItemBreadClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onClick = useCallback(
    (item: number) => {
      onMenuItemClick(item)
      onClose()
    },
    [onClose],
  )

  const breadcrumbMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={onClose}
      MenuListProps={{
        style: {
          width: '100%',
        },
      }}
      PaperProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
          minWidth: 200,
          // minHeight: 200,
          maxWidth: 450,
          maxHeight: 300,
        },
      }}
    >
      {list?.length > 1 ? (
        list
          .filter((item: any) => item.id !== itemId)
          ?.map((item: any) => (
            <MenuItem onClick={() => onClick(item)} style={{ maxWidth: 450 }} key={item.id}>
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.name}
              </Typography>
            </MenuItem>
          ))
      ) : (
        <MenuItem style={{ maxWidth: 450 }} disabled>
          <Typography>{emptyTxt}</Typography>
        </MenuItem>
      )}
    </Menu>
  )

  return {
    onItemBreadClick,
    item,
    breadcrumbMenu,
    isMenuOpen,
  }
}
