import { StyledMenu } from './Menu.styles'
import { IMenuProps } from './Menu.types'
import { Close as CloseIcon } from '@mui/icons-material'
import { MenuItem, Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { FC, useMemo } from 'react'
import { theme } from 'styles/theme'

export const Menu: FC<IMenuProps> = ({ anchorEl, title, onClose, menuData }) => {
  const onMenuItemClick = (action: () => void) => {
    action()
    onClose()
  }

  const filtredMenuData = useMemo(() => {
    return menuData.filter((item) => !item.hidden)
  }, [menuData])

  return (
    <StyledMenu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      <Stack spacing={0.5} width={'170px'}>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography
            color={theme.palette.secondary.gray}
            variant='body2'
            display={'flex'}
            flex={1}
            justifyContent={'center'}
            paddingLeft={'40px'}
          >
            {title}
          </Typography>
          <Button icon variant='text' onClick={onClose}>
            <CloseIcon color='primary' />
          </Button>
        </Stack>
        <Stack spacing={1}>
          {filtredMenuData.map((item) => (
            <MenuItem
              onClick={() => onMenuItemClick(item.action)}
              sx={{
                borderRadius: '100px',
                border: `1px solid ${theme.palette.primary.main}`,
                justifyContent: 'center',
              }}
            >
              <Stack direction={'row'} spacing={1}>
                {item.icon}
                <Typography color={item.labelColor || theme.palette.primary.main} variant='body1'>
                  {item.label}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </Stack>
    </StyledMenu>
  )
}
