import { userRolesArray } from 'core/types/user'
import { i18n } from 'i18n/index'
import { MIN_PASSWORD_LENGTH_VALIDATION } from 'utils/constants'
import * as yup from 'yup'

export const BASE_USER_MAX_LENGTH = 20
export const POSITION_USER_MAX_LENGTH = 80
export const COMPANY_NAME_MAX_LENGTH = 80
export const EMAIL_USER_MAX_LENGTH = 80
export const LOGIN_USER_MAX_LENGTH = 80

export const validationProfile = yup.object({
  lastName: yup
    .string()
    .trim()
    .max(BASE_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: BASE_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  firstName: yup
    .string()
    .trim()
    .max(BASE_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: BASE_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  middleName: yup
    .string()
    .trim()
    .max(BASE_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: BASE_USER_MAX_LENGTH })),
  companyName: yup
    .string()
    .trim()
    .max(COMPANY_NAME_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: COMPANY_NAME_MAX_LENGTH })),
  position: yup
    .string()
    .trim()
    .max(POSITION_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: POSITION_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  phone: yup.string().required('common:errors.required'),
  email: yup
    .string()
    .email('common:errors.email')
    .max(EMAIL_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: EMAIL_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  login: yup
    .string()
    .trim()
    .max(LOGIN_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: LOGIN_USER_MAX_LENGTH })),
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH_VALIDATION, i18n.t('common:errors.min', { minLength: MIN_PASSWORD_LENGTH_VALIDATION }))
    .required('common:errors.required'),
  role: yup.string().oneOf(userRolesArray),
  projects: yup.array(),
  companies: yup.array(),
  //   companies: yup.array().when('role', {
  //   is: 'contractor',
  //   then: yup.array().when('projects', {
  //     is: ,
  //     then: yup.array().min(1, 'fkdsjkf')
  //   })
  // })
})
