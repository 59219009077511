import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date(new Date().setHours(0, 0, 0, 0))

export const eliminationValidation = yup.object({
  status: yup.string().trim().required('common:errors.required'),
  responsible: yup
    .object({
      id: yup.number(),
      value: yup.string().trim(),
    })
    .nullable(),
  contractorReason: yup
    .string()
    .trim()
    .when('status', {
      is: 'DISCARDED',
      then: yup.string().trim().required('common:errors.required'),
    }),
  engineerReason: yup.string().trim(),

  remarkDateStart: yup.date().transform(formatDateInInput),
  dateDone: yup
    .date()
    .transform(formatDateInInput)
    .min(yup.ref('remarkDateStart'))
    .max(today)
    .when('status', {
      is: 'COMPLETE',
      then: yup.date().transform(formatDateInInput).max(today).required('common:errors.required'),
      otherwise: yup.date().transform(formatDateInInput).max(today).nullable(),
    }),
  measures: yup
    .string()
    .trim()
    .when('status', {
      is: 'COMPLETE',
      then: yup.string().trim().required('common:errors.required'),
    }),
})
