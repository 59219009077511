import { analyticsReducer } from './slices/analytics'
import { authReducer } from './slices/auth'
import { documentsReducer } from './slices/documents'
import { infiniteScrollReducer } from './slices/infiniteScroll'
import { profileReducer } from './slices/profile'
import { uiReducer } from './slices/ui'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from 'api/api'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    profile: profileReducer,
    ui: uiReducer,
    infiniteScroll: infiniteScrollReducer,
    documents: documentsReducer,
    analytics: analyticsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
