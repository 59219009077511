import { TableHeadRadius } from 'components/Table/Table.styles'
import styled from 'styled-components'

export const TABLE_CELL_HEIGHT = 60

export const RemarksTableCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${TABLE_CELL_HEIGHT}px;
  padding: 8px;
  background-color: ${(props) => props.theme.palette.bg.white};
  box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;

  & .MuiTypography-root {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${(props) => props.theme.palette.text.dark};
  }

  // &:hover p {
  //   display: block;
  // }
`

interface RemarksTableRowProps {
  showAnimation?: boolean
}

export const RemarksTableRow = styled.div<RemarksTableRowProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;

  &:hover {
    z-index: 2;
  }

  ${(props) =>
    props.showAnimation &&
    `
    ${RemarksTableCell}, .display-cell, .cell {
      background-color: ${props.theme.palette.bg.gray};
    }
  `}
`

export const RemarksTableBody = styled.div`
  position: relative;

  ${RemarksTableRow}:hover {
    cursor: pointer;

    ${RemarksTableCell}, .display-cell, .cell {
      background-color: ${(props) => props.theme.palette.bg.shades};
    }
  }
`

export const RemarksTableHead = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;

  ${RemarksTableRow} {
    position: static;
  }

  ${RemarksTableCell} {
    background-color: ${(props) => props.theme.palette.secondary.gray};

    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.text.light};
    }
  }
`

export const RemarksTableHeadRow = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;

  background-color: ${(props) => props.theme.palette.secondary.gray};

  & > div {
    background-color: transparent !important;
  }
`

export const RemarksTableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 7px;
  overflow-y: scroll;
`

export const ObserverBound = styled.div`
  position: absolute;
  top: calc(100% - 500px);
  width: 500%;
`
