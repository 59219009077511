import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const ReturnIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  background-color: rgba(25, 118, 210, 0.08);
  border: ${(props) => `1px solid ${props.theme.palette.primary.main}`};
  border-radius: 6px;
`
