import { ProjectMembersDialogTrigger } from '../../ProjectMembers.types'
import { ProjectMembersFormData, ProjectMembersManagmentFormProps } from './ProjectMembersManagmentForm.types'
import { validationProjectMembers } from './validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import {
  useCreateProjectMemberByProjectMutation,
  useCreateProjectMemberMutation,
  useDeleteProjectMemberByProjectMutation,
  useDeleteProjectMemberMutation,
  useEditProjectMemberByProjectMutation,
  useEditProjectMemberMutation,
} from 'api/references/projectMembers'
import { ProjectMemberShort } from 'api/references/projectMembers/types'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import {
  ProjectMemberRole,
  projectMemberRoleEnByRu,
  projectMemberRoleRuByEn,
  projectMemberRoles,
} from 'core/types/projectMember'
import { UserRoles, userRolesArray, userRolesEnToRu } from 'core/types/user'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { theme } from 'styles/theme'

export const ProjectMembersManagmentForm: FC<ProjectMembersManagmentFormProps> = ({
  onFormChange,
  projectMember,
  onClose,
}) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const [createProjectMember, { isLoading: isCreating, ...createProjectMemberResponse }] =
    useCreateProjectMemberMutation()
  const [createProjectMemberByProject, { isLoading: isCreatingByProject, ...createProjectMemberByProjectResponse }] =
    useCreateProjectMemberByProjectMutation()

  const [editProjectMember, { isLoading: isEditing, ...editProjectMemberResponse }] = useEditProjectMemberMutation()
  const [editProjectMemberByProject, { isLoading: isEditingByProject, ...editProjectMemberByProjectResponse }] =
    useEditProjectMemberByProjectMutation()

  const [deleteProjectMember, { isLoading: isDeleting, ...deleteProjectMemberResponse }] =
    useDeleteProjectMemberMutation()
  const [deleteProjectMemberByProject, { isLoading: isDeletingByProject, ...deleteProjectMemberByProjectResponse }] =
    useDeleteProjectMemberByProjectMutation()

  const isQuerying = isCreating || isCreatingByProject || isEditing || isEditingByProject
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { shortName, fullName, basis, role, supervisorName, supervisorPosition, note } = projectMember || {}

  const initialValues: ProjectMembersFormData = useMemo(
    () => ({
      shortName: shortName || '',
      fullName: fullName || '',
      basis: basis || '',
      role: role
        ? {
            id: role || '',
            value: projectMemberRoleRuByEn[role] || '',
          }
        : null,
      supervisorName: supervisorName || '',
      supervisorPosition: supervisorPosition || '',
      note: note || '',
    }),
    [projectMember],
  )

  const onSubmit = useCallback(
    (values: ProjectMembersFormData) => {
      const { shortName, fullName, basis, role, supervisorName, supervisorPosition, note } = values

      const dataForRequest: ProjectMemberShort = {
        shortName,
        fullName,
        basis,
        role: role!.id as ProjectMemberRole,
        supervisorName,
        supervisorPosition,
        note,
      }

      if (projectMember) {
        isProjectMode
          ? editProjectMemberByProject({ projectId, projectMemberId: projectMember.id, body: dataForRequest })
          : editProjectMember({ projectMemberId: projectMember.id, body: dataForRequest })
      } else {
        isProjectMode
          ? createProjectMemberByProject({ projectId, body: dataForRequest })
          : createProjectMember({ body: dataForRequest })
      }
    },
    [projectMember],
  )

  const onDelete = () => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }

  const { formik } = useForm({
    validationSchema: validationProjectMembers,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const handleDeleteProjectmember = useCallback((confirm: boolean) => {
    if (confirm) {
      isProjectMode
        ? deleteProjectMemberByProject({ projectId, projectMemberId: projectMember!.id })
        : deleteProjectMember({ projectMemberId: projectMember!.id })
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<ProjectMembersDialogTrigger>('closeManagment')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeManagment: { handleConfirm: handleDeleteProjectmember },
    delete: {
      handleConfirm: handleDeleteProjectmember,
      title: 'Удалить участника проекта?',
      body: 'Участник проекта будет удалён.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  const handleDuplicateError = useCallback((duplicateField: string[]) => {
    let duplicates = 'с данной ролью и кратким наименованием'
    if (duplicateField.length == 1) {
      duplicates = duplicateField[0] === 'role' ? 'с данной ролью' : 'с данным кратким наименованием'
    }

    enqueueSnackbar(`Участник проекта ${duplicates} уже существует.`, { variant: 'error' })
  }, [])

  useMutationHandlers(
    isProjectMode ? createProjectMemberByProjectResponse : createProjectMemberResponse,
    (data) => {
      // if (!data.success) {
      //   enqueueSnackbar(data.description, { variant: 'error' })
      //   return
      // }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Участник проекта успешно добавлен.', { variant: 'success' })
      onClose(dirty, true)
      // onChangeTab('view', true)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) handleDuplicateError(data?.data)
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  useMutationHandlers(
    isProjectMode ? editProjectMemberByProjectResponse : editProjectMemberResponse,
    (data) => {
      // if (!data.success) {
      //   enqueueSnackbar(data.description, { variant: 'error' })
      //   return
      // }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Участник проекта успешно изменён.', { variant: 'success' })
      onClose(dirty, true)
      // onChangeTab('view', true)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) handleDuplicateError(data?.data)
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  useMutationHandlers(
    isProjectMode ? deleteProjectMemberByProjectResponse : deleteProjectMemberResponse,
    (data) => {
      // if (!data.success) {
      //   enqueueSnackbar(data.description, { variant: 'error' })
      //   return
      // }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Участник проекта успешно удалён.', { variant: 'success' })
      onClose(dirty, true)
    },
    (data) => handleError(),
  )

  return (
    <Stack height={'100%'} overflow={'auto'}>
      <FormikProvider value={formik}>
        <Stack component={Form} height={'100%'} justifyContent={'space-between'} overflow={'auto'}>
          <Stack spacing={2.5} height={'100%'} overflow={'auto'}>
            <FormWrapper spacing={1.5} height={'100%'}>
              <FieldForm
                version='project'
                name='shortName'
                placeholder='Краткое наименование'
                multiline
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='fullName'
                placeholder='Полное наименование'
                multiline
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='basis'
                placeholder='Основание'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                  },
                }}
              />

              <CustomSelect
                name='role'
                list={projectMemberRoles.map((type) => ({ id: projectMemberRoleEnByRu[type], text: type })) || []}
                placeholder='Роль'
                // readOnly={viewingOnly}
                isSearch={true}
                style={{
                  textAlign: 'start',
                }}
                // width={216}
              />

              <FieldForm
                version='project'
                name='supervisorName'
                placeholder='ФИО руководителя'
                multiline
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='supervisorPosition'
                placeholder='Должность руководителя'
                multiline
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='note'
                placeholder='Примечание'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                  },
                }}
              />
            </FormWrapper>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width={'100%'}>
                {projectMember?.id && (
                  <Button
                    onClick={onDelete}
                    size='medium'
                    color='error'
                    width={'fit-content'}
                    loading={isDeleting || isDeletingByProject}
                  >
                    <DeleteIcon fontSize='medium' style={{ fill: theme.palette.bg.white }} />
                  </Button>
                )}
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  loading={isFormLoading}
                  color='success'
                  size='medium'
                  fullWidth
                >
                  Сохранить
                </Button>
                <Button size='medium' fullWidth onClick={() => onClose(dirty)}>
                  Отменить
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>

      <ConfirmDialog />
    </Stack>
  )
}
