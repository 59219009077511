import { Stack } from '@mui/material'
import styled from 'styled-components'

export const StatisticalWidgetWrapper = styled(Stack)`
  flex-direction: row;
  align-items: center;
  min-width: 800px;

  @media (max-width: 900px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
`
