import { StatusColor } from './ColoredStatus.types'
import { Box } from '@mui/material'
import styled from 'styled-components'

interface StatusedWrapperProps {
  color: StatusColor
}

export const StatusedWrapper = styled(Box)<StatusedWrapperProps>`
  padding: 2px 6px;
  background-color: ${(props) => props.color === 'secondary' && props.theme.palette.bg.shades};
  background-color: ${(props) => props.color === 'error' && 'rgba(255, 177, 177, 0.15)'};
  background-color: ${(props) => props.color === 'empty' && 'transparent'};
  border-radius: 100px;

  & .MuiTypography-root {
    color: ${(props) => props.theme.palette.text.dark};
    color: ${(props) => props.color === 'error' && props.theme.palette.error.main};
  }
`
