import { ProjectCardContentProps } from './ProjectCardContent.types'
import { StyledProjectCardContent, TypeInfoRow } from './styles'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import EventNoteIcon from '@mui/icons-material/EventNote'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Stack } from '@mui/material'
import { Divider } from 'components/Divider'
import { InfoRow, InfoRowData } from 'components/InfoRow'
import { projectTypeShort } from 'core/types/project'
import React from 'react'

export const ProjectCardContent: React.FC<ProjectCardContentProps> = ({ data }) => {
  const { startDate, endDate, type } = data

  const rowsData: InfoRowData[] = [
    {
      icon: EventNoteIcon,
      title: 'Дата начала',
      value: startDate,
    },
    {
      icon: EventAvailableIcon,
      title: 'Дата окончания',
      value: endDate,
    },
  ]

  const projectTypeRowData: InfoRowData = {
    icon: InsertDriveFileOutlinedIcon,
    title: 'Тип проекта',
    value: projectTypeShort[type],
  }

  return (
    <StyledProjectCardContent>
      <Stack spacing={1.25}>
        <TypeInfoRow rowData={projectTypeRowData} />

        <Divider />

        <Stack spacing={1.5} pt={0.5}>
          <Stack spacing={1}>
            {rowsData.map((row) => (
              <InfoRow rowData={row} key={row.title} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </StyledProjectCardContent>
  )
}
