import { PrescriptionFormData } from '../../../PrescriptionsForm/PrescriptionsForm.types'
import { ObjectsList } from '../ObjectsList'
import { RemarksList } from '../RemarksList'
import { BindRemarkInfo, BindRemarksFormWrapper, BindRemarksSearch } from './BindRemarksForm.styles'
import {
  BindRemarksFormData,
  BindRemarksFormProps,
  IBindRemarkFilter,
  IBindRemarkInfoData,
} from './BindRemarksForm.types'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material'
import { useGetBindObjectsQuery } from 'api/prescriptions'
import { BindObjectInfo, RequestBindChoice } from 'api/prescriptions/types'
import { StyledSearchIcon } from 'components/Header/components/Search'
import { Form, FormikProvider, useFormikContext } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import lodash from 'lodash'
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { debounce } from 'throttle-debounce'
import { connectNames } from 'utils/connectNames'
import { SEARCH_QUERY_DELAY } from 'utils/constants'
import { Person as PersonIcon, HomeWork as HouseIcon } from '@mui/icons-material'
import { Tooltip } from 'components/Tooltip'

export const BindRemarksForm: FC<BindRemarksFormProps> = ({ onChange, onClose, rootChoice, localDirty }) => {
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams<{
    projectId: string
    prescriptionId?: string
  }>()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString) || undefined
  const profile = useTypedSelector(profileSelector)

  const { values: prescriptionValues, setFieldValue: setRootFieldValue } = useFormikContext<PrescriptionFormData>()

  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [bindRemarksSearchKey, setBindRemarksSearchKey] = useState<number>(1)

  // const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setSearchInputValue(e.target.value)

  //   if (!e.target.value) setSearchValue('')
  //   else onThrottledSearchChange(e)
  // }

  const onThrottledSearchChange = debounce(SEARCH_QUERY_DELAY, (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  })

  const onSearchClear = () => {
    setBindRemarksSearchKey(Date.now())
    setSearchInputValue('')
    setSearchValue('')
  }

  const initialValues: BindRemarksFormData = useMemo(
    () => ({
      viewObjectId: null,
      choice: rootChoice || {
        all: false,
        includedObjects: {},
        excludedObjects: [],
      },
      filter: {
        all: false,
        senderUser:
          prescriptionValues.senderUser?.type === 'engineerQC' ? Number(prescriptionValues.senderUser?.id) : null,
        senderRepresentative:
          prescriptionValues.senderUser?.type === 'representative' ? Number(prescriptionValues.senderUser?.id) : null,
        contractor: Number(prescriptionValues.contractor?.id) || null,
      },
    }),
    [rootChoice],
  )

  console.log('prescriptionValues', prescriptionValues)

  const onSubmit = (values: BindRemarksFormData) => {
    setRootFieldValue('choice', { ...values.choice, all: false })
  }

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, dirty, setFieldValue, isSubmitting } = formik

  const { data, isLoading } = useGetBindObjectsQuery({
    projectId,
    body: {},
    // body: { choice: values.choice },
    prescriptionId,
    preloadShortcomings: 10,
    query: searchValue || undefined,
    senderUser: values.filter.senderUser,
    senderRepresentative: values.filter.senderRepresentative,
    contractor: values.filter.contractor || undefined,
  })

  const [choiceMerged, setChoiceMerged] = useState<boolean>(false)
  // const parsedChoice: RequestBindChoice = {
  //   all: false,
  //   excludedObjects: [],
  //   includedObjects:
  //     data?.data
  //       .filter((item) => item.chosenShortcomings)
  //       .map((item) => ({
  //         [item.id]: { all: false, exclude: [], include: item.shortcomings.map((remark) => remark.shortcoming.id) },
  //       }))
  //       .reduce((acc, obj) => {
  //         const key: any = Object.keys(obj)[0]
  //         acc[key] = obj[key]
  //         return acc
  //       }, {}) || {},
  // }

  useEffect(() => {
    if (data?.data?.length && !data.data.find((item) => item.id === values.viewObjectId)) {
      setFieldValue('viewObjectId', data.data[0].id)
    }

    if (!rootChoice && prescriptionId && data?.choice && !choiceMerged) {
      // setFieldValue('choice', parsedChoice)
      setFieldValue('choice', data.choice)
      setChoiceMerged(true)
    }

    setFieldValue(
      'choice.all',
      !!data?.data?.length &&
        data?.data.every((item) => Object.keys(values.choice.includedObjects).includes(String(item.id))),
    )
  }, [data])

  useEffect(() => {
    if (choiceMerged) {
      setFieldValue(
        'choice.all',
        !!data?.data?.length &&
          data?.data.every((item) => Object.keys(values.choice.includedObjects).includes(String(item.id))),
      )
      // setChoiceMerged(false)
    }
  }, [choiceMerged])

  // const [getBindObjects, getBindObjectsResponse] = useGetBindObjectsQuery()
  // const { data, isLoading } = getBindObjectsResponse

  // useLayoutEffect(() => {
  //   getBindObjects({
  //     projectId,
  //     body: {},
  //     prescriptionId,
  //     preloadShortcomings: 10,
  //     query: searchValue || undefined,
  //   })
  // }, [projectId, searchValue])

  // useMutationHandlers(getBindObjectsResponse, (data) => {
  //   if (data?.data?.length) {
  //     setFieldValue('viewObjectId', data.data[0].id)
  //   }

  //   if (!rootChoice && prescriptionId && data?.choice) {
  //     setFieldValue('choice', data.choice)
  //   }
  // })

  useEffect(() => {
    if (!rootChoice && !data?.choice) return
    const trueDirty = !lodash.isEqual(
      rootChoice?.includedObjects || data?.choice.includedObjects,
      values.choice.includedObjects,
    )
    onChange(trueDirty)
  }, [rootChoice, initialValues, values, data?.choice])

  const getAuthor = () => {
    if (!prescriptionValues.senderUser) return '—'

    const [lastName, firstName, middleName] = prescriptionValues.senderUser.value.split(' ')

    return connectNames({ lastName, firstName, middleName }, true)
  }

  const changeRemarkFilter = (filterField: keyof IBindRemarkFilter, id?: number) => {
    let localFilter: IBindRemarkFilter = { ...values.filter }

    if (filterField === 'all') {
      const newValue = !values.filter.all

      if (newValue) {
        localFilter = {
          all: newValue,
          senderUser: null,
          senderRepresentative: null,
          contractor: null,
        }
      } else {
        localFilter = {
          all: newValue,
          senderUser:
            prescriptionValues.senderUser?.type === 'engineerQC' ? Number(prescriptionValues.senderUser?.id) : null,
          senderRepresentative:
            prescriptionValues.senderUser?.type === 'representative' ? Number(prescriptionValues.senderUser?.id) : null,
          contractor: Number(prescriptionValues.contractor?.id) || null,
        }
      }
    } else {
      if (values.filter[filterField]) localFilter[filterField] = null
      else if (id) localFilter[filterField] = id

      localFilter.all = !localFilter.senderUser && !localFilter.senderRepresentative && !localFilter.contractor
    }

    setFieldValue('filter', localFilter)
  }

  const bindRemarkInfoData: IBindRemarkInfoData[] = [
    {
      id: 'senderUser',
      value: '—',
      icon: <PersonIcon fontSize={'medium'} color={'primary'} />,
      width: '35%',
      tooltipText: 'Замечание составил',
      disabled: true,
      hidden: !!prescriptionValues.senderUser,
    },
    {
      id: 'senderUser',
      value: getAuthor(),
      icon: <PersonIcon fontSize={'medium'} color={'primary'} />,
      width: '35%',
      onClick: () => changeRemarkFilter('senderUser', Number(prescriptionValues.senderUser?.id)),
      tooltipText: 'Замечание составил',
      disabled: !prescriptionValues.senderUser?.id,
      hidden: prescriptionValues.senderUser?.type !== 'engineerQC',
    },
    {
      id: 'senderRepresentative',
      value: getAuthor(),
      icon: <PersonIcon fontSize={'medium'} color={'primary'} />,
      width: '35%',
      onClick: () => changeRemarkFilter('senderRepresentative', Number(prescriptionValues.senderUser?.id)),
      tooltipText: 'Замечание составил',
      disabled: !prescriptionValues.senderUser?.id,
      hidden: prescriptionValues.senderUser?.type !== 'representative',
    },
    {
      id: 'contractor',
      value: prescriptionValues.contractor?.value || '—',
      icon: <HouseIcon fontSize={'medium'} color={'primary'} />,
      width: '40%',
      onClick: () => changeRemarkFilter('contractor', Number(prescriptionValues.contractor?.id)),
      disabled: !prescriptionValues.contractor?.id,
      tooltipText: 'Организация, получившая замечание',
    },
    {
      id: 'all',
      value: 'Показать все',
      width: 'fit-content',
      onClick: () => changeRemarkFilter('all'),
    },
  ]

  return (
    <BindRemarksFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={2.5} component={Form} height='100%' maxHeight='100%'>
          <BindRemarksSearch
            // value={searchInputValue}
            key={bindRemarksSearchKey}
            onChange={onThrottledSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <StyledSearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={onSearchClear} className='search-clear' disableRipple>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder='Поиск по всем замечаниям'
          />
          <Stack direction={'row'} spacing={2} paddingRight={2.5}>
            {bindRemarkInfoData.map(({ id, label, value, icon, width, onClick, tooltipText, disabled, hidden }) => {
              if (hidden) return null

              return (
                <Tooltip title={tooltipText} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
                  <BindRemarkInfo
                    key={id}
                    isChecked={!!values.filter[id]}
                    onClick={!disabled ? onClick : undefined}
                    width={width}
                    isDisabled={!!disabled}
                    style={{ cursor: disabled ? 'auto' : 'pointer' }}
                  >
                    {icon}
                    {label && <Typography variant='body2'>{label}:&nbsp;</Typography>}
                    <Typography variant='body2' fontWeight={500} color={theme.palette.primary.main}>
                      {value}
                    </Typography>
                  </BindRemarkInfo>
                </Tooltip>
              )
            })}
          </Stack>
          <Stack direction='row' flex={1} spacing={1.25} overflow='hidden'>
            <ObjectsList
              searchValue={searchValue}
              shouldWriteFirstChoice={!rootChoice}
              data={data}
              isLoading={isLoading}
            />
            <RemarksList
              onSubmit={onSubmit}
              onClose={onClose}
              searchValue={searchValue}
              objectList={data?.data || []}
              localDirty={localDirty}
              rootChoice={rootChoice}
            />
          </Stack>
        </Stack>
      </FormikProvider>
    </BindRemarksFormWrapper>
  )
}
