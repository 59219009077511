import { ProjectFormData } from '../../ProjectManagement.types'
import { FormItem } from '../FormItem'
import { FormTitledWrapper } from '../FormLeftBlock'
import { MapFormButton } from '../MapForm'
import { ContractorWrapper, EmployeesContractorsDelete, EmployeesContractorsWrapper } from './EmployeesBlock.styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { Stack } from '@mui/material'
import { EmployeeShortProfile } from 'api/profile/types'
import { useGetCompaniesDropdownQuery, useGetResponsibleDropdownQuery } from 'api/projects'
import { useGetCompanyNamesQuery } from 'api/users'
import { AutocompleteField } from 'components/AutocompleteField'
import { FieldArray, useFormikContext } from 'formik'
import { useFilteredEmployees } from 'hooks/useFilteredEmployees'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { currentCompanyIdSelector, profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const EmployeesBlock: FC = () => {
  const { employeeId } = useTypedSelector(profileSelector)
  const companyId = useTypedSelector(currentCompanyIdSelector)
  const { values: formValues, setFieldValue, initialValues } = useFormikContext<ProjectFormData>()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const initialContractorCount = initialValues.responsiblePersons.contractorsArray.length

  const { clientsAndAdmins, contractors } = useFilteredEmployees()

  const selectedContractorsData = useMemo(() => {
    return formValues.responsiblePersons?.contractorsArray.map((contractor) => contractor.data) || []
  }, [formValues.responsiblePersons?.contractorsArray])

  const availableContractors: EmployeeShortProfile[] = contractors.filter(
    (contractor) => !selectedContractorsData.includes(contractor.id),
  )

  const { data: companyNamesData } = useGetCompanyNamesQuery({ companyId })
  const { data: companyNames } = companyNamesData || {}

  const onResponsibleClick = (employeeId: number) => {
    // setFieldValue("responsiblePersons.client.data", employeeId);
    setFieldValue('responsiblePersons.client.curatorData', employeeId)
  }

  const { data: companyDropdown } = useGetCompaniesDropdownQuery({ projectId })
  const { data: responsibleDropdown } = useGetResponsibleDropdownQuery({ projectId })

  return (
    <>
      <FormTitledWrapper title='Заказчик' variant='primary' contentWrapper='stack' bordered='border'>
        <Stack alignItems='end' spacing={1.5}>
          <FormItem
            title='Компания'
            input={
              <AutocompleteField
                fieldName='responsiblePersons.client.company'
                placeholder='Введите компанию или выберите из списка'
                data={
                  companyNames?.map((company) => ({
                    value: company,
                    label: company,
                    key: company,
                  })) || []
                }
                freeSolo
              />
            }
          />
          <FormItem
            title='Куратор'
            input={
              <AutocompleteField
                // fieldName='responsiblePersons.client.data'
                fieldName='responsiblePersons.client.curatorData'
                placeholder='Введите ФИО или выберите из списка'
                data={clientsAndAdmins.map(({ id, name }) => ({
                  value: id,
                  label: name,
                  key: id,
                }))}
                freeSolo
              />
            }
          />
          <MapFormButton onClick={() => onResponsibleClick(employeeId)} variant='text'>
            Я ответственный за проект
          </MapFormButton>
        </Stack>
      </FormTitledWrapper>

      <EmployeesContractorsWrapper title='Подрядчики' variant='primary' contentWrapper='stack' bordered='none'>
        <FieldArray
          name='responsiblePersons.contractorsArray'
          validateOnChange={false}
          render={({ push, remove }) => {
            return formValues.responsiblePersons?.contractorsArray?.map((contractor, index) => (
              <ContractorWrapper title='' variant='primary' contentWrapper='stack' bordered='border'>
                {index > initialContractorCount - 1 && (
                  <EmployeesContractorsDelete onClick={() => remove(index)}>
                    <DeleteIcon fontSize='medium' color='error' />
                  </EmployeesContractorsDelete>
                )}

                <FormItem
                  title='Компания'
                  input={
                    <AutocompleteField
                      fieldName={`responsiblePersons.contractorsArray[${index}].company`}
                      placeholder='Введите компанию или выберите из списка'
                      // @ts-ignore
                      data={
                        companyDropdown?.map((item) => ({
                          group: item.data === 'company' ? 'Компании:' : 'Участники проекта:',
                          value: item,
                          // value: item.data === 'company' ? `${item.text}&${item.data}` : `${item.id}&${item.data}`,
                          label: item.text,
                          key: item.id,
                        })) || []
                      }
                      groupBy={(item) => item.group}
                      freeSolo
                    />
                  }
                />

                <FormItem
                  title='Ответственный'
                  input={
                    <Stack flex={1} alignItems='end' spacing={1}>
                      <AutocompleteField
                        fieldName={`responsiblePersons.contractorsArray[${index}].data`}
                        placeholder='Введите ФИО или выберите из списка'
                        // @ts-ignore
                        data={
                          responsibleDropdown?.map((item) => ({
                            group: item.data === 'representative' ? 'Представители подрядчика:' : 'Пользователи:',
                            value: item,
                            // value: `${item.id}&${item.data}`,
                            label: item.text,
                            key: item.id,
                          })) || []
                        }
                        groupBy={(item) => item.group}
                        freeSolo
                      />

                      {index === formValues.responsiblePersons?.contractorsArray?.length - 1 && (
                        <MapFormButton
                          onClick={() => push({ data: '', company: '' })}
                          disabled={
                            !formValues.responsiblePersons?.contractorsArray?.every((item) => item.data || item.company)
                          }
                          variant='text'
                        >
                          Добавить подрядчика
                        </MapFormButton>
                      )}
                    </Stack>
                  }
                />
              </ContractorWrapper>
            ))
          }}
        />
      </EmployeesContractorsWrapper>
    </>
  )
}
