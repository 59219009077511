import { PublicUserProfile } from 'api/profile/types'
import { UserManagement } from 'components/UserManagement/UserManagement'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import React from 'react'

export const AddUser: React.FC = () => {
  useBreadcrumbs([{ title: 'Создание пользователя' }])

  return (
    <>
      <UserManagement profile={{} as PublicUserProfile} />
    </>
  )
}
