import { Autocomplete, FormControl } from '@mui/material'
import styled from 'styled-components'

export const AutocompleteFormControl = styled(FormControl)`
  width: 100%;
`

interface AutocompleteFieldProps {
  disablePlaceholder?: boolean
  remark?: boolean
}

export const StyledAutocompleteField = styled(Autocomplete)<AutocompleteFieldProps>`
  background-color: ${(props) => props.theme.palette.bg.white};

  & .MuiOutlinedInput-root {
    height: ${(props) => (props.remark ? '30px' : '41px')};
    padding: 10px 18px;
    padding-right: 32px !important;
    font-size: 14px;
    line-height: ${(props) => (props.remark ? '12px' : '22px')};
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.text.dark};
    background-color: ${(props) => props.theme.palette.bg.shades};
    border-radius: 8px;
  }

  & .MuiOutlinedInput-input {
    padding: 0 !important;
    height: ${(props) => props.remark && '12px'};

    width: ${(props) => props.remark && '90.3%'};
    text-align: ${(props) => props.remark && 'end'};
    padding-right: ${(props) => props.remark && '28px'};
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: ${(props) => (props.remark ? '12px' : '22px')};
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.disabled};
    transform: translate(18px, 10px) scale(1);

    &.MuiInputLabel-shrink {
      opacity: ${(props) => (props.disablePlaceholder ? 0 : 1)};
      transform: translate(18px, 10px) scale(1);
    }

    width: ${(props) => props.remark && '90.3%'};
    text-align: ${(props) => props.remark && 'end'};
    padding-right: ${(props) => props.remark && '28px'};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);

    & legend {
      padding: 0;

      & span {
        display: none;
      }
    }
  }

  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 11px);
    right: 12px;

    & .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.text.dark};
      font-size: 16px !important;
    }
  }

  & .MuiFormHelperText-root {
    text-align: right;
  }
`
