import { FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles'
import styled from 'styled-components'

export const ImagesTitledWrapper = styled(FormTitledWrapper)`
  width: fit-content;
  min-width: 616px;

  @media (max-width: 1283px) {
    min-width: 100%;
  }

  & .stack-wrapper {
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      margin: 0;
    }
  }
`
