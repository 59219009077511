import { Button, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const DeleteConfirmWrapper = styled(Stack)`
  width: 100%;
  padding: 14px 16px;
  background: #fdf0e5;
  border-radius: 4px;

  & .MuiButtonBase-root {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`

export interface DeleteConfirmTextProps {
  textColor?: string
}
export const DeleteConfirmText = styled(Typography)<DeleteConfirmTextProps>`
  max-width: 200px;
  color: ${(props) => props.textColor};
`

export const DeleteConfirmTitle = styled(DeleteConfirmText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.46px;
`

export interface DeleteConfirmButtonProps {
  textColor?: string
}
export const DeleteConfirmButton = styled(Button)<DeleteConfirmButtonProps>`
  min-width: auto;
  color: ${(props) => props.textColor};
`
