import { RemarkMediaImageWrapper } from '../../RemarkMediaItem.styles'
import { MediaImageProps } from './MediaImage.types'
import { FC, memo } from 'react'

export const MediaImage: FC<MediaImageProps> = memo(({ imageSrc, onViewerOpen }) => {
  return (
    <RemarkMediaImageWrapper>
      <img onClick={onViewerOpen} src={imageSrc} width={140} height={178} alt='Файл замечания' />
      {/* <img  src={imageSource} width={140} height={178} alt='Файл замечания' /> */}
    </RemarkMediaImageWrapper>
  )
})
