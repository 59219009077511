import { uiContext } from '../../contexts/ui'
import { RemarksLegend } from '../Remarks/components/RemarksLegend'
import { defaultPrescriptionFilters } from './Prescriptions.types'
import { PrescriptionLocationState } from './components/PrescriptionsForm/PrescriptionsForm.types'
import { CurrentSortableColumn, PrescriptionFilters, PrescriptionsTable } from './components/PrescriptionsTable'
import DownloadIcon from '@mui/icons-material/Downloading'
import { Stack } from '@mui/material'
import { useDownloadPrescriptionsTableMutation, useLazyGetPrescriptionsTableExportResultQuery } from 'api/prescriptions'
import { Button } from 'components/Button'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector, profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { downloadMedia } from 'utils/downloadMedia'

export const Prescriptions: FC = () => {
  const cUI = useContext(uiContext)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const [isDownloading, setIsDownloading] = useState(false)

  // Location
  const location = useLocation()
  const locationState: PrescriptionLocationState = (location?.state as PrescriptionLocationState) || {}
  const savedLocationState = useMemo(() => locationState, [])
  const { sortableColumn: locationSortableColumn, filterData: locationFilterData } = savedLocationState || {}

  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const [sortableColumn, setSortableColumn] = useState<CurrentSortableColumn>(
    locationSortableColumn || { column: null, order: null },
  )
  const [filterData, setFilterData] = useState<PrescriptionFilters>(locationFilterData || defaultPrescriptionFilters)

  const [downloadPrescriptionsTable, downloadPrescriptionsTableResponse] = useDownloadPrescriptionsTableMutation()
  const [getPrescriptionsTableExportResult, prescriptionsTableExportResult] =
    useLazyGetPrescriptionsTableExportResultQuery()

  const onMenuItemClickBefore = useCallback(() => {
    setSortableColumn({ column: null, order: null })
    setFilterData(defaultPrescriptionFilters)
  }, [])

  const { onProjectBreadClick, project, isFetching, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/prescriptions',
    onMenuItemClickBefore,
  })

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Предписания' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  const onDownloadClick = () => {
    setIsDownloading(true)
    downloadPrescriptionsTable({
      projectId,
      date: `${formatDateForServer(new Date())}T${new Date().toTimeString().split(' ')[0]}.Z`,
      sort: sortableColumn,
      filter: filterData,
    })
  }

  useMutationHandlers(downloadPrescriptionsTableResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    getPrescriptionsTableExportResult({ projectId, exportId: data.data })
  })

  useMutationHandlers(prescriptionsTableExportResult, (data) => {
    downloadMedia(data.link)
    enqueueSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
    setIsDownloading(false)
    // if (!data.success) {
    //   enqueueSnackbar(data.description, { variant: 'error' })
    //   return
    // }
  })
  // navigate(`/project/${projectId}/prescriptions`, { state: { sortableColumn, filterData, transaction: true } })

  return (
    <Stack flex={1} maxWidth='100%'>
      <RemarksLegend
        accessToAdd={isUserHighAccess}
        createButtonData={{
          text: 'Создать предписание',
          href: `/project/${projectId}/prescriptions/add`,
          hrefState: { state: { sortableColumn, filterData } },
        }}
        columnSettings='prescriptions'
      >
        <Button
          variant='outlined'
          color='secondary'
          startIcon={!isDownloading && <DownloadIcon />}
          onClick={onDownloadClick}
          // disabled={isDownloading}
          loading={isDownloading}
          style={{ minWidth: '126px' }}
        >
          {!isDownloading && 'Скачать'}
        </Button>
      </RemarksLegend>

      <PrescriptionsTable
        key={projectId}
        sort={{ sortableColumn, setSortableColumn }}
        filter={{ filterData, setFilterData }}
      />

      {ProjectsMenu}
    </Stack>
  )
}
