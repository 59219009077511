export const IAS_MONITORING = (
  <svg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M56.5 58L58.5 56L57.5 55L55.5 57L56.5 58Z' fill='#005CE5' />
    <path d='M57.5 57L59 55.5L74.5 68H65L56 58.5L57.5 57Z' fill='#005CE5' />
    <path d='M56.6641 61H32.5L29.5 64H59.5L56.6641 61Z' fill='#005CE5' />
    <path d='M25.5 68H63.2891L60.4531 65H28.5L25.5 68Z' fill='#005CE5' />
    <path
      d='M10.5 65.5C10.5 69.0899 13.4101 72 17 72H49.5V69H17.5C15.2909 69 13.5 67.2091 13.5 65C13.5 62.7909 15.2909 61 17.5 61C17.5 61 18.5 61 18.5 61.5C18.5 62 17.5001 62.4999 16.5001 62.9999L15.5001 63.5C14.7878 63.8269 14.5415 64.133 14.625 65C14.9636 66.3413 15.9768 67.5224 17.5 67.8855C18.3673 67.7785 18.8272 67.5358 19.75 67L32.5 58.5V58L21.5 63C21.5 61.6985 20.6563 60.397 19.3759 59.6466L33.5 55V53L15.3651 59.2073C12.5666 59.9324 10.5 62.4748 10.5 65.5Z'
      fill='#005CE5'
    />
    <path
      d='M89.5 65.5C89.5 69.0899 86.5899 72 83 72H49.5V69H82.5C84.7091 69 86.5 67.2091 86.5 65C86.5 62.7909 84.7091 61 82.5 61C82.5 61 81.5 61 81.5 61.5C81.5 62 82.4999 62.4999 83.4999 62.9999L84.4999 63.5C85.2122 63.8269 85.4585 64.133 85.375 65C85.0364 66.3413 84.0232 67.5224 82.5 67.8855C81.8752 67.8218 81.3394 67.5988 80.25 67L65.5 58.5V58L78.5 63C78.5 61.6985 79.3437 60.397 80.6241 59.6466L65.5 55V53L84.6349 59.2073C87.4334 59.9324 89.5 62.4748 89.5 65.5Z'
      fill='#005CE5'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M69.5 40L49.5 28L29.5 40H43.1738C44.9632 38.7399 47.1452 38 49.5 38C51.8548 38 54.0368 38.7399 55.8262 40H69.5ZM41.9501 41H31.5V43H40.2789C40.7559 42.2684 41.3176 41.5971 41.9501 41ZM58.7211 43C58.2441 42.2684 57.6824 41.5971 57.0499 41H67.5V43H58.7211ZM37.5 44H33.5V46H34.5V58H33.5V60H37.5V58H36.5V46H37.5V44ZM61.5 44H65.5V46H64.5V57.8594L62.5 56.6719V46H61.5V44Z'
      fill='#005CE5'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M49.5 59C55.0228 59 59.5 54.5228 59.5 49C59.5 43.4772 55.0228 39 49.5 39C43.9772 39 39.5 43.4772 39.5 49C39.5 54.5228 43.9772 59 49.5 59ZM49.5 57C53.9183 57 57.5 53.4183 57.5 49C57.5 44.5817 53.9183 41 49.5 41C45.0817 41 41.5 44.5817 41.5 49C41.5 53.4183 45.0817 57 49.5 57Z'
      fill='#005CE5'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M50.25 42H48.75V43H45V44.5V46.5H42.5V48H48.75V50H42.5V51.5H45V53.5V55H48.75V56H50.25V55H54V53.5V51.5H56.5V50H50.25V48H56V46.5H54V44.5V43H50.25V42ZM52.5 46.5V44.5H46.5V46.5H52.5ZM52.5 51.5V53.5H46.5V51.5H52.5Z'
      fill='#005CE5'
    />
  </svg>
)
