import { SetShouldResetPagePayload } from './infiniteScroll.payloads.types'
import { InfiniteScrollState } from './infiniteScroll.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

let initialState: InfiniteScrollState = {
  shouldResetPage: {
    regulations: false,
    ksg: false,
    documents: false,
    remarks: false,
  },
}

const infiniteScrollSlice = createSlice({
  name: 'infiniteScrollState',
  initialState,
  reducers: {
    setShouldResetPage: (state, { payload }: PayloadAction<SetShouldResetPagePayload>) => {
      const { table, shouldResetPage } = payload
      state.shouldResetPage[table] = shouldResetPage
    },
    clearShouldResetPage: (state) => {
      state.shouldResetPage = { ...initialState.shouldResetPage }
    },
  },
})

export const { reducer: infiniteScrollReducer } = infiniteScrollSlice
export const { setShouldResetPage, clearShouldResetPage } = infiniteScrollSlice.actions
