import { EditAndAddDrawer } from '../../ReferenceDrawers/EditAndAddDrawer'
import { Cell, CellValue, CellWrapper, DownloadFile, Row } from './TableItem.styles'
import { TableItemProps } from './TableItem.types'
import { DownloadFileIcon } from 'assets/icons/DownloadFileIcon'
import { Reference, basicReferences } from 'pages/Administration/References/References.types'
import { FC, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const TableItem: FC<TableItemProps> = ({ item, viewingOnly }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const { referenceId } = useParams()

  const getCurrentReference = useMemo(
    (): Reference => basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0],
    [referenceId],
  )

  const onDrawerOpen = useCallback(() => {
    !viewingOnly && setIsDrawerOpen(true)
  }, [])

  const onDrawerClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const renderColumns = useMemo(() => {
    function getWidth(index: number) {
      const widthCell = ['18.56%', '8.44%', '8.44%', '20.56%', '44%']
      let width: string | undefined = widthCell[index]
      if (!getCurrentReference.doubleRow?.length) {
        width = undefined
      }
      return width
    }

    /**
     * поменял вывод для корректной отрисовки ячеек таблицы
     * с установкой ширины и подстройки под шаблон из фигмы
     */
    const columns = getCurrentReference.columns
    return columns.map((column, index) => {
      if (column.id === 'originalLink') return
      const td =
        getWidth(index) && column.id === 'link' ? (
          <DownloadFile onClick={(e) => e.stopPropagation()} href={item[column.id]}>
            Скачать шаблон <DownloadFileIcon />
          </DownloadFile>
        ) : column.id === 'projectMember' ? (
          <CellValue>{item[column.id]?.shortName || '—'}</CellValue>
        ) : (
          <CellValue>{item[column.id] || '—'}</CellValue>
        )

      return (
        <CellWrapper key={column.id} widthCell={getWidth(index)} cellType={getCurrentReference.id}>
          <Cell>{td}</Cell>
        </CellWrapper>
      )
    })

    //eslint-disable-next-line
  }, [getCurrentReference])

  return (
    <>
      <EditAndAddDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} target={item} />
      <Row onClick={onDrawerOpen}>{renderColumns}</Row>
    </>
  )
}
