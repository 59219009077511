import { LabelWrapper, TypeName } from './FilterItem.styles'
import { FilterItemProps } from './FilterItem.types'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Stack, Typography } from '@mui/material'
import { StyledCheckbox } from 'components/FieldForm'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const FilterItem: FC<FilterItemProps> = ({
  item,
  checked,
  onCheckboxChange,

  isAvailableCheckbox,
}) => {
  const label = (
    <Stack direction={'row'} spacing={1} alignItems={'center'} width={'100%'}>
      <TypeName variant='subtitle2' color={theme.palette.text.dark}>
        {item.text}
      </TypeName>
      {item.subtext && (
        <Typography variant='h1' color={theme.palette.text.disabled} fontSize={14}>
          ({item.subtext})
        </Typography>
      )}
    </Stack>
  )

  return isAvailableCheckbox ? (
    <Stack height={'100%'}>
      <LabelWrapper
        onClick={() => {}}
        control={
          <StyledCheckbox
            checked={checked}
            onChange={() => onCheckboxChange(checked, item)}
            icon={checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          />
        }
        label={label}
        sx={{ mr: 0 }}
      />
    </Stack>
  ) : (
    label
  )
}
