import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const AnalyticsFilterLegendWrapper = styled(Stack)`
  flex-direction: row;
  gap: 16px;
  width: fit-content;

  &:hover {
    cursor: pointer;
    & .value {
      text-decoration: underline;
    }
  }
`

export const AnalyticsLegendFilterValue = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
`
