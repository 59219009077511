import { DocumentsManagmentWrapper, DocumentsManagmentTopBar } from './DocumentManagment.styles'
import { DocumentManagmentProps } from './DocumentManagment.types'
import { DocumentManagmentForm } from './DocumentManagmentForm'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { FC, useState, useCallback } from 'react'
import { theme } from 'styles/theme'

export const DocumentManagment: FC<DocumentManagmentProps> = ({ open, onClose, regulation, sectionId }) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    onClose(dirty, immediately)
  }, [])

  return (
    <Drawer anchor='right' open={open} onClose={() => onDrawerClose(dirty)}>
      <DocumentsManagmentWrapper>
        <DocumentsManagmentTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {regulation ? 'Редактирование документа' : 'Добавление документа'}
          </Typography>
        </DocumentsManagmentTopBar>

        <Divider />

        <DocumentManagmentForm
          regulation={regulation}
          sectionId={sectionId}
          onClose={(dirty) => onDrawerClose(dirty)}
          onFormChange={onFormChange}
        />
      </DocumentsManagmentWrapper>
    </Drawer>
  )
}
