import { IconProps } from '../types/iconProps'
import { FC } from 'react'

export const AlertIcon: FC<IconProps> = (props) => {
  return (
    <svg {...props} viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.00016 13.6665C3.32016 13.6665 0.333496 10.6798 0.333496 6.99984C0.333496 3.31984 3.32016 0.33317 7.00016 0.33317C10.6802 0.33317 13.6668 3.31984 13.6668 6.99984C13.6668 10.6798 10.6802 13.6665 7.00016 13.6665ZM6.3335 3.6665V4.99984H7.66683V3.6665H6.3335ZM6.3335 10.3332V6.33317H7.66683V10.3332H6.3335Z'
      />
    </svg>
  )
}
