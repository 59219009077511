import { GroupTitle } from './CompanySelect.styles'
import { CompanySelectProps } from './CompanySelect.types'
import { Checkbox, Typography } from '@mui/material'
import { useGetCompaniesDropdownForProjectQuery } from 'api/projects'
import { GetCompaniesDropdownForProjectResponse } from 'api/projects/api.types'
import { UserFormData } from 'components/UserManagement/components/UserForm'
import { ROLE_SELECT_WIDTH } from 'components/UserManagement/components/UserRoleSelection'
import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { MultiSelect, MultiSelectMenuItem } from 'styles/global/MultiSelect'
import { theme } from 'styles/theme'

export const CompanySelect: FC<CompanySelectProps> = ({ open, projectId, setSelectHidden }) => {
  const { values: formValues, setFieldValue } = useFormikContext<UserFormData>()
  const isCompaniesSelected = !!formValues.companies?.filter((company) => company.projectId === projectId).length
  const isAllCompaniesSelected = formValues.allCompanies.includes(projectId)
  const profile = useTypedSelector(profileSelector)
  const { companyID } = profile.company || {}
  const isProjectsSelected = !!formValues.projects?.length
  const [localOpen, setLocalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!!open && projectId === open) setLocalOpen(true)
  }, [open])

  const { data: companies } = useGetCompaniesDropdownForProjectQuery(
    {
      companyID,
      projectId: projectId,
      userID: profile.id,
    },
    {
      skip: !isProjectsSelected,
    },
  )

  const onSelectOpen = useCallback(() => {
    setLocalOpen(true)
    setSelectHidden(false)
  }, [])

  const onSelectClose = useCallback(() => {
    setLocalOpen(false)
    if (isCompaniesSelected || isAllCompaniesSelected) {
      setSelectHidden(true)
    }
  }, [formValues.companies, isCompaniesSelected, isAllCompaniesSelected])

  const groupedCompanies = useMemo(() => {
    const resultList: { title: string; array: GetCompaniesDropdownForProjectResponse[] }[] = []

    let title = (companies?.[0]?.data === 'projectMember' ? 'Участники проекта:' : 'Компании:') || ''
    let array: GetCompaniesDropdownForProjectResponse[] = []

    companies?.forEach((item) => {
      const localGroup = (item?.data === 'projectMember' ? 'Участники проекта:' : 'Компании:') || ''
      if (localGroup === title) array.push(item)
      else {
        resultList.push({ title, array })

        title = localGroup
        array = []
        array.push(item)
      }
    })
    resultList.push({ title, array })

    return resultList
  }, [companies])

  return (
    <MultiSelect
      version='select'
      name='helper'
      value={formValues.companies.map((company) => company.id)}
      bgColor={theme.palette.bg.white}
      width='311px'
      SelectProps={{
        multiple: true,
        displayEmpty: true,
        MenuProps: {
          PaperProps: {
            style: { maxWidth: ROLE_SELECT_WIDTH },
          },
        },
        renderValue: () => <Typography component='span'>Выберите компании</Typography>,
        onChange: (e, child) => {
          // @ts-ignore
          const checked: boolean = !child?.props?.children?.[0]?.props?.checked
          // @ts-ignore
          const currentValueId = child?.props?.value

          const currentProjectId =
            typeof currentValueId === 'string'
              ? companies?.find((company) => company.data === 'company' && company.text === currentValueId)?.projectId
              : companies?.find((company) => company.id === currentValueId)?.projectId

          // all true clicked
          if (currentValueId === 'all') {
            if (isAllCompaniesSelected) {
              setFieldValue(
                `allCompanies`,
                [...formValues.allCompanies].filter((itemId) => itemId !== projectId),
              )
            } else {
              setFieldValue(`allCompanies`, [...formValues.allCompanies, projectId])
            }
            setFieldValue(
              `companies`,
              [...formValues.companies].filter((company) => company.projectId !== projectId),
            )
            return
          }
          // all false clicked
          if (isAllCompaniesSelected) {
            const localCompanies =
              companies
                ?.filter((company) =>
                  company.data === 'company' ? company.text !== currentValueId : company.id !== currentValueId,
                )
                .map((company) => ({
                  id: company.data === 'company' ? company.text : company.id,
                  name: company.text,
                  projectId: company.projectId,
                  data: company.data,
                })) || []

            setFieldValue(`companies`, [...formValues.companies, ...localCompanies])

            setFieldValue(
              `allCompanies`,
              [...formValues.allCompanies].filter((itemId) => itemId !== projectId),
            )
            return
          }

          if (!checked) {
            // turned off the checkbox
            setFieldValue(
              `companies`,
              [...formValues.companies].filter(
                (company) => !(company.id === currentValueId && company.projectId === currentProjectId),
              ),
            )
          } else {
            // turned on the checkbox
            if (typeof currentValueId === 'string') {
              setFieldValue(`companies`, [
                ...formValues.companies,
                {
                  id: currentValueId,
                  name: currentValueId,
                  projectId: currentProjectId,
                  data: companies?.find((company) => company.data === 'company' && company.text === currentValueId)
                    ?.data,
                },
              ])
            } else {
              setFieldValue(`companies`, [
                ...formValues.companies,
                {
                  id: currentValueId,
                  name: companies?.find((company) => company.id === currentValueId)?.text,
                  projectId: currentProjectId,
                  data: companies?.find((company) => company.id === currentValueId)?.data,
                },
              ])
            }
          }
        },
        open: localOpen,
        onOpen: onSelectOpen,
        onClose: onSelectClose,
      }}
    >
      {companies?.length && (
        <MultiSelectMenuItem value='all'>
          <Checkbox checked={isAllCompaniesSelected} />
          <Typography variant='body1'>Все компании</Typography>
        </MultiSelectMenuItem>
      )}

      <GroupTitle fontSize={14}>{groupedCompanies[0]?.title}</GroupTitle>
      {groupedCompanies[0].array.map((company) => (
        <MultiSelectMenuItem value={company.id} key={`projectMember-${company.id}-${company.projectId}`}>
          <Checkbox
            checked={
              isAllCompaniesSelected
                ? true
                : formValues.companies.findIndex(
                    (rootCompany) => rootCompany.id === company.id && rootCompany.projectId === company.projectId,
                  ) > -1
            }
          />
          <Typography variant='body1'>{company.text}</Typography>
        </MultiSelectMenuItem>
      ))}

      <GroupTitle fontSize={14}>{groupedCompanies[1]?.title}</GroupTitle>
      {groupedCompanies[1]?.array.map((company) => (
        <MultiSelectMenuItem value={company.text} key={`company-${company.text}-${company.projectId}-`}>
          <Checkbox
            checked={
              isAllCompaniesSelected
                ? true
                : formValues.companies.findIndex(
                    (rootCompany) =>
                      rootCompany.data === 'company' &&
                      rootCompany.name === company.text &&
                      rootCompany.projectId === company.projectId,
                  ) > -1
            }
          />
          <Typography variant='body1'>{company.text}</Typography>
        </MultiSelectMenuItem>
      ))}
    </MultiSelect>
  )
}
