import { Drawer, Stack, Typography } from '@mui/material'
import { ReactComponent as QCIcon } from 'assets/qc-logo-mini.svg'
import styled from 'styled-components'

export const DocDrawer = styled(Drawer)`
  width: 350px;
  text-align: left;

  & > .MuiPaper-root {
    width: 350px;
    box-shadow: 8px 0 8px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }

  @media (max-width: 1150px) {
    display: none;
  }
`

export const DocDrawerHeader = styled(Stack)`
  height: 64px;
  padding: 0 20px;

  & .MuiIconButton-root {
    background-color: ${(props) => props.theme.palette.bg.shades};
    border-radius: 6px;
  }
`

export const DocLogoWrapper = styled.div`
  width: 200px;
  height: 64px;
`

export const DocQCLogo = styled(QCIcon)`
  width: 72px;
  height: 64px;
`

export const DocDrawerContent = styled(Stack)`
  padding: 10px 20px 20px 20px;
  overflow: hidden;

  & .MuiDivider-root {
    height: 1px;
  }

  @supports (-moz-appearance: none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  }
`

export const DocDrawerDocTitle = styled(Typography)`
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.palette.text.dark};
`
