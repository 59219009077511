import { FilterItem } from '../FilterItem'
import { FilterListWrapper } from './FilterList.styles'
import { FilterListProps } from './FilterList.types'
import { documentsApi, useGetDocumentFilterTypesQuery } from 'api/documents'
import { DocumentFilterType } from 'api/documents/types'
import { Divider } from 'components/Divider'
import { Progress } from 'components/Progress'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { chosenTypesIdsLocalSelector, chosenTypesIdsSelector } from 'store/slices/documents'
import { useTypedSelector } from 'store/store'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'

export const FilterList: FC<FilterListProps> = ({ searchValue }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const chosenTypesIdsLocal = useTypedSelector(chosenTypesIdsLocalSelector)

  const { queryData, onScrollWithInfiniteLoad } = useInfiniteScroll({
    api: documentsApi,
    tagName: 'Documents',
    limit: 13,
    query: useGetDocumentFilterTypesQuery,
    arg: {
      projectId,
      query: searchValue || undefined,
      body: chosenTypesIdsLocal,
    },
  })

  const { data = [], total } = queryData.data || {}
  const { isLoading } = queryData || {}

  const filtredTypes = useMemo(() => {
    const chosenTypes: DocumentFilterType[] = []
    const unselectedTypes: DocumentFilterType[] = []

    data.forEach((type) => {
      if (chosenTypesIdsLocal.includes(type.id)) chosenTypes.push(type)
      else unselectedTypes.push(type)
    })

    return {
      chosenTypes,
      unselectedTypes,
    }
  }, [data, chosenTypesIdsLocal])

  return (
    <FilterListWrapper onScroll={onScrollWithInfiniteLoad} spacing={2}>
      {isLoading ? (
        <Progress />
      ) : (
        <>
          {filtredTypes.chosenTypes.length
            ? filtredTypes.chosenTypes.map((type) => (
                <FilterItem type={type} key={type.id} checked={!!chosenTypesIdsLocal.includes(type.id)} />
              ))
            : null}

          {filtredTypes.chosenTypes.length && filtredTypes.unselectedTypes.length ? <Divider /> : null}

          {filtredTypes.unselectedTypes.map((type) => (
            <FilterItem type={type} key={type.id} checked={!!chosenTypesIdsLocal.includes(type.id)} />
          ))}
        </>
      )}
    </FilterListWrapper>
  )
}
