import { Stack } from '@mui/material'
import styled from 'styled-components'

export const DrawerWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
  justify-content: space-between;
`

export const DrawerTopBar = styled.div`
  padding: 20px 20px 15px;
  text-align: center;
`
