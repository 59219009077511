import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    text-decoration: underline;
  }
`
