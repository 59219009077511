import { PdfViewerPopup } from '../../../Prescriptions/components/PrescriptionsForm/components/PdfViewerPopup'
import { CustomColumnDef } from '../../../Remarks/components/RemarksTable'
import { RegulationsTableProps } from './RegulationsTable.types'
import { AttachmentCell } from './cells/AttachmentCell'
import { Stack, Typography } from '@mui/material'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { regulationsApi, useEditRegulationMutation, useGetRegulationDocsQuery } from 'api/regulations'
import { RegulationDoc, RegulationDocBody, RegulationDocType, RegulationShort } from 'api/regulations/types'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { PdfViewerProps } from 'components/PdfViewer'
import { Progress } from 'components/Progress'
import {
  regulationBodyRuByEn,
  RegulationStatus,
  regulationStatusEnByRu,
  regulationStatuses,
  regulationStatusesColor,
  regulationStatusRuByEn,
  regulationTypeRuByEn,
} from 'core/types/regulation'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useSearch } from 'hooks/useSearch'
import { TableCell, TableCellWrapper } from 'pages/Documents/components/DocumentsTable/DocumentsTable.styles'
import { getEmptyPageData } from 'pages/Projects'
import { RegulationLocationState } from 'pages/Regulations/Regulations.types'
import {
  TABLE_CELL_HEIGHT,
  RemarksTableBody,
  RemarksTableCell,
  RemarksTableContainer,
  RemarksTableHead,
  RemarksTableRow,
} from 'pages/Remarks/components/RemarksTable/RemarksTable.styles'
import { StatusCell, StatusCellMenuItemProps } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { regulationsShouldResetPageSelector } from 'store/slices/infiniteScroll'
import { theme } from 'styles/theme'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const RegulationsTable: FC<RegulationsTableProps> = ({ sectionId, currentTab }) => {
  const navigate = useNavigate()
  const { searchValue } = useSearch({ preventClearOnUnmount: true })

  // Location
  const location = useLocation()
  const locationState: RegulationLocationState = (location?.state as RegulationLocationState) || {}

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => {
    return locationState
  }, [])

  const { currentPage: locationCurrentPage, rowId: locationRowId } = savedLocationState || {}

  const onRowClick = (docId: number) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(`/regulations/${sectionId}/doc/${docId}`, { state: { currentPage, currentTab } })
  }

  // Attachment cell
  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)

  const openViewer = (fileUrl: string, title: string, onDownload: () => void) => {
    setOpenedPdfData({
      fileUrl,
      title,
      onDownload,
      disableDelete: true,
      onDelete: () => {},
    })
  }

  const onViewerClose = () => {
    setOpenedPdfData(null)
  }
  // Status cell
  const [editRegulation] = useEditRegulationMutation()

  const onStatusSelect = (sectionId: number, docId: number, body: RegulationShort) => {
    editRegulation({ sectionId, docId, body })
  }

  const columns: CustomColumnDef<RegulationDoc>[] = [
    {
      accessorKey: 'type',
      header: 'Тип документа',
      minSize: 250,
      size: 250,
      textAlign: 'left',
      cell: (info) => {
        const { id, custom } = info.getValue<RegulationDocType>()
        return id ? regulationTypeRuByEn[id] : custom || '—'
      },
    },
    {
      accessorKey: 'identifier',
      header: 'Идентификатор',
      minSize: 190,
      size: 190,
      maxSize: 190,
      textAlign: 'left',
      cell: (info) => {
        const identifier = info.getValue<string | null>()
        return identifier || '—'
      },
    },
    {
      accessorKey: 'name',
      header: 'Название документа',
      minSize: 270,
      size: 270,
      textAlign: 'left',
    },
    {
      accessorKey: 'body',
      header: 'Принявший орган',
      minSize: 250,
      size: 250,
      maxSize: 250,
      cell: (info) => {
        const { id, custom } = info.getValue<RegulationDocBody>() || {}
        return id ? regulationBodyRuByEn[id] : custom || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'accepted',
      header: 'Введен в действие',
      minSize: 200,
      size: 200,
      maxSize: 200,
      cell: (info) => {
        const acceptedDate = info.getValue<string | null>()
        return (acceptedDate && parseResponseDate(acceptedDate).fullDate) || '—'
      },
    },
    {
      accessorKey: 'file',
      header: 'PDF',
      minSize: 85,
      size: 85,
      maxSize: 85,
      cell: (info) => <AttachmentCell info={info} variant='file' onFileClick={openViewer} />,
      isCustomCell: true,
    },
    {
      accessorKey: 'link',
      header: 'Ссылка',
      minSize: 85,
      size: 85,
      maxSize: 85,
      cell: (info) => <AttachmentCell info={info} variant='link' />,
      isCustomCell: true,
    },
    {
      accessorKey: 'status',
      header: 'Статус',
      minSize: 180,
      size: 180,
      maxSize: 180,
      cell: (info) => {
        const status = info.getValue<RegulationStatus>()

        const statusCellMenuItemsData: StatusCellMenuItemProps<RegulationStatus>[] = regulationStatuses.map(
          (menuStatus) => {
            const valueForOnClick = regulationStatusEnByRu[menuStatus]
            const checked = status === valueForOnClick

            return {
              value: menuStatus,
              valueForOnClick,
              checked: checked,
              key: menuStatus,
              children: menuStatus,
            }
          },
        )

        return (
          <StatusCell
            variant='regulations'
            info={info}
            onSelect={onStatusSelect}
            itemsData={statusCellMenuItemsData}
            statusData={{
              body: regulationStatusRuByEn[status],
              color: regulationStatusesColor[status],
            }}
          />
        )
      },
      isCustomCell: true,
    },
  ]

  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const { queryData, onScrollWithInfiniteLoad, currentPage } = useInfiniteScroll({
    refElement: tableContainerRef,
    api: regulationsApi,
    tagName: 'Regulations',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetRegulationDocsQuery,
    shouldResetPageSelector: regulationsShouldResetPageSelector,
    startPage: locationCurrentPage || 1,
    arg: {
      sectionId,
      query: searchValue || undefined,
      status: currentTab === 'all' ? undefined : currentTab,
    },
  })

  const { data = [] } = queryData.data || {}
  const { isLoading } = queryData || {}

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef?.current!,
  })

  const emptyPageData: EmptyPageData = getEmptyPageData(<>Данные отсутствуют.</>)

  // Positioning
  const [selectedId, setSelectedId] = useState<number | null>(null)

  useEffect(() => {
    if (locationRowId) {
      const rowIndex = data.findIndex((regulation) => regulation.id === locationRowId)

      if (rowIndex >= 0) {
        const rowPosition = rowIndex * TABLE_CELL_HEIGHT

        const scrollValue = rowPosition - (tableContainerRef.current?.offsetHeight || 0) / 2.5
        tableContainerRef.current?.scrollTo({ top: scrollValue, behavior: 'auto' })

        setSelectedId(locationRowId)
      }
    }
  }, [locationRowId])

  const onHoverRow = (rowId: number) => {
    if (rowId === selectedId) setSelectedId(null)
  }

  return (
    <>
      {isLoading && <Progress />}

      {!isLoading && !!data.length && (
        <RemarksTableContainer
          onScroll={onScrollWithInfiniteLoad}
          ref={tableContainerRef}
          style={{ height: 'fit-content' }}
        >
          <RemarksTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <RemarksTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <RemarksTableCell
                    key={header.id}
                    style={{
                      flex: 1,
                      minWidth: header.column.columnDef.minSize,
                      width: header.column.columnDef.size,
                      maxWidth: header.column.columnDef.maxSize,
                    }}
                  >
                    <Typography variant='body2'>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Typography>
                  </RemarksTableCell>
                ))}
              </RemarksTableRow>
            ))}
          </RemarksTableHead>

          <RemarksTableBody style={{ height: virtualizer.getTotalSize() }}>
            {virtualizer.getVirtualItems().map((row) => {
              const rowId = data[row.index].id

              return (
                <RemarksTableRow
                  onClick={onRowClick(rowId)}
                  key={row.key}
                  data-index={row.index}
                  ref={virtualizer.measureElement}
                  showAnimation={rowId === selectedId}
                  onMouseEnter={() => onHoverRow(rowId)}
                  style={{
                    width: '100%',
                    transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                  }}
                >
                  {columns.map((column, columnIndex) => {
                    const cell = rows[row.index].getVisibleCells()[columnIndex]
                    const { minSize, size, maxSize, textAlign } = column

                    if (column.isCustomCell) {
                      return flexRender(cell.column.columnDef.cell, cell.getContext())
                    }

                    return (
                      <TableCellWrapper
                        key={column.id}
                        style={{
                          flex: 1,
                          minWidth: minSize,
                          width: size,
                          maxWidth: maxSize,
                        }}
                      >
                        <TableCell className='cell'>
                          <Typography variant='body2' width='100%' textAlign={textAlign}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Typography>
                        </TableCell>
                      </TableCellWrapper>
                    )
                  })}
                </RemarksTableRow>
              )
            })}

            <PdfViewerPopup opened={openedPdfData !== null} viewerProps={openedPdfData} onViewerClose={onViewerClose} />
          </RemarksTableBody>

          {!data.length && (
            <Stack height={'calc(100% - 60px)'} minHeight={670} flexDirection={'row'} bgcolor={theme.palette.bg.white}>
              {!isLoading ? <EmptyPage data={emptyPageData} fullPage /> : <Progress />}
            </Stack>
          )}
        </RemarksTableContainer>
      )}

      {!isLoading && !data.length && <EmptyPage data={emptyPageData} fullPage />}
    </>
  )
}
