import { ColumnsDrawerFormData } from '../ColumnsDrawer.types'
import { ColumnsDrawerItemWrapper } from './ColumnsDrawerItem.styles'
import { ColumnsDrawerItemProps } from './ColumnsDrawerItem.types'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { prescriptionColumnNameByValue } from 'api/prescriptions/types'
import { remarkColumnNameByValue } from 'api/remarks/types'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { ColumnSettings } from 'pages/Remarks/components/RemarksLegend'
import { ChangeEvent, FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'

export const ColumnsDrawerItem: FC<ColumnsDrawerItemProps> = ({ column, index, columnSettings, draggable }) => {
  const { values, setFieldValue } = useFormikContext<ColumnsDrawerFormData>()

  const checked = (values.columns as string[]).includes(column)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const columnsCopy = [...values.columns]

    e.target.checked
      ? columnsCopy.push(column)
      : (() => {
          const deletedIndex = columnsCopy.findIndex((item) => item === column)
          columnsCopy.splice(deletedIndex, 1)
        })()

    setFieldValue('columns', columnsCopy)
  }

  const columnNameByValue: Record<ColumnSettings, any> = {
    prescriptions: prescriptionColumnNameByValue,
    remarks: remarkColumnNameByValue,
  }
  const currentColumnByValue = columnSettings && columnNameByValue[columnSettings]

  // const resetQuery = columnSettings && resetColumnSettingsQueries[columnSettings]

  const Content = (
    <FieldForm
      version='switch'
      name=''
      checkboxData={{
        checked,
        onChange,
        label: currentColumnByValue[column],
      }}
    />
  )

  const DraggableContent = (
    <Draggable key={column} draggableId={column} index={index!}>
      {(provided) => (
        <ColumnsDrawerItemWrapper checked={checked} ref={provided.innerRef} {...provided.draggableProps}>
          {checked && (
            <div {...provided.dragHandleProps}>
              <DragHandleIcon fontSize='medium' color='secondary' />
            </div>
          )}

          {Content}
        </ColumnsDrawerItemWrapper>
      )}
    </Draggable>
  )

  return draggable ? DraggableContent : <ColumnsDrawerItemWrapper checked={checked}>{Content}</ColumnsDrawerItemWrapper>
}
