import { AutocompleteFormControl, StyledAutocompleteField } from './AutocompleteField.styles'
import { AutocompleteFieldProps, OptionType } from './AutocompleteField.types'
import { AutocompleteInputChangeReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete'
import { createFilterOptions, TextField } from '@mui/material'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { useField, useFormikContext } from 'formik'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

const filter = createFilterOptions<OptionType>()

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  fieldName,
  data,
  placeholder,
  onInputChange: parentOnInputChange,
  groupBy,
  readOnly,
  helperText,
  remark,
  ...props
}) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const [disablePlaceholder, setDisablePlaceholder] = useState<boolean>(false)

  const [{ onBlur: onFieldBlur, ...field }, { value, touched, error }] = useField({
    name: fieldName,
  })

  const onInputChange = (e: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    value ? setDisablePlaceholder(true) : setDisablePlaceholder(false)

    parentOnInputChange?.(e, value, reason)
  }

  const isError = touched && !!error

  return (
    <AutocompleteFormControl>
      <StyledAutocompleteField
        {...field}
        readOnly={readOnly}
        value={data.find((item) => item.value === value)?.label || value || ''}
        onChange={(event, newValue: any) => {
          if (typeof newValue === 'string') {
            setFieldValue(fieldName, newValue)
          } else if (newValue && newValue.inputValue) {
            setFieldValue(fieldName, newValue.inputValue)
          } else if (newValue === null) {
            setFieldValue(fieldName, '')
          } else {
            setFieldValue(fieldName, newValue.value)
          }
        }}
        onInputChange={onInputChange}
        options={data}
        renderInput={(params) => (
          <TextField
            {...params}
            name={fieldName}
            label={placeholder}
            helperText={isError && helperText ? t(error as string) : undefined}
            onBlur={onFieldBlur}
            error={isError}
          />
        )}
        renderOption={(props, option: any) => {
          return (
            <StyledSelectMenuItem {...props} remark={remark} key={option.key}>
              {option.label}
            </StyledSelectMenuItem>
          )
        }}
        filterOptions={(options: any, params) => {
          const filteredBySelected = options.filter((item: any) => !item.removeFromSelect)
          const filtered = filter(filteredBySelected, params)
          if (!props.freeSolo) return filtered

          const { inputValue } = params
          const isExisting = options.some((option: any) => option.label.includes(inputValue))
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Добавить "${inputValue}"`,
            })
          }

          return filtered
        }}
        disablePlaceholder={disablePlaceholder}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        noOptionsText='Ничего не найдено'
        clearText='Удалить'
        groupBy={groupBy}
        remark={remark}
        {...props}
      />
    </AutocompleteFormControl>
  )
}
