import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const validationAnalyticsFilter = yup.object({
  dateAfter: yup
    .date()
    .transform(formatDateInInput)
    .nullable()
    .test((dateAfter, testContext) => {
      const { dateBefore } = testContext.parent || {}

      let maxDate: Date = today
      if (dateBefore) maxDate = dateBefore

      if (!dateAfter) return true

      return dateAfter <= maxDate
    }),
  dateBefore: yup
    .date()
    .transform(formatDateInInput)
    .max(today)
    .nullable()
    .test((dateBefore, testContext) => {
      const { dateAfter } = testContext.parent || {}

      let minDate: Date = dateAfter
      if (!minDate || !dateBefore) return true

      return dateBefore >= minDate
    }),
  projects: yup.array().min(1),
  contractors: yup.array().min(1),
})
