import styled from 'styled-components'

export const TABLE_CELL_HEIGHT = 60

export const TableCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${TABLE_CELL_HEIGHT}px;
  padding: 8px;
  box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;

  & .MuiTypography-root {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${(props) => props.theme.palette.text.dark};
  }
`
