import { api } from '../api'
import { UserProfile } from '../profile/types'
import {
  BanUserInModuleRequest,
  CreateFullUserRequest,
  CreateFullUserResponse,
  CreateUserRequest,
  CreateUserResponse,
  GetCompanyNamesRequest,
  GetCompanyNamesResponse,
  GetEmployeesRequest,
  GetEmployeesResponse,
  GetUsersRequest,
  GetUsersResponse,
  InviteUserRequest,
  InviteUserResponse,
  SetAvatarRequest,
  UpdateUserAccessRequest,
} from './api.types'

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<GetUsersResponse, GetUsersRequest>({
      query: ({ companyId, ...params }) => ({
        url: `/company/${companyId}/users`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.offset === 0) {
          currentCache.data = [...newData.data]
          return
        }
        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        // return currentArg?.offset !== previousArg?.offset

        const offsetChanged = currentArg?.offset !== previousArg?.offset
        const queryChanged = currentArg?.query !== previousArg?.query
        const roleChanged = currentArg?.role !== previousArg?.role

        const otherArgsChanged = queryChanged || roleChanged

        if (currentArg && otherArgsChanged) {
          currentArg.offset = 0
        }

        return offsetChanged || otherArgsChanged
      },
      providesTags: ['Users'],
    }),
    getEmployees: build.query<GetEmployeesResponse, GetEmployeesRequest>({
      query: ({ limit, offset, companyId, roles }) => ({
        url: `/company/${companyId}/employees`,
        params: { limit, offset, roles },
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getCompanyNames: build.query<GetCompanyNamesResponse, GetCompanyNamesRequest>({
      query: ({ companyId }) => ({
        url: `/company/${companyId}/company-names`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    inviteUser: build.mutation<InviteUserResponse, InviteUserRequest>({
      query: ({ userId, profile, employment }) => ({
        url: `/company/${employment?.companyID}/admin/${userId}/invite`,
        method: 'POST',
        body: { profile, employment },
      }),
    }),
    setUserAvatar: build.mutation<UserProfile, SetAvatarRequest>({
      query: ({ body, userId, companyId }) => {
        const { file } = body || {}

        if (file instanceof Blob) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/company/${companyId}/admin/${userId}/set-avatar`,
            method: 'POST',
            body: formData,
          }
        }
      },
      invalidatesTags: ['Users'],
    }),
    updateUserAccess: build.mutation<UserProfile, UpdateUserAccessRequest>({
      query: ({ body, userId, companyId }) => ({
        url: `/company/${companyId}/admin/${userId}/update-access`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    createUser: build.mutation<any, CreateFullUserRequest>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { dataForCreate, dataForSetAvatar, dataForSetAccess, companyId } = arg || {}

        const data = await baseQuery({
          url: `/company/${dataForCreate?.employment?.companyID}/admin/create-user`,
          method: 'POST',
          body: dataForCreate,
        })

        if (data?.error) {
          return { error: data.error }
        }

        const { success: newUser } = data.data as CreateUserResponse

        if (!!newUser) {
          const { id: userId } = newUser || {}
          const { file } = dataForSetAvatar || {}

          if (file instanceof Blob) {
            const formData = new FormData()
            formData.append('file', file)
            await baseQuery({
              url: `/company/${companyId}/admin/${userId}/set-avatar`,
              method: 'POST',
              body: formData,
            })
          }

          if (dataForSetAccess.newRole) {
            await baseQuery({
              url: `/company/${companyId}/admin/${userId}/update-access`,
              method: 'POST',
              body: dataForSetAccess,
            })
          }
        }
        return { data: data.data } as CreateFullUserResponse
      },
      invalidatesTags: ['Users'],
    }),
    banUserInModule: build.mutation<unknown, BanUserInModuleRequest>({
      query: ({ companyId, userId }) => ({
        url: `/company/${companyId}/admin/${userId}/ban`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersQuery,
  useGetEmployeesQuery,
  useGetCompanyNamesQuery,
  useInviteUserMutation,
  useSetUserAvatarMutation,
  useUpdateUserAccessMutation,
  useCreateUserMutation,
  useBanUserInModuleMutation,
} = usersApi
