import { formatMaskPhone } from '../PhoneFieldForm/PhoneFieldForm.utils'
import { CreateUserResponse } from 'api/users/api.types'
import { UserBindCandidate } from 'api/users/types'
import i18n from 'i18next'

export const getLabelBindField = (candidates: CreateUserResponse['candidates']) => {
  return candidates?.[0]?.bindFields
    ?.map((field: 'email' | 'login' | 'phone') =>
      field === 'phone' ? formatMaskPhone(candidates[0]?.profile[field]) : candidates[0]?.profile[field],
    )
    .join(', ')
}

export const getLabelBindFields = (bindFields: UserBindCandidate['bindFields']) => {
  return bindFields
    ?.map((field) => i18n.t(`user:status.${field}`))
    .join(' И ')
    .toUpperCase()
}
