import { DrawerTopBar, DrawerWrapper } from './EditAndAddDrawer.styles'
import { EditAndAddDrawerProps, FormDataType } from './EditAndAddDrawer.types'
import { EditAndAddDrawerForm } from './EditAndAddDrawerForm'
import { EditAndAddDrawerFormForPattern } from './EditAndAddDrawerFormForPattern'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Drawer, Stack, Typography } from '@mui/material'
import { useDeleteSectionMutation } from 'api/references'
import { Button } from 'components/Button'
import { DeleteConfirm } from 'components/DeleteConfirm'
import { Divider } from 'components/Divider'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { Reference, basicReferences } from 'pages/Administration/References/References.types'
import { FC, useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const EditAndAddDrawer: FC<EditAndAddDrawerProps> = ({ isOpen, onClose, target }) => {
  const { projectId, referenceId } = useParams()
  const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false)
  const [deleteSection, deleteSectionResponse] = useDeleteSectionMutation()
  const [formData, setFormData] = useState<FormDataType>({} as FormDataType)
  const { enqueueSnackbar } = useSnackbar()

  useMutationHandlers(deleteSectionResponse, (data) => {
    if (deleteSectionResponse.isUninitialized || deleteSectionResponse.isLoading || deleteSectionResponse.isError)
      return
    enqueueSnackbar('Позиция успешно удалена.', { variant: 'success' })
  })

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
      resetForm()
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const getCurrentReference = (): Reference => {
    return basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0]
  }

  const onDeleteButtonClick = useCallback(() => {
    setIsDeleteClicked(true)
  }, [])

  const onDeleteSubmit = useCallback(() => {
    if (target)
      deleteSection({
        url: projectId
          ? getCurrentReference()
              .api.deleteSection.projectUrl.replace('{id}', String(target.id))
              .replace('{project}', String(projectId))
          : getCurrentReference().api.deleteSection.url.replace('{id}', String(target.id)),
      })
    onClose()
  }, [])

  /**
   * Здесь я убрал ts-ignore и добавил условия для проверки isDirty отправляемых данных
   */
  const isDirty = useCallback(() => {
    let dirty: boolean = false

    let key: keyof typeof formData
    if (target) {
      for (key in formData) {
        if (key !== 'newFile' && formData?.[key].value !== (target?.[key] || '')) {
          return true
        } else {
          if (key === 'newFile' && formData?.[key].value !== undefined) {
            return true
          }
        }
      }
    } else {
      for (key in formData) {
        if (formData?.[key].value) {
          return true
        }
      }
    }
    return dirty
  }, [formData, target])

  const onCancelSubmit = useCallback(() => {
    setIsDeleteClicked(false)
  }, [])

  const onDrawerClose = () => {
    if (isDirty() || dirty) openConfirm()
    else {
      onClose()
      resetForm()
    }
  }

  const initialValues = {
    projectMember: target?.projectMember
      ? {
          id: target?.projectMember.id,
          value: target?.projectMember.shortName,
        }
      : null,
  }

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  })

  const { values, setFieldValue, dirty, isValid, resetForm } = formik

  return (
    <>
      <Drawer anchor='right' open={isOpen} onClose={onDrawerClose}>
        <DrawerWrapper>
          <FormikProvider value={formik}>
            <Stack component={Form} overflow={'auto'} height={'100%'}>
              <DrawerTopBar>
                <Typography variant='h1' color={theme.palette.primary.main}>
                  {target ? 'Редактирование позиции справочника' : 'Добавление позиции справочника'}
                </Typography>
              </DrawerTopBar>
              <Divider />

              {/* Здесь разделил дроверы */}

              {getCurrentReference().doubleRow ? (
                <EditAndAddDrawerFormForPattern
                  onClose={onClose}
                  closeWithCheckingDirty={onDrawerClose}
                  target={target}
                  isDirty={isDirty}
                  formData={formData}
                  setFormData={setFormData}
                />
              ) : (
                <EditAndAddDrawerForm
                  onClose={onClose}
                  closeWithCheckingDirty={onDrawerClose}
                  target={target}
                  isDirty={isDirty}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}
            </Stack>
          </FormikProvider>

          {/* При открытии дровера для справочника шаблонов отсутствует кнопка удаление, поэтому по условию я ее прячу */}

          {target && !getCurrentReference().doubleRow && (
            <Stack justifyContent='flex-end' alignItems='center' flex={1} sx={{ p: 2.5, width: '100%' }}>
              {!!isDeleteClicked && (
                <Box style={{ width: '100%' }} marginBottom={2.5}>
                  <DeleteConfirm
                    text='
                                Вы действительно хотите удалить позицию справочника?
                                Это действие нельзя отменить.
                            '
                    onDelete={onDeleteSubmit}
                    onCancel={onCancelSubmit}
                  />
                </Box>
              )}
              <Box style={{ width: 'fit-content' }}>
                <Button onClick={onDeleteButtonClick} startIcon={<DeleteIcon />} variant='text' color='error'>
                  Удалить позицию
                </Button>
              </Box>
            </Stack>
          )}
        </DrawerWrapper>
        <ConfirmDialog />
      </Drawer>
    </>
  )
}
