import { Stack, Typography } from '@mui/material'
import { LegendColors } from 'core/types/global'
import styled from 'styled-components'

interface CardItemRowWrapperProps {
  isSum?: boolean
}

export const CardItemRowWrapper = styled(Stack)<CardItemRowWrapperProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 30px;
  margin: ${(props) => `${props.isSum ? 6 : 0} 0`};
`

interface CardItemRowCircleProps {
  bgColor: LegendColors
}

export const CardItemRowCircle = styled.div<CardItemRowCircleProps>`
  width: 10px;
  height: 10px;
  margin-top: -1px;
  background-color: ${(props) => props.theme.palette.legends[props.bgColor]};
  border-radius: 50%;
`

interface CardItemRowTextProps {
  textColor?: string
  bold?: boolean
}

export const CardItemRowText = styled(Typography)<CardItemRowTextProps>`
  font-weight: ${(props) => props.bold && 500};
  font-size: 12px;
  line-height: normal;
  color: ${(props) => props.textColor || props.theme.palette.secondary.main};
`
