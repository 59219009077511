import { RemarkSecondaryDrawer } from '../../RemarkSecondaryDrawer'
import {
  CustomSelectValueWithType,
  RemarkAutofilledData,
  RemarkFormData,
  remarkSecondaryInfo,
} from '../RemarkForm.types'
import { FieldItem } from '../components/FieldItem'
import { Wrapper } from './Info.styles'
import { InfoProps } from './Info.types'
import {
  ApartmentOutlined as ObjectIcon,
  EventAvailable as EventAvailableIcon,
  ErrorOutline as ErrorOutlineIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Person as PersonIcon,
  InfoOutlined as InfoIcon,
  MapsHomeWork as BuildingIcon,
} from '@mui/icons-material'
import { IconButton, Menu, Stack, Typography } from '@mui/material'
import {
  useGetDropdownCompanySendersQuery,
  useGetDropdownObjectsQuery,
  useLazyGetDropdownSendersQuery,
} from 'api/remarks'
import { useGetDropdownContractorsQuery, useLazyGetDropdownResponsibleQuery } from 'api/remarks'
import {
  RemarkStatusesRu,
  remarkStatuses,
  remarkStatusesBackend,
  remarkStatusesClient,
  remarkStatusesColor,
} from 'api/remarks/types'
import { ColoredTitle } from 'components/ColoredTitle'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { Tooltip } from 'components/Tooltip'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { useFormikContext } from 'formik'
import { isEqual } from 'lodash'
import { StatusCellContent } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { FC, useCallback, useState, MouseEvent, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { addOneDayToDate } from 'utils/dates/addOneDayToDate'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { removeTimeFromDate } from 'utils/dates/removeTimeFromDate'

export const Info: FC<InfoProps> = ({
  dirty,
  viewingOnly,
  formMode,
  setAutofilledData,
  onDrawerOpen,
  onDrawerClose,
  openedDrawer,
  allCopiesExpired,
  isIncludedCopy,
  isNotIncludedCopy,
  primaryId,
  eliminationStatus,
}) => {
  const { values, setFieldValue, initialValues } = useFormikContext<RemarkFormData>()
  const { projectId: projectIdString, remarkId: remarkIdString } = useParams()
  const projectId = Number(projectIdString)
  const remarkId = Number(remarkIdString) || null
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [isDateDoneRequired, setIsDateDoneRequired] = useState<boolean>(false)
  const isDateDoneDisabled = !!(values.status !== 'COMPLETE' && !initialValues.prescriptionNumber)
  const [isDateDoneFocused, setIsDateDoneFocused] = useState(false)
  const isAddingMode = formMode === 'add'
  const isCopy = !!values.copyNumber
  const isStatusAvailable = !viewingOnly && formMode !== 'add' && (isIncludedCopy || !isNotIncludedCopy)
  const secondaryAccess =
    allCopiesExpired &&
    !viewingOnly &&
    !!remarkId &&
    eliminationStatus !== 'COMPLETE' &&
    eliminationStatus !== 'DISCARDED'

  const { data: objectsDropdown } = useGetDropdownObjectsQuery({ projectId })

  const { data: contractorsDropdown } = useGetDropdownContractorsQuery({ projectId })
  const [responsibleDropdownRequest, { data: responsibleDropdown }] = useLazyGetDropdownResponsibleQuery()
  const { data: companySendersDropdown } = useGetDropdownCompanySendersQuery({ projectId })
  const [sendersDropdownRequest, { data: sendersDropdown }] = useLazyGetDropdownSendersQuery()

  const [localResponsibleList, setLocalResponsibleList] = useState<any[]>([])

  useEffect(() => {
    const { id, value, type } = values.contractor || {}

    if (value && !responsibleDropdown?.data.length) {
      responsibleDropdownRequest({
        projectId,
        contractor: type === 'projectMember' ? String(id) : null,
        company: type === 'company' ? value : null,
      })
    }
  }, [values.contractor])

  useEffect(() => {
    const { id, value, type } = values.senderCompany || {}

    if (value && !sendersDropdown?.data.length) {
      sendersDropdownRequest({
        projectId,
        company: type === 'company' ? value : null,
        member: type === 'projectMember' ? Number(id) : null,
      })
    }
  }, [values.senderCompany])

  useEffect(() => {
    const responsibleListWithGroup =
      responsibleDropdown?.data?.map((item) => ({
        ...item,
        group: item.data === 'representative' ? 'Представители подрядчика:' : 'Пользователи:',
      })) || []

    if (!isEqual(localResponsibleList, responsibleListWithGroup)) setLocalResponsibleList(responsibleListWithGroup)
  }, [responsibleDropdown])

  useEffect(() => {
    if (viewingOnly) return

    if (formatDateForServer(values.dateDone) && values.prescriptionNumber && !isDateDoneFocused) {
      setFieldValue('status', 'COMPLETE')
    }
  }, [values.dateDone, values.prescriptionNumber])

  useEffect(() => {
    if (viewingOnly) return

    if (values.status === 'COMPLETE' && values.prescriptionNumber) {
      if (!values.dateDone) {
        setFieldValue('dateDone', new Date())
      }
      setIsDateDoneRequired(true)
    } else {
      // setFieldValue('dateDone', null)
      setIsDateDoneRequired(false)
    }
  }, [values.status, values.prescriptionNumber])

  // contractor autofill
  useEffect(() => {
    const localData = contractorsDropdown?.data || []
    const firstContractor = localData[0]
    const { id, text, data, subtext } = firstContractor || {}

    if (
      isAddingMode &&
      localData[0]?.data === 'projectMember' &&
      localData[1]?.data !== 'projectMember' &&
      !values.contractor
    ) {
      const contractorValue = {
        id,
        value: text,
        type: data || '',
        subtext: subtext || null,
      }

      setFieldValue('contractor', contractorValue)

      setAutofilledData((prev: RemarkAutofilledData) => ({ ...prev, contractor: contractorValue }))
    }
  }, [contractorsDropdown])

  // object autofill
  useEffect(() => {
    const objectsList = objectsDropdown?.data || []
    const firstObject = objectsList[0]
    const { id, text } = firstObject || {}

    if (isAddingMode && objectsList.length === 1 && !values.object) {
      const objectValue = {
        id: id,
        value: text,
      }

      setFieldValue('object', objectValue)

      setAutofilledData((prev: RemarkAutofilledData) => ({ ...prev, object: objectValue }))
    }
  }, [objectsDropdown])

  // const { data: basisDropdown } = useGetDropdownBasisQuery({ projectId })

  const onStatusCellClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const onMenuItemClick = useCallback(
    (status: RemarkStatusesRu) => {
      setMenuAnchor(null)
      setFieldValue('status', remarkStatusesBackend[status])
    },
    [projectId],
  )

  const ColoredStatus = (
    <ColoredTitle body={remarkStatusesClient[values.status]} color={remarkStatusesColor[values.status]} />
  )

  const getDateStartRange = () => {
    const { dateEnd, dateDone, previousDateEnd } = values || {}
    const today = new Date()
    let localPreviousDateEnd = new Date(previousDateEnd || 0)

    if (isCopy && previousDateEnd) {
      localPreviousDateEnd.setDate(localPreviousDateEnd.getDate() + 1)
    }

    const range = {
      minDate: (isCopy && localPreviousDateEnd) || undefined,
      maxDate: today,
    }

    if (dateEnd && dateEnd < today) range.maxDate = dateEnd

    if (dateDone && dateDone < range.maxDate) range.maxDate = dateDone

    return range
  }

  const handleSenderCompanySelection = (data: CustomSelectValueWithType) => {
    const { id, value, type } = data

    setFieldValue('senderUser', null)

    sendersDropdownRequest({
      projectId,
      company: type === 'company' ? String(value) : null,
      member: type === 'projectMember' ? Number(id) : null,
    })
  }

  const handleContractorSelection = (data: CustomSelectValueWithType) => {
    const { id, value, type } = data

    setFieldValue('responsible', null)

    responsibleDropdownRequest({
      projectId,
      contractor: type === 'projectMember' ? String(id) : null,
      company: type === 'company' ? value : null,
    })
  }

  const changeDateDoneFocus = (value: boolean) => {
    setIsDateDoneFocused(value)
  }

  return (
    <Wrapper>
      <Typography marginBottom={1.5} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
        Общая информация
      </Typography>
      <Stack spacing={2.5}>
        <Stack spacing={1.5}>
          <FieldItem title='Статус' marginBottom={1}>
            <StatusCellContent onClick={onStatusCellClick} display={'flex'} alignItems={'center'}>
              {ColoredStatus}
              {isStatusAvailable && (
                <IconButton>
                  <KeyboardArrowDownIcon fontSize='small' />
                </IconButton>
              )}
            </StatusCellContent>
            {isStatusAvailable && (
              <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
                {remarkStatuses
                  .filter(
                    (menuStatus) =>
                      menuStatus !== 'Исполнено' || (menuStatus === 'Исполнено' && values.prescriptionNumber),
                  )
                  .map((menuStatus) => {
                    const checked = remarkStatusesClient[values.status] === menuStatus

                    return (
                      <StyledSelectMenuItem
                        onClick={() => onMenuItemClick(menuStatus)}
                        style={{ width: '100%', maxWidth: '100%' }}
                        value={menuStatus}
                        checked={checked}
                        key={menuStatus}
                      >
                        {menuStatus}
                      </StyledSelectMenuItem>
                    )
                  })}
              </Menu>
            )}
          </FieldItem>
          <FieldItem
            title={values?.copyNumber ? 'Дата повторного замечания' : 'Дата замечания'}
            icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
            isRequired={true}
          >
            <FieldForm
              version='date'
              name='dateStart'
              placeholder='дд.мм.гггг'
              helperText=''
              dataValue={values.dateStart}
              onDataChange={(value: Date | null) => setFieldValue('dateStart', value)}
              style={{ maxWidth: '144px', width: '100%' }}
              dateFieldProps={{
                readOnly: viewingOnly,
                ...getDateStartRange(),
              }}
            />
          </FieldItem>
          <FieldItem
            title='Номер'
            icon={<ErrorOutlineIcon fontSize={'medium'} color={'secondary'} />}
            isRequired={true}
          >
            <FieldForm
              version='project'
              placeholder={'Номер замечания'}
              name='number'
              fullWidth={false}
              disabled={true}
              inputProps={{
                readOnly: true,
                style: { padding: '5px 8px', width: '126px', textAlign: 'end' },
              }}
            />
          </FieldItem>

          <CustomSelect
            name='object'
            icon={<ObjectIcon fontSize={'medium'} color={'secondary'} />}
            list={objectsDropdown?.data || []}
            placeholder={!viewingOnly && !isCopy ? 'Выберите из списка' : ''}
            readOnly={viewingOnly || isCopy}
            label={'Объект'}
            isRequired={true}
            // isAutofill={isAddingMode}
            isSearch={true}
            style={{
              textAlign: 'start',
            }}
          />
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
            Строительный контроль
          </Typography>
          <Stack spacing={1.5}>
            <CustomSelect
              name='senderCompany'
              list={
                companySendersDropdown?.data?.map((item) => ({
                  ...item,
                  group: item.data === 'company' ? 'Компании:' : 'Участники проекта:',
                })) || []
              }
              isSearch={true}
              placeholder={!viewingOnly && !isCopy ? 'Выберите из списка' : ''}
              readOnly={viewingOnly || isCopy}
              isGroup={true}
              label='Организация, выдавшая замечание'
              style={{
                textAlign: 'start',
              }}
              handleValueSelection={handleSenderCompanySelection}
              isRequired={true}
              icon={<BuildingIcon fontSize={'medium'} color={'secondary'} />}
            />
            <CustomSelect
              name='senderUser'
              list={sendersDropdown?.data || []}
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              isSubtext={true}
              isDisabled={!values?.senderCompany?.value}
              label='Замечание составил'
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly || isCopy}
              isRequired={true}
              icon={<PersonIcon fontSize={'medium'} color={'secondary'} />}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
            Ответственный
          </Typography>
          <Stack spacing={1.5}>
            <CustomSelect
              name='contractor'
              list={contractorsDropdown?.data || []}
              isSearch={true}
              placeholder={!viewingOnly && !isCopy ? 'Выберите из списка' : ''}
              readOnly={viewingOnly || isCopy}
              label='Организация, получившая замечание'
              style={{
                textAlign: 'start',
              }}
              handleValueSelection={handleContractorSelection}
              isRequired={true}
              icon={<BuildingIcon fontSize={'medium'} color={'secondary'} />}
            />
            <CustomSelect
              name='responsible'
              list={localResponsibleList}
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              readOnly={viewingOnly}
              isSubtext={true}
              isDisabled={!values?.contractor?.value}
              label='Ответственный'
              style={{
                textAlign: 'start',
              }}
              icon={<PersonIcon fontSize={'medium'} color={'secondary'} />}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
            Сроки
          </Typography>
          <Stack spacing={1.5}>
            <FieldItem
              title='Установленная дата устранения'
              icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
              isRequired={true}
            >
              <FieldForm
                version='date'
                name='dateEnd'
                placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                helperText=''
                dataValue={values.dateEnd}
                onDataChange={(value: Date | null) => setFieldValue('dateEnd', value)}
                style={{ maxWidth: '144px', width: '100%' }}
                dateFieldProps={{
                  minDate: values.dateStart || undefined,
                  readOnly: viewingOnly,
                }}
              />
            </FieldItem>
            {!!remarkId && (
              <FieldItem
                title='Фактическая дата устранения'
                icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
                isRequired={isDateDoneRequired}
              >
                <FieldForm
                  version='date'
                  name='dateDone'
                  placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                  helperText=''
                  dataValue={values.dateDone}
                  onFocus={() => changeDateDoneFocus(true)}
                  onBlur={() => changeDateDoneFocus(false)}
                  onDataChange={(value: Date | null) => setFieldValue('dateDone', value)}
                  style={{ maxWidth: '144px', width: '100%' }}
                  dateFieldProps={{
                    minDate: values.dateStart || undefined,
                    maxDate: new Date() || undefined,
                    readOnly: viewingOnly || isDateDoneDisabled,
                  }}
                />
              </FieldItem>
            )}
            {secondaryAccess && (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1.5}>
                <Typography
                  onClick={() => !dirty && onDrawerOpen('remarkSecondary')}
                  variant='body2'
                  sx={{ cursor: 'pointer' }}
                  color={!dirty ? theme.palette.primary.main : theme.palette.secondary.main}
                >
                  Создать повторное замечание
                </Typography>
                <Tooltip
                  title={remarkSecondaryInfo}
                  bgColor={theme.palette.bg.white}
                  textColor={theme.palette.text.dark}
                >
                  <InfoIcon fontSize='inherit' color='action' />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
        {remarkId && (
          <Stack>
            <Typography marginBottom={2} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
              Документы
            </Typography>
            <Stack spacing={1.5}>
              <FieldItem title='Номер предписания' icon={<ErrorOutlineIcon fontSize={'medium'} color={'secondary'} />}>
                <FieldForm
                  version='project'
                  name='prescriptionNumber'
                  dataValue={values.prescriptionNumber}
                  fullWidth={false}
                  disabled={true}
                  inputProps={{
                    readOnly: true,
                    style: { padding: '5px 8px', width: '126px', textAlign: 'end' },
                  }}
                />
              </FieldItem>
              <FieldItem title='Дата предписания' icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}>
                <FieldForm
                  version='date'
                  name='prescriptionDate'
                  placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                  dataValue={values.prescriptionDate}
                  onDataChange={(value: Date | null) => setFieldValue('prescriptionDate', value)}
                  style={{ maxWidth: '144px', width: '100%' }}
                  dateFieldProps={{
                    disabled: true,
                    readOnly: true,
                  }}
                />
              </FieldItem>
            </Stack>
          </Stack>
        )}
      </Stack>
      {allCopiesExpired && !viewingOnly && !!remarkId && (
        <RemarkSecondaryDrawer
          isOpen={openedDrawer === 'remarkSecondary'}
          onClose={(dirty, immediately) => onDrawerClose('remarkSecondaryExit', dirty, immediately)}
          localResponsibleList={localResponsibleList}
          initialValues={{
            copyNumber: values.copyNumber,
            remarkResponsible: values.responsible,
            remarkNumber: values.number,
            remarkDateStart:
              values.dateEnd instanceof Date ? addOneDayToDate(removeTimeFromDate(values.dateEnd)) : new Date(),
            primary: primaryId,
          }}
        />
      )}
    </Wrapper>
  )
}
