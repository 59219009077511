import { ProjectCard } from './ProjectCard'
import { Cards, CardsFullWrapper } from './index.styles'
import AddIcon from '@mui/icons-material/Add'
import { Stack } from '@mui/material'
import { useGetProjectsOverallQuery } from 'api/projects'
import { GetProjectsOverallResponse } from 'api/projects/api.types'
import { OverallProject, Project } from 'api/projects/types'
import { ButtonData, EmptyPageData } from 'components/EmptyPage'
import { EmptyPage } from 'components/EmptyPage'
import { Progress } from 'components/Progress/Progress'
import { ProjectTabLabel, projectTabLabels, projectStatusByTabLabel } from 'core/types/project'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useSearch } from 'hooks/useSearch'
import { AdminLegend } from 'pages/Administration/components/AdminLegend'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { filterByFieldNames } from 'utils/filterByFieldNames'

export const getEmptyPageData = (text: React.ReactNode, buttons?: ButtonData[]): EmptyPageData => ({
  text,
  buttons:
    buttons?.map((button) => ({
      text: button.text,
      icon: button.icon,
      onClick: button.onClick,
    })) || [],
})

export const Projects: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<ProjectTabLabel>('Все проекты')
  const { data, isFetching } = useGetProjectsOverallQuery({})
  const { data: projectsData } = data || ({} as GetProjectsOverallResponse)
  const { searchValue } = useSearch()
  const navigate = useNavigate()
  const profile = useTypedSelector(profileSelector)
  const { firstName } = profile

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: ProjectTabLabel) => {
    setCurrentTab(tabValue)
  }, [])

  const filteredBySearch = useMemo(() => {
    return searchValue && projectsData?.length
      ? // @ts-ignore
        filterByFieldNames<Project>(projectsData, ['project.fullName', 'project.shortName'], searchValue)
      : projectsData
  }, [projectsData, searchValue])

  const filteredByStatus = useMemo(() => {
    return currentTab === 'Все проекты'
      ? filteredBySearch
      : // @ts-ignore
        filteredBySearch?.filter(
          ({ project }: OverallProject) => project.status === projectStatusByTabLabel[currentTab],
        )
  }, [filteredBySearch, currentTab])

  useBreadcrumbs([{ title: 'Проекты' }])

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}.<br /> У Вас еще нет проектов для отображения.
      <br />
      {profile.role === 'admin' ? 'Давайте создадим Ваш первый проект.' : ''}{' '}
    </>,
    profile.role === 'admin'
      ? [
          {
            text: 'Добавить проект',
            icon: AddIcon,
            onClick: () => onAddProjectClick(),
          },
        ]
      : undefined,
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют проекты, соответствующие результатам запроса.</>,
  )

  const onAddProjectClick = useCallback(() => {
    navigate('/administration/projects/add')
  }, [])

  return (
    <Stack flex={1}>
      {isFetching ? (
        <Progress />
      ) : projectsData?.length ? (
        <>
          <AdminLegend<ProjectTabLabel>
            currentTab={currentTab}
            tabsData={projectTabLabels.map((tabName) => ({ value: tabName, label: tabName }))}
            onTabChange={onTabChange}
            onAddClick={onAddProjectClick}
          />
          {filteredByStatus?.length ? (
            <CardsFullWrapper>
              <Cards>
                {filteredByStatus?.map((item: OverallProject, i: number) => (
                  <ProjectCard key={item.project.id} item={item} />
                ))}
              </Cards>
            </CardsFullWrapper>
          ) : (
            <EmptyPage data={emptyFilteredData} forFilter />
          )}
        </>
      ) : (
        <EmptyPage data={emptyPageData} fullPage />
      )}
    </Stack>
  )
}
