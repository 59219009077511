import { IconProps } from '../types/iconProps'
import { FC } from 'react'

export const MapMarkerIcon: FC<IconProps> = (props) => {
  return (
    <svg {...props} viewBox='0 0 12 14' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.99984 0.333496C3.19984 0.333496 0.666504 2.48016 0.666504 5.80016C0.666504 8.0135 2.4465 10.6335 5.99984 13.6668C9.55317 10.6335 11.3332 8.0135 11.3332 5.80016C11.3332 2.48016 8.79984 0.333496 5.99984 0.333496ZM5.99984 7.00016C5.2665 7.00016 4.6665 6.40016 4.6665 5.66683C4.6665 4.9335 5.2665 4.3335 5.99984 4.3335C6.73317 4.3335 7.33317 4.9335 7.33317 5.66683C7.33317 6.40016 6.73317 7.00016 5.99984 7.00016Z' />
    </svg>
  )
}
