import * as yup from 'yup'

export const checkIfFilesAreCorrectType = (files: File[], formats: string[], required: boolean): boolean => {
  if (!formats.length) return true

  if (files?.length) {
    return files.every((file) => formats.includes(file.type))
  }

  return !required
}

export const getUploadValidation = (formats: string[]) => {
  return yup.object({
    files: yup.mixed(),
  })
}
