import { Stack } from '@mui/material'
import styled from 'styled-components'

export const GeneralChartWrapper = styled(Stack)`
  height: 458px;
  position: relative;
`

interface ChartDividerWrapperProps {
  translateY: string
}

export const ChartDividerWrapper = styled(Stack)<ChartDividerWrapperProps>`
  position: absolute;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  transform: translateY(${(props) => props.translateY});
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`

export const ChartDivider = styled.div`
  border-bottom: 1px dashed rgb(120, 144, 178);
  flex-grow: 1;
`
