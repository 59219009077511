import { LegendColors } from '../global'

export type ProjectStatus = 'Активный' | 'Приостановленный' | 'Завершенный'
export type ProjectStatusForCard = 'Активный' | 'Приостановлен' | 'Завершенный'

export type ProjectType = 'Производственного и непроизводственного назначения' | 'Линейные объекты'
export type ProjectFinType = 'Инвестиционный' | 'Подрядный' | 'Смешанный'

export type ProjectStatusForFilter = 'Все проекты' | ProjectStatus
export type ProjectStatusForTabLabel = 'Активные' | 'Приостановленные' | 'Завершенные'
export type ProjectTabLabel = 'Все проекты' | ProjectStatusForTabLabel

// Arrays
export const projectStatuses: ProjectStatus[] = ['Активный', 'Приостановленный', 'Завершенный']

export const projectStatusByTabLabel: Record<ProjectStatusForTabLabel, ProjectStatus> = {
  Активные: 'Активный',
  Приостановленные: 'Приостановленный',
  Завершенные: 'Завершенный',
}

export const projectStatusesForCard: Record<ProjectStatus, ProjectStatusForCard> = {
  Активный: 'Активный',
  Приостановленный: 'Приостановлен',
  Завершенный: 'Завершенный',
}

export const projectTypes: ProjectType[] = ['Производственного и непроизводственного назначения', 'Линейные объекты']
export const projectFinTypes: ProjectFinType[] = ['Инвестиционный', 'Подрядный', 'Смешанный']

export const projectStatusesForFilter: ProjectStatusForFilter[] = [
  'Все проекты',
  'Активный',
  'Приостановленный',
  'Завершенный',
]
export const projectTabLabels: ProjectTabLabel[] = ['Все проекты', 'Активные', 'Приостановленные', 'Завершенные']

export const projectTypeShort: Record<ProjectType, string> = {
  'Линейные объекты': 'Линейные объекты',
  'Производственного и непроизводственного назначения': 'Произв. и непроизв. назначения',
}

export const projectStatusesColors: Record<ProjectStatus, LegendColors> = {
  Активный: 'lightgreen',
  Приостановленный: 'purple',
  Завершенный: 'disabled',
}
