import { IconProps } from '../types/iconProps'
import { FC } from 'react'

export const CalendarIcon: FC<IconProps> = (props) => {
  return (
    <svg {...props} viewBox='0 0 14 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.6668 1.99984H12.3335C13.0668 1.99984 13.6668 2.59984 13.6668 3.33317V13.9998C13.6668 14.7332 13.0668 15.3332 12.3335 15.3332H1.66683C0.933496 15.3332 0.333496 14.7332 0.333496 13.9998V3.33317C0.333496 2.59984 0.933496 1.99984 1.66683 1.99984H2.3335V0.666504H3.66683V1.99984H10.3335V0.666504H11.6668V1.99984ZM1.66683 13.9998H12.3335V5.33317H1.66683V13.9998Z'
      />
    </svg>
  )
}
