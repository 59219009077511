import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const MultiSelectHeader = styled(Stack)`
  flex-direction: row;
  padding: 8px 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.legends.gray};
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 5;
  background-color: ${(props) => props.theme.palette.bg.white};

  & h6:hover {
    cursor: pointer;
  }
`
