import { LegendColors } from 'core/types/global'

export const workStatusesColor: Record<WorkStatuses, LegendColors> = {
  ACCEPT: 'green',
  PARTIAL: 'yellow',
  REJECT: 'red',
  EMPTY: 'disabled',
}
export enum workStatusesClient {
  'ACCEPT' = 'Принято',
  'PARTIAL' = 'Частично принято',
  'REJECT' = 'Не принято',
  'EMPTY' = 'Не установлено',
}
export enum workStatusesBack {
  'Принято' = 'ACCEPT',
  'Частично принято' = 'PARTIAL',
  'Не принято' = 'REJECT',
  'Не установлено' = 'EMPTY',
}
export const workStatuses: WorkStatusesRu[] = ['Принято', 'Частично принято', 'Не принято', 'Не установлено']
export type WorkStatuses = 'ACCEPT' | 'PARTIAL' | 'REJECT' | 'EMPTY'
export type WorkStatusesRu = 'Принято' | 'Частично принято' | 'Не принято' | 'Не установлено'
