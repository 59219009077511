import { StatusedWrapper } from './ColoredStatus.styles'
import { ColoredStatusProps } from './ColoredStatus.types'
import { WithChildren } from 'core/types/global'
import React from 'react'

export const ColoredStatus: React.FC<ColoredStatusProps & WithChildren> = ({ color, children }) => {
  return (
    <StatusedWrapper color={color} className='status-wrapper'>
      {children}
    </StatusedWrapper>
  )
}
