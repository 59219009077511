import { UserAccessesProps } from './UserAccesses.types'
import { AccessTitle } from './styles'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { Box, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

export const UserAccesses: FC<UserAccessesProps> = ({ access, role }) => {
  if (!access.allProjects && !access.projects.length) {
    return <Typography variant='body2'>Нет проектов</Typography>
  }

  return (
    <ScrollableContainer spacing={1} maxHeight={90}>
      {access.allProjects ? (
        <Box>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Box>
              <DescriptionOutlinedIcon fontSize='medium' color='primary' />
            </Box>
            <AccessTitle variant='subtitle2'>Все проекты</AccessTitle>
          </Stack>
        </Box>
      ) : (
        access.projects.map((project) => (
          <Stack direction='row' spacing={1} alignItems='center' key={project.id}>
            <Box>
              <DescriptionOutlinedIcon fontSize='medium' color='primary' />
            </Box>
            <AccessTitle variant='subtitle2'>{project.name}</AccessTitle>
          </Stack>
        ))
      )}
    </ScrollableContainer>
  )
}
