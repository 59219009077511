import { AppBar } from '@mui/material'
import { ReactComponent as QcLogoMini } from 'assets/qc-logo-mini.svg'
import styled from 'styled-components'

export const StyledHeader = styled(AppBar)`
  padding: 0 20px;
  background: ${(props) => props.theme.palette.bg.gray};
  box-shadow: none;
`

export const HeaderLogo = styled(QcLogoMini)``
