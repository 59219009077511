import { ProjectWidgetTabItem, ProjectWidgetTabs } from './ProjectWidget.styles'
import {
  ProjectChartStatus,
  ProjectWidgetProps,
  ProjectWidgetTab,
  projectChartColors,
  projectChartStatusRuByEn,
  projectChartStatuses,
  projectChartTypeColors,
  projectChartTypeRuByEn,
  projectChartTypes,
} from './ProjectWidget.types'
import { ProjectChart } from './components/ProjectChart'
import { ProjectChartData } from './components/ProjectChart/ProjectChart.types'
import { ProjectChartInfo } from './components/ProjectChartInfo'
import { ChartDataInfoItem } from './components/ProjectChartInfo/ProjectChartInfo.types'
import { Stack, Typography } from '@mui/material'
import { useGetPrescriptionProjectStatsQuery } from 'api/analytics'
import { Progress } from 'components/Progress'
import { TabData } from 'components/Tabs'
import { AnalyticsWidgetItem } from 'pages/Analytics/Analytics.styles'
import { WidgetHeader } from 'pages/Analytics/components/WidgetHeader'
import { FC, useEffect, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const ProjectWidget: FC<ProjectWidgetProps> = ({ analyticsQueryData }) => {
  const { role } = useTypedSelector(profileSelector)
  const { queryBody, skip } = analyticsQueryData
  const { data: prescriptionProjectStats, isFetching } = useGetPrescriptionProjectStatsQuery(queryBody, skip)
  const { data: prescriptionProjectData } = prescriptionProjectStats || {}

  const initialTab: ProjectWidgetTab = 'status'
  const [currentTab, setCurrentTab] = useState<ProjectWidgetTab>(initialTab)
  const [chartData, setChartData] = useState<ProjectChartData | null>(null)
  const [chartDataInfo, setChartDataInfo] = useState<ChartDataInfoItem[]>([])

  const onChangeTab = (tab: ProjectWidgetTab) => {
    if (tab !== currentTab) setCurrentTab(tab)
  }

  const tabsData: TabData<ProjectWidgetTab>[] = [
    { value: 'status', label: 'Статус' },
    { value: 'type', label: 'Тип' },
  ]

  useEffect(() => {
    let localChartData: ProjectChartData = {
      outerData: [],
      outerColors: [],
      total: 0,
    }

    let localChartDataInfo: ChartDataInfoItem[] = []

    const { byStatus, byType } = prescriptionProjectData || {}

    if (currentTab === 'status') {
      const { total, ...rest } = byStatus || {}

      localChartData.total = total || 0

      projectChartStatuses.forEach((status) => {
        const { data = 0 } = rest[status as keyof typeof rest] || {}
        const color = theme.palette.legends[projectChartColors[status]]

        localChartData.outerData.push(data)
        localChartData.outerColors.push(color)

        localChartDataInfo.push({
          value: data,
          label: projectChartStatusRuByEn[status],
          color,
        })
      })
    }

    if (currentTab === 'type') {
      const { total, ...rest } = byType || {}

      localChartData.total = total || 0

      projectChartTypes.forEach((type) => {
        const { data = 0 } = rest[type as keyof typeof rest] || {}
        const color = theme.palette.legends[projectChartTypeColors[type]]

        localChartData.outerData.push(data)
        localChartData.outerColors.push(color)

        localChartDataInfo.push({
          value: data,
          label: projectChartTypeRuByEn[type],
          color,
        })
      })
    }

    setChartData(localChartData)
    setChartDataInfo(localChartDataInfo)
  }, [currentTab, prescriptionProjectData])

  return (
    <AnalyticsWidgetItem
      width={'400px'}
      minWidth={'400px'}
      spacing={'14px'}
      padding={'20px !important'}
      minHeight={'246px'}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={3}>
        <WidgetHeader
          title='Проекты'
          tooltipTitle='В статистике учитываются проекты, в которых было создано, как минимум, одно замечание или предписание за выбранный временной интервал.'
          showLegend={false}
        />
        {role === 'admin' && (
          <ProjectWidgetTabs>
            {tabsData.map((tab) => (
              <ProjectWidgetTabItem onClick={() => onChangeTab(tab.value)} isActive={currentTab === tab.value}>
                <Typography
                  variant='body2'
                  color={currentTab === tab.value ? theme.palette.primary.main : theme.palette.text.disabled}
                >
                  {tab.label}
                </Typography>
              </ProjectWidgetTabItem>
            ))}
          </ProjectWidgetTabs>
        )}
      </Stack>

      {isFetching ? (
        <Progress />
      ) : (
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <ProjectChart chartData={chartData} />
          <ProjectChartInfo chartDataInfo={chartDataInfo} />
        </Stack>
      )}
    </AnalyticsWidgetItem>
  )
}
