import { DefaultTheme, ThemedStyledProps } from 'styled-components'

type TempType<T> = ThemedStyledProps<any & T, DefaultTheme>
/**
 * использует два дженерика
 * T  - интерфейс для реализации пропсов
 *
 * Важно, для функции необходимы дефолтные параметры (смотри ниже)
 *
 *
 * @example
 *
 * // пример использования на div элементе и реализации интерфейса IFlexContainer
 * interface IFlexContainer {
 *   direction?:'row'|'flex'
 *   align?:AlignsFlex
 *   justify?:AlignsFlex
 *   gap?:number
 * }
 *
 * // нужно проверить возможно будет работать с таким синтаксисом ${getProps('align') || 'center'}
 * const FlexContainer = styled.div<IFlexContainer>`
 *   display: flex;
 *   align-items: ${getProps('align')};
 *   justify-content: ${getProps('justify')};
 *   gap: ${getProps('gap')};
 *   direction: ${getProps('direction')};
 * `
 *
 * FlexContainer.defaultProps = {
 *     align:'center',
 *     justify:'center',
 *     gap:20,
 *     direction:'row'
 * }
 *
 *
 * @param key ключ из реализуемого интерфейса
 * @param modify модификатор для цифровых параметров
 */
export function getProps<T>(key: keyof T, modify: 'px' | '%' | 'rem' | 'em' | 'pt' = 'px') {
  return (props: TempType<T>) => {
    if (typeof props[key] === 'number') {
      return props[key] + modify
    }
    return props[key]
  }
}
