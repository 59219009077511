import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { theme } from 'styles/theme'

export interface UseExitConfirmProps {
  handleConfirm: (confirm: boolean, ...params: any) => void
  closeDeps?: any[]
  title?: string
  body?: string | ReactElement<{ children: string }>
  confirmButtonText?: string
  denyButtonText?: string
  confirmButtonLoading?: boolean
  denyButtonDisabled?: boolean
}

export interface UseExitConfirmData {
  ConfirmDialog: FC
  openConfirm: (...params: any) => void
  isConfirmDialogOpened: boolean
}

export const useConfirmDialog = (data: UseExitConfirmProps): UseExitConfirmData => {
  const {
    handleConfirm,
    closeDeps,
    confirmButtonText,
    denyButtonText,
    title,
    body,
    confirmButtonLoading,
    denyButtonDisabled,
  } = data

  const [open, setOpen] = useState(false)
  const [params, setParams] = useState<any[]>([])

  const openConfirm = useCallback((...params: any) => {
    setOpen(true)
    setParams(params)
  }, [])

  const onClose = useCallback(
    (confirm: boolean) => {
      setOpen(false)
      handleConfirm(confirm, ...params)
    },
    [params, ...(closeDeps || [])],
  )

  useEffect(() => {
    return () => {
      if (open) {
        setParams([])
      }
    }
  }, [open])

  const ConfirmDialog: React.FC = () => (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      PaperProps={{
        style: {
          width: 400,
          textAlign: 'center',
        },
      }}
    >
      <DialogTitle style={{ justifyContent: 'center', paddingTop: 30, paddingBottom: 12 }}>
        <Typography variant='subtitle1'>{title || 'Подтвердить закрытие формы?'}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant='body2' color={theme.palette.text.dark}>
            {body || 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.'}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 30 }}>
        <Button
          onClick={() => onClose(true)}
          loading={confirmButtonLoading}
          autoFocus
          color='success'
          fullWidth
          style={{ maxWidth: 165 }}
        >
          {confirmButtonText || 'Да'}
        </Button>
        <Button onClick={() => onClose(false)} disabled={denyButtonDisabled} fullWidth style={{ maxWidth: 165 }}>
          {denyButtonText || 'Отменить'}
        </Button>
      </DialogActions>
    </Dialog>
  )
  return {
    ConfirmDialog,
    openConfirm,
    isConfirmDialogOpened: open,
  }
}
