import { IconProps } from '../types/iconProps'
import { FC } from 'react'

export const EditCircleIcon: FC<IconProps> = (props) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.3335 7.99992C1.3335 4.31992 4.32016 1.33325 8.00016 1.33325C11.6802 1.33325 14.6668 4.31992 14.6668 7.99992C14.6668 11.6799 11.6802 14.6666 8.00016 14.6666C4.32016 14.6666 1.3335 11.6799 1.3335 7.99992ZM11.2268 6.16397C11.3713 6.01951 11.3713 5.78615 11.2268 5.64169L10.3601 4.77493C10.2156 4.63047 9.98224 4.63047 9.83778 4.77493L9.15992 5.45278L10.549 6.84183L11.2268 6.16397ZM4.66683 9.94588V11.3349H6.05587L10.1526 7.23817L8.76358 5.84912L4.66683 9.94588Z'
        fill='#0044B4'
      />
    </svg>
  )
}
