import { Box, Button, Menu } from '@mui/material'
import styled from 'styled-components'

export const UserMenuButton = styled(Button)`
  padding: ${(props) => `${props.theme.spacing(0.75)} 0`};

  & span {
    color: ${(props) => props.theme.palette.text.dark};
  }

  & .MuiButton-startIcon {
    margin-right: ${(props) => props.theme.spacing(2)};
    margin-left: 0;
  }
`

export const StyledMenu = styled(Menu)`
  & > .MuiPaper-root {
    width: 300px;
    margin-top: 12px;
    overflow: visible;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 13px;
      width: 10px;
      height: 10px;
      background-color: #fff;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }

    & > .MuiList-root {
      display: flex;
      flex-direction: column;
      padding: 1.25rem;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`

export const CompanyWrapper = styled(Box)`
  text-align: center;
  max-width: 18rem;
  overflow: hidden;
  padding: 0.5rem 1rem;
  background-color: #edeff399;
  font-size: 18px;
  font-weight: 500;
`

export const UserInfo = styled(Box)`
  display: flex;

  > .MuiAvatar-root {
    width: 64px;
    height: 64px;
    font-size: 32px;
    border-radius: 8px;
    margin-right: 1.25rem;
  }
`

export const UserData = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`

export const UserName = styled(Box)`
  font-weight: 500;
`
