import {
  FormItemsWrapper as RemarkFormItemsWrapper,
  FullWrapper as RemarkFullWrapper,
  FormWrapper as RemarkFormWrapper,
} from 'pages/Remarks/components/RemarkForm/RemarkForm.styles'
import styled from 'styled-components'

export const FormWrapper = styled(RemarkFormWrapper)``

export const FullWrapper = styled(RemarkFullWrapper)``

export const FormItemsWrapper = styled(RemarkFormItemsWrapper)``
