import { Stack } from '@mui/material'
import styled from 'styled-components'

export const FormWrapper = styled(Stack)`
  padding: 20px 7px 20px 20px;
  overflow: auto;
  scrollbar-gutter: stable;
`

export const FormButtonWrapper = styled(Stack)`
  box-shadow:
    0px 4px 18px 3px rgba(0, 0, 0, 0.12),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 6px 6px -3px rgba(0, 0, 0, 0.2);
  align-items: center;
`
