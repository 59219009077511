import { EmployeeShortProfile } from 'api/profile/types'
import { useGetEmployeesQuery } from 'api/users'
import { GetEmployeesResponse } from 'api/users/api.types'
import { UserRoles } from 'core/types/user'
import { useCallback } from 'react'
import { currentCompanyIdSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export interface FilteredEmployees {
  admins: EmployeeShortProfile[]
  contractors: EmployeeShortProfile[]
  clients: EmployeeShortProfile[]
  engineers_qc: EmployeeShortProfile[]

  clientsAndAdmins: EmployeeShortProfile[]
  engineers_qcAndAdmins: EmployeeShortProfile[]
}

export const useFilteredEmployees = (): FilteredEmployees => {
  const currentCompanyId = useTypedSelector(currentCompanyIdSelector)

  const { data: employeesData } = useGetEmployeesQuery({
    companyId: currentCompanyId,
    roles: ['admin', 'client', 'engineer_qc', 'contractor'],
  })
  const { data: employees } = employeesData || ({} as GetEmployeesResponse)

  const filterEmployees = useCallback(
    (roles: UserRoles[]) => {
      return employees?.filter((employee) => roles.includes(employee.role)) || []
    },
    [employees],
  )

  return {
    admins: filterEmployees(['admin']),
    contractors: filterEmployees(['contractor']),
    clients: filterEmployees(['client']),
    engineers_qc: filterEmployees(['engineer_qc']),

    clientsAndAdmins: filterEmployees(['admin', 'client']),
    engineers_qcAndAdmins: filterEmployees(['admin', 'engineer_qc']),
  }
}
