import { Drawer, Stack } from '@mui/material'
import { ReactComponent as QcLogo } from 'assets/qc-logo.svg'
import { LogoWrapper } from 'components/AdminNavBar'
import styled from 'styled-components'

export const UserDrawer = styled(Drawer)`
  width: 350px;
  text-align: left;

  & > .MuiPaper-root {
    width: 350px;
    box-shadow: 8px 0 8px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }
`

export const UserDrawerHeader = styled(Stack)`
  height: 64px;
  padding: 0 20px;
`

export const UserLogoWrapper = styled(LogoWrapper)`
  width: 200px;
  // height: 64px;

  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UserQcLogo = styled(QcLogo)`
  width: 220px;
  height: 64px;
`

export const UserDrawerContent = styled(Stack)`
  padding: 10px 0 20px 20px;
  overflow: hidden;

  @supports (-moz-appearance: none) {
    scrollbar-width: none;

    &:hover {
      padding-right: 12px;
      scrollbar-width: thin;
    }
  }
`
