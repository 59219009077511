import { Project } from 'api/projects/types'
import {
  IntegrationModuleStatus,
  PragmaModule,
  PragmaModuleLabel,
} from 'pages/Administration/components/ProjectIntegrationDrawer/ProjectIntegrationDrawer.types'
import { theme } from 'styles/theme'

export interface ProjectIntegration {
  global: ProjectIntegrationModule[]
}

export interface ProjectIntegrationModule {
  module: PragmaModule
  status: IntegrationModuleStatus
}

export interface RequestIntegration {
  dashboardModule: PragmaModuleLabel
  email: string
  externalModule: PragmaModuleLabel
  message: string | null
  name: string
  phone: string
}

export interface IntegrationDesignProject extends Omit<Project, 'type' | 'finType'> {}

export interface IntegrationRmProject {
  id: number
  title: string
  description: string
  abbr: string
  status: string
  reportStatus: string
  startDate: string
  endDate: string
}

export interface IntegratedProject {
  id: number
  module: PragmaModule
  name: string
}

export interface IntegratedModuleInfo {
  error: IntegratedModuleError | null
  url: string
}

export type IntegratedModuleError = 'module_not_binded' | 'module_offline' | 'access_denied'

// если есть url, то модуль подключен
// если нет url и нет error, то "Нет связанного проекта"
// "Нет связанного проекта" - ошибка 404
export const integratedModuleInfo: Record<IntegratedModuleError | IntegrationModuleStatus, IntegratedModuleData> = {
  module_not_binded: {
    tooltipData: {
      title: 'Проект не найден',
      text: 'В случае если проект в модуле был пересоздан, необходимо обновить связь с проектом на экране управления проектами. Обратитесь к администратору модуля.',
    },
    indicatorColor: theme.palette.legends.gray,
    disabled: true,
  },
  module_offline: {
    tooltipData: {
      title: 'Модуль временно недоступен',
      text: 'Модуль находится на техническом обслуживании. Повторите запрос позже.',
    },
    indicatorColor: theme.palette.warning.main,
    disabled: false,
  },
  access_denied: {
    tooltipData: {
      title: 'Нет доступа',
      text: 'Вам не предоставлены права доступа для просмотра проекта в модуле. Обратитесь к администратору компании.',
    },
    indicatorColor: theme.palette.legends.yellow,
    disabled: false,
  },

  available: {
    tooltipData: {
      title: 'Модуль не подключен',
      text: 'Модуль доступен для подключения. Обратитесь к администратору компании.',
    },
    indicatorColor: theme.palette.legends.gray,
    disabled: true,
  },
  binded: {
    tooltipData: {
      title: 'Нет связанного проекта',
      text: 'Настроить переход в соответствующий проект в модуле можно в разделе администрирования на странице Управление проектами.',
    },
    indicatorColor: theme.palette.legends.purple,
    disabled: true,
  },
  active: {
    tooltipData: null,
    indicatorColor: theme.palette.primary.main,
    disabled: false,
  },
}

export interface IntegratedModuleData {
  tooltipData: IntegratedModuleTooltip | null
  indicatorColor: string
  disabled: boolean
}

export interface IntegratedModuleTooltip {
  title: string
  text: string
}
