import { format as FnsFormat, isDate, isMatch } from 'date-fns'

// принимает Date с коротким или полным годом
// возвращает Date с полным годом
export function formatDateInInput(value: any, originalValue: any) {
  if (typeof originalValue === 'string') return undefined

  if (!originalValue?.getFullYear()) return undefined

  try {
    const firstFormattedDate = FnsFormat(originalValue, 'MM/dd/yy')
    const receivedYear = firstFormattedDate.split('/')[2]
    const finalYear = receivedYear.length === 2 ? `20${receivedYear}` : receivedYear
    const secondFormattedDate = [...firstFormattedDate.split('/').slice(0, 2), finalYear]

    const formattedDate = new Date(secondFormattedDate.join('/'))
    const parsedDate = isDate(formattedDate) ? formattedDate : null

    return parsedDate
  } catch {
    return null
  }
}
