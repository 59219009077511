import { StatisticsCardItemWrapper, StatisticsWrapper, StatisticsWrapperColumnTitle } from './StatisticsCardItem.styles'
import { StatisticsCardItemProps } from './StatisticsCardItem.types'
import { StatisticsCardItemRow } from './StatisticsCardItemRow'
import { Box, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const StatisticsCardItem: FC<StatisticsCardItemProps> = ({ title, link, disabled, data, columns, rows }) => {
  return (
    <StatisticsCardItemWrapper disabled={disabled}>
      <Link to={link || ''}>
        <Typography textAlign='left' color={theme.palette.primary.main}>
          {title}
        </Typography>

        <StatisticsWrapper>
          <Stack direction='row' justifyContent='end'>
            {columns.map((column) => (
              <Box width={column.width}>
                <StatisticsWrapperColumnTitle variant='body2'>{column.title}</StatisticsWrapperColumnTitle>
              </Box>
            ))}
          </Stack>

          <Stack pt={1}>
            {rows.map((row) => !row?.hidden && <StatisticsCardItemRow columns={columns} data={data} row={row} />)}
          </Stack>

          <Stack>
            <StatisticsCardItemRow
              columns={columns}
              data={data}
              row={{
                title: 'Итого',
                fieldName: 'total',
                isSum: true,
                dividerOnTop: true,
              }}
            />
          </Stack>
        </StatisticsWrapper>
      </Link>
    </StatisticsCardItemWrapper>
  )
}
