import { Stack } from '@mui/material'
import styled from 'styled-components'

interface ColumnsDrawerGlobalItemWrapperProps {
  checked: boolean
}

export const ColumnsDrawerGlobalItemWrapper = styled(Stack)<ColumnsDrawerGlobalItemWrapperProps>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 42px;
  padding-left: 26px;
  & .MuiSvgIcon-root {
    cursor: grab;
  }
`
