import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

export const validationDocument = yup.object({
  name: yup.string().trim().required('common:errors.required'),
  type: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .required('common:errors.required'),
  identifier: yup.string().trim(),
  body: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .nullable(),
  accepted: yup.date().transform(formatDateInInput).nullable(),
  status: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .required('common:errors.required'),
  comment: yup.string().trim(),
  file: yup.mixed(),
  link: yup
    .string()
    .trim()
    .url()
    .test('oneOfRequired', '', (item, testContext) => {
      return (
        ((testContext.parent.link || testContext.parent.fileForCreate) && !testContext.parent.file) ||
        testContext.parent.file
      )
    }),
  fileForCreate: yup.mixed().test('oneOfRequired', '', (item, testContext) => {
    return (
      ((testContext.parent.link || testContext.parent.fileForCreate) && !testContext.parent.file) ||
      testContext.parent.file
    )
  }),
})
