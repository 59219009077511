import * as yup from 'yup'

export const validationProjectMembers = yup.object({
  shortName: yup.string().trim().required('common:errors.required'),
  fullName: yup.string().trim(),
  basis: yup.string().trim(),
  role: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .required('common:errors.required'),
  supervisorName: yup.string().trim().required('common:errors.required'),
  supervisorPosition: yup.string().trim().required('common:errors.required'),
  note: yup.string().trim(),
})
