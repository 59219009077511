import { PragmaModule } from '../../ProjectIntegrationDrawer.types'

export interface IntegrationRequestSlideProps {
  isOpen: boolean
  onClose: (dirty: boolean, immediately?: boolean) => void
  isConfirmDialogOpened: boolean
  projectId: number
  module: PragmaModule
}

export interface IntegrationRequestFormData {
  phone: string
  email: string
  message: string
}

export const integrationRequestEmptyValues: IntegrationRequestFormData = {
  phone: '',
  email: '',
  message: '',
}
