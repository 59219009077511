import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

export const remarkSecondaryValidation = yup.object({
  dateStart: yup
    .date()
    .transform(formatDateInInput)
    .required('common:errors.required')
    .min(
      yup.ref('initialValues.remarkDateStart'),
      'Дата повторного замечания не может быть раньше установленной даты устранения связанного замечания',
    )
    .max(yup.ref('dateNow'), 'Установленная дата устранения не может быть раньше текущей даты'),
  dateEnd: yup.date().transform(formatDateInInput).min(yup.ref('dateNow')).required('common:errors.required'),
  // comment: yup.string().trim(),
})
