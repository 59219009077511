import { CurrentSortableColumn, PrescriptionFilters } from './components/PrescriptionsTable'

export interface PrescriptionLocationState {
  sortableColumn: CurrentSortableColumn | null
  filterData: PrescriptionFilters
}

export const defaultPrescriptionFilters: PrescriptionFilters = {
  status: null,
  contractor: null,
  sender_company: null,
  sender_user: null,
  period_dateStart: null,
  period_dateStart_range: null,
  period_dateEnd: null,
  period_dateEnd_range: null,
  period_dateDone: null,
  period_dateDone_range: null,
  type: null,
  author: null,
  receiver: null,
  execution_control: null,
}
