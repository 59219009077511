import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

interface AnalyticsWidgetItemProps {
  width: string
}

export const AnalyticsWrapper = styled(Stack)`
  padding-top: 16px;
  width: 100%;
`

export const WidgetsWrapper = styled(Stack)`
  padding-right: 7px;
  overflow-y: scroll;
`

export const AnalyticsWidgetItem = styled(Stack)<AnalyticsWidgetItemProps>`
  padding: 20px 30px 30px 30px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.bg.white};
  width: ${(props) => props.width};
  position: relative;

  @media (max-width: 1340px) {
    width: 100%;
  }
`

export const GroupWidgets = styled(Stack)`
  flex-direction: row;
  gap: 20px;

  @media (max-width: 1340px) {
    flex-wrap: wrap;
  }
`
