import { ItemWrapper } from '../RemarkForm.styles'
import styled from 'styled-components'

export const Wrapper = styled(ItemWrapper)`
  width: 24.5%;
  margin-right: 20px;

  @media (max-width: 1555px) {
    width: 49%;
  }

  @media (max-width: 1052px) {
    width: 99.5%;
    margin-right: 0;
  }
`
