import { ProjectFormData, ProjectManagementTabs } from '../../ProjectManagement.types'
import { ManagementLegendProps } from './ManagementLegend.types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useDeleteProjectMutation } from 'api/projects'
import { TabData } from 'components/Tabs'
import { useFormikContext } from 'formik'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const ManagementLegend: FC<ManagementLegendProps> = ({ tabValue, onTabChange, navigateToSourcePath }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { dirty, isSubmitting } = useFormikContext<ProjectFormData>()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit')

  const tabsData: TabData<ProjectManagementTabs>[] = [
    { value: 'info', label: 'О проекте' },
    { value: 'additional', label: 'Дополнительно' },
  ]

  const onChange = (e: React.SyntheticEvent, tabValue: ProjectManagementTabs) => {
    onTabChange(tabValue)
  }

  const onReturnClick = useCallback(() => {
    setConfirmDialogTrigger('exit')

    dirty ? openConfirm() : navigateToSourcePath()
  }, [dirty])

  const [deleteProject, deleteProjectResponse] = useDeleteProjectMutation()

  const onDeleteClick = useCallback(() => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }, [])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      navigateToSourcePath()
    }
  }, [])

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm && projectId) {
        deleteProject({ id: projectId })
      }
    },
    [projectId],
  )

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить проект?',
      body: 'Проект будет удален из модуля Quality Control.',
    },
    exit: {
      handleConfirm: handleExitConfirm,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  useMutationHandlers(deleteProjectResponse, () => {
    navigate('/administration/projects')
    enqueueSnackbar('Проект успешно удален.', {
      variant: 'success',
    })
  })

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{ py: 1.5, pr: 2.5 }}
      flexWrap={'wrap'}
      gap={1.5}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Box>
          <Button onClick={onReturnClick} startIcon={<ArrowBackIcon color='secondary' />} variant='text'>
            <Typography variant='buttonMedium'>К проектам</Typography>
          </Button>
        </Box>

        <Tabs value={tabValue} onChange={onChange}>
          {tabsData.map((tabData) => (
            <Tab {...tabData} />
          ))}
        </Tabs>
      </Stack>

      <Stack direction='row' alignItems='center' spacing={1.25}>
        {!!projectId && (
          <Button
            onClick={onDeleteClick}
            startIcon={<DeleteIcon fontSize='medium' />}
            size='small'
            variant='text'
            color='error'
          >
            Удалить проект
          </Button>
        )}
        <Button type='submit' disabled={!dirty || isSubmitting} variant='outlined' size='small' color='success'>
          Сохранить проект
        </Button>
      </Stack>
      <ConfirmDialog />
    </Stack>
  )
}
