import { uiContext } from '../../contexts/ui'
import { Divider } from '../Divider'
import { HeaderLogo, StyledHeader } from './Header.styles'
import { Search } from './components/Search'
import { UserButtons } from './components/UserButtons'
import { UserMenu } from './components/UserMenu'
import { Stack, Toolbar } from '@mui/material'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { HeaderProps } from 'components/Header/Header.types'
import { UserLogoWrapper, UserQcLogo } from 'components/UserNavBar'
import { config, isCompany } from 'core/config'
import { FC, useCallback, useContext } from 'react'
import { Link, matchRoutes, useLocation } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

const routesForViewLogo = [{ path: '/project/:projectId' }]

export const Header: FC<HeaderProps> = ({ hideLogo }) => {
  const location = useLocation()
  const userMatch = matchRoutes(routesForViewLogo, location)
  const routeMatch = userMatch?.length && userMatch[0].route.path
  const { showSearch } = useContext(uiContext)
  const profile = useTypedSelector(profileSelector)
  const { role } = profile

  const subDomain = window.location.host.split('.')[0]
  const currentCompanyName = isCompany(subDomain) ? subDomain : null
  const companyParam = currentCompanyName ? `?company=${currentCompanyName}` : ''

  const showLogo = routesForViewLogo.some((route) => route.path === routeMatch)

  const onChangeModule = useCallback(() => {
    window.location.replace(`${config.coreURL}${companyParam}`)
  }, [currentCompanyName])

  return (
    <>
      <StyledHeader position='static'>
        <Toolbar style={{ padding: '12px 0', minHeight: 'auto' }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            width='100%'
            alignItems='center'
            // spacing={2.5}
            gap={'15px'}
            flexWrap={'wrap'}
          >
            <Stack direction='row' alignItems='center' justifyContent={'space-between'} flex={10}>
              <Stack direction='row' alignItems='center' spacing={1}>
                {!hideLogo && (
                  <Link to='/projects'>
                    <UserLogoWrapper style={{ width: 90, overflow: 'hidden' }}>
                      <HeaderLogo />
                    </UserLogoWrapper>
                  </Link>
                )}

                <Breadcrumbs />
              </Stack>
              {showSearch ? (
                <Stack width={'248px'}>
                  <Search />
                </Stack>
              ) : null}
            </Stack>

            <Stack
              paddingLeft={'5px'}
              direction='row'
              spacing={theme.spacing(2.5)}
              alignItems='center'
              flex={1}
              justifyContent={'space-between'}
            >
              <UserMenu onChangeModule={onChangeModule} profile={profile} />
              <UserButtons role={role} />
            </Stack>
          </Stack>
        </Toolbar>
        <Divider color='#CBD3F5' />
      </StyledHeader>
    </>
  )
}
