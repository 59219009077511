import {
  ArrowIcon,
  Dropdown,
  DropdownBottomBound,
  FullWrapper,
  GroupTitle,
  List,
  ListItem,
  Select,
  SelectWrapper,
  Subtext,
  TextArea,
} from './CustomSelect.styles'
import { CustomGroup, CustomSelectProps, Item } from './CustomSelect.types'
import { Stack, Typography } from '@mui/material'
import { useField } from 'formik'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { theme } from 'styles/theme'

export const CustomSelect: FC<CustomSelectProps> = ({
  name,
  list,
  variant = 'input',
  placeholder,
  label,
  width,
  readOnly,
  free,
  isSearch = true,
  isGroup,
  isSubtext,
  isRequired,
  isDisabled,
  icon,
  labelProps,
  isAutofill,
  handleValueSelection,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [cleared, setCleared] = useState<boolean>(false)
  const selectedRef: any = useRef(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const textareaRef: any = useRef(null)
  const [dropdownBottomRef, dropdownBottomRefInView] = useInView()

  const [{ onBlur: onFieldBlur, ...field }, { touched, error: fieldError }, { setValue, setTouched }] = useField({
    name: name,
  })

  const { id, value, type, subtext } = field.value || {}

  useEffect(() => {
    if (variant === 'textarea') {
      textareaRef.current.style.height = '48px'
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 2 + 'px'
    }
  }, [searchValue])

  const handle = (item: Item, selected: boolean) => {
    if (selected) return

    const newValue = {
      id: item.id,
      value: item.text,
      type: item?.data || '',
      subtext: item?.subtext || null,
    }

    setSearchValue(item.text || '')
    setValue(newValue)
    setCleared(false)
    handleValueSelection && handleValueSelection(newValue)
  }

  useEffect(() => {
    if (value) {
      setSearchValue(value || '')
      setCleared(false)
    } else {
      if (!cleared) {
        setSearchValue(value || '')
        setCleared(true)
      }
    }
  }, [value])

  useEffect(() => {
    if (!isFocus && searchValue && value !== searchValue) {
      if (!free) {
        setSearchValue('')
        setValue(null)
        setCleared(true)
      } else addCustom()
    }
  }, [isFocus])

  useEffect(() => {
    if (isFocus && value) {
      const scrollValue =
        selectedRef.current?.offsetTop -
        (dropdownRef.current?.offsetHeight || 0) / 2 +
        selectedRef.current?.offsetHeight / 2
      dropdownRef.current?.scrollTo({ top: scrollValue })
    }
  }, [isFocus])

  useEffect(() => {
    if (isAutofill && !value && list.length === 1) {
      handle(list[0], false)
    }
  }, [list])

  const changeHandler = (text: string) => {
    setSearchValue(text)
    if (value && text !== value && !cleared) {
      // setValue({ id: null, value: '' });
      setValue(null)
      // handleValueSelection && handleValueSelection({})
      setCleared(true)
    }
  }

  const addCustom = () => {
    if (!free) return
    setValue({
      id: 0,
      value: searchValue,
      type: 'custom',
    })
    setCleared(false)
  }

  const filtredList = useMemo(() => {
    let resultList: Item[] = []
    if (isSearch) {
      if ((free && type === 'custom' && searchValue === value) || searchValue === value) {
        resultList = list
        // resultList = [{ id: 0, text: searchValue, data: 'custom' }, ...list]
      } else resultList = list.filter((item) => item.text.toLowerCase().includes(searchValue.toLowerCase().trim()))
    } else resultList = list

    return resultList
  }, [searchValue, list, value])

  const groupedList = useMemo(() => {
    if (!isGroup) return []

    const searchedList = searchValue.trim() && isSearch && !value ? filtredList : list
    if (!searchedList.length) return []

    const resultList: CustomGroup[] = []
    let title = searchedList[0].group || ''
    let array: Item[] = []
    searchedList.forEach((item: Item) => {
      if (item.group === title) array.push(item)
      else {
        resultList.push({ title, array })

        title = item.group || ''
        array = []
        array.push(item)
      }
    })
    resultList.push({ title, array })

    return resultList
  }, [searchValue, list, value, filtredList])

  const emptyBlock = (
    <ListItem availableToAdd={false}>
      <Typography textAlign={'start'} fontSize={14} color={theme.palette.text.dark}>
        Ничего не найдено
      </Typography>
    </ListItem>
  )

  const addingBlock = () => {
    const availableToAdd: boolean = !!(searchValue && !listByExactMatch.length && free && searchValue !== value)

    if (!availableToAdd) return null

    return (
      <ListItem availableToAdd={true} onMouseDown={addCustom}>
        <Typography textAlign={'start'} fontSize={14} color={theme.palette.text.dark}>
          {searchValue}
        </Typography>
      </ListItem>
    )
  }

  const listByExactMatch = useMemo(() => {
    let resultList: Item[] = []
    if (isSearch) {
      if (free && type === 'custom' && searchValue === value) {
        resultList = list
      } else resultList = list.filter((item) => item.text.toLowerCase().trim() === searchValue.toLowerCase().trim())
    } else resultList = list

    return resultList
  }, [searchValue, list, value])

  const closeDropdown = () => setTimeout(() => setIsFocus(false), 100)

  const isError = touched && !!fieldError

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onFieldBlur?.(e)
      setTouched(true)
      closeDropdown()
    },
    [onFieldBlur],
  )

  const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (readOnly || isDisabled) return
    isFocus ? e.target.blur() : e.target.select()
    // if (isSearch) isFocus ? e.target.blur() : e.target.select();
    setIsFocus(true)
  }

  const listItemBlock = (item: Item) => {
    const selected = id === item.id && value === item.text

    return (
      <ListItem
        onMouseDown={() => handle(item, selected)}
        key={`${item.id}${item.text}`}
        selected={selected}
        availableToAdd={true}
        ref={selected ? selectedRef : undefined}
      >
        <Typography textAlign={'start'} fontSize={14} color={theme.palette.text.dark}>
          {item.text}
        </Typography>
        {item?.subtext && (
          <Typography textAlign={'start'} fontSize={12} color={theme.palette.secondary.gray}>
            {item.subtext}
          </Typography>
        )}
      </ListItem>
    )
  }

  return (
    <FullWrapper maxWidth={width}>
      {label && (
        <Stack direction={'row'} marginBottom={'4px'}>
          {icon && <Stack mr={1}>{icon}</Stack>}
          <Typography
            {...labelProps}
            paddingLeft={icon ? '' : '10px'}
            fontSize={14}
            textAlign={'start'}
            color={theme.palette.secondary.dark}
          >
            {label}
          </Typography>
          {isRequired && (
            <Typography fontSize={14} color={theme.palette.error.main}>
              &nbsp;*
            </Typography>
          )}
        </Stack>
      )}
      <SelectWrapper isDisabled={isDisabled || readOnly}>
        {variant === 'input' && (
          <Select
            value={searchValue}
            onChange={(e) => changeHandler(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={!isSearch || readOnly}
            isSubtext={isSubtext && value}
            placeholder={placeholder}
            isDisabled={isDisabled}
            disabled={isDisabled}
            type='text'
            error={isError}
            {...props}
          />
        )}
        {variant === 'textarea' && (
          <TextArea
            ref={textareaRef}
            value={searchValue}
            onChange={(e) => changeHandler(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={!isSearch || readOnly}
            isSubtext={isSubtext && value}
            placeholder={placeholder}
            isDisabled={isDisabled}
            disabled={isDisabled}
            error={isError}
            {...props}
          />
        )}
        {isSubtext && value && (
          <Subtext textAlign={'start'} fontSize={12} color={theme.palette.secondary.gray}>
            {subtext || '—'}
          </Subtext>
        )}
        <ArrowIcon fontSize={'medium'} />
      </SelectWrapper>
      {isFocus && !isDisabled && (
        <Dropdown ref={dropdownRef} _up={!dropdownBottomRefInView}>
          {addingBlock()}
          {(isGroup ? groupedList : filtredList).length ? (
            <List>
              {isGroup
                ? groupedList.map((group) => (
                    <>
                      <GroupTitle fontSize={14}>{group.title}</GroupTitle>
                      {group.array.map((item) => listItemBlock(item))}
                    </>
                  ))
                : (searchValue.trim() && isSearch && !value ? filtredList : list).map((item) => listItemBlock(item))}
            </List>
          ) : (
            (!free ||
              (free && !listByExactMatch.length && value && searchValue) ||
              (free && !listByExactMatch.length && !value && !searchValue)) &&
            emptyBlock
          )}
        </Dropdown>
      )}
      <DropdownBottomBound ref={dropdownBottomRef} _top={41 * list.length} />
    </FullWrapper>
  )
}
