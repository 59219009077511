import { Stack } from '@mui/material'
import styled from 'styled-components'

interface DocumentRemarkProps {
  isSelectable: boolean
}

export const DocumentRemark = styled(Stack)<DocumentRemarkProps>`
  width: 100%;
  padding: 9px 8px;
  ${(props) =>
    !props.isSelectable &&
    `
    border-bottom: 2px solid ${props.theme.palette.bg.gray};
  `}
`

export const DocumentRemarkHeader = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
`
