import { ButtonProps } from './Button.types'
import { Button } from '@mui/material'
import styled from 'styled-components'

export const StyledButton = styled(Button)<ButtonProps>`
  position: relative;
  min-width: ${(props) => props.icon && '40px'};
  max-width: 230px;
  width: ${(props) => props.width};
  height: 40px;
  padding: ${(props) => `${props.theme.spacing(1)} ${props.theme.spacing(2)} ${props.theme.spacing(0.75)}`};
  padding: ${(props) => props.icon && props.theme.spacing(1)};
  font-weight: ${(props) =>
    props.theme.typography[props.customSize === 'medium' ? 'buttonMedium' : 'buttonSmall'].fontWeight};
  font-size: ${(props) =>
    props.theme.typography[props.customSize === 'medium' ? 'buttonMedium' : 'buttonSmall'].fontSize};
  line-height: ${(props) =>
    props.theme.typography[props.customSize === 'medium' ? 'buttonMedium' : 'buttonSmall'].lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography[props.customSize === 'medium' ? 'buttonMedium' : 'buttonSmall'].letterSpacing};
  text-transform: ${(props) =>
    props.theme.typography[props.customSize === 'medium' ? 'buttonMedium' : 'buttonSmall'].textTransform};
  color: ${(props) => props.color === 'error' && props.icon && props.theme.palette.text.light};
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  box-shadow: none;
  overflow: hidden;

  & .MuiButton-startIcon {
    margin-right: ${(props) => (props.paddingSize === 'small' ? '8px' : '')} !important;
    margin-right: ${(props) => (props.paddingSize === 'medium' ? '10px' : '')} !important;
    margin-right: ${(props) => (props.paddingSize === 'large' ? '16px' : '')} !important;

    & svg {
      margin-top: -2px;
      font-size: 24px;
    }
  }

  ${(props) =>
    props.loading &&
    `
    pointer-events: none;
  `}
`
export const ProgressWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: inherit;
`
