import { RemarkFormData } from '../RemarkForm.types'
import { RemarkMediaList, Wrapper } from './Media.styles'
import { MediaProps } from './Media.types'
import { AddMediaButton } from './components/AddMediaButton'
import { RemarkMediaItem } from './components/RemarkMediaItem'
import { Add as AddIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { RemarkMediaComments } from 'api/remarks/types'
import { Button } from 'components/Button'
import { CarouselViewer } from 'components/CarouselViewer'
import { VieweredItemInfo } from 'components/CarouselViewer/CarouselViewer.types'
import { generateNextVieweredItemInfo } from 'components/CarouselViewer/utils/generateNextVieweredItemInfo'
import { generateVieweredItemInfo } from 'components/CarouselViewer/utils/generateVieweredItemInfo'
import { FileWithId } from 'core/types/global'
import { useFormikContext } from 'formik'
import { isEqual } from 'lodash'
import { RemarkWarning } from 'pages/Prescriptions/components/PrescriptionsForm/components/Remarks/Remarks.styles'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const Media: FC<MediaProps> = ({ viewingOnly }) => {
  const { values, setFieldValue } = useFormikContext<RemarkFormData>()
  const { media, mediaForCreate, mediaCommentById, newMediaCommentById, mediaIdsToDelete } = values || {}
  const acceptedFormats = ['.jpg', '.jpeg', '.png', 'application/pdf']
  const mediaRef = useRef<HTMLDivElement>(null)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [vieweredItemInfo, setVieweredItemInfo] = useState<VieweredItemInfo | null>(null)
  const profile = useTypedSelector(profileSelector)
  const isCopy = !!values.copyNumber

  const filtredByType = useMemo(() => {
    const pdfList: any[] = []
    const imgList: any[] = []

    media.forEach((media) => {
      const [format] = media.link.split('.').slice(-1)

      format === 'pdf' ? pdfList.push(media) : imgList.push(media)
    })

    mediaForCreate.forEach((media) => {
      const format = media.type

      format === 'application/pdf' ? pdfList.push(media) : imgList.push(media)
    })

    return [imgList, pdfList].filter((innerList) => innerList.length)
  }, [media, mediaForCreate])

  const onDelete = useCallback(
    (deletedMedia: any) => {
      const isNewMedia = !deletedMedia.id

      if (isNewMedia) {
        setFieldValue('mediaForCreate', [...mediaForCreate.filter((media) => !isEqual(deletedMedia, media))])
      } else {
        setFieldValue('media', [...media.filter((media) => media.id !== deletedMedia.id)])
        setFieldValue('mediaIdsToDelete', [...values.mediaIdsToDelete, deletedMedia.id])
      }

      let mediaCommentByIdLocal: RemarkMediaComments | null = isNewMedia ? newMediaCommentById : mediaCommentById
      let localMediaKey = deletedMedia.temporaryId || deletedMedia.id
      delete mediaCommentByIdLocal?.[localMediaKey]

      if (!Object.keys(mediaCommentByIdLocal || {}).length) mediaCommentByIdLocal = null

      setFieldValue(isNewMedia ? 'newMediaCommentById' : 'mediaCommentById', mediaCommentByIdLocal)
    },
    [mediaForCreate, media, mediaIdsToDelete, newMediaCommentById, mediaCommentById],
  )

  const onFileSet: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e?.target?.files?.[0] || null
      if (!file) return
      const temporaryId = Date.now()
      const fileWithId: Partial<FileWithId> = file
      fileWithId.temporaryId = temporaryId

      setFieldValue('mediaForCreate', [...mediaForCreate, fileWithId])
      setFieldValue('newMediaCommentById', { ...(newMediaCommentById || {}), [temporaryId]: '' })
    },
    [mediaForCreate, newMediaCommentById],
  )

  // Viewer
  const onViewerOpen = useCallback(
    (media: any, mediaIndex: number, listIndex: number) => {
      const localVieweredItemInfo = generateVieweredItemInfo(
        media,
        mediaIndex,
        listIndex,
        newMediaCommentById,
        mediaCommentById,
        profile,
      )
      setVieweredItemInfo(localVieweredItemInfo)
      setIsViewerOpen(true)
    },
    [newMediaCommentById, mediaCommentById, profile],
  )

  const onViewerClose = useCallback(() => {
    setIsViewerOpen(false)
    setVieweredItemInfo(null)
  }, [])

  const onArrowClick = useCallback(
    (step: number) => {
      const nextVieweredItemInfo = generateNextVieweredItemInfo(
        step,
        vieweredItemInfo,
        filtredByType,
        newMediaCommentById,
        mediaCommentById,
        profile,
      )

      setVieweredItemInfo(nextVieweredItemInfo)
    },
    [filtredByType, vieweredItemInfo, newMediaCommentById, mediaCommentById, profile],
  )

  return (
    <Wrapper width={'51.1%'}>
      <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'28px'}>
        <Typography variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
          Файлы
        </Typography>

        {!viewingOnly && !isCopy && (
          <AddMediaButton id={'remark_media'} onChange={onFileSet} acceptedFormats={acceptedFormats}>
            <Button
              icon={true}
              color='secondary'
              variant='outlined'
              style={{
                pointerEvents: 'none',
              }}
            >
              <AddIcon />
            </Button>
          </AddMediaButton>
        )}
      </Stack>

      <RemarkMediaList flex={1} spacing={2.5}>
        {!values.mediaForCreate.length && !values.media.length && (
          <RemarkWarning variant='body1'>Файлы отсутствуют.</RemarkWarning>
        )}

        {filtredByType.map((list, listIndex) =>
          list.map((media, index) => {
            const isNewMedia = !media.id
            let format = '' as any

            isNewMedia
              ? (format = media.type)
              : (format = media.link.split('.').slice(-1)[0] === 'pdf' ? 'application/pdf' : 'image')

            return (
              <RemarkMediaItem
                key={media.id || media.temporaryId}
                number={index + 1}
                media={media}
                onDelete={() => onDelete(media)}
                mediaRef={mediaRef}
                viewingOnly={viewingOnly || isCopy}
                onViewerOpen={() => onViewerOpen(media, index, listIndex)}
                format={format}
              />
            )
          }),
        )}
      </RemarkMediaList>

      <CarouselViewer
        isOpen={isViewerOpen}
        itemInfo={vieweredItemInfo}
        isDisableArrows={filtredByType.flat().length === 1}
        onPrevClick={() => onArrowClick(-1)}
        onNextClick={() => onArrowClick(1)}
        onClose={onViewerClose}
      />
    </Wrapper>
  )
}
