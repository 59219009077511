import { AddTypePayload } from './documents.payloads'
import { DocumentsState } from './documents.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: DocumentsState = {
  chosenTypesIdsLocal: [],
  chosenTypesIds: [],
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    addTypeId(state, { payload }: PayloadAction<number>) {
      state.chosenTypesIdsLocal.push(payload)
    },

    removeTypeId(state, { payload }: PayloadAction<number>) {
      state.chosenTypesIdsLocal = state.chosenTypesIdsLocal.filter((type) => type !== payload)
    },

    submitFilter(state) {
      state.chosenTypesIds = state.chosenTypesIdsLocal
    },

    undoFilterChanges(state) {
      state.chosenTypesIdsLocal = state.chosenTypesIds
    },

    clearChosenTypesIdsIdLocal(state) {
      state.chosenTypesIdsLocal = []
    },
  },
})

export const { reducer: documentsReducer } = documentsSlice

export const { addTypeId, removeTypeId, submitFilter, undoFilterChanges, clearChosenTypesIdsIdLocal } =
  documentsSlice.actions
