import { FC } from 'react'

export const ClipboardTextIcon: FC = () => {
  return (
    <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 18.8H22.5M12 24.8H18.57M15 9.5H21C24 9.5 24 8 24 6.5C24 3.5 22.5 3.5 21 3.5H15C13.5 3.5 12 3.5 12 6.5C12 9.5 13.5 9.5 15 9.5Z'
        stroke='#6D9ADC'
        stroke-width='2.21875'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M24 6.5293C28.995 6.7993 31.5 8.6443 31.5 15.4993V24.4993C31.5 30.4993 30 33.4993 22.5 33.4993H13.5C6 33.4993 4.5 30.4993 4.5 24.4993V15.4993C4.5 8.6593 7.005 6.7993 12 6.5293'
        stroke='#6D9ADC'
        stroke-width='2.21875'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
