import { DocumentFieldItemProps } from './DocumentFieldItem.types'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'

export const DocumentFieldItem: FC<DocumentFieldItemProps> = ({ title, children, ...props }) => {
  return (
    <Stack direction={'row'} paddingLeft={1.25} alignItems={'center'} justifyContent={'space-between'}>
      <Typography variant='body2' lineHeight={'inherit'}>
        {title}:
      </Typography>
      {children}
    </Stack>
  )
}
