import { Stack, Typography } from '@mui/material'
import { ProjectDrawerFormWrapper } from 'pages/Administration/components/ProjectDrawerForm/styles'
import styled from 'styled-components'

export const UploadDrawerFormWrapper = styled(ProjectDrawerFormWrapper)`
  overflow-x: hidden;
`

export const UploadTextWrapper = styled(Stack)`
  text-align: center;
  color: ${(props) => props.theme.palette.text.dark};
`

export const UploadDrawerText = styled(Typography)`
  color: ${(props) => props.theme.palette.text.dark};
`

export const UploadDrawerAttention = styled(Typography)`
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.warning.main};
`
