import { MapHeight, MapWidth } from '../AboutTabImage'
import Carousel from 'react-material-ui-carousel'
import styled from 'styled-components'

export const StyledCarousel = styled(Carousel)`
  width: ${MapWidth}px;
  height: ${MapHeight}px;

  & .navButtonsWrapper:hover .MuiIconButton-root,
  & .MuiIconButton-root {
    color: ${(props) => props.theme.palette.text.dark};
    background-color: ${(props) => props.theme.palette.bg.shades};
    opacity: 1;
    filter: none;
  }
`
