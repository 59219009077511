import { CardHeader, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledCardHeader = styled(CardHeader)`
  position: relative;
  padding: 0 0 20px 0;

  .MuiCardHeader-avatar {
    align-self: flex-start;

    & > div {
      & > div:first-child {
        position: absolute;
      }
    }
  }

  .MuiAvatar-root {
    margin-top: 29px;
  }

  .MuiCardHeader-content {
    align-self: flex-end;
    max-width: calc(100% - 136px); // 80 - avatar, 20+20 card paddings, 16 - avatar margin
    padding-top: 24px;
    text-align: left;
  }
`

export const CardUserName = styled(Typography)`
  margin-bottom: 8px;

  @media (max-width: 1660px) {
    height: calc(28px * 3); // 28 - lineHeight, 3 - rows count
  }
`
