import { Stack } from '@mui/material'
import styled from 'styled-components'

export const AuditRemarkWrapper = styled(Stack)`
  padding: 16px 20px;
  gap: 8px;
`

export const AuditRemarkHeader = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  background-color: #6d9adc26;
  border-radius: 4px;
`

export const InfoItem = styled(Stack)`
  flex-direction: row;
  padding-left: 8px;
`

export const ReasonWrapper = styled(Stack)`
  padding: 8px;
`
