import { Stack } from '@mui/material'
import { SortingOrder } from 'core/types/prescription'
import styled from 'styled-components'

interface SortButtonProps {
  sorting: SortingOrder
}

export const SortButton = styled(Stack)<SortButtonProps>`
  // width: 24px;
  // height: 24px;

  ${(props) =>
    props.sorting === 'DESC' &&
    `
      & svg:nth-child(1) {
        color: red;
        opacity: 1;
      }    
    `}

  ${(props) =>
    props.sorting === 'ASC' &&
    `
      & svg:nth-child(2) {
        color: red;
        opacity: 1;
      }    
    `}

  & svg {
    fill ${(props) => props.theme.palette.text.light};
    opacity: 0.5;

    &:first-child {
      transform: rotate(180deg);
    }
  }

  &:hover {
    cursor: pointer;
  }
`
