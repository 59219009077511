import { alpha, Stack } from '@mui/material'
import styled, { css } from 'styled-components'

export const SectionsControlsWrapper = styled(Stack)`
  flex-direction: row;
  display: none;

  & .MuiButtonBase-root {
    padding: 4px;
  }
`

const SectionsMenuItemWrapperSelected = css`
  background-color: ${(props) => `${alpha(props.theme.palette.primary.main, 0.08)}`};

  & .MuiTypography-root {
    color: ${(props) => props.theme.palette.primary.main};
  }
`

interface SectionsMenuItemWrapperProps {
  selected: boolean
}

export const SectionsMenuItemWrapper = styled(Stack)<SectionsMenuItemWrapperProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 6px;
  text-align: left;
  cursor: pointer;

  ${(props) => props.selected && SectionsMenuItemWrapperSelected}

  &:hover {
    ${SectionsMenuItemWrapperSelected}
    ${SectionsControlsWrapper} {
      display: flex;
    }

    //& .MuiTypography-root {
    //  -webkit-line-clamp: inherit;
    //}
  }

  & .MuiTypography-root {
    max-width: 300px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
