import { placeholderNoData } from '../../../../index'
import { MapHeight } from '../AboutTabImage'
import { RowItem } from '../RowItem'
import { MoreTabContentProps } from './MoreTabContent.types'
import { formatMaskPhone } from 'components/PhoneFieldForm/PhoneFieldForm.utils'
import React, { FC, ReactElement, ReactNode, useMemo } from 'react'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

export const MoreTabContent: FC<MoreTabContentProps> = ({ responsiblePersons }) => {
  /**
   * деструктуризация пропсов
   */
  const { clientDirector, clientCurator, contractors } = useMemo(() => {
    // if (responsiblePersons) {
    return responsiblePersons
    // }
    // return { clientDirector:null, clientCurator:null, contractors:null }
  }, [responsiblePersons])

  return (
    <ScrollableContainer minWidth={'200px'} alignItems='start' maxHeight={MapHeight} pt={1} flex={1} spacing={2}>
      <RowItem
        title='Заказчик (компания)'
        rowStrings={[
          clientDirector?.company || '',
          clientDirector?.customName,
          clientDirector ? (clientDirector?.phone ? formatMaskPhone(clientDirector.phone) : '') : placeholderNoData,
        ]}
        emptyDataText='Заказчик проекта не указан'
      />

      <RowItem
        title='Заказчик (куратор)'
        rowStrings={[
          // clientCurator?.company || '',
          clientCurator?.customName,
          clientCurator?.phone ? formatMaskPhone(clientCurator.phone) : '',
        ]}
        emptyDataText='Заказчик проекта не указан'
      />

      {contractors?.length ? (
        contractors.map((contractorData) => {
          const { company, contractor } = contractorData?.contractor || {}
          const isContractor = !!(company || contractor?.shortName)

          const { representative, user } = contractorData?.responsible || {}
          const isResponsible = !!(representative?.fullName || user?.id)

          let contractorElement: ReactNode = null
          let responsibleElement: ReactNode = null

          if (isContractor) {
            contractorElement = (
              <RowItem
                title='Подрядчик (компания)'
                rowStrings={[
                  company || '',
                  contractor?.shortName || '',
                  // contractor?.phone ? formatMaskPhone(contractor.phone) : '',
                ]}
              />
            )
          }

          if (isResponsible) {
            const userName =
              user?.lastName || user?.firstName || user?.middleName
                ? `${user?.lastName || ''} ${user?.firstName || ''} ${user?.middleName || ''}`
                : ''

            responsibleElement = (
              <RowItem
                title='Подрядчик (ответственный)'
                rowStrings={[
                  // company || '',
                  representative?.fullName || userName || '',
                  user?.phone ? formatMaskPhone(user.phone) : '',
                ]}
              />
            )
          }

          return (
            <>
              {contractorElement}
              {responsibleElement}
            </>
          )
        })
      ) : (
        <RowItem title='Подрядчик' rowStrings={[]} emptyDataText='Подрядчик проекта не указан' />
      )}
    </ScrollableContainer>
  )
}
