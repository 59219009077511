import { format } from 'date-fns'
import { DateResponse, emptyDateResponse, getErrorDateResponse, parseResponseDate } from 'utils/dates/parseResponseDate'

interface FullDateResponse extends DateResponse {
  time: string // 12:30
  fullTime: string // 12:30:00
}
const emptyFullDateResponse: FullDateResponse = {
  ...emptyDateResponse,
  time: '',
  fullTime: '',
}
const getErrorFullDateResponse = (responseDate?: string): FullDateResponse => ({
  ...getErrorDateResponse(responseDate),
  time: '',
  fullTime: '',
})

// 2023-01-25T12:30:00Z
export const parseResponseDateTime = (responseDate?: string): FullDateResponse => {
  if (!responseDate) return emptyFullDateResponse

  const incorrectSymbols = responseDate.replace(/\d{2,4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/gm, '')
  if (incorrectSymbols) return getErrorFullDateResponse(responseDate)

  const parsedDate = parseResponseDate(responseDate, true)
  if (!parsedDate.date) return getErrorFullDateResponse(responseDate)

  const time = format(parsedDate.date, 'HH:mm')
  const fullTime = format(parsedDate.date, 'HH:mm:ss')

  return {
    ...parsedDate,
    time,
    fullTime,
  }
}
