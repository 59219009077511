import { dadataApi } from '../dadataApi'
import { GetGeoLocateRequest, GetGeoLocateResponse } from './types'

export const geoCodingApi = dadataApi.injectEndpoints({
  endpoints: (build) => ({
    getGeoLocate: build.mutation<GetGeoLocateResponse, GetGeoLocateRequest>({
      query: (body) => ({
        url: `/geolocate/address`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetGeoLocateMutation } = geoCodingApi
