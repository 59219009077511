import { Divider } from '@mui/material'
import styled from 'styled-components'

export const StyledDivider = styled(Divider)`
  height: 2px;
  margin-left: 0;
  background-color: ${(props) => props.color ?? props.theme.palette.legends.gray};
  border-bottom: none;
  border-radius: 1px;
`
