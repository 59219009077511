import { ColumnsDrawerFormData } from '../ColumnsDrawer.types'
import { ColumnsDrawerGlobalItem } from '../ColumnsDrawerGlobalItem/ColumnsDrawerGlobalItem'
import {
  ColumnsDrawerSectionAccordion,
  ColumnsDrawerSectionAccordionDetails,
} from './ColumnsDrawerGlobalSection.styles'
import { ColumnsDrawerGlobalSectionProps } from './ColumnsDrawerGlobalSection.types'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'

export const ColumnsDrawerGlobalSection: FC<ColumnsDrawerGlobalSectionProps> = ({ columnSettings, columns }) => {
  const { values } = useFormikContext<ColumnsDrawerFormData>()
  return columnSettings === 'remarks' ? (
    <ColumnsDrawerSectionAccordion disableGutters defaultExpanded>
      <ColumnsDrawerSectionAccordionDetails>
        {columns.map((column) => (
          <ColumnsDrawerGlobalItem
            key={column}
            column={column}
            columnSettings={columnSettings}
            checked={values[column] || false}
          />
        ))}
      </ColumnsDrawerSectionAccordionDetails>
    </ColumnsDrawerSectionAccordion>
  ) : null
}
