import { InnerContainer, MainContainer } from './MainLayout.styles'
import { Header } from 'components/Header'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const MainLayout: React.FC = () => {
  return (
    <MainContainer>
      <Header />
      <InnerContainer>
        <Outlet />
      </InnerContainer>
    </MainContainer>
  )
}
