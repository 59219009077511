import { format, isValid } from 'date-fns'

export interface DateResponse {
  date: Date | null
  fullDate: string // 25.01.2023
  fullDateShortYear: string // 25.01.23
}

export const emptyDateResponse: DateResponse = {
  date: null,
  fullDate: '',
  fullDateShortYear: '',
}

export const getErrorDateResponse = (responseDate?: string): DateResponse => ({
  date: null,
  fullDate: responseDate || '',
  fullDateShortYear: '',
})

// 2023-01-25
export const parseResponseDate = (responseDate?: string, skipRegexpCheck: boolean = false): DateResponse => {
  if (!responseDate) return emptyDateResponse

  const incorrectSymbols = responseDate.replace(/\d{2,4}-\d{2}-\d{2}/gm, '')
  if (incorrectSymbols && !skipRegexpCheck) return getErrorDateResponse(responseDate)

  const timestamp = Date.parse(responseDate)
  const isDateValid = isValid(timestamp)
  if (!isDateValid) return getErrorDateResponse(responseDate)

  const date = new Date(timestamp)

  const fullDate = format(date, 'dd.MM.yyyy')
  const fullDateShortYear = format(date, 'dd.MM.yy')

  return {
    date,
    fullDate,
    fullDateShortYear,
  }
}
