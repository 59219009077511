import { DrawerWrapper } from '../../../Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import styled from 'styled-components'

export const ColumnsDrawerWrapper = styled(DrawerWrapper)`
  justify-content: start;
  width: 370px;

  & .divider--small {
    height: 1px;
  }
`
