import { LegendColors } from './global'
import {
  REMARK_ELIMINATION_COMPLETE_STATUS_COLOR,
  REMARK_ELIMINATION_CREATED_STATUS_COLOR,
  REMARK_ELIMINATION_DISCARDED_STATUS_COLOR,
  REMARK_ELIMINATION_IN_PROGRESS_STATUS_COLOR,
  REMARK_ELIMINATION_RETURNED_STATUS_COLOR,
  REMARK_ELIMINATION_SENT_STATUS_COLOR,
} from 'utils/constants'

// Status
export type EliminationStatus = 'CREATED' | 'SENT' | 'IN_PROGRESS' | 'COMPLETE' | 'DISCARDED' | 'RETURNED'
export type EliminationStatusRu =
  | 'Не установлено'
  | 'К устранению'
  | 'В работе'
  | 'Устранено'
  | 'Отклонено'
  | 'Не принято'

export const eliminationStatuses: EliminationStatusRu[] = ['В работе', 'Устранено', 'Отклонено']

export const eliminationStatusRuByEn: Record<EliminationStatus, EliminationStatusRu> = {
  CREATED: 'Не установлено',
  SENT: 'К устранению',
  IN_PROGRESS: 'В работе',
  COMPLETE: 'Устранено',
  DISCARDED: 'Отклонено',
  RETURNED: 'Не принято',
}

export const eliminationStatusEnByRu: Record<EliminationStatusRu, EliminationStatus> = {
  'Не установлено': 'CREATED',
  'К устранению': 'SENT',
  'В работе': 'IN_PROGRESS',
  Устранено: 'COMPLETE',
  Отклонено: 'DISCARDED',
  'Не принято': 'RETURNED',
}

export const eliminationStatusesColor: Record<EliminationStatus, LegendColors> = {
  CREATED: REMARK_ELIMINATION_CREATED_STATUS_COLOR,
  SENT: REMARK_ELIMINATION_SENT_STATUS_COLOR,
  IN_PROGRESS: REMARK_ELIMINATION_IN_PROGRESS_STATUS_COLOR,
  COMPLETE: REMARK_ELIMINATION_COMPLETE_STATUS_COLOR,
  DISCARDED: REMARK_ELIMINATION_DISCARDED_STATUS_COLOR,
  RETURNED: REMARK_ELIMINATION_RETURNED_STATUS_COLOR,
}
