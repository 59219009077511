import { Button, Stack } from '@mui/material'
import styled from 'styled-components'

export const MapFormButton = styled(Button)`
  justify-content: end;
  height: auto;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 13.2px;
  text-transform: none;

  &:hover {
    background-color: transparent;
  }
`

export const MapWrapper = styled(Stack)`
  flex: 1;
  height: 100%;

  & > iframe {
    height: 100%;
    border: 0;
    border-radius: 16px;
  }
`
