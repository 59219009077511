import { TableBody, TableContainer, TableHead, TableRow } from './Table.styles'
import { TableProps } from './Table.types'
import { TableBodyCell } from './components/TableBodyCell'
import { TableCell } from './components/TableCell'
import { TABLE_CELL_HEIGHT } from './components/TableCell/TableCell.styles'
import { Stack } from '@mui/material'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { FC, useRef } from 'react'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'

export const Table: FC<TableProps> = ({ api, tags, query, columns, onRowClick, queryArgs, startPage = 1 }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { queryData, onScrollWithInfiniteLoad, onRefetch, currentPage } = useInfiniteScroll({
    refElement: tableContainerRef,
    api,
    tagName: 'tags',
    limit: 99999,
    // limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query,
    startPage,
    arg: queryArgs,
  })

  const { data = [] } = queryData.data || {}
  const { isLoading } = queryData || {}

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef?.current!,
  })

  return (
    <>
      <TableContainer ref={tableContainerRef} onScroll={onScrollWithInfiniteLoad}>
        {!data.length && isLoading && (
          <Stack height='100%'>
            <Progress />
          </Stack>
        )}
        {!data.length && !isLoading && <EmptyPage data={{ text: 'Данные отсутствуют.', buttons: [] }} fullPage />}

        {!!data.length && !isLoading && (
          <>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow head style={{ zIndex: '10' }} key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} header={header} />
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody style={{ height: virtualizer.getTotalSize() }}>
              {virtualizer.getVirtualItems().map((row) => {
                const rowData = data[row.index]

                return (
                  <TableRow
                    key={row.key}
                    onClick={() => onRowClick(rowData)}
                    data-index={row.index}
                    ref={virtualizer.measureElement}
                    style={{
                      width: '100%',
                      transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                    }}
                  >
                    {columns.map((column, columnIndex) => {
                      const cell = rows[row.index].getVisibleCells()[columnIndex]

                      return <TableBodyCell key={column.id} column={column} cell={cell} />
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </>
        )}
      </TableContainer>
    </>
  )
}
