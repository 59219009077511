import { alpha, Box, IconButton, Stack } from '@mui/material'
import styled from 'styled-components'

export const ImageItemWrapper = styled(Box)`
  position: relative;
  width: fit-content;

  & img {
    border-radius: 16px;
  }
`

export const PdfWrapper = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 277px;
  height: 305px;

  background-color: ${(props) => alpha(props.theme.palette.primary.main, 0.08)};
  border-radius: 16px;
`

export const ImageWrapper = styled(Stack)`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const ImageItemDeleteButton = styled(IconButton)`
  padding: 4px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 6px;

  &:hover {
    background-color: ${(props) => props.theme.palette.bg.white};
  }
`
