import { RemarksAuditCell } from './AuditCell.styles'
import { AuditCellProps } from './AuditCell.types'
import HistoryIcon from '@mui/icons-material/History'
import { RemarkFull } from 'api/remarks/types'
import { FC, MouseEvent } from 'react'

export const AuditCell: FC<AuditCellProps> = ({ info, onAuditOpen }) => {
  const { row, column } = info
  const { minSize, size, maxSize } = column.columnDef
  const { id } = row.original
  const { primary: primaryId, number: remarkNumber } = row.original as RemarkFull

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onAuditOpen(id, row.index, primaryId, remarkNumber)
  }

  return (
    <RemarksAuditCell
      onClick={onClick}
      key={id}
      style={{
        minWidth: minSize,
        width: size,
        maxWidth: maxSize,
      }}
      className='display-cell audit-cell'
    >
      <HistoryIcon fontSize='medium' color='disabled' />
    </RemarksAuditCell>
  )
}
