import { Stack, Grid } from '@mui/material'
import styled from 'styled-components'

export const CardsFullWrapper = styled(Stack)`
  overflow-y: scroll;
  padding-right: 7px;
`

export const Cards = styled(Grid)`
  display: flex;
  margin-left: -20px;
  flex-wrap: wrap;
  margin-top: -20px;
  width: calc(100% + 20px);
`
