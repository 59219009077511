import { Stack } from '@mui/material'
import styled from 'styled-components'

export const HeaderWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none;

  @media (max-width: 1150px) {
    display: flex;
  }
`
