import { ButtonWrapper } from './AddMediaButton.styles'
import { AddMediaButtonProps } from './AddMediaButton.types'
import { FC } from 'react'

export const AddMediaButton: FC<AddMediaButtonProps> = ({ id, onChange, acceptedFormats, children }) => {
  return (
    <label htmlFor={id}>
      <input
        accept={acceptedFormats.join(',')}
        id={id}
        type='file'
        onChange={onChange}
        onClick={(e: React.BaseSyntheticEvent) => (e.target.value = '')}
        style={{ display: 'none' }}
      />
      <ButtonWrapper>{children}</ButtonWrapper>
    </label>
  )
}
