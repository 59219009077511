// Status
import { LegendColors } from 'core/types/global'

export type RegulationStatus = 'PROJECT' | 'ACTUAL' | 'PAUSED' | 'DISCARDED'
export type RegulationStatusRu = 'Проект' | 'Действующий' | 'Приостановлен' | 'Утратил силу'
export type RegulationStatusTab = 'all' | RegulationStatus

export const regulationStatuses: RegulationStatusRu[] = ['Проект', 'Действующий', 'Приостановлен', 'Утратил силу']

export const regulationStatusRuByEn: Record<RegulationStatus, RegulationStatusRu> = {
  PROJECT: 'Проект',
  ACTUAL: 'Действующий',
  PAUSED: 'Приостановлен',
  DISCARDED: 'Утратил силу',
}

export const regulationStatusEnByRu: Record<RegulationStatusRu, RegulationStatus> = {
  Проект: 'PROJECT',
  Действующий: 'ACTUAL',
  Приостановлен: 'PAUSED',
  'Утратил силу': 'DISCARDED',
}

export const regulationStatusesColor: Record<RegulationStatus, LegendColors> = {
  PROJECT: 'blue',
  ACTUAL: 'lightgreen',
  PAUSED: 'purple',
  DISCARDED: 'red',
}

// Type
export type RegulationType =
  | 'GOST'
  | 'GOST_R'
  | 'GOVERNMENT_DECREE'
  | 'FOIV_ORDER'
  | 'GOVERNMENT_ORDER'
  | 'EEK_BOARD_DECISION'
  | 'EEK_COUNCIL_DECISION'
  | 'SANPIN'
  | 'SN'
  | 'SP'
  | 'ST_SEV'
  | 'EAEU_TECHNICAL_REGULATIONS'
  | 'FEDERAL_LAW'
  | 'FNIP'

export type RegulationTypeRu =
  | 'ГОСТ'
  | 'ГОСТ Р'
  | 'Постановление правительства РФ'
  | 'Приказ ФОИВа'
  | 'Распоряжение правительства РФ'
  | 'Решение коллегии ЕЭК'
  | 'Решение Совета ЕЭК'
  | 'СанПиН'
  | 'СН'
  | 'СП'
  | 'СТ СЭВ'
  | 'Технический регламент ЕАЭС'
  | 'Федеральный закон'
  | 'ФНиП'

export const regulationTypes: RegulationTypeRu[] = [
  'ГОСТ',
  'ГОСТ Р',
  'Постановление правительства РФ',
  'Приказ ФОИВа',
  'Распоряжение правительства РФ',
  'Решение коллегии ЕЭК',
  'Решение Совета ЕЭК',
  'СанПиН',
  'СН',
  'СП',
  'СТ СЭВ',
  'Технический регламент ЕАЭС',
  'Федеральный закон',
  'ФНиП',
]

export const regulationTypeRuByEn: Record<RegulationType, RegulationTypeRu> = {
  GOST: 'ГОСТ',
  GOST_R: 'ГОСТ Р',
  GOVERNMENT_DECREE: 'Постановление правительства РФ',
  FOIV_ORDER: 'Приказ ФОИВа',
  GOVERNMENT_ORDER: 'Распоряжение правительства РФ',
  EEK_BOARD_DECISION: 'Решение коллегии ЕЭК',
  EEK_COUNCIL_DECISION: 'Решение Совета ЕЭК',
  SANPIN: 'СанПиН',
  SN: 'СН',
  SP: 'СП',
  ST_SEV: 'СТ СЭВ',
  EAEU_TECHNICAL_REGULATIONS: 'Технический регламент ЕАЭС',
  FEDERAL_LAW: 'Федеральный закон',
  FNIP: 'ФНиП',
}

export const regulationTypeEnByRu: Record<RegulationTypeRu, RegulationType> = {
  ГОСТ: 'GOST',
  'ГОСТ Р': 'GOST_R',
  'Постановление правительства РФ': 'GOVERNMENT_DECREE',
  'Приказ ФОИВа': 'FOIV_ORDER',
  'Распоряжение правительства РФ': 'GOVERNMENT_ORDER',
  'Решение коллегии ЕЭК': 'EEK_BOARD_DECISION',
  'Решение Совета ЕЭК': 'EEK_COUNCIL_DECISION',
  СанПиН: 'SANPIN',
  СН: 'SN',
  СП: 'SP',
  'СТ СЭВ': 'ST_SEV',
  'Технический регламент ЕАЭС': 'EAEU_TECHNICAL_REGULATIONS',
  'Федеральный закон': 'FEDERAL_LAW',
  ФНиП: 'FNIP',
}

// Body
export type RegulationBody =
  | 'EEK_BOARD'
  | 'MINISTRY_OF_CULTURE'
  | 'MINISTRY_OF_NATURE'
  | 'MINISTRY_OF_AGRICULTURE'
  | 'MINISTRY_OF_CONSTRUCTION'
  | 'MINISTRY_OF_TRANSPORT'
  | 'MINISTRY_OF_LABOR'
  | 'MINISTRY_OF_ENERGY'
  | 'MINISTRY_OF_EMERGENCIES'
  | 'GOVERNMENT'
  | 'PRESIDENT'
  | 'ROSAVIATION'
  | 'ROSPOTREBNADZOR'
  | 'ROSSTANDARD'
  | 'ROSTECHNADZOR'
  | 'EEK_COUNCIL'

export type RegulationBodyRu =
  | 'Коллегия ЕЭК'
  | 'Минкультуры России'
  | 'Минприроды России'
  | 'Минсельхоз России'
  | 'Минстрой России'
  | 'Минтранс России'
  | 'Минтруд России'
  | 'Минэнерго России'
  | 'МЧС России'
  | 'Правительство РФ'
  | 'Президент РФ'
  | 'Росавиация'
  | 'Роспотребнадзор'
  | 'Росстандарт'
  | 'Ростехнадзор'
  | 'Совет ЕЭК'

export const regulationBodies: RegulationBodyRu[] = [
  'Коллегия ЕЭК',
  'Минкультуры России',
  'Минприроды России',
  'Минсельхоз России',
  'Минстрой России',
  'Минтранс России',
  'Минтруд России',
  'Минэнерго России',
  'МЧС России',
  'Правительство РФ',
  'Президент РФ',
  'Росавиация',
  'Роспотребнадзор',
  'Росстандарт',
  'Ростехнадзор',
  'Совет ЕЭК',
]

export const regulationBodyRuByEn: Record<RegulationBody, RegulationBodyRu> = {
  EEK_BOARD: 'Коллегия ЕЭК',
  MINISTRY_OF_CULTURE: 'Минкультуры России',
  MINISTRY_OF_NATURE: 'Минприроды России',
  MINISTRY_OF_AGRICULTURE: 'Минсельхоз России',
  MINISTRY_OF_CONSTRUCTION: 'Минстрой России',
  MINISTRY_OF_TRANSPORT: 'Минтранс России',
  MINISTRY_OF_LABOR: 'Минтруд России',
  MINISTRY_OF_ENERGY: 'Минэнерго России',
  MINISTRY_OF_EMERGENCIES: 'МЧС России',
  GOVERNMENT: 'Правительство РФ',
  PRESIDENT: 'Президент РФ',
  ROSAVIATION: 'Росавиация',
  ROSPOTREBNADZOR: 'Роспотребнадзор',
  ROSSTANDARD: 'Росстандарт',
  ROSTECHNADZOR: 'Ростехнадзор',
  EEK_COUNCIL: 'Совет ЕЭК',
}

export const regulationBodyEnByRu: Record<RegulationBodyRu, RegulationBody> = {
  'Коллегия ЕЭК': 'EEK_BOARD',
  'Минкультуры России': 'MINISTRY_OF_CULTURE',
  'Минприроды России': 'MINISTRY_OF_NATURE',
  'Минсельхоз России': 'MINISTRY_OF_AGRICULTURE',
  'Минстрой России': 'MINISTRY_OF_CONSTRUCTION',
  'Минтранс России': 'MINISTRY_OF_TRANSPORT',
  'Минтруд России': 'MINISTRY_OF_LABOR',
  'Минэнерго России': 'MINISTRY_OF_ENERGY',
  'МЧС России': 'MINISTRY_OF_EMERGENCIES',
  'Правительство РФ': 'GOVERNMENT',
  'Президент РФ': 'PRESIDENT',
  Росавиация: 'ROSAVIATION',
  Роспотребнадзор: 'ROSPOTREBNADZOR',
  Росстандарт: 'ROSSTANDARD',
  Ростехнадзор: 'ROSTECHNADZOR',
  'Совет ЕЭК': 'EEK_COUNCIL',
}
