import { DocInfoRowLink, DocInfoRowText, DocInfoRowWrapper } from './DocInfoRow.styles'
import { DocInfoRowProps } from './DocInfoRow.types'
import { Typography } from '@mui/material'
import { FC } from 'react'

export const DocInfoRow: FC<DocInfoRowProps> = ({ title, text, direction = 'row', rows = 1, isLink }) => {
  return (
    <DocInfoRowWrapper direction={direction}>
      <Typography variant='body2' whiteSpace='pre-wrap'>
        {title}:
      </Typography>
      {isLink ? (
        <DocInfoRowLink href={text} target='_blank'>
          {text}
        </DocInfoRowLink>
      ) : (
        <DocInfoRowText variant='body2' rows={rows}>
          {text}
        </DocInfoRowText>
      )}
    </DocInfoRowWrapper>
  )
}
