import { StyledButton } from 'components/Button'
import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding: 20px 7px 20px 20px;
  overflow: auto;
  scrollbar-gutter: stable;
  height: 100%;
`
/**
 * начал использовать TextField as textarea и слетел шрифт,
 * просто обертка
 * не несёт смысла
 */
export const FormTextArea = styled.div`
  textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    resize: none;
  }
`
export const TextField = styled.input`
  padding: 12px 16px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border: 1px solid #5c6e8c1a;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.palette.text.dark};

  &:focus {
    outline: none;
  }
`

/**
 * Тупой стиль (без пропсов) для горизонтальной линии
 */
export const HorizontalLine = styled.div`
  background: ${({ theme }) => theme.palette.bg.gray};
  width: 80%;
  height: 1px;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 10%;
`
/**
 * Стиль для ссылки, за основу взяты стили от кнопки
 */
export const DownloadLinkAsButton = styled(StyledButton)`
  background: white;
  max-width: 100%;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin: auto;
  &:hover {
    background: inherit;
  }
`

/**
 * стиль для отображения ошибки формата документа
 */
export const IncorrectFormatBox = styled.span`
  margin-top: 3px !important;
  color: ${({ theme }) => theme.palette.error.main};
  padding-left: 28px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
`
