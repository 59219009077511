/**
 * Функция работает в обе стороны
 *
 * если на вход получает цифру, преобразует ее в строку, ставит вместо точки запятую, округляет,
 * оставляет два знака после запятой в любом случае,
 * даже если на конце ноль (запрос бизнеса, может регулироваться дополнительным пропсом, если добавить)
 *
 * если на вход войдет строка, заменяется запятая на точку, преобразуется в number
 *
 * @param {(number|string)} num
 * @return {(number|string)}
 */
export const replaceNumber = (num: number | string): number | string => {
  if (typeof num === 'number') {
    return (Math.round(num * 100) / 100).toFixed(2).replace('.', ',')
  }
  return parseFloat(num.replace(',', '.'))
}
