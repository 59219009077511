import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

export const IntegrationProjectsWrapper = styled(Stack)`
  height: 100%;
  overflow: scroll;
  position: relative;
  margin-right: -20px;
  padding-right: 7px;
  // overflow: scroll;
  //   padding: 5px 7px 5px 20px
`

export const IntegrationProjectItem = styled(Stack)`
  flex-direction: row;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

export const IntegrationProjectValue = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-right: 8px;
`
