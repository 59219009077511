import { PdfViewerModal, PdfViewerModalClose } from './PdfViewerPopup.styles'
import { PdfViewerPopupProps } from './PdfViewerPopup.types'
import CloseIcon from '@mui/icons-material/Close'
import { SpecialZoomLevel } from '@react-pdf-viewer/core'
import PdfViewer from 'components/PdfViewer/PdfViewer'
import { FC } from 'react'

export const PdfViewerPopup: FC<PdfViewerPopupProps> = ({ opened, viewerProps, onViewerClose }) => {
  if (!viewerProps) return null
  return (
    <PdfViewerModal open={opened} onClose={onViewerClose}>
      <>
        <PdfViewer {...viewerProps} disableMultiView rootViewerProps={{ defaultScale: SpecialZoomLevel.ActualSize }} />

        <PdfViewerModalClose onClick={onViewerClose}>
          <CloseIcon />
        </PdfViewerModalClose>
      </>
    </PdfViewerModal>
  )
}
