import styled from 'styled-components'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

export const RemarksListWrapper = styled(ScrollableContainer)`
  width: 340px;
  padding-right: 4px;
  gap: 4px;

  & .MuiDivider-root {
    width: 100%;
    height: 1px;
  }
`
