import { AnalyticsQueryData } from 'pages/Analytics/Analytics.types'

export interface GeneralWidgetChartProps {
  analyticsQueryData: AnalyticsQueryData
}

export const labelByRowType = {
  total: 'Всего:',
  expired: 'Просроченные:',
  workContinues: 'Без остановки работ:',
  workPaused: 'С остановкой работ:',
  completionActs: 'Акты об устранении нарушений:',
}

export const GENERAL_WIDGET_CHART_BAR_THICKNESS = 20
