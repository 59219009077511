import { Wrapper as InfoWrapper } from 'pages/Remarks/components/RemarkForm/Info/Info.styles'
import styled from 'styled-components'

export const Wrapper = styled(InfoWrapper)`
  width: 27.5%;
  border: none;
  padding: 0;

  @media (max-width: 1555px) {
    width: 49%;
  }

  @media (max-width: 1052px) {
    width: 99.5%;
    margin-right: 0;
  }
`
