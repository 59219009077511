import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Modal } from '@mui/material'
import styled from 'styled-components'

export const PdfViewerModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  & .pdf-wrapper {
    width: 1000px;
  }
`

export const PdfViewerModalClose = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;

  & .MuiSvgIcon-root {
    font-size: 48px !important;
    color: ${(props) => props.theme.palette.text.light};
  }
`
