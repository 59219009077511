import { DrawerTopBar } from '../../../Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { BindRemarksDrawerWrapper } from './BindRemarksDrawer.styles'
import { BindRemarksDrawerProps } from './BindRemarksDrawer.types'
import { BindRemarksForm } from './components/BindRemarksForm'
import { Drawer, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { FC, useEffect, useState } from 'react'
import { theme } from 'styles/theme'

export const BindRemarksDrawer: FC<BindRemarksDrawerProps> = ({ open, onClose, rootChoice }) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const [isInitialization, setIsInitialization] = useState<boolean>(true)

  const onFormChange = (dirty: boolean) => {
    // if (isInitialization) return;
    setDirty(dirty)
  }

  useEffect(() => {
    if (open) setIsInitialization(true)
    else setIsInitialization(false)
  }, [open])

  useEffect(() => {
    document.addEventListener('mouseup', () => setIsInitialization(false))

    return () => {
      document.removeEventListener('mouseup', () => setIsInitialization(false))
    }
  }, [])

  const onCloseClick = (value: boolean) => {
    if (value) onClose(dirty)
    else onClose(false)
  }

  return (
    <Drawer anchor='right' open={open} onClose={() => onCloseClick(true)}>
      <BindRemarksDrawerWrapper>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Добавление замечаний в предписание
          </Typography>
        </DrawerTopBar>

        <Divider />

        <BindRemarksForm
          onChange={onFormChange}
          onClose={(value) => onCloseClick(value)}
          rootChoice={rootChoice}
          localDirty={dirty}
        />
      </BindRemarksDrawerWrapper>
    </Drawer>
  )
}
