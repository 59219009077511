/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { Block, Field } from '../ChoreDrawer.style'
import { formatDate } from '../helper'
import { Typography } from '@mui/material'
import { useGetDropdownAssigmentQuery, useGetDropdownKsgObjectsQuery, useGetDropdownRdQuery } from 'api/ksg'
import { CreateWork } from 'api/ksg/api.types'
import { useGetProjectByIdQuery } from 'api/projects'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { compareAsc } from 'date-fns'
import { useFormikContext } from 'formik'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

const WorkInformation: FC<{ viewingOnly: boolean }> = ({ viewingOnly }) => {
  const { projectId: projectString } = useParams()
  const projectId = Number(projectString)

  /* Данные с БД о текущем проекте */
  const { data: project } = useGetProjectByIdQuery({ id: projectId })

  /* Данные с БД о видах работ */
  const { data: assigmentOptions } = useGetDropdownAssigmentQuery({ projectId })

  /* Данные с БД о кодах объекта */
  const { data: objectOptions } = useGetDropdownKsgObjectsQuery({ projectId })

  /* Данные с БД о разделах/марках РД */
  const { data: rdOptions } = useGetDropdownRdQuery({ projectId })

  const { values, setFieldValue } = useFormikContext<CreateWork>()

  /**
   * Динамически изменяемая переменная в зависимости от проекта,
   * и данных с формика о начале и конце работ
   */
  const dateOutOfProjectDate = useMemo(() => {
    const projectStartDate = project?.data?.startDate ? new Date(formatDate(project.data.startDate)) : null
    const projectEndDate = project?.data?.endDate ? new Date(formatDate(project.data.endDate)) : null

    if (!projectStartDate || !projectEndDate) return {}

    const startDate = (values.start || null) as Date | null
    const endDate = (values.end || null) as Date | null

    const startDateOutOfProjectDate =
      startDate &&
      (compareAsc(startDate.setHours(0, 0, 0, 0), projectStartDate.setHours(0, 0, 0, 0)) === -1 ||
        compareAsc(startDate.setHours(0, 0, 0, 0), projectEndDate.setHours(0, 0, 0, 0)) === 1)

    const endDateOutOfProjectDate =
      endDate &&
      (compareAsc(endDate.setHours(0, 0, 0, 0), projectStartDate.setHours(0, 0, 0, 0)) === -1 ||
        compareAsc(endDate.setHours(0, 0, 0, 0), projectEndDate.setHours(0, 0, 0, 0)) === 1)

    return {
      start: startDateOutOfProjectDate,
      end: endDateOutOfProjectDate,
    }
  }, [project, values.start, values.end])

  return (
    <Block>
      <Typography variant={'h1'}>Сведения о работе</Typography>
      <Block inner>
        <CustomSelect
          list={assigmentOptions?.data || []}
          name={'assignmentType'}
          placeholder={!viewingOnly ? 'Выберите из списка' : ''}
          free
          readOnly={viewingOnly}
          variant='textarea'
          label='Вид работ'
          style={{
            textAlign: 'start',
          }}
          labelProps={{
            style: { paddingLeft: 0 },
          }}
        />
        <CustomSelect
          list={objectOptions?.data || []}
          name={'object'}
          placeholder={!viewingOnly ? 'Выберите из списка' : ''}
          free
          readOnly={viewingOnly}
          label='Объект'
          style={{
            textAlign: 'start',
          }}
          labelProps={{
            style: { paddingLeft: 0 },
          }}
        />
        <Field textPercent={35} title={'Раздел/Марка'}>
          <CustomSelect
            list={rdOptions?.data || []}
            name={'rd'}
            placeholder={!viewingOnly ? 'Выберите из списка' : ''}
            free
            isRequired={true}
            readOnly={viewingOnly}
          />
        </Field>
        <Field textPercent={35} title={'Шифр документа'}>
          <FieldForm
            version='project'
            name='documentCypher'
            dataValue={values.documentCypher || ''}
            InputProps={{ style: { height: 30 } }}
            inputProps={{ style: { textAlign: 'right' }, readOnly: viewingOnly }}
            placeholder={!viewingOnly ? 'Введите значение' : ''}
            helperText=''
          />
        </Field>
        <Field textPercent={35} title={'ID работы'}>
          <FieldForm
            version='project'
            name='identifier'
            dataValue={values.identifier || ''}
            InputProps={{ style: { height: 30 } }}
            inputProps={{ style: { textAlign: 'right' }, readOnly: viewingOnly }}
            placeholder={!viewingOnly ? 'Введите значение' : ''}
          />
        </Field>
        <Field title={'Дата начала'} textPercent={60}>
          <FieldForm
            name={'start'}
            dataValue={values.start}
            onDataChange={(some: any) => {
              setFieldValue('start', some)
            }}
            className={dateOutOfProjectDate?.start ? 'out-of-project-range' : ''}
            placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
            InputProps={{ style: { height: 30 } }}
            helperText={''}
            version='date'
            dateFieldProps={{
              readOnly: viewingOnly,
            }}
          />
        </Field>
        <Field title={'Дата окончания'} textPercent={60}>
          <FieldForm
            name={'end'}
            dataValue={values.end}
            onDataChange={(some: any) => {
              setFieldValue('end', some)
            }}
            className={dateOutOfProjectDate?.end ? 'out-of-project-range' : ''}
            placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
            helperText={''}
            InputProps={{ style: { height: 30 } }}
            version='date'
            dateFieldProps={{
              readOnly: viewingOnly,
            }}
          />
        </Field>
      </Block>
    </Block>
  )
}

export default WorkInformation
