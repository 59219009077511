import {
  CreateDocumentRequest,
  CreateDocumentResponse,
  DeleteDocumentRequest,
  DeleteDocumentResponse,
  EditDocumentRequest,
  EditDocumentResponse,
  GetDocumentAuditRequest,
  GetDocumentAuditResponse,
  GetDocumentByIdRequest,
  GetDocumentByIdResponse,
  GetDocumentFilterTypesRequest,
  GetDocumentFilterTypesResponse,
  GetDocumentsRequest,
  GetDocumentsResponse,
  GetWorksDropdownForDocsResponse,
  UploadFileToDocumentRequest,
  UploadFileToDocumentResponse,
} from './api.types'
import { api } from 'api/api'
import { GetDropdownDataRequest } from 'api/remarks/api.types'
import { isEqual } from 'lodash'
import { setShouldResetPage } from 'store/slices/infiniteScroll'

export const documentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDocuments: build.query<GetDocumentsResponse, GetDocumentsRequest>({
      query: ({ projectId, ...params }) => {
        const localParams = { ...params }
        // @ts-ignore
        delete localParams.body

        return {
          url: `/documents/${projectId}/list`,
          method: 'POST',
          params: localParams,
          body: {
            type: params.body.length ? params.body : null,
          },
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        currentCache.total = newData.total

        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query
        const filterChanged = !isEqual(
          [...(currentArg?.body || [])].sort((a, b) => a - b),
          [...(previousArg?.body || [])].sort((a, b) => a - b),
        )

        const otherArgsChanged = queryChanged || filterChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: [{ type: 'Documents' }],
    }),
    getDocumentById: build.query<GetDocumentByIdResponse, GetDocumentByIdRequest>({
      query: ({ projectId, docId }) => ({
        url: `/documents/${projectId}/${docId}/get`,
        method: 'GET',
      }),
      // providesTags: ['Documents'],
    }),
    getWorksDropdownForDocs: build.query<GetWorksDropdownForDocsResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/documents/${projectId}/create/dropdown/type`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    createDocument: build.mutation<CreateDocumentResponse, CreateDocumentRequest>({
      query: ({ projectId, body }) => ({
        url: `/documents/${projectId}/create`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          // set should reset page
          dispatch(setShouldResetPage({ table: 'documents', shouldResetPage: true }))
        } catch {}
      },
    }),
    editDocument: build.mutation<EditDocumentResponse, EditDocumentRequest>({
      query: ({ projectId, documentId, body, chosenTypesIds }) => ({
        url: `/documents/${projectId}/${documentId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ projectId, documentId, chosenTypesIds, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedDocData } = await queryFulfilled
          const updatedDoc = updatedDocData.data

          // dispatch(
          //   documentsApi.util.invalidateTags(['Documents'])
          // )

          dispatch(
            documentsApi.util.updateQueryData('getDocuments', { projectId, body: chosenTypesIds }, (draft) => {
              const updatedWorkIndex = draft.data.findIndex((work) => work.id === updatedDoc.id)
              draft.data[updatedWorkIndex] = updatedDoc
            }),
          )

          dispatch(
            documentsApi.util.updateQueryData('getDocumentById', { projectId, docId: documentId }, (draft) => {
              Object.assign(draft, updatedDoc)
            }),
          )

          dispatch(documentsApi.util.invalidateTags([{ type: 'Documents', id: 'DOCUMENTS-FILTER' }]))
        } catch {}
      },
    }),
    uploadFileToDocument: build.mutation<UploadFileToDocumentResponse, UploadFileToDocumentRequest>({
      query: ({ projectId, documentId, file, chosenTypesIds }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/documents/${projectId}/${documentId}/update/file`,
          method: 'PUT',
          body: formData,
        }
      },
      async onQueryStarted({ projectId, documentId, chosenTypesIds, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedDocumentData } = await queryFulfilled
          const updatedDocument = updatedDocumentData.data

          dispatch(
            documentsApi.util.updateQueryData('getDocuments', { projectId, body: chosenTypesIds }, (draft) => {
              const updatedDocumentIndex = draft.data.findIndex((document) => document.id === updatedDocument.id)
              draft.data[updatedDocumentIndex] = updatedDocument
            }),
          )

          dispatch(
            documentsApi.util.updateQueryData('getDocumentById', { projectId, docId: documentId }, (draft) => {
              Object.assign(draft, updatedDocument)
            }),
          )
        } catch {}
      },
    }),
    deleteDocument: build.mutation<DeleteDocumentResponse, DeleteDocumentRequest>({
      query: ({ projectId, documentId }) => ({
        url: `/documents/${projectId}/${documentId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(documentsApi.util.invalidateTags(['Documents']))
        } catch {}
      },
    }),
    getDocumentAudit: build.query<GetDocumentAuditResponse, GetDocumentAuditRequest>({
      query: ({ projectId, docId, ...params }) => ({
        url: `/audit/${projectId}/document/${docId}`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
          docId: queryArgs.docId,
        }
      },
      merge: (currentCacheData, responseData, args) => {
        if (args.arg.page === 1) {
          return responseData
        }

        currentCacheData.data.push(...responseData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const projectChanged = currentArg?.projectId !== previousArg?.projectId

        const otherArgsChanged = projectChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Documents', { type: 'Documents', id: 'DOCUMENTS-AUDIT' }],
    }),
    getDocumentFilterTypes: build.query<GetDocumentFilterTypesResponse, GetDocumentFilterTypesRequest>({
      query: ({ projectId, ...params }) => {
        const localParams = { ...params }
        delete localParams.body

        return {
          url: `/documents/${projectId}/list/filters/type`,
          method: 'POST',
          params: localParams,
          body: {
            type: params.body.length ? params.body : null,
          },
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        currentCache.total = newData.total

        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query
        const filterChanged = !isEqual(
          [...(currentArg?.body || [])].sort((a, b) => a - b),
          [...(previousArg?.body || [])].sort((a, b) => a - b),
        )

        const otherArgsChanged = filterChanged || queryChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return (pageChanged && !filterChanged) || queryChanged
      },
      providesTags: ['Documents', { type: 'Documents', id: 'DOCUMENTS-FILTER' }],
    }),
  }),
  overrideExisting: false,
})
export const {
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useGetWorksDropdownForDocsQuery,
  useCreateDocumentMutation,
  useEditDocumentMutation,
  useUploadFileToDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentAuditQuery,
  useGetDocumentFilterTypesQuery,
} = documentsApi
