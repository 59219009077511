import { UserCardContentProps } from './UserCardContent.types'
import { UserCardHeader } from './UserCardHeader'
import { UserCardInfo } from './UserCardInfo'
import { Divider } from 'components/Divider'
import React from 'react'

export const UserCardContent: React.FC<UserCardContentProps> = ({ userData, employeeData, mode }) => {
  return (
    <>
      <UserCardHeader userData={userData} employeeData={employeeData} mode={mode} />
      <Divider />
      <UserCardInfo userData={userData} employeeData={employeeData} mode={mode} />
    </>
  )
}
