import { BindRemarksFormData } from '../BindRemarksForm'
import { ObjectItemTitle, ObjectItemWrapper } from './ObjectItem.styles'
import { ObjectItemProps } from './ObjectItem.types'
import { Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { cloneDeep } from 'lodash'
import { ChangeEvent, FC, MouseEvent, useEffect } from 'react'
import { theme } from 'styles/theme'

export const ObjectItem: FC<ObjectItemProps> = ({ object, objectList }) => {
  const { values: formValues, setFieldValue } = useFormikContext<BindRemarksFormData>()
  const { choice: formChoice, viewObjectId } = formValues
  const { includedObjects, excludedObjects } = formChoice

  const { id, title, total } = object

  const isObjectsAllSelected = formChoice.all

  const checked = formChoice.all ? !excludedObjects.includes(id) && !!includedObjects[id] : !!includedObjects[id]

  // const checked = !!includedObjects[id]

  const indeterminate = checked ? !!includedObjects[id]?.exclude?.length : !!includedObjects[id]?.include?.length

  // const selectedRemarksCount =
  //   (checked ? total - (includedObjects[id]?.exclude?.length || 0) : includedObjects[id]?.include?.length) || 0

  const selectedRemarksCount = includedObjects[id]?.include?.length || 0

  // objects (same in RemarkItem)
  const objectAddToInclude = (isObjectChecked: boolean) => {
    setFieldValue(`choice.includedObjects[${id}]`, { all: isObjectChecked, include: [], exclude: [] })
  }
  const objectRemoveFromInclude = () => {
    const dataForSet = { ...formChoice.includedObjects }

    delete dataForSet[id]

    setFieldValue('choice.includedObjects', dataForSet)
  }

  const objectAddToExclude = () => {
    const dataForSet = [...formChoice.excludedObjects]

    setFieldValue(`choice.excludedObjects`, [...dataForSet, id])
  }
  const objectRemoveFromExclude = () => {
    const dataForSet = [...formChoice.excludedObjects]

    const dataWithoutCheckedObject = dataForSet.filter((currentId) => currentId !== id)
    setFieldValue(`choice.excludedObjects`, dataWithoutCheckedObject)
  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const checked = e.target.checked
    let localChoice = cloneDeep(formChoice)

    delete localChoice.includedObjects[object.id]

    // objectRemoveFromInclude()

    if (checked) {
      const newIncludedObject = {
        [object.id]: { all: false, exclude: [], include: object.shortcomings.map((item) => item.shortcoming.id) },
      }
      localChoice = { ...localChoice, includedObjects: { ...localChoice.includedObjects, ...newIncludedObject } }
    }

    localChoice.all = objectList.every((item) => Object.keys(localChoice.includedObjects).includes(String(item.id)))
    // localChoice.all = objectList.length === Object.keys(localChoice.includedObjects).length
    setFieldValue('choice', localChoice)

    // checked
    //   ? (() => {
    //       objectRemoveFromInclude()
    //       objectRemoveFromExclude()
    //     })()
    //   : objectAddToExclude()
  }

  const onWrapperClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (viewObjectId !== id) {
      setFieldValue('viewObjectId', id)
    }
  }

  return (
    <ObjectItemWrapper onClick={onWrapperClick} selected={viewObjectId === id}>
      <Stack direction={'row'} alignItems={'center'}>
        <FieldForm
          version='checkbox'
          name={``}
          checkboxData={{
            label: <></>,
            checked,
            // indeterminate,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e),
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
        <ObjectItemTitle variant='subtitle2'>{title}</ObjectItemTitle>
      </Stack>

      <Typography
        variant='subtitle2'
        fontWeight={400}
        lineHeight='11px'
        color={theme.palette.secondary.gray}
        component='p'
      >
        {selectedRemarksCount > total ? total : selectedRemarksCount}/{total}
      </Typography>
    </ObjectItemWrapper>
  )
}
