import { Stack } from '@mui/material'
import styled from 'styled-components'

export const StatusLegendItem = styled(Stack)`
  flex-direction: row;
  align-items: center;
  padding: 6px 12px 6px 10px;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  border-radius: 100px;
`
