/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components'

export const OuterChart = styled.div`
  width: 130px;
  height: 130px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const InnerChart = styled.div`
  position: absolute;
`
