import { api } from '../api'
import { GetEmployeeRequest, GetEmployeeResponse, GetUserByIdRequest } from './api.types'
import { PublicUserProfile, UserProfile } from './types'

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<UserProfile, void>({
      query: () => '/profile/get',
      providesTags: ['Profile'],
    }),
    getUserById: build.query<PublicUserProfile, GetUserByIdRequest>({
      query: ({ userId }) => ({
        url: `/profile/${userId}/get`,
        method: 'GET',
      }),
    }),
    getEmployee: build.query<GetEmployeeResponse, GetEmployeeRequest>({
      query: ({ employeeId }) => ({
        url: `/profile/${employeeId}/get-employee`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
  }),
  overrideExisting: false,
})

export const { useGetProfileQuery, useGetEmployeeQuery, useGetUserByIdQuery } = profileApi
