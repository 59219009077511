import { MenuItem } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import styled from 'styled-components'

interface MultiSelectProps {
  bgColor?: string
  width?: string
}

export const MultiSelect = styled(FieldForm)<MultiSelectProps>`
  width: ${(props) => props.width || ''};

  & .MuiSelect-select {
    text-align: left;
    background-color: ${(props) => props.bgColor || props.theme.palette.bg.shades};
    width: 100%;
  }

  & .MuiSelect-select {
    padding: 8px 16px;
  }

  & .MuiSvgIcon-root {
    right: 14px;
    font-size: 16px;

    & path {
      fill: ${(props) => props.theme.palette.text.dark};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.3);
    border-radius: 6px;
  }
`

export const MultiSelectMenuItem = styled(MenuItem)`
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-selected {
    background-color: ${(props) => props.theme.palette.bg.white};
  }

  &.Mui-focusVisible,
  &.Mui-selected:hover {
    background-color: ${(props) => props.theme.palette.bg.gray} !important;
  }

  & .MuiCheckbox-root {
    margin-right: 16px;
    padding: 0;

    & .MuiSvgIcon-root {
      fill: ${(props) => props.theme.palette.primary.main};
    }
  }
`
