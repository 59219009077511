import { NewAuditItem } from '../NewAuditItem'
import { AuditInnerInfo } from '../NewAuditItem/components/AuditInfoItem/AuditInfoItem.types'
import { AuditStatusGraduation } from '../NewAuditItem/components/AuditStatusGraduation'
import { ReasonWrapper } from './AuditRemarkItem.styles'
import { AuditRemarkItemProps } from './AuditRemarkItem.types'
import {
  Person as PersonIcon,
  InfoOutlined as InfoOutlinedIcon,
  InsertDriveFile as FileIcon,
  Description as SecondRemarkIcon,
  Info as InfoIcon,
} from '@mui/icons-material'
import { Typography } from '@mui/material'
import { RemarkAuditAction, remarkStatusesClient } from 'api/remarks/types'
import { eliminationStatusRuByEn } from 'core/types/elimination'
import { userRolesEnToRu } from 'core/types/user'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { connectNames } from 'utils/connectNames'

export const AuditRemarkItem: FC<AuditRemarkItemProps> = ({ remarkAudit }) => {
  const {
    action,
    timestamp,
    user: userData,
    value: currentRemarkStatus,
    previous: previousRemarkStatus,
    contractorStatus,
    previousContractorStatus,
    prescription,
    shortcoming,
    qcReason,
    contractorReason,
    mailRecipients,
    responsible,
    previousResponsible,
  } = remarkAudit || {}
  const { role, user: user } = userData

  const remarkAuditTitleByAction: Record<RemarkAuditAction, string> = {
    CREATE: 'Создано замечание',
    STATUS: 'Изменен статус',
    CONTRACTOR_STATUS: 'Изменен статус устранения',
    CONTRACTOR_RESPONSIBLE: 'Изменен ответственный за устранение',
  }

  const userText = `${connectNames(user, true)}, ${userRolesEnToRu[role]}`

  const remarkInfoData: AuditInnerInfo[] = [
    { icon: <PersonIcon fontSize={'medium'} color={'secondary'} />, text: 'Автор', value: userText },
    {
      icon: <InfoIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Статус',
      value: (
        <AuditStatusGraduation
          previous={remarkStatusesClient[previousRemarkStatus]}
          current={remarkStatusesClient[currentRemarkStatus]}
        />
      ),
    },
    {
      icon: <FileIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Предписание',
      value: `№ ${prescription}`,
      hidden: !prescription,
    },
    {
      icon: <SecondRemarkIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Замечание',
      value: `№ ${shortcoming}`,
      hidden: !shortcoming,
    },
    {
      icon: <InfoOutlinedIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Устранение',
      value: (
        <AuditStatusGraduation
          previous={eliminationStatusRuByEn[previousContractorStatus]}
          current={eliminationStatusRuByEn[contractorStatus]}
        />
      ),
    },
    {
      icon: <PersonIcon fontSize={'medium'} color={'secondary'} />,
      text: 'Ответственный',
      value: (
        <AuditStatusGraduation previous={previousResponsible?.fullName || ''} current={responsible?.fullName || ''} />
      ),
      hidden: !responsible,
    },
  ]

  return (
    <NewAuditItem
      auditData={{
        title: remarkAuditTitleByAction[action],
        timestamp,
        mailRecipients,
        info: remarkInfoData,
      }}
    >
      {(contractorReason || qcReason) && (
        <ReasonWrapper>
          <Typography variant='body1' fontSize={14} color={theme.palette.secondary.gray}>
            Обоснование:
          </Typography>
          <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
            {contractorReason || qcReason}
          </Typography>
        </ReasonWrapper>
      )}
    </NewAuditItem>
  )
}
