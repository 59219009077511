import { RemarkSecondaryDrawerWrapper } from './RemarkSecondaryDrawer.styles'
import { RemarkSecondaryDrawerProps } from './RemarkSecondaryDrawer.types'
import { RemarkSecondaryForm } from './components/RemarkSecondaryForm'
import { Drawer, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const RemarkSecondaryDrawer: FC<RemarkSecondaryDrawerProps> = (props: RemarkSecondaryDrawerProps) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer anchor='right' open={props.isOpen} onClose={() => props.onClose(dirty)}>
      <RemarkSecondaryDrawerWrapper>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Создание повторного замечания
          </Typography>
        </DrawerTopBar>

        <Divider />
        <RemarkSecondaryForm
          onFormChange={onFormChange}
          onClose={props.onClose}
          localResponsibleList={props.localResponsibleList}
          initialValues={props.initialValues}
        />
      </RemarkSecondaryDrawerWrapper>
    </Drawer>
  )
}
