import { BindRemarksFormData } from '../BindRemarksForm'
import { RemarkItemCheckboxWrapper, RemarkItemDescription, RemarkItemWrapper } from './RemarkItem.styles'
import { RemarkItemProps } from './RemarkItem.types'
import { Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { ChangeEvent, FC } from 'react'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const RemarkItem: FC<RemarkItemProps> = ({ remarkData, remarkList, objectList }) => {
  const { values: formValues, setFieldValue } = useFormikContext<BindRemarksFormData>()
  const { choice: formChoice, viewObjectId } = formValues
  const { includedObjects, excludedObjects } = formChoice

  const { shortcoming: remark, chosen } = remarkData
  const { id, number, description, period, object } = remark
  const { id: objectId } = object
  const { fullDate } = parseResponseDate(period.dateEnd)

  const allObjectsChecked = false
  // const allObjectsChecked = formChoice.all

  const objectIsExcluded = excludedObjects.includes(objectId)
  const remarkIsIncluded = !!includedObjects[objectId]?.include?.includes(id)
  const remarkIsExcluded = includedObjects[objectId]?.exclude?.includes(id)

  // const objectChecked = formChoice.all
  //   ? !objectIsExcluded && (includedObjects[objectId] ? includedObjects[objectId]?.all : true)
  //   : includedObjects[objectId]?.all
  const objectChecked = formChoice.all ? !excludedObjects.includes(id) && !!includedObjects[id] : !!includedObjects[id]
  // const objectChecked = includedObjects[objectId]?.all

  const checked = objectChecked ? !remarkIsExcluded : remarkIsIncluded

  // objects (duplicate from ObjectItem)
  const objectAddToInclude = (isObjectChecked: boolean) => {
    setFieldValue(`choice.includedObjects[${objectId}]`, { all: isObjectChecked, include: [], exclude: [] })
  }
  const objectRemoveFromInclude = () => {
    const dataForSet = { ...formChoice.includedObjects }

    delete dataForSet[objectId]
    setFieldValue('choice.includedObjects', dataForSet)
    setFieldValue('choice.all', false)
  }

  const objectAddToExclude = () => {
    const dataForSet = [...formChoice.excludedObjects]

    setFieldValue(`choice.excludedObjects`, [...dataForSet, objectId])
  }
  const objectRemoveFromExclude = () => {
    const dataForSet = [...formChoice.excludedObjects]

    const dataWithoutCheckedObject = dataForSet.filter((currentId) => currentId !== objectId)
    setFieldValue(`choice.excludedObjects`, dataWithoutCheckedObject)
  }

  // remarks
  const currentIncludedObject = formChoice.includedObjects[objectId]

  const remarkAddToInclude = () => {
    const dataForSet = [...(currentIncludedObject?.include || [])]

    if (allObjectsChecked && !currentIncludedObject) {
      // Первое добавляемое замечание (объекта еще нет в included)
      objectRemoveFromExclude()
    }

    objectAddToInclude(false)
    setFieldValue(`choice.includedObjects[${objectId}].include`, [...dataForSet, id])
  }
  const remarkRemoveFromInclude = () => {
    const dataForSet = [...(currentIncludedObject?.include || [])]
    const dataWithoutRemovedRemark = dataForSet.filter((currentId) => currentId !== id)

    setFieldValue(`choice.includedObjects[${objectId}].include`, dataWithoutRemovedRemark)

    if (currentIncludedObject?.include?.length === 1) {
      // Удалил последнее замечание
      objectRemoveFromInclude()
    }

    if (allObjectsChecked && currentIncludedObject?.include?.length === 1) {
      // Удалил последнее замечание
      objectAddToExclude()
    }
  }

  const remarkAddToExclude = () => {
    const dataForSet = [...(currentIncludedObject?.exclude || [])]

    if (allObjectsChecked && !currentIncludedObject) {
      // Первое удаляемое замечание (объекта еще нет в included)
      objectAddToInclude(true)
    }

    setFieldValue(`choice.includedObjects[${objectId}].exclude`, [...dataForSet, id])
  }
  const remarkRemoveFromExclude = () => {
    const dataForSet = [...(currentIncludedObject?.exclude || [])]
    const dataWithoutAddedRemark = dataForSet.filter((currentId) => currentId !== id)

    setFieldValue(`choice.includedObjects[${objectId}].exclude`, dataWithoutAddedRemark)

    if (allObjectsChecked && currentIncludedObject?.exclude?.length === 1) {
      // Последнее удаляемое замечание
      objectRemoveFromInclude()
    }
  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const checked = e.target.checked

    if (checked && remarkList.length === (formValues.choice.includedObjects[viewObjectId!]?.include.length || 0) + 1) {
      const includedObjectsIds = Object.keys(formChoice.includedObjects)
      !objectChecked && includedObjectsIds.push(String(viewObjectId))

      setFieldValue(
        'choice.all',
        objectList.every((item) => includedObjectsIds.includes(String(item.id))),
      )
      // setFieldValue('choice.all', objectList.length <= Object.keys(formChoice.includedObjects).length + 1)
    }

    if (objectChecked) {
      checked ? remarkRemoveFromExclude() : remarkAddToExclude()
    } else {
      checked ? remarkAddToInclude() : remarkRemoveFromInclude()
    }
  }

  return (
    <RemarkItemWrapper>
      <RemarkItemCheckboxWrapper>
        <FieldForm
          version='checkbox'
          name={``}
          checkboxData={{
            label: (
              <Typography variant='body2' fontWeight={500} fontSize={16} color={theme.palette.text.dark}>
                Замечание {number}
              </Typography>
            ),
            checked,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e),
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
      </RemarkItemCheckboxWrapper>

      <Stack spacing={1} pl={3.75}>
        <Typography
          variant='subtitle2'
          fontWeight={400}
          fontSize='12px'
          lineHeight='11px'
          color={theme.palette.secondary.gray}
          component='p'
        >
          Установленная дата устранения: {fullDate}
        </Typography>

        <RemarkItemDescription variant='body2'>{description.custom || description.defect?.title}</RemarkItemDescription>
      </Stack>
    </RemarkItemWrapper>
  )
}
