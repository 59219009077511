import styled from 'styled-components'

interface IFileLabelProps {
  isDisabled?: boolean
}

export const FileLabel = styled.label<IFileLabelProps>`
  ${(props) =>
    props.isDisabled
      ? `
        pointer-events: none;
      `
      : `
        &:hover {
          cursor: pointer;
        }
      `}
`

export const HiddenInput = styled.input`
  display: none;
`
