import styled from 'styled-components'

export const CardWrapper = styled.div`
  padding: 10px;
  max-width: 25%;
  width: 100%;

  @media (max-width: 1605px) {
    max-width: calc(100% / 3);
  }

  @media (max-width: 1230px) {
    max-width: 50%;
  }

  @media (max-width: 820px) {
    max-width: 100%;
  }
`

export const Card = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-height: 156px;

  &:hover {
    cursor: pointer;
    box-shadow:
      0px 6px 6px -3px rgba(0, 0, 0, 0.2),
      0px 10px 14px 1px rgba(0, 0, 0, 0.14),
      0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
`

export const name = styled.div``

export const Type = styled.div`
  margin: 0 0 7px;
  padding: 4px 8px;
  background-color: ${(props) => props.color};
  border-radius: 4px;

  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.light};
`

export const Title = styled.span`
  text-align: start;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.primary.main};
`
