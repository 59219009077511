import { DashboardMainCard, MainCardTabs } from './MainCard.styles'
import { MainCardProps, MainCardView } from './MainCard.types'
import { AboutTabContent } from './components/AboutTabContent'
import { AboutTabImage } from './components/AboutTabImage'
import { IntegratedModule } from './components/IntegratedModule'
import { LinkItem, LinkItemProps } from './components/LinkItem'
import { MoreTabContent } from './components/MoreTabContent'
import { MoreTabImage } from './components/MoreTabImage'
import { Box, Stack, Tab, TabProps, Typography } from '@mui/material'
import { useGetIntegrationStatusByProjectQuery } from 'api/integrations'
import { BuildingsIcon } from 'assets/self-icons/BuildingsIcon'
import { ClipboardTextIcon } from 'assets/self-icons/ClipboardTextIcon'
import { DocumentsSelfIcon } from 'assets/self-icons/DocumentsSelfIcon'
import { StickyNoteIcon } from 'assets/self-icons/StickyNoteIcon'
import React, { FC, ReactElement, useMemo, useState } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const MainCard: FC<MainCardProps> = ({ dashboardProject }) => {
  const profile = useTypedSelector(profileSelector)
  /**
   * деструктуризация пропсов
   */
  const { project, addressData, responsiblePersons, images, shortName } = useMemo(() => {
    // if (dashboardProject) {
    const { project, addressData, responsiblePersons, images } = dashboardProject || {}
    const { shortName } = project || {}
    return { project, shortName, addressData, responsiblePersons, images }
    // }else{
    //   return {project:null,shortName:null, addressData:null, responsiblePersons:null, images:null}
    // }
  }, [dashboardProject])

  const [currentTab, setCurrentTab] = useState<MainCardView>('about')
  const onTabChange = (value: MainCardView) => {
    value !== currentTab && setCurrentTab(value)
  }

  const { data: integratedModulesData, isFetching } = useGetIntegrationStatusByProjectQuery({ projectId: project.id })
  const integratedModules = integratedModulesData?.data.global || []

  const tabsData: TabProps[] = [
    { value: 'about', label: 'О проекте' },
    { value: 'more', label: 'Ещё' },
  ]

  const LinkItemsData: LinkItemProps[] = [
    { text: 'КСГ', link: project ? `/project/${project.id}/ksg` : '', Icon: StickyNoteIcon, accesses: [] },
    {
      text: 'Объекты',
      link: project ? `/objects/${project.id}` : '',
      Icon: BuildingsIcon,
      accesses: ['admin', 'engineer_qc'],
    },
    {
      text: 'Справочники проекта',
      link: project ? `/project/${project.id}/references` : '',
      Icon: ClipboardTextIcon,
      accesses: ['admin', 'engineer_qc'],
    },
    {
      text: 'Документы',
      link: project ? `/project/${project.id}/documents` : '',
      Icon: DocumentsSelfIcon,
      accesses: [],
    },
  ]

  const ImageByTab: Record<MainCardView, ReactElement> = {
    about: <AboutTabImage addressData={addressData!} />,
    more: <MoreTabImage images={images || []} />,
  }

  const ContentByTab: Record<MainCardView, ReactElement> = {
    about: <AboutTabContent project={project} addressData={addressData} />,
    more: <MoreTabContent responsiblePersons={responsiblePersons} />,
  }

  const renderLinkItems = useMemo(() => {
    return LinkItemsData.filter((item) => item.accesses.includes(profile.role) || !item.accesses.length)
  }, [profile, project.id])

  return (
    <DashboardMainCard>
      <Stack alignItems='start' spacing={1.5}>
        <Stack
          width='100%'
          flex={1}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          gap={1.5}
          flexWrap={'wrap'}
        >
          <Box>
            <Typography variant='h1' color={theme.palette.primary.main}>
              {shortName}
            </Typography>
          </Box>

          <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <Stack direction={'row'} spacing={1.5}>
              {integratedModules.map((module) => (
                <IntegratedModule projectId={project.id} module={module} role={profile.role} />
              ))}
            </Stack>

            <MainCardTabs
              value={currentTab}
              onChange={(e: React.SyntheticEvent, tabValue: any) => onTabChange(tabValue as MainCardView)}
            >
              {tabsData.map((tabData) => (
                <Tab {...tabData} />
              ))}
            </MainCardTabs>
          </Stack>
        </Stack>

        <Stack direction='row' flex={1} spacing={2} width='100%' flexWrap={'wrap'}>
          {ImageByTab[currentTab]}
          {ContentByTab[currentTab]}
        </Stack>
      </Stack>

      <Stack flex={1} direction='row' justifyContent='space-between' alignItems='center' gap={2.5} flexWrap={'wrap'}>
        {renderLinkItems.map((item) => (
          <LinkItem {...item} />
        ))}
      </Stack>
    </DashboardMainCard>
  )
}
