import { ColoredStatus } from '../ColoredStatus'
import { InfoRowProps } from './InfoRow.types'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const InfoRow: FC<InfoRowProps> = ({ rowData: row, className }) => {
  const defaultColor: string = theme.palette.text.dark!

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' className={className}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <row.icon fontSize={row.iconProps?.fontSize || 'medium'} color={row.iconProps?.color || 'secondary'} />
        <Typography variant='body2' component='span' className='info-row-title'>
          {row.title}:
        </Typography>
      </Stack>
      {row.status ? (
        <ColoredStatus color={row.statusColor || 'error'}>
          <Typography variant='subtitle2' component='span' className='row-value'>
            {row.value}
          </Typography>
        </ColoredStatus>
      ) : (
        <Typography
          variant='subtitle2'
          component='span'
          color={row.color ?? defaultColor}
          textAlign='right'
          className='row-value'
        >
          {row.value}
        </Typography>
      )}
    </Stack>
  )
}
