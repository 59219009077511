import { RemarksTableCell } from '../../RemarksTable.styles'
import { alpha, Box, Stack } from '@mui/material'
import styled from 'styled-components'

export const RemarksDateDoneCell = styled(RemarksTableCell)``

export const DateDoneCellWrapper = styled(Box)`
  position: relative;
`

interface DeviationElementProps {
  value: number
  isEmpty: boolean
}

export const DeviationElement = styled(Stack)<DeviationElementProps>`
  position: absolute;
  top: -1px;
  left: ${(props) => (props.isEmpty ? '15px' : '75px')};
  place-content: center;
  height: 20px;
  padding: 5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
  color: ${(props) => (props.value > 0 ? props.theme.palette.error.main : props.theme.palette.success.main)};
  background-color: ${(props) =>
    props.value > 0 ? alpha(props.theme.palette.table.orange, 0.1) : alpha(props.theme.palette.table.green, 0.15)};
  border-radius: 8px;
`
