import { UseQuery, UseQueryOptions, UseQueryResult } from 'api/api.types'
import { regulationsApi } from 'api/regulations'
import React, { ReactHTMLElement, RefObject, UIEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { clearShouldResetPage, setShouldResetPage } from 'store/slices/infiniteScroll'
import { RootState, useAppDispatch, useTypedSelector } from 'store/store'
import { throttle } from 'throttle-debounce'

export interface UseInfiniteScrollProps<Res, Req> {
  refElement?: RefObject<any>
  api?: any
  tagName?: string
  limit: number
  query: UseQuery<Res, Req>
  arg: Req
  startPage?: number
  options?: UseQueryOptions
  shouldResetPageSelector?: (state: RootState) => boolean
  threshold?: number
}

export interface UseInfiniteScrollData<Res> {
  onScrollWithInfiniteLoad: throttle<(e: UIEvent<HTMLDivElement>) => void>
  queryData: UseQueryResult<Res>
  hasNextPage: boolean
  onRefetch: () => void
  currentPage: number
}

export const useInfiniteScroll = <Res, Req>({
  refElement,
  api,
  tagName,
  limit,
  query,
  arg,
  startPage,
  options,
  shouldResetPageSelector = () => false,
  threshold = 800,
}: UseInfiniteScrollProps<Res, Req>): UseInfiniteScrollData<Res> => {
  const dispatch = useAppDispatch()
  const shouldResetPage = useTypedSelector(shouldResetPageSelector)

  const [page, setPage] = useState<number>(startPage || 1)
  const [isPageSaved, setIsPageSaved] = useState(false)

  useEffect(() => {
    if (startPage && startPage === page && !isPageSaved) {
      // @ts-ignore
      const isDataExisted = Array.isArray(queryData.data?.data) && queryData.data?.data?.length
      if (isDataExisted) {
        setIsPageSaved(true)
        return
      }
    }

    refElement && refElement?.current?.scrollTo({ top: 0 })
    setPage(() => 1)
  }, [...(Object.values(arg || {}) || [])])

  useEffect(() => {
    if (shouldResetPage) {
      refElement && refElement?.current?.scrollTo({ top: 0 })

      page === 1 ? dispatch(api.util.invalidateTags([{ type: tagName }])) : setPage(() => 1)

      dispatch(clearShouldResetPage())
    }
  }, [shouldResetPage])

  const fetchNextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
  }, [])

  const queryData = query(
    {
      ...arg,
      num: limit,
      page,
    },
    options,
  )

  // @ts-ignore
  const hasNextPage = Array.isArray(queryData.data?.data) && queryData.data?.data?.length === page * limit
  const loading = queryData.isFetching

  const debouncedOnScroll = useCallback(
    throttle(300, (e: UIEvent<HTMLDivElement>) => fetchMoreOnBottomReached(e.target as HTMLDivElement), {
      noTrailing: true,
    }),
    [loading],
  )

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        if (scrollHeight - scrollTop - clientHeight < threshold && !loading && hasNextPage) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, loading, hasNextPage],
  )

  const onRefetch = () => {
    queryData.refetch()
  }

  return {
    onScrollWithInfiniteLoad: debouncedOnScroll,
    queryData,
    hasNextPage,
    onRefetch,
    currentPage: page,
  }
}
