import { ItemWrapper } from '../RemarkForm.styles'
import { Stack } from '@mui/material'
import { FormTitledWrapper } from 'pages/Administration/ProjectManagement/components/FormLeftBlock'
import styled from 'styled-components'

export const Wrapper = styled(ItemWrapper)`
  width: 34%;
  position: relative;
  min-height: 410px;

  @media (max-width: 1555px) {
    width: 99.5%;
    margin-top: 20px;
  }
`

export const ImagesTitledWrapper = styled(FormTitledWrapper)`
  width: fit-content;

  & .stack-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    & > div {
      margin: 0;
    }
  }
`

export const RemarkMediaList = styled(Stack)`
  overflow: auto;
  scrollbar-gutter: stable;
  margin-right: -20px;
  padding-right: 7px;
`
