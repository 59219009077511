import styled from 'styled-components'

export const TableHeadWrapper = styled.div`
  padding-right: 13px;
  background-color: ${(props) => props.theme.palette.secondary.gray};
  border-radius: 8px 8px 0 0;
`

export const TableHead = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const Head = styled.thead``

export const Row = styled.tr``

interface CellProps {
  cellType: string
  widthCell?: string
}

export const Cell = styled.th<CellProps>`
  padding: 11px 16px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.theme.palette.text.light};

  width: ${(props) =>
    ((props.cellType === 'PROJECT_MEMBER' || props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE') && '19.55%') ||
    (props.cellType === 'DEFECT' && '25%') ||
    (props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE' && '25%') ||
    props.widthCell ||
    '32.5%'};

  &:first-child {
    width: ${(props) =>
      ((props.cellType === 'PROJECT_MEMBER' || props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE') && '21.8%') ||
      (props.cellType === 'DEFECT' && '27.3%') ||
      (props.cellType === 'PROJECT_MEMBER_REPRESENTATIVE' && '27.3%') ||
      props.widthCell ||
      '35%'};
    padding-left: ${(props) => (props.widthCell ? '16px' : '56px')};
  }
`

export const TableBodyWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // scrollbar-gutter: stable;
  // background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 0 0 8px 8px;
`

export const TableBody = styled.table`
  border-collapse: collapse;
  width: 100%;
`
export const Body = styled.tbody``
