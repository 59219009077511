/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { FieldItem } from '../../../../Remarks/components/RemarkForm/components/FieldItem'
import { Button } from 'components/Button'
import { Form } from 'formik'
import styled from 'styled-components'

export const Colontitul = styled.div<{ top: boolean }>`
  display: flex;
  gap: 16px;
  padding: 20px;
  ${({ top, theme: { palette } }) => {
    if (top) {
      return `
        border-bottom:1px solid ${palette.legends.gray};
        align-items: center;
        justify-content: center;`
    } else {
      return `
        box-shadow: 0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.20);
        align-items: center;
        justify-content: flex-start;`
    }
  }}
`

export const WrapperDrower = styled(Form)`
  // min-width: 981px;
  width: 100%;
  display: grid;
  height: 100%;
  grid-template-rows: 64px 1fr 80px;
  gap: 20px;
`
export const ListWrapper = styled.div`
  display: flex;
  justify-content: space-berween;
  gap: 20px;
  padding: 0 20px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`
export const List = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: stretch;
`
export const Item = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 40px;
  border: 1px solid ${({ theme }) => theme.palette.legends.gray};
  // margin: 0 20px;
  flex: 1;
`
export const Field = styled(FieldItem)<{ textPercent?: number }>`
  & > div:nth-child(1) {
    width: ${({ textPercent }) => (textPercent ? `${textPercent}%` : 'inherit')} !important;
  }
  & > div:nth-child(2) {
    width: ${({ textPercent }) => (textPercent ? `${100 - textPercent}%` : 'inherit')} !important;
  }
`

export const Block = styled.div<{ inner?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ inner }) => (inner ? 15 : 12)}px;
`
export const BtnIcon = styled(Button)`
  display: flex;
  gap: 8px;
`
