import { Stack } from '@mui/material'
import { InnerContainer } from 'layouts/MainLayout'
import styled from 'styled-components'

export const Container = styled(InnerContainer)`
  padding-top: 10px;
  padding-right: 20px;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1150px) {
    padding: 10px 20px 70px 20px;
  }
`

export const ContainerHeader = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none;

  @media (max-width: 1150px) {
    display: flex;
  }
`
