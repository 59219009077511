import { DocumentViewingFormData, DocumentViewingFormProps } from './DocumentViewingForm.types'
import { DocumentViewingItem } from './components/DocumentViewingItem'
import { validationDocumentViewing } from './validation'
import { Stack } from '@mui/material'
import {
  prescriptionsApi,
  useDeleteEliminationResultsMutation,
  useDeleteFilesFromActsMutation,
  useGetEliminationResultsQuery,
  useUploadFilesToActsMutation,
} from 'api/prescriptions'
import { Button } from 'components/Button'
import { Progress } from 'components/Progress'
import { FieldArray, Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { RemarkWarning } from 'pages/Prescriptions/components/PrescriptionsForm/components/Remarks/Remarks.styles'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'

export const DocumentViewingForm: FC<DocumentViewingFormProps> = ({ viewingOnly, onFormChange, onClose }) => {
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [docWasDeleted, setDocWasDeleted] = useState(false)

  const [uploadFilesToActs, { isLoading: isFilesUploading, ...uploadFilesToActsResponse }] =
    useUploadFilesToActsMutation()
  const [deleteFilesFromActs, { isLoading: isFilesDeleting, ...deleteFilesFromActsResponse }] =
    useDeleteFilesFromActsMutation()

  const isQuerying = isFilesUploading || isFilesDeleting
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  useEffect(() => {
    return () => {
      docWasDeleted &&
        dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
    }
  }, [docWasDeleted])

  const { queryData, onScrollWithInfiniteLoad } = useInfiniteScroll({
    api: prescriptionsApi,
    tagName: 'Prescriptions',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetEliminationResultsQuery,
    arg: {
      projectId,
      prescriptionId,
    },
  })
  const { data = [] } = queryData.data || {}
  const { isLoading } = queryData || {}

  const initialValues: DocumentViewingFormData = useMemo(
    () => ({
      initialDocs: data,
      // docIdsToDelete: [],
      externalFilesForCreate: {},
      internalFilesForCreate: {},
      filesIdsToDelete: {},
    }),
    [data],
  )

  const onSubmit = useCallback((values: DocumentViewingFormData) => {
    const { internalFilesForCreate, filesIdsToDelete } = values

    // if (docIdsToDelete.length) deleteEliminationResults({ projectId, prescriptionId, docIds: docIdsToDelete })

    if (Object.keys(internalFilesForCreate).length)
      uploadFilesToActs({ projectId, prescriptionId, internalFilesForCreate })

    if (Object.keys(filesIdsToDelete).length) deleteFilesFromActs({ projectId, prescriptionId, filesIdsToDelete })
  }, [])

  const { formik } = useForm({
    validationSchema: validationDocumentViewing,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, dirty, isValid } = formik

  const dirtyWithoutInitialDocs = useMemo(() => {
    const { initialDocs, ...localInitialValues }: Partial<DocumentViewingFormData> = { ...initialValues }
    const { initialDocs: valuesDocs, ...localValues }: Partial<DocumentViewingFormData> = { ...values }

    return !isEqual(localInitialValues, localValues)
  }, [initialValues, values])

  useEffect(() => {
    onFormChange(dirtyWithoutInitialDocs)
  }, [dirtyWithoutInitialDocs])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    uploadFilesToActsResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Файлы успешно добавлены.', { variant: 'success' })
      setIsFormLoading(false)
    },
    () => handleError(),
  )

  useMutationHandlers(
    deleteFilesFromActsResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Файлы успешно изменены.', { variant: 'success' })
      setIsFormLoading(false)
    },
    () => handleError(),
  )

  return (
    <Stack height={'100%'} overflow={'auto'}>
      <FormikProvider value={formik}>
        <Stack component={Form} height={'100%'} justifyContent={'space-between'} overflow={'auto'}>
          <Stack spacing={2.5} height={'100%'} overflow={'auto'}>
            <FormWrapper
              onScroll={onScrollWithInfiniteLoad}
              spacing={1.25}
              padding={'0 7px 0 20px !important'}
              height={'100%'}
            >
              {isLoading ? (
                <Progress />
              ) : values.initialDocs.length ? (
                <FieldArray
                  name='initialDocs'
                  render={({ remove }) => (
                    <>
                      {values.initialDocs.map((doc, index) => (
                        <DocumentViewingItem
                          key={doc.id}
                          doc={doc}
                          viewingOnly={viewingOnly}
                          onRemove={() => remove(index)}
                          setDocWasDeleted={setDocWasDeleted}
                        />
                      ))}
                    </>
                  )}
                />
              ) : (
                <RemarkWarning variant='body1'>Документы отсутствуют.</RemarkWarning>
              )}
            </FormWrapper>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width={'100%'}>
                {!viewingOnly ? (
                  <Button
                    type='submit'
                    disabled={!dirtyWithoutInitialDocs || !isValid}
                    loading={isFormLoading}
                    color='success'
                    width={'50%'}
                    size='medium'
                    fullWidth
                  >
                    Сохранить
                  </Button>
                ) : (
                  <div style={{ width: '50%' }}></div>
                )}
                <Button width={'50%'} size='medium' fullWidth onClick={() => onClose(dirty)}>
                  {viewingOnly ? 'Закрыть' : 'Отменить'}
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>
    </Stack>
  )
}
