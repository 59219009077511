import {
  ClearRepresentativesByProjectRequest,
  CreateRepresentativeByProjectRequest,
  CreateRepresentativeByProjectResponse,
  CreateRepresentativeRequest,
  CreateRepresentativeResponse,
  DeleteRepresentativeByProjectRequest,
  DeleteRepresentativeByProjectResponse,
  DeleteRepresentativeRequest,
  DeleteRepresentativeResponse,
  EditRepresentativeByProjectRequest,
  EditRepresentativeByProjectResponse,
  EditRepresentativeRequest,
  EditRepresentativeResponse,
  GetDropdownProjectMembersByProjectRequest,
  GetDropdownProjectMembersByProjectResponse,
  GetDropdownProjectMembersResponse,
  GetDropdownRepresentativesByProjectRequest,
  GetDropdownRepresentativesByProjectResponse,
  GetDropdownRepresentativesRequest,
  GetDropdownRepresentativesResponse,
  GetRepresentativesByProjectRequest,
  GetRepresentativesByProjectResponse,
  GetRepresentativesRequest,
  GetRepresentativesResponse,
} from './api.types'
import { api } from 'api/api'

export const representativesApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Admin mode
    getRepresentatives: build.query<GetRepresentativesResponse, GetRepresentativesRequest>({
      query: ({ ...params }) => {
        return {
          method: 'GET',
          url: `/project-member-representative/list`,
          params: params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          referenceId: 'representatives',
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged

        if (currentArg && otherArgsChanged) currentArg.page = 1

        return pageChanged || otherArgsChanged
      },
      providesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    clearRepresentatives: build.mutation<void, void>({
      query: () => ({
        url: '/project-member-representative/delete/all',
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    getDropdownProjectMembers: build.query<GetDropdownProjectMembersResponse, void>({
      query: () => ({
        url: `/project-member-representative/create/project-members`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    getDropdownRepresentatives: build.query<GetDropdownRepresentativesResponse, GetDropdownRepresentativesRequest>({
      query: ({ role }) => ({
        url: `/project-member-representative/create/user`,
        params: { role, page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    createRepresentative: build.mutation<CreateRepresentativeResponse, CreateRepresentativeRequest>({
      query: ({ body }) => ({
        url: '/project-member-representative/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    editRepresentative: build.mutation<EditRepresentativeResponse, EditRepresentativeRequest>({
      query: ({ representativeId, body }) => ({
        url: `/project-member-representative/${representativeId}/update`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    deleteRepresentative: build.mutation<DeleteRepresentativeResponse, DeleteRepresentativeRequest>({
      query: ({ representativeId }) => ({
        url: `/project-member-representative/${representativeId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),

    // Project mode
    getRepresentativesByProject: build.query<GetRepresentativesByProjectResponse, GetRepresentativesByProjectRequest>({
      query: ({ projectId, ...params }) => {
        return {
          method: 'GET',
          url: `/project-member-representative/${projectId}/list`,
          params: params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged

        if (currentArg && otherArgsChanged) currentArg.page = 1

        return pageChanged || otherArgsChanged
      },
      providesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    clearRepresentativesByProject: build.mutation<void, ClearRepresentativesByProjectRequest>({
      query: ({ projectId }) => ({
        url: `/project-member-representative/${projectId}/delete/all`,
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    getDropdownProjectMembersByProject: build.query<
      GetDropdownProjectMembersByProjectResponse,
      GetDropdownProjectMembersByProjectRequest
    >({
      query: ({ projectId }) => ({
        url: `/project-member-representative/${projectId}/create/project-member`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References', { type: 'References', id: 'PROJECT_MEMBERS' }],
    }),
    getDropdownRepresentativesByProject: build.query<
      GetDropdownRepresentativesByProjectResponse,
      GetDropdownRepresentativesByProjectRequest
    >({
      query: ({ role, projectId }) => ({
        url: `/project-member-representative/${projectId}/create/user`,
        params: { role, page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    createRepresentativeByProject: build.mutation<
      CreateRepresentativeByProjectResponse,
      CreateRepresentativeByProjectRequest
    >({
      query: ({ projectId, body }) => ({
        url: `/project-member-representative/${projectId}/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    editRepresentativeByProject: build.mutation<
      EditRepresentativeByProjectResponse,
      EditRepresentativeByProjectRequest
    >({
      query: ({ projectId, representativeId, body }) => ({
        url: `/project-member-representative/${projectId}/${representativeId}/update`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
    deleteRepresentativeByProject: build.mutation<
      DeleteRepresentativeByProjectResponse,
      DeleteRepresentativeByProjectRequest
    >({
      query: ({ projectId, representativeId }) => ({
        url: `/project-member-representative/${projectId}/${representativeId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'REPRESENTATIVES' }],
    }),
  }),
})

export const {
  // Admin mode
  useGetRepresentativesQuery,
  useClearRepresentativesMutation,
  useGetDropdownProjectMembersQuery,
  useLazyGetDropdownRepresentativesQuery,
  useCreateRepresentativeMutation,
  useEditRepresentativeMutation,
  useDeleteRepresentativeMutation,

  // Project mode
  useGetRepresentativesByProjectQuery,
  useClearRepresentativesByProjectMutation,
  useGetDropdownProjectMembersByProjectQuery,
  useLazyGetDropdownRepresentativesByProjectQuery,
  useCreateRepresentativeByProjectMutation,
  useEditRepresentativeByProjectMutation,
  useDeleteRepresentativeByProjectMutation,
} = representativesApi
