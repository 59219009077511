import { DocInfoRowProps } from './DocInfoRow.types'
import { Stack, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

const DocInfoRowTextRows = css<DocInfoRowTextProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.rows};
  -webkit-box-orient: vertical;
  white-space: normal;
`

interface DocInfoRowTextProps extends Pick<DocInfoRowProps, 'rows'> {}

export const DocInfoRowText = styled(Typography)<DocInfoRowTextProps>`
  color: ${(props) => props.theme.palette.text.dark};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${(props) => props?.rows && props.rows > 1 && DocInfoRowTextRows}
`

interface DocInfoRowWrapperProps extends Pick<DocInfoRowProps, 'direction'> {}

export const DocInfoRowWrapper = styled(Stack)<DocInfoRowWrapperProps>`
  flex-direction: ${(props) => props.direction};
  justify-content: space-between;
  gap: 8px;
  align-items: ${(props) => props.direction === 'row' && 'center'};

  ${DocInfoRowText} {
    max-width: ${(props) => props.direction === 'row' && '200px'};
    padding-left: ${(props) => props.direction === 'column' && '4px'};
  }
`

interface DocInfoRowLinkProps extends Pick<DocInfoRowProps, 'isLink'> {}

export const DocInfoRowLink = styled.a<DocInfoRowLinkProps>`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.light};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`
