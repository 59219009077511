import { Stack } from '@mui/material'
import styled from 'styled-components'

export const ProjectChartWrapper = styled(Stack)`
  height: 160px;
  width: 160px;
  position: relative;
`

export const TotalCountWrapper = styled(Stack)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
