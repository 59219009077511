import { ImportDrawerProps } from './ImportDrawer.types'
import { useGetObjectsLinkExampleMutation, useUploadObjectsMutation } from 'api/objects'
import { UploadDrawer } from 'components/UploadDrawer'
import { ExcelUploadResponse } from 'core/types/global'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const ImportDrawer: FC<ImportDrawerProps> = ({ isOpen, onClose }) => {
  const [files, setFiles] = useState<File[] | null>(null)
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [uploadObjects, { isLoading, ...uploadObjectsResponse }] = useUploadObjectsMutation()
  const [getObjectsLinkExample, getObjectsLinkExampleResponse] = useGetObjectsLinkExampleMutation()

  useEffect(() => {
    if (files?.length && files[0] instanceof File) {
      uploadObjects({
        projectId,
        file: files[0],
      })
    }
  }, [files])

  useMutationHandlers(uploadObjectsResponse, (data) => {
    setResponseData(data)
  })

  useMutationHandlers(getObjectsLinkExampleResponse, (data) => {
    window.location.href = data.data
  })

  const onFormSubmit = useCallback((files: File[]) => {
    setFiles(files)
  }, [])

  const onFormChange = useCallback(() => {
    setResponseData(undefined)
  }, [])

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    !dirty ? onClose() : openConfirm()
    setResponseData(undefined)
    setFiles(null)
  }, [])

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  return (
    <>
      <ConfirmDialog />
      <UploadDrawer
        open={isOpen}
        mode='single'
        onSubmit={onFormSubmit}
        responseData={responseData}
        onChange={onFormChange}
        onClose={onDrawerClose}
        title='Загрузка объектов'
        text='Вы можете добавить справочник с помощью exсel документа с уже подготовленными данными.'
        attentionText='В случае если у Вас будут совпадения названий существующих объектов с новыми из файла, новые значения заменят существующие.
              Это действие нельзя отменить.'
        uploadData={{
          title: 'Загрузить xls(х)',
          text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
          formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
        }}
        loading={isLoading}
        getExampleLink={getObjectsLinkExample}
      />
    </>
  )
}
