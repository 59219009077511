import { HeaderWrapper } from './HeaderDocument.styles'
import { IHeaderDocumentProps } from './HeaderDocument.types'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { Button } from 'components/Button'
import { FC } from 'react'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const HeaderDocument: FC<IHeaderDocumentProps> = ({ onEditClick, onReturnClick, children }) => {
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)

  return (
    <HeaderWrapper>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Button onClick={onReturnClick} variant='outlined' color='secondary' icon>
          <ArrowBackIcon color='primary' />
        </Button>
        {children}
      </Stack>
      {isUserHighAccess && (
        <Button onClick={onEditClick} variant='outlined' color='secondary'>
          Редактировать
        </Button>
      )}
    </HeaderWrapper>
  )
}
